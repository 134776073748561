import React, { useEffect, useState } from 'react';
import { Keyboard, Platform } from 'react-native';
import { Text, Box, Pressable } from 'native-base';
import { theme } from '../../config';

import { useNavigation } from '@react-navigation/core';
import { Button, Icon, TextBox } from '../../ui';
import { Promt } from '../../ui/Promt';
import { Loading } from '../../ui/Loading';
import { useMutation, useQuery } from '@apollo/client';
import { ScrollView } from 'react-native-gesture-handler';
import { User, USER_EDIT_INFO, USER_ME } from '../../stores/queries/user';
import { EditBox } from '../../ui/EditBox';
import { BlurModal } from '../../ui/BlurModal';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { LinearGradient } from 'expo-linear-gradient';
import { T } from '../../config/localization';
import { AddressSelector } from '../../components/Profile/AddressSelector';

interface AddressesProps {
  navigation: any;
}

const Addresses = (props: AddressesProps) => {
  return (
    <Box bg={theme.vars.colors.light[0]} flex={1} style={{ alignItems: 'center', width: '100%' }}>
      <Box
        style={{
          maxWidth: 1024,
          width: '100%',
          alignItems: 'center',
        }}
        flex={1}
      >
        <AddressSelector />
      </Box>
    </Box>
  );
};

export { Addresses };
