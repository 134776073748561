import { LOG } from '../../config';
import { DB_AUTH, ACTUAL_AUTH } from '../../stores/db/auth';
import { fetchNewAccessToken, isTokenValid, logout } from './auth';
import { onErrorRefreshTokenLinkCreator } from './onErrorRefreshTokenLinkCreator';

const log = LOG.extend('APOLLOCLIENT');

// Configurazione middleware per gestione autenticazione e refresh tokens

const onErrorRefreshTokenLink = onErrorRefreshTokenLinkCreator({
  authorizationHeaderKey: 'authentication',
  fetchNewAccessToken: async (refreshToken: string) => {
    const tokens = await fetchNewAccessToken(refreshToken);
    return tokens;
  },
  getAccessToken: () => {
    return ACTUAL_AUTH.accessToken;
  },
  getRefreshToken: () => {
    return ACTUAL_AUTH.refreshToken;
  },
  isAccessTokenValid: accessToken => isTokenValid(accessToken),
  isUnauthenticatedError: (graphQLError, operationName) => {
    const { extensions, message } = graphQLError;
    log.warn('GRAPHQL QUERY ERROR | ' + operationName + ' | ' + message);
    if (extensions && extensions.code === 'UNAUTHENTICATED') {
      log.info(operationName + ' | Request UNAUTHENTICATED, try to refresh accessToken');
      return true;
    }
    return false;
  },
  onSuccessfulRefresh: tokens => {
    log.info('Save fetched new acceessToken | ' + tokens.accessToken);
    let newAuth: {
      accessToken: string;
      refreshToken?: string;
    } = {
      accessToken: tokens.accessToken,
    };
    if (tokens.refreshToken) {
      newAuth['refreshToken'] = tokens.refreshToken;
    }
    DB_AUTH.set({ ...newAuth });
  },
  onFailedRefresh: (doLogout: boolean, error) => {
    log.warn('Failed Refresh Token, logout: ' + doLogout);
    if (doLogout) {
      logout();
    }
    log.warn(error);
  },
});

export { onErrorRefreshTokenLink };
