import React, { useEffect, useRef, useState } from 'react';
import { Platform, useWindowDimensions, View } from 'react-native';
import { Box, Image, Pressable, Text } from 'native-base';
import { AppState } from 'react-native';

import { useQuery } from '@apollo/client';
import { theme } from '../../config';
import { LinearGradient } from 'expo-linear-gradient';
import { Promo, Shop, USER_SHOP } from '../../stores/queries/shop';
import { ACTUAL_AUTH, DB_AUTH } from '../../stores/db/auth';
import { FolderView } from '../../components/ProductTree/FolderView';
import { Loading } from '../../ui/Loading';
import { APP_STATE, APP_STATE_PROPS } from '../../stores/queries/appstate';

import { destructureTree } from '../../libs/utils';
import RNRestart from 'react-native-restart';
import { shopInstant } from '../../stores/db/appstate';

const ShopWindow = ({ navigation }: any) => {
  const dimensions = useWindowDimensions();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [promos, setPromos] = useState<Promo[]>();

  const nullShopReset = useRef();

  const { data: APP } = useQuery<APP_STATE_PROPS>(APP_STATE);

  const { loading, error, data, refetch } = useQuery<{ UserShop: Shop }>(USER_SHOP, {
    fetchPolicy: 'cache-and-network',
    skip: !APP.shopCode,
    variables: {
      code: ACTUAL_AUTH.shopCode,
    },
  });

  const [appCurState, setAppCurState] = useState<string>('active');
  const handleAppStateChange = (state: string) => {
    setAppCurState(state);
  };
  useEffect(() => {
    const appstateEvent = AppState.addEventListener('change', handleAppStateChange);
    return () => {
      appstateEvent.remove();
    };
  }, []);

  useEffect(() => {
    if (appCurState === 'active') {
      refetch();
    }
  }, [appCurState]);

  useEffect(() => {
    if (data?.UserShop?.isMaintenance) {
      navigation.navigate('Maintenance', {
        shop: APP.shopCode,
      });
    }
  }, [data?.UserShop?.isMaintenance]);

  useEffect(() => {
    if (data?.UserShop?.type === 'light') {
      shopInstant(true);
    }
  }, [data?.UserShop?.type]);

  useEffect(() => {
    if (!loading && !error && data && data.UserShop === null && !nullShopReset.current) {
      nullShopReset.current === true;
      DB_AUTH.set({ shopCode: null }).then(() => {
        if (Platform.OS === 'web') {
          location.reload();
        } else {
          RNRestart.Restart();
        }
      });
    }
  }, [data?.UserShop]);

  useEffect(() => {
    if (APP.activeJob && data?.UserShop?._id) {
      navigation.navigate('Job', {
        screen: 'JobUpload',
        params: {
          jobId: APP.activeJob,
        },
      });
    }

    if (data?.UserShop) {
      let tPromos: Promo[] = [];
      for (let index = 0; index < data?.UserShop?.productTree?.length; index++) {
        const element = data.UserShop.productTree[index];
        if (element.isSponsored && !(element.type === 'folder' && !element.isVisible)) {
          let { title, desc, color, image, grouped, price } = destructureTree(element);
          let promo: Promo = {
            type: element.type,
            title,
            desc,
            color,
            image,
            grouped,
            price,
            product: element.product ? element.product : undefined,
            next: element.next ? element.next : undefined,
          };
          tPromos.push(promo);
        }
        if (element.next) {
          for (let j = 0; j < element.next.length; j++) {
            const nextElement = element.next[j];
            if (
              nextElement.isSponsored &&
              !(nextElement.type === 'folder' && !nextElement.isVisible)
            ) {
              let { title, desc, color, image, grouped, price } = destructureTree(nextElement);
              let promo: Promo = {
                type: nextElement.type,
                title,
                desc,
                color,
                image,
                grouped,
                price,
                product: nextElement.product ? nextElement.product : undefined,
                next: nextElement.next ? nextElement.next : undefined,
              };
              tPromos.push(promo);
            }
            if (nextElement.next) {
              for (let j = 0; j < nextElement.next.length; j++) {
                const nextNextElement = nextElement.next[j];
                if (
                  nextNextElement.isSponsored &&
                  !(nextNextElement.type === 'folder' && !nextNextElement.isVisible)
                ) {
                  let { title, desc, color, image, grouped, price } =
                    destructureTree(nextNextElement);
                  let promo: Promo = {
                    type: nextNextElement.type,
                    title,
                    desc,
                    color,
                    image,
                    grouped,
                    price,
                    product: nextNextElement.product ? nextNextElement.product : undefined,
                    next: nextNextElement.next ? nextNextElement.next : undefined,
                  };
                  tPromos.push(promo);
                }
              }
            }
          }
        }
      }
      if (tPromos.length > 0) {
        setPromos(tPromos);
      }
      setTimeout(() => {
        setIsLoading(false);
      }, 10);
    }
  }, [data?.UserShop?.productTree]);

  if (data?.UserShop?.isMaintenance || data?.UserShop?.type === 'light') {
    return <Loading bg={theme.vars.colors.light[0]} visible={true} />;
  }

  return (
    <Box bg={theme.vars.colors.light[0]} flex={1} flexDirection="column">
      {dimensions.width <= theme.vars.screen.sm ? (
        <Box flexDirection={'column'}>
          <Box
            pl={2}
            pr={2}
            pb={2}
            width="100%"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            safeAreaTop
            bg={theme.vars.colors.light[0]}
            //shadow={9}
            //style={{ borderRadius: 20 }}
          >
            <Box pl={2} pt={2} alignItems="center" flexDirection="row" justifyContent="flex-start">
              <Image
                style={{
                  width: 180,
                  height: 40,
                  resizeMode: 'contain',
                }}
                source={require('../../assets/img/logo.png')}
                alt="logo"
              />
            </Box>

            {/* <Pressable
              onPress={() => {
                navigation.navigate('User');
              }}
              style={{ padding: 7, marginTop: 5 }}
            >
              <Icon name="notifiche" size={24} color="violet" />
              <Box
                style={{
                  width: 20,
                  height: 20,
                  borderRadius: 10,
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: theme.vars.colors.red[0],
                  position: 'absolute',
                  top: 2,
                  right: 2,
                }}
              >
                <Text style={{ color: '#fff' }}>2</Text>
              </Box>
            </Pressable> */}
          </Box>
          <Box
            width={'100%'}
            p={4}
            bg={theme.vars.colors.violet[2]}
            flexDirection={'row'}
            justifyContent="space-between"
          >
            <Text
              ml={0}
              style={{
                fontSize: 18,
                color: theme.vars.colors.violet[0],
                textTransform: 'uppercase',
              }}
              bold
            >
              {data?.UserShop?.name}
            </Text>
            <Text
              ml={0}
              style={{
                fontSize: 18,
                color: theme.vars.colors.violet[0],
                textTransform: 'uppercase',
              }}
              bold
            >
              #{data?.UserShop?.code}
            </Text>
          </Box>
        </Box>
      ) : null}
      {/* <Box
        style={{
          margin: 10,
          padding: 10,
          paddingHorizontal: 15,
          backgroundColor: theme.vars.colors.blue[2],
          borderRadius: 20,
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Text flex={1}>
          Un ordine che avevi nel carrello o nei tuoi progetti ha cambiato il suo prezzo!
        </Text>
        <IconButton style={{ margin: 0, marginLeft: 5 }} color="blue" icon={'elimina'} />
      </Box> */}
      {data?.UserShop?.productTree && (
        <FolderView promos={promos} productTree={data.UserShop.productTree} />
      )}
      <Loading
        bg={theme.vars.colors.light[0]}
        visible={!data?.UserShop?.productTree ? true : false}
      />
      <LinearGradient
        colors={['rgba(255,255,255,0)', theme.vars.colors.light[0]]}
        style={{
          height: 40,
          width: '100%',
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
        }}
        pointerEvents={'none'}
      />
    </Box>
  );
};

export { ShopWindow };
