import * as React from 'react';
import { Box, Pressable, ScrollView, Text } from 'native-base';
import { LoginForm } from '../../components/Profile/LoginForm';
import { useQuery } from '@apollo/client';
import { APP_STATE, APP_STATE_PROPS } from '../../stores/queries/appstate';
import { theme } from '../../config';
import { Button } from '../../ui';
import { T } from '../../config/localization';

interface JobUserProps {
  navigation: any;
  route: {
    params: {
      orders: string[];
    };
  };
}

const JobUser = (props: JobUserProps) => {
  const { data: APP } = useQuery<APP_STATE_PROPS>(APP_STATE);

  React.useEffect(() => {
    if (APP.userLogged) {
      props.navigation.replace('JobCreate', {
        orders: props.route.params.orders,
      });
    }
  }, [APP.userLogged]);

  return (
    <Box bg={theme.vars.colors.light[0]} flex={1} flexDirection="column">
      <ScrollView flex={1} pt={6} showsVerticalScrollIndicator={false}>
        <LoginForm
          onSuccess={() => {
            props.navigation.replace('JobCreate', {
              orders: props.route.params.orders,
            });
          }}
        />
      </ScrollView>
      <Pressable
        style={{
          width: '100%',
          bottom: 30,
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onPress={() => {
          props.navigation.navigate('Home', {
            screen: 'Cart',
            initial: false,
          });
        }}
      >
        <Text color={theme.vars.colors.dark[0]} fontSize={12} underline bold>
          {T('cancel_order')}
        </Text>
      </Pressable>
    </Box>
  );
};

export { JobUser };
