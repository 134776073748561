import { makeVar } from '@apollo/client';

const userLogged = makeVar<string | null>(null);
const shopCode = makeVar<string | null>(null);
const shopInstant = makeVar<boolean>(false);

const activeOrder = makeVar<string | null>(null);
const activeJob = makeVar<string | null>(null);

const filledProjects = makeVar<boolean>(false);
const filledCart = makeVar<boolean>(false);

export { userLogged, shopCode, shopInstant, filledProjects, filledCart, activeOrder, activeJob };
