import { gql } from '@apollo/client';
import { Product, SHOP_MIN_FRAGMENT } from './shop';
import { Shop, SHOP_FRAGMENT, PRODUCT_FRAGMENT } from './shop';
import { User, UserAddress } from './user';

interface JobPaymentInput {
  jobId: string;
  /*   user: {
    contacts: {
      name: string;
      phone: string;
      email: string;
    };
  }; */
  payment: {
    type: 'onSite' | 'stripe';
  };
  delivery: {
    type: 'atHome' | 'atShop';
    timing: number;
    cost: number;
    address: UserAddress;
    business: {
      country: string;
      state: string;
      city: string;
      zipCode: string;
      phoneNumber: string;
      denomination: string;
      street: string;
    };
    country: string;
  };
  finalPrice: number;
}

interface JobInput {
  deviceRef: string;
  orders: {
    orderKey: string;
    product: string;
    finalPrice: number;
    userEditing: {
      editor: {
        version: string;
      };
      pages: {
        name: string;
        choice: {
          name: string;
          percentualPriceIncrement: number;
        };
      }[];
      images: {
        attachmentKey: string;
        quantity: number;
        historyKey: string;
        wizardId: string;
      }[];
    };
  }[];
}

interface UserEditingPage {
  name: string;
  choice: {
    name: string;
    percentualPriceIncrement: number;
  };
}

interface UserEditingImage {
  attachmentKey: string; // ID
  attachmentUrl: string; // url dove si trova il file
  quantity: number;
  historyKey: string; // ID
  historyUrl: string; // url dove si trova il file
  status: 'initialized' | 'uploading' | 'uploaded';
  wizardId: string;
  dpi: number;
}

interface UserEditing {
  editor: {
    version: string;
  };
  pages: UserEditingPage[];
  images: UserEditingImage[];
}

interface JobOrder {
  orderKey: string;
  finalPrice: number;
  rawProduct: Product;
  userEditing: UserEditing;
  status: 'uploading' | 'complete';
  version: number;
}

interface Job {
  _id: string;
  shop: Shop;
  creationDate: string;
  delivery: JobPaymentInput['delivery'];
  deviceRef: string;
  lastUpdateDate: string;
  user: User;
  payment: {
    type: 'onSite' | 'stripe';
    status:
      | 'initializing'
      | 'inProcessing'
      | 'failed'
      | 'needActionFromAdmin'
      | 'needActionFromUser'
      | 'payed'
      | 'canceled';
    stripe: {
      paymentStatus:
        | 'canceled'
        | 'created'
        | 'payment_failed'
        | 'processing'
        | 'requires_action'
        | 'succeeded';
      clientSecret: string;
      paymentIntentId: string;
    };
  };
  finalPrice: number;
  orders: JobOrder[];
  status:
    | 'initializing'
    | 'readyForPayment'
    | 'completed'
    | 'canceledByUser'
    | 'canceledByAdmin'
    | 'confirmed'
    | 'printed';
}

const JOB_PRODUCT_FRAGMENT = gql`
  fragment jobProductFragment on JobOrderProduct {
    name
    type
    version
  }
`;

const ORDER_FRAGMENT = gql`
  ${JOB_PRODUCT_FRAGMENT}
  fragment orderFragment on JobOrder {
    orderKey
    finalPrice
    version
    status
    rawProduct {
      ...jobProductFragment
    }
    userEditing {
      editor {
        version
      }
      pages {
        name
        choice {
          name
          percentualPriceIncrement
        }
      }
      images {
        attachmentKey
        attachmentUrl
        quantity
        historyKey
        historyUrl
        status
        wizardId
        dpi
      }
    }
  }
`;
/* 
${SHOP_FRAGMENT}
shop {
  ...shopFragment
}
+
address {
        name
        street
        city
        province
        state
        zipCode
      }

      delivery {
      type
      timing
      cost
      country
    }
 */

const JOB_FRAGMENT = gql`
  ${ORDER_FRAGMENT}
  ${SHOP_MIN_FRAGMENT}
  fragment jobFragment on Job {
    _id
    status
    shop {
      ...shopMinFragment
    }
    creationDate
    delivery {
      type
      timing
      cost
      country
      address {
        name
        street
        city
        province
        state
        zipCode
        phone
      }
      business {
        country
        state
        city
        zipCode
        phoneNumber
        denomination
        street
      }
    }
    deviceRef
    lastUpdateDate
    user {
      _id
      email
      username
    }
    payment {
      type
      status
      stripe {
        paymentIntentId
        paymentStatus
        clientSecret
      }
    }
    finalPrice
    orders {
      ...orderFragment
    }
  }
`;

const JOB = gql`
  ${JOB_FRAGMENT}
  query JOB($jobId: ID!) {
    GuestJobById(jobId: $jobId) {
      ...jobFragment
    }
  }
`;

const JOBS = gql`
  ${JOB_FRAGMENT}
  query JOBS {
    UserJobs {
      ...jobFragment
    }
  }
`;

const JOB_ADD = gql`
  ${SHOP_MIN_FRAGMENT}
  mutation UserJobAdd($job: JobInput!, $shopId: ID!) {
    UserJobAdd(job: $job, shopId: $shopId) {
      _id
      status
      finalPrice
      orders {
        orderKey
        finalPrice
      }
      shop {
        ...shopMinFragment
      }
      creationDate
    }
  }
`;

const JOB_ADD_PAYMENT = gql`
  ${SHOP_MIN_FRAGMENT}
  mutation UserJobPaymentAdd(
    $jobId: ID!
    $payment: JobPaymentInput!
    $delivery: JobDeliveryInput!
    $finalPrice: Float!
  ) {
    UserJobPaymentAdd(
      jobId: $jobId
      payment: $payment
      delivery: $delivery
      finalPrice: $finalPrice
    ) {
      _id
      status
      shop {
        ...shopMinFragment
      }
      creationDate
      payment {
        type
        status
        stripe {
          paymentIntentId
          paymentStatus
          clientSecret
        }
      }
      finalPrice
    }
  }
`;

const JOB_CHANGE_PAYMENT = gql`
  ${SHOP_MIN_FRAGMENT}
  mutation UserJobChangePayment($jobId: ID!, $payment: JobPaymentInput!) {
    UserJobChangePayment(jobId: $jobId, payment: $payment) {
      _id
      status
      shop {
        ...shopMinFragment
      }
      creationDate
      payment {
        type
        status
        stripe {
          paymentIntentId
          paymentStatus
          clientSecret
        }
      }
      finalPrice
    }
  }
`;

const JOB_CANCEL = gql`
  mutation UserJobCancel($jobId: ID!) {
    UserJobCancel(jobId: $jobId)
  }
`;

export {
  JobPaymentInput,
  JobInput,
  Job,
  UserEditingPage,
  ORDER_FRAGMENT,
  JOB_FRAGMENT,
  JOB,
  JOBS,
  JOB_ADD,
  JOB_ADD_PAYMENT,
  JOB_CHANGE_PAYMENT,
  JOB_CANCEL,
};
