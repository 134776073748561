import React, { useEffect, useState } from 'react';
import { Linking, StyleSheet } from 'react-native';
import { Text, Box, Pressable } from 'native-base';
import { ENV, theme } from '../../config';
import { Icon } from '../../ui';
import { Loading } from '../../ui/Loading';
import { LOCALE, T } from '../../config/localization';

interface HelpProps {
  navigation: any;
}

const Help = (props: HelpProps) => {
  return (
    <Box bg={theme.vars.colors.light[0]} flex={1} style={{ alignItems: 'center' }}>
      <Box
        style={{
          maxWidth: 1024,
          width: '100%',
          alignItems: 'center',
        }}
        flex={1}
      >
        <Text
          width={200}
          mt={5}
          mb={2}
          color={theme.vars.colors.dark[0]}
          textAlign={'center'}
          lineHeight={20}
          fontSize={'lg'}
          bold
        >
          {T('page_header')}
        </Text>

        <Box style={styles.entry}>
          <Text color={theme.vars.colors.dark[0]} fontSize={'md'} flex={1} mr={5}>
            {T('app_version')}
          </Text>
          <Text color={theme.vars.colors.dark[0]} fontSize={'md'} bold>
            {ENV.deviceInfo.app.version}
          </Text>
        </Box>

        <Box style={styles.entry}>
          <Text color={theme.vars.colors.dark[0]} fontSize={'md'} flex={1} mr={5}>
            {T('editor_version')}
          </Text>
          <Text color={theme.vars.colors.dark[0]} fontSize={'md'} bold>
            {ENV.deviceInfo.app.editor}
          </Text>
        </Box>

        <Box style={styles.entry}>
          <Text color={theme.vars.colors.dark[0]} fontSize={'md'} flex={1} mr={5}>
            {T('device_language')}
          </Text>
          <Text color={theme.vars.colors.dark[0]} fontSize={'md'} bold>
            {LOCALE}
          </Text>
        </Box>

        <Pressable
          style={styles.entry}
          onPress={() => {
            Linking.openURL('https://app.easypixy.com/privacy');
          }}
        >
          <Text color={theme.vars.colors.dark[0]} fontSize={'md'} flex={1} mr={5}>
            {T('privacy_policy')}
          </Text>
          <Icon color="dark" size={20} name={'freccia-destra'} />
        </Pressable>

        <Pressable
          style={styles.entry}
          onPress={() => {
            Linking.openURL('https://app.easypixy.com/privacy/terms.html');
          }}
        >
          <Text color={theme.vars.colors.dark[0]} fontSize={'md'} flex={1} mr={5}>
            {T('use_terms')}
          </Text>
          <Icon color="dark" size={20} name={'freccia-destra'} />
        </Pressable>

        {/* <Pressable style={styles.entry} onPress={() => {}}>
          <Text color={theme.vars.colors.dark[0]} fontSize={'md'} flex={1} mr={5}>
            {T('credits')}
          </Text>
          <Icon color="dark" size={20} name={'freccia-destra'} />
        </Pressable> */}
      </Box>
      <Loading bg={theme.vars.colors.light[0]} visible={false} />
    </Box>
  );
};

const styles = StyleSheet.create({
  entry: {
    width: '100%',
    paddingHorizontal: 20,
    paddingVertical: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.vars.colors.light[2],
    borderColor: theme.vars.colors.blue[2],
    borderBottomWidth: 1,
  },
});

export { Help };
