import { gql } from '@apollo/client';

export const USER_LOGGED = gql`
  query userLogged {
    userLogged @client
  }
`;

export type APP_STATE_PROPS = {
  userLogged: boolean;
  shopCode: string | null;
  shopInstant: boolean;
  activeOrder: string | null;
  activeJob: string | null;
  filledCart: boolean;
  filledProjects: boolean;
};

export const APP_STATE = gql`
  query appState {
    userLogged @client
    shopCode @client
    shopInstant @client
    activeOrder @client
    activeJob @client
    filledCart @client
    filledProjects @client
  }
`;
