import { Input } from 'native-base';
import React from 'react';
import {
  StyleSheet,
  Text,
  View,
  ViewStyle,
  KeyboardType,
  ReturnKeyType,
  TextStyle,
} from 'react-native';

import { theme } from '../../config';
import { Colors } from '../../config/theme';

interface TextBoxProps {
  color?: Colors;
  flat?: boolean;
  style?: ViewStyle;
  textStyle?: TextStyle;
  labelStyle?: TextStyle;
  value?: string;
  onSubmitEditing?: Function;
  onChangeText?: (e: string) => void;
  placeholder?: string;
  keyboardType?: KeyboardType;
  returnKeyType?: ReturnKeyType | 'default';
  autoComplete?:
    | 'off'
    | 'username'
    | 'password'
    | 'email'
    | 'name'
    | 'tel'
    | 'street-address'
    | 'postal-code'
    | 'cc-number'
    | 'cc-csc'
    | 'cc-exp'
    | 'cc-exp-month'
    | 'cc-exp-year';
  autoCapitalize?: 'none' | 'sentences' | 'words' | 'characters';
  secureTextEntry?: boolean;
  labelText?: string;
  maxLength?: number;
  autoFocus?: boolean;
  multiline?: boolean;
  numberOfLines?: number;
  disabled?: boolean;
  variant?: 'outline' | 'filled' | 'underlined' | 'unstyled' | 'rounded';
  error?: boolean;
}

const TextBox = (props: TextBoxProps) => {
  let color = theme.vars.colors.violet;

  if (props.color && theme.vars.colors[props.color]) {
    color = theme.vars.colors[props.color];
  }

  let placeColor = theme.vars.colors.grey[0];
  let textColor = props.flat ? theme.vars.colors.dark[2] : color[0];
  let fillColor = props.flat ? color[1] : color[2];

  if (props.error) {
    fillColor = theme.vars.colors.red[0];
  }

  return (
    <View style={[styles.container, props.style]}>
      {props.labelText && (
        <Text style={[theme.text.m, styles.label, props.labelStyle]}>{props.labelText}</Text>
      )}
      <View style={styles.inputContainer}>
        <Input
          background={props.disabled ? theme.vars.colors.grey[2] : fillColor}
          borderColor={props.disabled ? theme.vars.colors.grey[1] : fillColor}
          fontSize={20}
          color={textColor}
          p={2}
          _focus={{
            borderColor: fillColor,
            borderWidth: 1,
            color: textColor,
          }}
          borderWidth={1}
          style={[styles.input, props.textStyle]}
          variant={props.variant || 'rounded'}
          value={props.value}
          maxLength={props.maxLength}
          autoFocus={props.autoFocus}
          autoCapitalize={props.autoCapitalize}
          autoComplete={props.autoComplete}
          autoCorrect={false}
          underlineColorAndroid="transparent"
          secureTextEntry={props.secureTextEntry}
          blurOnSubmit={false}
          keyboardType={props.keyboardType}
          returnKeyType={props.returnKeyType}
          onSubmitEditing={() => {
            props.onSubmitEditing && props.onSubmitEditing();
          }}
          placeholder={props.placeholder}
          placeholderTextColor={placeColor}
          onChangeText={(e: string) => {
            props.onChangeText && props.onChangeText(e);
          }}
          multiline={props.multiline}
          numberOfLines={props.numberOfLines}
          isFullWidth={true}
          isDisabled={props.disabled}
          width={'100%'}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    width: 260,

    //maxWidth: 340,
  },
  inputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  input: {
    fontSize: 20,
    height: 44,
    width: '100%',
    padding: 21,
  },
  label: {
    width: '100%',
    textAlign: 'left',
    marginLeft: 20,
    marginBottom: 0,
    marginTop: 10,
    color: '#fff',
  },
});

export { TextBox };
