import { LOG } from '../../config';
import { appDB } from '../../libs/storages';
import { UserEditingPage } from '../queries/jobs';
import { Product } from '../queries/shop';
import { activeJob, filledCart, filledProjects } from './appstate';
import { ACTUAL_AUTH } from './auth';

const log = LOG.extend('DB');

const getRandomInt = (base = 100) => {
  return Math.floor(Math.random() * base);
};

type LocalImage = {
  width?: number;
  height?: number;
  path?: string;
  attachment?: string;
  quantity: number;
  history?: string;
  waiting?: boolean;
  label?: string;
  wizardId: string;
  placeholder?: boolean;
  lowRes?: boolean;
  dpi: number;
};

interface Order {
  _id?: string;
  _jobId?: string;
  key?: string;
  state: 'temp' | 'project' | 'cart' | 'order' | 'upload';
  finalPrice: number;
  priceExpiration: number;
  pages: UserEditingPage[];
  images: LocalImage[];
  rawProduct: Product;
  createdAt: number;
  quantity: number;
}

const ORDER_LIST_KEY = 'ORDER_KEYS';
const ORDER_KEY = 'ORDER_';
const ATTACHMENT_KEY = 'ATTACHMENT_';
const CURRENT_JOB_KEY = 'JOB_CURRENT';

let ACTUAL_ORDERS: Order[] = [];

const generateOrderKeysKey = (): string => {
  return ORDER_LIST_KEY + '_' + ACTUAL_AUTH.shopCode;
};

const generateOrderKey = (): string => {
  let rdm = getRandomInt(100);
  return ORDER_KEY + new Date().getTime() + '_' + rdm;
};

const generateAttachmentKey = (i?: number): string => {
  let index = i || 0;
  let rdm = getRandomInt(1000000);
  return ATTACHMENT_KEY + new Date().getTime() + '_' + index + '_' + rdm;
};

const DB_ORDERS = {
  getOrders: async (): Promise<Order[]> => {
    log.debug('Get orders');
    let orderKeys = await appDB.getObject(generateOrderKeysKey());
    let orders = [];
    if (orderKeys && orderKeys.length > 0) {
      for (let i = 0; i < orderKeys.length; i++) {
        let orderKey = orderKeys[i];
        let order: Order = await appDB.getObject(orderKey);
        orders.push(order);
      }
      ACTUAL_ORDERS = orders;
    } else {
      ACTUAL_ORDERS = [];
    }
    return ACTUAL_ORDERS;
  },
  getOrdersByState: async (state: 'project' | 'cart'): Promise<Order[]> => {
    log.debug('Get orders by state');
    let orderKeys = await appDB.getObject(generateOrderKeysKey());
    let orders = [];
    if (orderKeys && orderKeys.length > 0) {
      for (let i = 0; i < orderKeys.length; i++) {
        let orderKey = orderKeys[i];
        let order: Order = await appDB.getObject(orderKey);
        if (order?.state === state) {
          orders.push(order);
        }
      }
      if (state === 'project') {
        if (orders.length > 0) {
          filledProjects(true);
        } else {
          filledProjects(false);
        }
      }
      if (state === 'cart') {
        if (orders.length > 0) {
          filledCart(true);
        } else {
          filledCart(false);
        }
      }
      return orders;
    } else {
      return [];
    }
  },
  getOrder: async (orderKey: string): Promise<Order> => {
    log.debug('Get order: ' + orderKey);
    return await appDB.getObject(orderKey);
  },
  saveOrder: async (order: Order): Promise<Order> => {
    let orderKey = generateOrderKey();
    log.debug('Save order: ' + orderKey);
    order.key = orderKey;
    await appDB.setObject(orderKey, order);
    let orderKeys = await appDB.getObject(generateOrderKeysKey());
    if (!orderKeys) {
      orderKeys = [];
    }
    orderKeys.push(orderKey);
    await appDB.setObject(generateOrderKeysKey(), orderKeys);
    return order;
  },
  updateOrder: async (order: Order): Promise<Order> => {
    log.debug('Update order: ' + order.key);
    await appDB.setObject(order.key, order);
    return order;
  },
  removeOrder: async (orderKey: string): Promise<string> => {
    log.debug('Remove order: ' + orderKey);
    let order: Order = await appDB.getObject(orderKey);
    if (!order) return;
    appDB.remove(orderKey);
    if (order.images) {
      for (let i = 0; i < order.images.length; i++) {
        const image = order.images[i];
        appDB.remove(image.attachment);
        if (image.history) appDB.remove(image.history);
      }
    }
    let orderKeys = await appDB.getObject(generateOrderKeysKey());
    if (orderKeys) {
      let newOrderKeys = orderKeys.filter(el => el !== orderKey);
      await appDB.setObject(generateOrderKeysKey(), newOrderKeys);
    }
    return orderKey;
  },
  saveAttachment: async (attachment: string, attachmentKey?: string) => {
    let key = attachmentKey || generateAttachmentKey();
    log.debug('Save attachment: ' + key);
    await appDB.setString(key, attachment);
    return { attachmentKey: key, attachment };
  },
  updateAttachment: async (attachmentKey: string, attachment: string) => {
    log.debug('Update attachment: ' + attachmentKey);
    await appDB.setString(attachmentKey, attachment);
    return { attachmentKey, attachment };
  },
  getAttachment: async (attachmentKey: string) => {
    log.debug('Get attachment: ' + attachmentKey);
    return await appDB.getString(attachmentKey);
  },
  removeAttachment: async (attachmentKey: string) => {
    log.debug('Remove attachment: ' + attachmentKey);
    await appDB.remove(attachmentKey);
  },
  clear: async () => {},
};

const DB_JOBS = {
  getCurrentJob: async (): Promise<string> => {
    log.debug('Get current job');
    return await appDB.getString(CURRENT_JOB_KEY);
  },
  saveCurrentJob: async (job: string): Promise<string> => {
    log.debug('Save current job');
    await appDB.setString(CURRENT_JOB_KEY, job);
    activeJob(job);
    return job;
  },
  removeCurrentJob: async () => {
    log.debug('Remove current job');
    await appDB.remove(CURRENT_JOB_KEY);
    activeJob(null);
    return;
  },
  clearCurrentJob: async () => {
    let cart = await DB_ORDERS.getOrdersByState('cart');
    for (let index = 0; index < cart.length; index++) {
      const order = cart[index];
      DB_ORDERS.removeOrder(order.key);
    }
    log.debug('Clear current job');
    await appDB.remove(CURRENT_JOB_KEY);
    activeJob(null);
    filledCart(false);
    return;
  },
  clear: async () => {},
};

const CLEAR_DB_CACHE = () => {};

export { Order, LocalImage, DB_ORDERS, DB_JOBS, CLEAR_DB_CACHE, generateAttachmentKey };
