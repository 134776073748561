import React, { useCallback, useEffect, useState } from 'react';
import { Box, Image, Text, View, Pressable } from 'native-base';
import { useMutation, useQuery } from '@apollo/client';
import { Shop, USER_SHOP_MIN } from '../../stores/queries/shop';
import { ACTUAL_AUTH } from '../../stores/db/auth';
import { BackHandler } from 'react-native';
import { Job, JobInput, JOB_ADD } from '../../stores/queries/jobs';
import { DB_JOBS, DB_ORDERS } from '../../stores/db/orders';
import { Loading } from '../../ui/Loading';
import { ENV, LOG, theme } from '../../config';
import { APP_STATE, APP_STATE_PROPS } from '../../stores/queries/appstate';
import { useFocusEffect } from '@react-navigation/native';
import { T } from '../../config/localization';

const log = LOG.extend('JOBCREATE');

interface JobCreateProps {
  navigation: any;
  route: {
    params: {
      orders: string[];
    };
  };
}

const JobCreate = (props: JobCreateProps) => {
  const [job, setJob] = useState<Job>();
  const [error, setError] = useState(null);

  const { data: APP } = useQuery<APP_STATE_PROPS>(APP_STATE);

  const {
    loading: shopLoading,
    error: shopError,
    data: shopData,
  } = useQuery<{ UserShop: Shop }>(USER_SHOP_MIN, {
    // fetchPolicy: 'cache-and-network',
    variables: {
      code: APP.shopCode,
    },
  });

  const [jobAdd, { loading: jobLoading, error: jobError, data: jobData }] = useMutation(JOB_ADD, {
    onCompleted: res => {
      if (res?.UserJobAdd?._id) {
        setJob(res.UserJobAdd);
        log.info('Job Sended:', res);
      } else {
        log.error('Error on null send job:', res);
        setError(T('unknown_error'));
      }
    },
    onError: err => {
      setError(T('unknown_error'));
      log.error('Error on send job:', err);
    },
  });

  useFocusEffect(
    useCallback(() => {
      const onBackPress = () => {
        if (error) {
          return false;
        } else {
          return true;
        }
      };

      BackHandler.addEventListener('hardwareBackPress', onBackPress);

      return () => BackHandler.removeEventListener('hardwareBackPress', onBackPress);
    }, [error])
  );

  useEffect(() => {
    props.navigation.setOptions({
      headerLeft: null,
    });
  }, []);

  useEffect(() => {
    if (shopData?.UserShop?._id && !job) {
      sendJob(shopData.UserShop._id);
    } else if (job?._id) {
      DB_JOBS.saveCurrentJob(job._id);
      props.navigation.replace('JobUpload', {
        jobId: job._id,
      });
    }
  }, [shopData?.UserShop?._id, job]);

  const sendJob = async (shopId: string) => {
    setError(null);
    let orders: JobInput['orders'] = [];
    for (let i = 0; i < props.route.params.orders.length; i++) {
      const orderKey = props.route.params.orders[i];
      let order = await DB_ORDERS.getOrder(orderKey).then();
      if (order) {
        let images: JobInput['orders'][0]['userEditing']['images'] = [];
        if (order.images) {
          for (let j = 0; j < order.images.length; j++) {
            const image = order.images[j];
            let orderImage: JobInput['orders'][0]['userEditing']['images'][0] = {
              wizardId: image.wizardId,
              attachmentKey: image.attachment,
              historyKey: image.history,
              quantity: image.quantity,
            };
            images.push(orderImage);
          }
        }
        let jobOrder: JobInput['orders'][0] = {
          orderKey,
          finalPrice: order.finalPrice,
          product: order.rawProduct._id,
          userEditing: {
            editor: { version: ENV.deviceInfo.app.editor },
            images,
            pages: order.pages,
          },
        };
        orders.push(jobOrder);
      }
    }

    let userJob: JobInput = {
      deviceRef: ACTUAL_AUTH.shopCode,
      orders,
    };

    let res = await jobAdd({
      variables: {
        job: userJob,
        shopId: shopId,
      },
    });
    return res;
  };

  return (
    <Box
      pt={2}
      bg={theme.vars.colors.light[0]}
      flex={1}
      alignItems="center"
      justifyContent="flex-start"
      safeAreaBottom
    >
      <Box flex={1} alignItems="center" justifyContent="center" safeAreaBottom>
        <Image
          mb={6}
          alt={'ritoro_negozio'}
          style={{ resizeMode: 'contain', width: 160, height: 160 }}
          source={require('../../assets/img/illustrations/rocket.png')}
        ></Image>

        {error ? (
          <>
            <Text color={theme.vars.colors.red[0]} bold>
              {error}
            </Text>
            <Pressable
              style={{
                width: '100%',
                position: 'absolute',
                bottom: 20,
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onPress={() => {
                props.navigation.navigate('Home', {
                  screen: 'Cart',
                  initial: false,
                });
              }}
            >
              <Text color={theme.vars.colors.dark[0]} fontSize={12} underline bold>
                {T('cancel_order')}
              </Text>
            </Pressable>
          </>
        ) : (
          <View style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Text color={theme.vars.colors.blue[0]} fontSize={'lg'}>
              {T('send_order_hint')}
            </Text>
            <View style={{ width: 100, height: 100 }}>
              <Loading visible={true} />
            </View>
          </View>
        )}
      </Box>
    </Box>
  );
};

export { JobCreate };
