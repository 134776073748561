import React, { useEffect, useRef, useState } from 'react';
import { Platform, useWindowDimensions, View } from 'react-native';
import { Text, Box, ScrollView, Pressable } from 'native-base';
import { useQuery } from '@apollo/client';
import { Button, Icon } from '../../ui';
import { BLURHASH, LOG, theme } from '../../config';
import { LinearGradient } from 'expo-linear-gradient';
import { Shop, Product, USER_SHOP_MIN } from '../../stores/queries/shop';
import Swiper from 'react-native-web-swiper';

import { ACTUAL_AUTH } from '../../stores/db/auth';
import { BlurModal } from '../../ui/BlurModal';
import { APP_STATE, APP_STATE_PROPS } from '../../stores/queries/appstate';
import { DB_ORDERS, Order } from '../../stores/db/orders';
import { calcProductPrice } from '../../libs/utils';
import { T } from '../../config/localization';
import { Image } from '../../ui/Image';
import { format, parse, parseISO } from 'date-fns';
import itLocale from 'date-fns/locale/it';

interface ProductSummaryProps {
  navigation: any;
  route: {
    params: {
      product: Product;
      title: string;
      orderKey?: string;
      quantity?: number;
    };
  };
}

const ProductSummary = (props: ProductSummaryProps) => {
  const dimensions = useWindowDimensions();
  const [showPrices, setShowPrices] = useState(false);
  const [quantity, setQuantity] = useState<number>(props.route.params.quantity || 1);
  const [price, setPrice] = useState<number>(0);

  const { data: APP } = useQuery<APP_STATE_PROPS>(APP_STATE);

  const { loading, error, data } = useQuery<{ UserShop: Shop }>(USER_SHOP_MIN, {
    fetchPolicy: 'cache-and-network',
    skip: !APP.shopCode,
    variables: {
      code: ACTUAL_AUTH.shopCode,
    },
  });

  const product = props?.route?.params?.product;
  const isGoods: boolean = product?.type === 'goods' ? true : false;

  const QuantitySelector = () => {
    return (
      <Box flexDirection="row" alignItems={'center'} justifyContent="space-between">
        <Pressable
          onPress={() => {
            if (quantity > 1) {
              let newQuantity = quantity - 1;
              setQuantity(newQuantity);
            }
          }}
          style={{
            width: 30,
            height: 30,
            margin: 0,
            padding: 0,
            borderRadius: 999,
            backgroundColor: theme.vars.colors.violet[0],
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Text color={theme.vars.colors.light[0]} fontSize={24} lineHeight={26}>
            -
          </Text>
        </Pressable>
        <Text
          fontSize={18}
          style={{ width: 36, textAlign: 'center' }}
          color={theme.vars.colors.violet[0]}
          bold
        >
          {quantity}
        </Text>
        <Pressable
          onPress={() => {
            let newQuantity = quantity + 1;
            setQuantity(newQuantity);
          }}
          style={{
            width: 30,
            height: 30,
            margin: 0,
            padding: 0,
            borderRadius: 999,
            backgroundColor: theme.vars.colors.violet[0],
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Text color={theme.vars.colors.light[0]} fontSize={24} lineHeight={24}>
            +
          </Text>
        </Pressable>
      </Box>
    );
  };

  useEffect(() => {
    props.navigation.setOptions({
      headerTitle: props?.route?.params?.title || 'Prodotto',
    });
  }, []);

  useEffect(() => {
    if (isGoods) {
      let calPrice = calcProductPrice(props.route.params.product, quantity, null);
      setPrice(calPrice.price);
    }
  }, [quantity]);

  return (
    <Box bg={theme.vars.colors.light[0]} flex={1} safeAreaBottom>
      <Box flex={1}>
        <ScrollView
          style={{ flex: 1 }}
          contentContainerStyle={{
            paddingTop: 20,
            paddingBottom: 40,
            alignItems: 'center',
            width: '100%',
          }}
        >
          {product?.images?.gallery[0]?.url && (
            <Box
              style={{ ...theme.vars.shadow['sm'], shadowRadius: 8, shadowOpacity: 0.3 }}
              rounded={20}
            >
              <Box
                style={{
                  overflow: 'hidden',
                  width: dimensions.width <= theme.vars.screen.sm ? dimensions.width * 0.9 : 500,
                  height: dimensions.width <= theme.vars.screen.sm ? dimensions.width * 0.5 : 350,
                  backgroundColor: theme.vars.colors.grey[1],
                }}
                rounded={20}
              >
                {/* @ts-ignore */}
                <Swiper
                  loop
                  timeout={5}
                  springConfig={{ speed: 3 }}
                  controlsProps={{
                    dotActiveStyle: { backgroundColor: theme.vars.colors.violet[0] },
                    dotsTouchable: true,
                    prevPos: false,
                    nextPos: false,
                  }}
                >
                  {product.images.gallery.map((image, index) => {
                    return (
                      <View key={'productImage_' + index} style={{ flex: 1 }}>
                        <Image
                          style={{
                            flex: 1,
                            width: '100%',
                            height: '100%',
                            resizeMode: 'cover',
                          }}
                          source={{
                            uri: image.url,
                          }}
                          blurhash={BLURHASH['violet']}
                        />
                      </View>
                    );
                  })}
                </Swiper>
              </Box>
            </Box>
          )}

          <Box
            p={2}
            rounded={20}
            style={{
              width: dimensions.width <= theme.vars.screen.sm ? dimensions.width * 0.9 : 450,
              marginVertical: 10,
            }}
            _text={{
              textAlign: 'center',
              fontSize: 'md',
              fontWeight: 'bold',
              color: theme.vars.colors.dark[0],
            }}
          >
            {product?.descriptions?.short}
          </Box>
          {/* 
          <Pressable mb={4} flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
            <Text
              //size="sm"
              style={{
                color: theme.vars.colors.dark[0],
                fontSize: 18,
              }}
              bold
              underline
            >
              Scopri il {T('quotes')}{' '}
            </Text>
            <Icon color={'dark'} size={14} name="freccia-destra"></Icon>
          </Pressable> */}

          <Box
            p={6}
            mx={6}
            my={2}
            borderRadius={20}
            alignItems="center"
            bg={theme.vars.colors.violet[2]}
            style={{
              width: '90%',
              maxWidth: 500,
            }}
          >
            {data?.UserShop?.deliveryInfo?.atHome?.timings[0]?.time ? (
              <Box
                style={{ width: '100%' }}
                flexDirection="row"
                alignItems="center"
                py={1}
                //justifyContent="space-between"
              >
                <Icon name="consegna" size={30} color={'violet'} />
                <Box
                  style={{ width: '100%' }}
                  flex={1}
                  marginLeft={5}
                  _text={{
                    fontSize: 'sm',
                    color: theme.vars.colors.dark[0],
                  }}
                >
                  <Text
                    //size="sm"
                    style={{
                      color: theme.vars.colors.dark[0],
                      fontSize: 14,
                    }}
                  >
                    {T('ship_at_home')} {T('in')}{' '}
                    {data?.UserShop?.deliveryInfo?.atHome?.timings[0]?.time} {T('work_days')}
                  </Text>
                  {data?.UserShop?.deliveryInfo?.atHome?.costs[0]?.cost && (
                    <Text
                      //size="sm"
                      style={{
                        color: theme.vars.colors.dark[0],
                        fontSize: 14,
                      }}
                      bold
                    >
                      {T('ship_price')}:{' '}
                      {data.UserShop.deliveryInfo.atHome.costs[0].cost.toFixed(2)} €
                    </Text>
                  )}
                </Box>
              </Box>
            ) : null}
            {data?.UserShop?.deliveryInfo?.atShop?.time ? (
              <Box py={1} style={{ width: '100%' }} flexDirection="row" alignItems="center">
                <Icon name="negozio" size={30} color={'violet'} />
                <Box
                  style={{ width: '100%' }}
                  flex={1}
                  marginLeft={5}
                  _text={{
                    fontSize: 'sm',
                    color: theme.vars.colors.dark[0],
                  }}
                >
                  <Text
                    //size="sm"
                    style={{
                      color: theme.vars.colors.dark[0],
                      fontSize: 14,
                    }}
                  >
                    {T('shop_delivery')} {T('in')} {data.UserShop.deliveryInfo.atShop.time}{' '}
                    {T('work_days')}
                  </Text>

                  <Text
                    //size="sm"
                    style={{
                      color: theme.vars.colors.dark[0],
                      fontSize: 14,
                    }}
                    bold
                  >
                    {T('ship_at_shop_cost')}:{' '}
                    {data?.UserShop?.deliveryInfo?.atShop?.cost
                      ? data.UserShop.deliveryInfo.atShop.cost.toFixed(2) + ' €'
                      : T('free_shipping')}
                  </Text>
                </Box>
              </Box>
            ) : null}

            {product?.wizards?.map((wizard, index) => {
              if (!wizard.sizes.height) return null;
              return (
                <Box
                  key={'wizard_' + index}
                  py={1}
                  style={{ width: '100%' }}
                  flexDirection="row"
                  alignItems="center"
                  //justifyContent="space-between"
                >
                  <Icon name="formato" size={30} color={'violet'} />
                  <Box
                    style={{ width: '100%' }}
                    flex={1}
                    marginLeft={5}
                    _text={{
                      fontSize: 'sm',
                      color: theme.vars.colors.dark[0],
                    }}
                  >
                    <Text
                      style={{
                        color: theme.vars.colors.dark[0],
                        fontSize: 14,
                      }}
                      bold
                      textTransform={'capitalize'}
                    >
                      {wizard.label && wizard.label.trim() !== ''
                        ? wizard.label.trim()
                        : T('dimensions')}
                    </Text>
                    <Text
                      //size="sm"
                      style={{
                        color: theme.vars.colors.dark[0],
                        fontSize: 14,
                        lineHeight: 18,
                      }}
                    >
                      {T('format_in_cm')}: {(wizard.sizes.height / 10).toFixed(2)} x{' '}
                      {(wizard.sizes.width / 10).toFixed(2)}
                    </Text>
                  </Box>
                </Box>
              );
            })}

            {product?.pages?.map((page, index) => {
              return (
                <Box
                  key={'page_' + index}
                  py={1}
                  style={{ width: '100%' }}
                  flexDirection="row"
                  alignItems="center"
                  //justifyContent="space-between"
                >
                  <Icon name="carta" size={30} color={'violet'} />
                  <Box
                    style={{ width: '100%' }}
                    flex={1}
                    marginLeft={5}
                    _text={{
                      fontSize: 'sm',
                      color: theme.vars.colors.dark[0],
                    }}
                  >
                    <Text
                      lineHeight={14}
                      style={{
                        color: theme.vars.colors.dark[0],
                        fontSize: 14,
                      }}
                      bold
                    >
                      {page.name}
                    </Text>
                    <Text
                      lineHeight={14}
                      style={{
                        color: theme.vars.colors.dark[0],
                        fontSize: 12,
                      }}
                    >
                      {page.choices.map((choice, index) => {
                        return choice.name + (index + 1 < page.choices.length ? ', ' : '');
                      })}
                    </Text>
                  </Box>
                </Box>
              );
            })}
          </Box>
          <Box
            p={6}
            pb={5}
            mx={6}
            my={6}
            borderRadius={20}
            alignItems="center"
            bg={theme.vars.colors.blue[2]}
            style={{
              width: '90%',
              maxWidth: 500,
            }}
          >
            <Text
              width={'100%'}
              textAlign={'center'}
              mb={2}
              color={theme.vars.colors.dark[0]}
              fontSize={'2xl'}
              bold
            >
              {product.prices?.quantityDiscounts?.length > 1
                ? T('more_print_less')
                : T('look_quotes')}
            </Text>

            <Box
              key={'price_' + '00'}
              mt={2}
              flexDirection="row"
              alignItems="center"
              justifyContent={'space-between'}
              style={{
                width: '100%',
                borderBottomWidth: 1,
                borderBottomColor: '#bbb',
                paddingBottom: 4,
              }}
            >
              <Box flexDirection={'row'}>
                <Text
                  color={theme.vars.colors.dark[0]}
                  width={60}
                  bold
                  style={{ textTransform: 'capitalize' }}
                >
                  {T('from')}
                </Text>

                <Text
                  color={theme.vars.colors.dark[0]}
                  bold
                  style={{ textTransform: 'capitalize' }}
                >
                  {T('to')}
                </Text>
              </Box>

              <Box flexDirection={'row'} justifyContent={'flex-end'}>
                <Text textTransform={'capitalize'} color={theme.vars.colors.dark[0]} bold>
                  {T('price')}
                </Text>
              </Box>
            </Box>
            {product.prices?.quantityDiscounts?.map((price, index) => {
              //if (index === 0) return null;
              let now = new Date().getTime();
              let basePrice = price.newPrice;
              let discountPrice;
              let from, to;
              let until = null;
              if (price.timeDiscount.from && price.timeDiscount.to) {
                from = new Date(price.timeDiscount.from);
                to = new Date(price.timeDiscount.to);
                if (from && now > from && now < to) {
                  discountPrice = price.timeDiscount.newPrice;
                }
                if (discountPrice) {
                  try {
                    until = format(to - 1, 'dd/MM', { locale: itLocale });
                  } catch (error) {
                    LOG.error(error);
                  }
                }
              }
              return (
                <Box
                  key={'price_' + index}
                  mt={2}
                  flex={1}
                  flexDirection="row"
                  //alignItems="center"
                  style={{
                    width: '100%',
                    borderBottomWidth:
                      index === product.prices.quantityDiscounts.length - 1 ? 0 : 1,
                    borderBottomColor: '#ccc',
                    paddingBottom: 4,
                  }}
                >
                  <Box flexDirection={'row'}>
                    <Text color={theme.vars.colors.dark[0]} width={60}>
                      {price.quantityFrom}
                    </Text>

                    <Text color={theme.vars.colors.dark[0]}>
                      {price.quantityTo === 999 ? '...' : price.quantityTo}
                    </Text>
                  </Box>

                  <Box flexDirection={'row'} justifyContent={'flex-end'} flex={1}>
                    <Text
                      color={theme.vars.colors.dark[0]}
                      style={{
                        textDecorationLine: discountPrice ? 'line-through' : 'none',
                      }}
                    >
                      {price?.newPrice?.toFixed(2)} €
                    </Text>
                    {discountPrice && (
                      <Text ml={1} color={theme.vars.colors.dark[0]} bold>
                        {discountPrice.toFixed(2)} €
                      </Text>
                    )}
                    {until ? (
                      <Text ml={1} color={theme.vars.colors.dark[0]}>
                        {T('until')} {until}
                      </Text>
                    ) : null}
                  </Box>
                </Box>
              );
            })}
            {product?.prices?.basePrice ? (
              <Box
                key={'price_' + '000'}
                mt={5}
                mb={1}
                flexDirection="column"
                alignItems="flex-start"
                justifyContent={'flex-start'}
                width={'100%'}
              >
                {product.prices.basePrice ? (
                  <Text color={theme.vars.colors.dark[0]} bold>
                    {product.prices.basePriceLabel || T('fixedCost')}
                    {': '}
                    {product.prices.basePrice?.toFixed(2)} €
                    {product.prices.basePriceMaxQnt ? (
                      <>
                        {' '}
                        {T('fixedCostHint')} {product.prices.basePriceMaxQnt}{' '}
                        {isGoods ? T('pieces') : T('prints')}
                      </>
                    ) : null}
                  </Text>
                ) : null}
              </Box>
            ) : null}
          </Box>
        </ScrollView>
        <LinearGradient
          colors={['rgba(255,255,255,0)', theme.vars.colors.light[0]]}
          style={{
            height: 40,
            width: '100%',
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 0,
          }}
          pointerEvents={'none'}
        />
      </Box>

      {isGoods && (
        <Box
          //width={'94%'}
          style={{ marginHorizontal: 25, marginVertical: 5 }}
          justifyContent="space-between"
          alignItems="center"
          flexDirection="row"
        >
          <Box
            rounded={20}
            flex={1}
            bg={theme.vars.colors.violet[2]}
            alignItems="center"
            justifyContent="space-around"
            flexDirection="row"
            //width="94%"
            p={1}
            mt={2}
          >
            <QuantitySelector />
            <Box flexDirection="row" alignItems="center" justifyContent="center">
              <Icon name="costo-spedizione" size={20} color={'violet'} />
              <Box flexDirection="column" alignItems="flex-start" justifyContent="center" pl={2}>
                <Text lineHeight={20} color={theme.vars.colors.violet[0]} fontSize="md">
                  {price?.toFixed(2)}
                </Text>
                <Text lineHeight={14} color={theme.vars.colors.violet[0]} fontSize="xs">
                  Euro
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      )}

      <Box
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'center'}
        style={{ marginHorizontal: 20, paddingVertical: 0, paddingBottom: 10 }}
      >
        <Button
          shadow={'lg'}
          onPress={() => {
            if (isGoods) {
              let order: Order = {
                state: 'cart',
                finalPrice: price,
                priceExpiration: 0,
                pages: null,
                images: null,
                rawProduct: props.route.params.product,
                createdAt: new Date().getTime(),
                quantity: quantity,
              };
              if (props.route.params.orderKey) {
                order['key'] = props.route.params.orderKey;
                DB_ORDERS.updateOrder(order).then(newOrder => {
                  props.navigation.navigate('Home', {
                    screen: 'Cart',
                    initial: false,
                    key: 'cart-from-summary',
                  });
                });
              } else {
                DB_ORDERS.saveOrder(order).then(newOrder => {
                  props.navigation.navigate('Home', {
                    screen: 'Cart',
                    initial: false,
                    key: 'cart-from-summary',
                  });
                });
              }
            } else if (product?.pages && product.pages.length > 0) {
              props.navigation.navigate('ProductPage', {
                product,
                pageIndex: 0,
                key: 'cart-from-summary',
              });
            } else {
              props.navigation.navigate('PhotoSelect', {
                product,
                key: 'cart-from-summary',
              });
            }
          }}
        >
          {isGoods ? T('to_cart') : T('begin')}
        </Button>
      </Box>
    </Box>
  );
};

export { ProductSummary };
