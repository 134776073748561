const EN = {
  // Generali
  yes: 'yes',
  no: 'no',
  continue: 'continue',
  retry: 'retry',
  begin: 'begin',
  begin_now: 'begin now',
  save: 'save',
  edit: 'edit',
  delete: 'delete',
  cancel: 'cancel',
  confirm: 'confirm',
  done: 'Done',
  ok: 'ok',
  name: 'Name',
  unknown_error: 'Unknown error, please retry later...',
  quantity: 'quantity',
  picture: 'picture',
  pictures: 'pictures',
  print: 'print',
  prints: 'prints',
  pieces: 'pieces',
  of: 'of',
  in: 'in',
  from: 'from',
  to: 'to',
  order: 'order',
  work: 'work',
  job: 'job',
  product: 'product',
  variant: 'variant',
  shop: 'Shop',

  // Selezione negozio
  frame_code: 'Scan qrcode',
  camera_permission: 'Grant permissions to use the camera',
  camera_permission_denied: "You don't have access to the camera",
  maintenance_title: 'Maintenance in progress',
  maintenance_sub: 'Sorry for the inconvenience, please try again later!',

  // Header menu webapp
  signin_header: 'Sign in or register',

  // Albero prodotti
  start_from: 'Starting from',
  comingsoon: 'Coming soon',

  // Carrello
  page_title_cart: 'My orders',
  cart: 'cart',
  move_in_projects: 'Move to\nprogects',
  minium_order_price: 'Minimum order 0,50€',
  continue_shop: 'Continue shopping',
  send_order: 'Proceed to order',
  void_cart: 'Your cart is still empty',
  void_cart_hint: 'Start printing your emotions now',
  delete_order_promt: 'Are you sure ',

  // Progetti
  projects: 'progects',
  void_projects: "You don't have any project yet",
  void_projects_hint: 'Start printing your emotions now',
  delete_project_promt: 'Are you sure you want to delete?',

  // Indirizzi
  page_title_addresses: 'My addresses',
  edit_address: 'Edit your address',
  street: 'Street',
  zip_code: 'Postal Code',
  city: 'City',
  province: 'Province',
  state: 'Country',
  delete_address_promt: 'Do you want to delete the address?',

  // Preferenze
  page_title_preferences: 'Preferences',

  // Info
  page_title_info: 'Information',
  page_header: 'EasyPixy by DLogic srl',
  privacy_policy: 'Privacy policy',
  use_terms: 'Terms of use',
  credits: 'Credits',

  // Profilo e login
  profile_title: 'Profile',
  code_sended: "We've sent a verification code to your email, enter it here!",
  code_resend: "Didn't receive the code? Please check your junk mail folder",
  code_retry: 'Or click here to try again',
  insert_email: 'Please insert your email',
  login: 'login',
  login_hint: 'Log in or register to complete and track your orders',
  login_facebook: 'Continue with Facebook',
  login_apple: 'Continue with Apple',
  login_email: 'Continue with email',
  logout: 'logout',
  logout_promt: 'Do you want to log out of your account?',
  shop_exit: 'Exit this shop',
  shop_exit_promt: 'Do you want to leave this shop?',
  my_orders: 'My orders',
  my_addresses: 'My addresses',
  my_preferences: 'Preferences',
  my_infos: 'Information',
  instant_order: 'Instant order',
  return_to_full: 'Back to the shop',
  user_verified: 'User verified',

  // Storico
  void_history: "You don't have any order yet",
  void_history_hint: 'Start printing your emotions now',
  total: 'Total',
  legend: 'Legend',
  waitingPayment: 'Waiting for payment',
  processingOrder: 'Order Processing',
  orderShipped: 'Order shipped or waiting for pickup',
  orderCanceld: 'Order cancelled',
  quantityMin: 'Q.',

  // Preferenze
  comunications: 'Messages',
  noti_orders: 'Receive updates on the status of your orders and shipments',
  noti_commercial: 'Receive messages about promotions and discounts from your shop',
  noti_ep: 'Receive updates on EasyPixy news and new features',
  system: 'System',
  app_version: 'App version',
  editor_version: 'Editor version',
  device_language: 'Device language',
  delete_cache: 'Clear cache',
  delete_cache_promt: 'Do you want to clear the cache?',
  delete_cache_promt_sub:
    "You are going to delete the cart and all your projects, then you'll log out...",
  delete_user: 'Delete your user and data',
  delete_user_promt: 'Delete user and data?',
  delete_user_promt_sub:
    'You are going to ask for the elimination of your user and all the data related to it from the Easypixy database. Are you sure you want to continue?',

  // Invio ordine
  send_order_hint: 'Order sending in progress...',
  cancel_order_promt: 'Are you sure you want to cancel the order?',
  cancel_order: 'Cancel the order and return to cart',

  // Upload Ordine
  upload_order: 'Pictures upload in progress',
  upload_order_hint: "Please don't close the application!",
  upload_complete: 'Upload complete!',
  upload_begin: 'Starting upload',

  // Spedizione ordine
  page_title_ship: 'Shipping',
  shipping: 'Shipping',
  contacts: 'Contacts',
  mandatory_input: '*required field',
  shipping_method: 'Delivery methods',
  shop_delivery: 'In store',
  home_shipping: 'Tracked shipping',
  free_shipping: 'Free',
  shipping_in: 'Delivery to',
  ready_shipping: 'Prompt delivery',
  shipping_address: 'Shipping address',
  void_shipping_address: 'Address not yet entered',
  shipping_place: 'Pickup point',
  go_to_payment: 'Go to payment',
  select_address: 'Press to use this address',
  new_address: 'New address',
  remember_address: 'Remember address',
  err_no_name: 'Please insert your name',
  err_no_email: 'Please insert your email',
  err_no_phone: 'Please insert your phone number',
  err_no_address: 'Please insert your address',
  homeAddress: 'Home',
  workAddress: 'Work',
  otherAddress: 'Other',
  phone: 'Mobile',
  address: 'Address',

  // Pagamento Ordine
  page_title_pay: 'Payment',
  order_price: 'Order amount',
  ship_price: 'Shipping cost',
  total_price: 'Total amount',
  payment_method: 'Choose payment method',
  payment_on_site: 'Pay in store',
  payment_on_site_hint: 'Pagamento in negozio disponibile solo con ritiro in sede',
  payment_cards: 'Credit card',
  pay: 'Pay',

  // Errore Ordine
  order_error_title: 'Oops, there was a problem loading your order!',
  order_no_methods: 'Oops, in this shop there are no shipping methods enabled, contact it.',

  // Completato Ordine
  thanks: 'Thanks!',
  complete_order_title: 'Order sent complete',
  complete_order_hint: 'Check the status in your profile section',

  // Selezione Foto
  process_picture: 'Working on pictures',
  prosessing_pictures: 'We are processing your pictures',
  processing_pictures_hint: "Please don't close the app",
  in_processing: 'Work in progress',
  select_pictures_title: 'Please select the pictures',
  select_pictures_hint:
    'We will try to frame them as best as possible and you can modify them as you prefer',
  min_prints: 'Minimum order',
  no_variants: 'No variants',
  add_others: 'Add more',
  prints_to_go: 'prints to continue',
  select_per_page: 'Select a picture for each page',
  to_cart: 'To cart',
  save_work_promt: 'Want to save the work?',
  save_work_hint:
    'You can continue later in the projects section!\nUnprocessed pictures will not be saved',
  open_editor: 'Open editor',
  select_all: 'Select all',
  deselect_all: 'Deselect all',
  select_pictures: 'Select pictures',
  select_picture: 'Select picture',
  selected_pictures: 'Selected pictures',
  pictures_permission_limited: 'You have permissions only for some photos',
  pictures_permission_denied: 'You do not have permission to access your photos',
  open_settings_to_add: 'Open settings to add',
  open_settings: 'Open settings',
  your_albums: 'Your albums',

  // Riepilogo prodotto
  ship_at_home: 'Ship at home',
  ship_at_shop: 'In shop',
  ship_at_shop_cost: 'In shop pickup cost',
  work_days: 'working days',
  format_in_cm: 'In cm format',
  quotes: 'Price list',
  more_print_less: 'More prints, less price!',
  look_quotes: 'Look at the price list',
  until: 'until',
  free: 'free',
  freeOver: 'Free starting from',
  fixedCost: 'Fixed cost',
  fixedCostHint: 'for orders less than',
  price: 'price',
  dimensions: 'sizes',
};

export { EN };
