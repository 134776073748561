import React, { useEffect, useState } from 'react';
import { Text, Box, Image, Modal } from 'native-base';
import { Button } from '../../ui';
import { Keyboard, Platform } from 'react-native';
import { QrReader } from '../../components/Shop/QrReader';
import { DB_AUTH } from '../../stores/db/auth';
import { LOG, theme } from '../../config';
import { useApolloClient } from '@apollo/client';
import { USER_SHOP_MIN } from '../../stores/queries/shop';
import { Loading } from '../../ui/Loading';
import { shopCode, shopInstant } from '../../stores/db/appstate';
import { CodeInput } from '../../ui/CodeInput';
import { T } from '../../config/localization';

const ShopSelect = ({ navigation }: any) => {
  const client = useApolloClient();
  const [actualShopCode, setActualShopCode] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [shop, setShop] = useState();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [message, setMessage] = useState<string>();

  useEffect(() => {
    if (shop) {
      LOG.info('Shop found: ' + shop);
      DB_AUTH.set({ shopCode: actualShopCode });
      shopCode(actualShopCode);
    }
  }, [shop]);

  const checkShop = async (code: string) => {
    setError('');
    setLoading(true);
    let errorMsg;
    let res;
    try {
      if (code) {
        res = await client.query({
          query: USER_SHOP_MIN,
          fetchPolicy: 'network-only',
          variables: {
            code,
          },
        });
      }
    } catch (err) {
      LOG.error(err);
      errorMsg = err;
      if (errorMsg && errorMsg?.message) {
        errorMsg = errorMsg.message;
      } else {
        errorMsg = 'Errore Imprevisto, Riprova più tardi!';
      }
    }

    if (res?.data?.UserShop?._id) {
      if (res?.data?.UserShop?.type === 'light') {
        shopInstant(true);
      }
      setShop(res?.data?.UserShop?.name);
    } else if (errorMsg) {
      setLoading(false);
      setError(errorMsg);
    } else {
      setLoading(false);
      setMessage('Codice negozio sconosciuto, controlla di averlo scritto correttamente...');
    }

    return shop;
  };

  let webInputStyle = {};

  if (Platform.OS === 'web') {
    webInputStyle = {
      outlineWidth: 0,
    };
  }

  if (error) {
    return (
      <Box
        flex={1}
        style={{ height: '100%', overflow: 'hidden' }}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Image
          style={{
            width: 240,
            height: 50,
            resizeMode: 'contain',
          }}
          source={require('../../assets/img/logo.png')}
          alt="logo"
        />
        <Text mt={10} mb={2} fontSize={50} bold>
          Oops!
        </Text>
        <Text mt={2} mb={10} fontSize={20}>
          Errore imprevisto, riprova più tardi!
        </Text>

        <Button
          shadow="lg"
          color="blue"
          onPress={() => {
            setError(null);
            setLoading(false);
          }}
        >
          Riprova
        </Button>
        <Text mt={10} fontSize={10}>
          {error}
        </Text>
      </Box>
    );
  }

  return (
    <Box
      flex={1}
      style={{ height: '100%', overflow: 'hidden' }}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Box w={'100%'} flex={1} pt={16} justifyContent={'center'} alignItems={'center'}>
        <Image
          style={{
            width: 240,
            height: 50,
            resizeMode: 'contain',
            marginBottom: 50,
          }}
          source={require('../../assets/img/logo.png')}
          alt="logo"
        />
        <Text color={theme.vars.colors.dark[0]} fontSize="2xl" bold>
          Shop Code
        </Text>
        <Box
          style={{
            width: '100%',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box style={{ marginVertical: 0, marginBottom: 50 }}>
            <CodeInput
              value={actualShopCode}
              onChange={code => setActualShopCode(code.toUpperCase())}
              onComplete={code => {
                checkShop(code);
              }}
            />
          </Box>

          {/* <TextInput
            placeholder={''}
            style={[
              {
                marginVertical: 20,
                fontSize: 55,
                width: 166,
                textAlign: 'left',
                borderWidth: 0,
                fontFamily: Platform.OS === 'ios' ? 'Courier' : 'monospace',
                fontWeight: 'bold',
                color: theme.vars.colors.dark[0],
              },
              //@ts-ignore
              webInputStyle,
            ]}
            placeholderTextColor={theme.vars.colors.grey[0]}
            onChangeText={code => setActualShopCode(code.toUpperCase())}
            value={actualShopCode}
            editable
            keyboardType={'number-pad'}
            onSubmitEditing={() => {
              if (actualShopCode.length > 4) {
                checkShop();
              }
            }}
            maxLength={5}
          /> */}
          {/* <Box
            pointerEvents="none"
            style={{
              flex: 1,
              width: '100%',
              position: 'absolute',
              left: 0,
              bottom: 10,
            }}
          >
            <Text
              color={theme.vars.colors.dark[0]}
              width={'100%'}
              textAlign={'center'}
              fontSize={52}
              bold
            >
              _ _ _ _ _
            </Text>
          </Box> */}
        </Box>

        {actualShopCode.length > 4 && Platform.OS !== 'web' ? (
          <Button
            shadow="lg"
            color="violet"
            onPress={() => {
              if (actualShopCode.length > 4) {
                Keyboard.dismiss();
                checkShop(actualShopCode);
              }
            }}
          >
            {T('begin')}
          </Button>
        ) : (
          <Button
            shadow="lg"
            color="violet"
            onPress={() => {
              Keyboard.dismiss();
              setModalVisible(true);
            }}
          >
            {T('frame_code')}
          </Button>
        )}

        <Box
          style={{
            backgroundColor: theme.vars.colors.light[0],
            borderRadius: 999,
            marginTop: 10,
            marginBottom: 10,
            width: '100%',
            maxWidth: 300,
            height: 42,
          }}
        >
          <Text textAlign={'center'} numberOfLines={2} color={theme.vars.colors.red[0]} bold>
            {message}
          </Text>
        </Box>
      </Box>

      <Image
        style={{
          width: 200,
          height: 200,
          resizeMode: 'contain',
          marginBottom: -10,
        }}
        source={require('../../assets/img/illustrations/richiesta-qr-code.png')}
        alt="richiesta_qr"
      />

      <Loading bg={theme.vars.colors.light[0]} visible={loading} />

      <Modal
        backdropVisible={true}
        _backdrop={{
          _dark: {
            bg: 'rgba(0,0,0,0.3)',
          },
          bg: 'rgba(0,0,0,0.3)',
        }}
        isOpen={modalVisible}
        onClose={setModalVisible}
        size={'md'}
        isKeyboardDismissable={true}
        avoidKeyboard={true}
        animationPreset={'slide'}
      >
        <Box
          style={{
            overflow: 'hidden',
            width: 310,
            height: 310,
            borderColor: theme.vars.colors.light[2],
            borderWidth: 3,
            borderRadius: 20,
          }}
        >
          <Modal.CloseButton style={{ backgroundColor: theme.vars.colors.light[0] }} />
          <Box style={{ width: 310, height: 310 }} bg={'#000'}>
            {modalVisible && (
              <QrReader
                onRead={code => {
                  setActualShopCode(code);
                  setModalVisible(false);
                  checkShop(code);
                }}
              />
            )}
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export { ShopSelect };
