let fbAuthResponse = null;

const loginWithFacebook = async () => {
  try {
    let res: any = await fbLogin();
    if (!res) {
      return { grantedPermissions: 'cancel', isCancelled: true };
    } else {
      fbAuthResponse = res;
      return { grantedPermissions: 'public_profile,email', isCancelled: false };
    }
  } catch (error) {
    return null;
  }
};

const fbLogin = () => {
  return new Promise((resolve, reject) => {
    //@ts-ignore
    FB.login(
      function (response) {
        if (response?.authResponse) {
          resolve(response.authResponse);
        } else {
          reject('fb login error');
        }
      },
      { scope: 'public_profile,email' }
    );
  });
};

const getFacebookAccessToken = async () => {
  return fbAuthResponse;
};

export { loginWithFacebook, getFacebookAccessToken };
