import React, { useEffect, useState } from 'react';
import { Text, Box, Image, Center, FlatList, Pressable } from 'native-base';
import { theme } from '../../config';
import { Button, Icon } from '../../ui';
import { Loading } from '../../ui/Loading';
import { Job, JOBS } from '../../stores/queries/jobs';
import { useQuery } from '@apollo/client';
import { HistoryItem } from '../../components/Order/HistoryItem';
import { useIsFocused } from '@react-navigation/native';
import { T } from '../../config/localization';
import { User, USER_ME } from '../../stores/queries/user';
import { IconButton } from '../../ui/IconButton';
import { BlurModal } from '../../ui/BlurModal';
import { Platform } from 'react-native';

const HeaderLegend = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Pressable
        mr={3}
        onPress={() => {
          setIsOpen(true);
        }}
      >
        <Icon color="dark" name="info"></Icon>
      </Pressable>
      <BlurModal
        height={Platform.OS === 'web' ? window.screen.height * 0.6 : undefined}
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <Box alignItems="center" justifyContent={'center'} flex={1} style={{ width: '100%' }}>
          <Text
            mb={10}
            color={theme.vars.colors.dark[0]}
            fontSize={'3xl'}
            textAlign={'center'}
            lineHeight={28}
            bold
          >
            {T('legend')}
          </Text>

          <Box
            bg={{
              linearGradient: {
                colors: [theme.vars.colors.yellow[0], theme.vars.colors.yellow[2]],
                start: [0.3, 1],
                end: [0.01, 0.1],
              },
            }}
            // @ts-ignore
            cursor={Platform.OS == 'web' ? 'pointer' : undefined}
            style={{
              borderRadius: 18,
              height: 36,
              width: 36,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          ></Box>
          <Text
            mb={6}
            mt={2}
            color={theme.vars.colors.dark[0]}
            fontSize={'xl'}
            textAlign={'center'}
          >
            {T('processingOrder')}
            {/* {T('waitingPayment')} */}
          </Text>

          {/* <Box
            bg={{
              linearGradient: {
                colors: [theme.vars.colors.blue[0], theme.vars.colors.blue[2]],
                start: [0.3, 1],
                end: [0.01, 0.1],
              },
            }}
            // @ts-ignore
            cursor={Platform.OS == 'web' ? 'pointer' : undefined}
            style={{
              borderRadius: 18,
              height: 36,
              width: 36,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          ></Box>
          <Text
            mb={6}
            mt={2}
            color={theme.vars.colors.dark[0]}
            fontSize={'xl'}
            textAlign={'center'}
          >
            {T('processingOrder')}
          </Text> */}

          <Box
            bg={{
              linearGradient: {
                colors: [theme.vars.colors.green[0], theme.vars.colors.green[2]],
                start: [0.3, 1],
                end: [0.01, 0.1],
              },
            }}
            // @ts-ignore
            cursor={Platform.OS == 'web' ? 'pointer' : undefined}
            style={{
              borderRadius: 18,
              height: 36,
              width: 36,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          ></Box>
          <Text
            mb={6}
            mt={2}
            color={theme.vars.colors.dark[0]}
            fontSize={'xl'}
            textAlign={'center'}
          >
            {T('orderShipped')}
          </Text>

          <Box
            bg={{
              linearGradient: {
                colors: [theme.vars.colors.red[0], theme.vars.colors.red[2]],
                start: [0.3, 1],
                end: [0.01, 0.1],
              },
            }}
            // @ts-ignore
            cursor={Platform.OS == 'web' ? 'pointer' : undefined}
            style={{
              borderRadius: 18,
              height: 36,
              width: 36,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          ></Box>
          <Text
            mb={6}
            mt={2}
            color={theme.vars.colors.dark[0]}
            fontSize={'xl'}
            textAlign={'center'}
          >
            {T('orderCanceld')}
          </Text>
          {/* <Button
            color="green"
            style={{ marginTop: 10, marginBottom: 15 }}
            shadow={'lg'}
            onPress={() => {
              setIsOpen(false);
            }}
            // disabled={isButtonDisabled()}
          >
            {T('continue')}
          </Button> */}
        </Box>
      </BlurModal>
    </>
  );
};

const VISIBLE_JOBS = ['canceledByAdmin', 'confirmed', 'inElaboration', 'elaborated', 'ready'];
interface HistoryProps {
  navigation: any;
}

const History = (props: HistoryProps) => {
  const { loading, error, data } = useQuery<{ UserMe: User }>(USER_ME, {
    fetchPolicy: 'cache-and-network',
  });

  const isFocused = useIsFocused();
  const [jobs, setJobs] = useState<Job[]>();

  useEffect(() => {
    props.navigation.setOptions({
      headerRight: () => <HeaderLegend />,
    });
  }, []);

  useEffect(() => {
    if (!isFocused) {
      //props.navigation.goBack();
    }
  }, [isFocused]);

  useEffect(() => {
    if (data?.UserMe?.jobs?.length) {
      let jobsArr = [];
      for (let i = 0; i < data.UserMe.jobs.length; i++) {
        const job = data.UserMe.jobs[i];
        if (VISIBLE_JOBS.includes(job.status)) {
          jobsArr.push(job);
        }
      }
      setJobs(jobsArr);
    }
  }, [data?.UserMe?.jobs]);

  const renderItem = ({ item, index }) => <HistoryItem key={index} job={item} />;

  return (
    <Box bg={theme.vars.colors.light[0]} flex={1} style={{ alignItems: 'center' }}>
      <Box
        style={{
          maxWidth: 1024,
          width: '100%',
          alignItems: 'center',
        }}
        flex={1}
      >
        {jobs?.length > 0 ? (
          <>
            <FlatList
              style={{ flex: 1, width: '100%' }}
              contentContainerStyle={{
                paddingTop: 20,
                paddingBottom: 40,
                paddingHorizontal: 20,
              }}
              data={jobs}
              renderItem={renderItem}
            />
          </>
        ) : (
          <>
            <Box style={{ width: '100%', flex: 1 }} justifyContent={'center'} alignItems={'center'}>
              <Text
                width={200}
                mb={2}
                color={theme.vars.colors.dark[0]}
                fontSize={'3xl'}
                textAlign={'center'}
                lineHeight={28}
                bold
              >
                {T('void_history')}
              </Text>
              <Text
                width={200}
                mb={2}
                color={theme.vars.colors.dark[0]}
                textAlign={'center'}
                lineHeight={20}
                fontSize={'lg'}
              >
                {T('void_history_hint')}
              </Text>
              <Image
                style={{
                  width: '70%',
                  maxWidth: 280,
                  height: 300,
                  resizeMode: 'contain',
                  marginBottom: -20,
                }}
                source={require('../../assets/img/illustrations/upload-foto.png')}
                alt="logo"
              />
              <Center style={{ width: '100%' }} mt={2}>
                <Button
                  color="violet"
                  shadow="md"
                  style={{ width: '100%', maxWidth: 200 }}
                  onPress={() => {
                    props.navigation.goBack();
                    props.navigation.navigate('Shop');
                  }}
                >
                  {T('begin_now')}
                </Button>
              </Center>
            </Box>
          </>
        )}
      </Box>
      <Loading bg={theme.vars.colors.light[0]} visible={loading} />
    </Box>
  );
};

export { History };
