import React, { useRef, useState } from 'react';
import { Pressable } from 'react-native';

import { ENV, LOG, theme } from '../../config';
import { Box, Text } from 'native-base';
import { Product } from '../../stores/queries/shop';

import { Editor } from '../../components/Editor/Editor';
import { DB_ORDERS, Order } from '../../stores/db/orders';
import { Loading } from '../../ui/Loading';
import { T } from '../../config/localization';
import { useDebounce } from '../../hooks/helpers';

const log = LOG.extend('EDITOR');

interface EditorEvent {
  event: string;
  data: any;
}

interface ImageEditorProps {
  navigation: any;
  route: {
    params: {
      image: Order['images'][0];
      index: number;
      wizard: Product['wizards'][0];
      updateImage: (index: number, image: Order['images'][0]) => {};
      setImageData: Function;
      setHistory: Function;
      imageData?: string;
    };
  };
}

const ImageEditor = (props: ImageEditorProps) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState(null);
  const { debounce } = useDebounce();

  const EditorRef = useRef(null);

  const initEditor = () => {
    let message = {
      event: 'setEditorInput',
    };
    log.debug('Send EditorInput to editorFrame');

    if (props.route.params.image.history) {
      DB_ORDERS.getAttachment(props.route.params.image.history).then(history => {
        message['data'] = {
          history: JSON.parse(history),
          editorConfig: props.route.params.wizard,
        };
        EditorRef.current && EditorRef.current.editorMessage(message);
      });
    } else {
      DB_ORDERS.getAttachment(props.route.params.image.attachment).then(image => {
        if (image) {
          message['data'] = {
            image,
            editorConfig: props.route.params.wizard,
          };
        } else {
          message['data'] = {
            image: props.route.params.imageData,
            editorConfig: props.route.params.wizard,
          };
        }

        EditorRef.current && EditorRef.current.editorMessage(message);
      });
    }
  };

  const update = async (imageData: string, historyData: string, dpi: number) => {
    DB_ORDERS.updateAttachment(props.route.params.image.attachment, imageData);
    let historyKey = props.route.params.image.history;
    if (historyKey) {
      DB_ORDERS.updateAttachment(historyKey, JSON.stringify(historyData));
      if (dpi !== props.route.params.image.dpi) {
        let newImage = {
          ...props.route.params.image,
          dpi,
        };
        props.route.params.updateImage(props.route.params.index, newImage);
      }
    } else {
      let newHistory = await DB_ORDERS.saveAttachment(JSON.stringify(historyData));
      let newHistoryKey = newHistory.attachmentKey;
      let newImage = {
        ...props.route.params.image,
        history: newHistoryKey,
        dpi,
      };
      props.route.params.updateImage(props.route.params.index, newImage);
      props.route.params.setHistory(newHistoryKey);
    }
    let imagePreview = { uri: imageData };

    props.route.params.setImageData(imagePreview.uri);
    props.navigation.goBack();
  };

  // Dispacher eventi mappa
  const editorEventDispatcher = (msg: EditorEvent): void => {
    if (!msg || !msg.event) {
      log.warn('EVENT: undefined');
    }
    switch (msg.event) {
      case 'loading':
        setLoading(true);
        log.debug('Editor loading');
        break;
      case 'loaded':
        log.debug('Editor loaded');
        break;
      case 'isReady':
        log.debug('Editor ready');
        initEditor();
        break;
      case 'isRendered':
        log.debug('Editor rendered');
        setTimeout(() => {
          setLoading(false);
        }, 200);
        break;
      case 'log':
        if (msg.data && msg.data.type) {
          log[msg.data.type] && log[msg.data.type]('EDITOR_FRAME: ' + msg.data.message);
        }
        break;
      case 'editorOutput':
        debounce(() => {
          log.debug('Editor output');
          if (msg.data.dpi !== 300) {
            log.warn('EDITOR DPI CHANGE:', msg.data.dpi);
          }
          update(msg.data.image, msg.data.history, msg.data.dpi);
        });
        break;
      case 'closeEditor':
        debounce(() => {
          log.debug('Close Editor');
          props.navigation.goBack();
        });
        break;
      default:
        log.debug('Uncontrolled Event: ' + msg.event);
        break;
    }
  };

  return (
    <Box
      safeAreaBottom
      style={{
        flex: 1,
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        backgroundColor: theme.vars.colors.light[0],
      }}
    >
      <Editor
        ref={EditorRef}
        style={{
          flex: 1,
          height: '100%',
          width: '100%',
        }}
        editorEventDispatcher={editorEventDispatcher}
        onError={error => {
          setError(JSON.stringify(error));
        }}
      />
      <Loading bg={theme.vars.colors.light[0]} text={error || 'Caricamento...'} visible={loading} />
      {loading && (
        <Pressable
          style={{ width: '100%', position: 'absolute', bottom: 30 }}
          onPress={() => {
            props.navigation.goBack();
          }}
        >
          <Text style={{ width: '100%', textAlign: 'center' }}>{T('cancel')}</Text>
        </Pressable>
      )}
    </Box>
  );
};

export { ImageEditor };
