import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, Text, View, ViewStyle, Image, TouchableOpacity, Platform } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { useNavigation } from '@react-navigation/native';

import { theme } from '../../config';
import { Button } from '../../ui';
import { FS } from '../../libs/fileSystem';

const Logs = () => {
  const navigation = useNavigation<any>();
  const [files, setFiles] = useState([]);
  const [file, setFile] = useState(null);
  const [logs, setLogs] = useState(null);

  const fileViewRef = useRef(null);

  useEffect(() => {
    FS.DocumentDirectoryPath &&
      FS.readdir(FS.DocumentDirectoryPath).then(result => {
        if (result) {
          setFiles(result);
        }
      });
  }, []);

  useEffect(() => {
    if (file) {
      FS.DocumentDirectoryPath &&
        FS.readFile(FS.DocumentDirectoryPath + '/' + file, 'utf8').then(result => {
          setLogs(result);
        });
    }
  }, [file]);

  return (
    <View style={styles.container}>
      <View style={styles.title}>
        <Text style={[theme.text.l, theme.text.bold]}>LOGS</Text>
      </View>
      <View style={{ flex: 1, paddingHorizontal: 10, width: '100%' }}>
        <ScrollView
          style={{
            height: '29%',
            backgroundColor: theme.vars.colors.grey[2],
            borderRadius: 10,
          }}
          contentContainerStyle={{ padding: 10 }}
          ref={fileViewRef}
          onContentSizeChange={() =>
            fileViewRef.current && fileViewRef.current.scrollToEnd({ animated: true })
          }
        >
          {files.map((item: string, index: number) => {
            if (item.substr(0, 5) === 'logs_') {
              return (
                <TouchableOpacity
                  key={index}
                  onPress={() => {
                    setFile(item);
                  }}
                >
                  <Text style={[theme.text.l, { margin: 5 }]}>{item}</Text>
                </TouchableOpacity>
              );
            }
          })}
        </ScrollView>
        <ScrollView
          style={{
            height: '65%',
            marginTop: '2%',
            marginBottom: 10,
            backgroundColor: theme.vars.colors.grey[2],
            borderRadius: 10,
          }}
          contentContainerStyle={{ padding: 10 }}
          ref={fileViewRef}
          onContentSizeChange={() =>
            fileViewRef.current && fileViewRef.current.scrollToEnd({ animated: true })
          }
        >
          {logs ? (
            <Text style={[theme.text.s]}>{logs}</Text>
          ) : (
            <Text style={[theme.text.l]}>SELECT LOG FILE...</Text>
          )}
        </ScrollView>
      </View>
      <Button
        style={{ marginBottom: 15 }}
        color={'blue'}
        onPress={() => {
          navigation.goBack();
        }}
      >
        Esci
      </Button>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 10,
    backgroundColor: theme.vars.colors.light[0],
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    //borderBottomWidth: 5,
    //borderBottomColor: theme.vars.colors.grey,
    marginTop: 10,
    marginBottom: 10,
    maxWidth: '50%',
  },
});

export { Logs };
