import React, { useState } from 'react';
import { Text, Box, Image, Pressable } from 'native-base';
import { useQuery } from '@apollo/client';
import RNRestart from 'react-native-restart';

import { ENV, theme } from '../../config';
import { Shop, USER_SHOP_MIN } from '../../stores/queries/shop';
import { ACTUAL_AUTH, DB_AUTH } from '../../stores/db/auth';
import { Promt } from '../../ui/Promt';
import { Platform } from 'react-native';
import { APP_STATE, APP_STATE_PROPS } from '../../stores/queries/appstate';

import QRCode from 'react-native-qrcode-svg';
import { LOCALE, T } from '../../config/localization';
import { clearCache } from '../../libs/storages';
import { logout } from '../../libs/apollo/auth';
import { filledCart, filledProjects, shopInstant } from '../../stores/db/appstate';

interface ShopInfoProps {
  min?: boolean;
}

const ShopInfo = (props: ShopInfoProps) => {
  const [delPromtVisible, setDelPromtVisible] = useState(false);
  const [resetPromtVisible, setResetPromtVisible] = useState(false);
  const { data: APP } = useQuery<APP_STATE_PROPS>(APP_STATE);
  const { loading, error, data } = useQuery<{ UserShop: Shop }>(USER_SHOP_MIN, {
    fetchPolicy: 'cache-and-network',
    variables: {
      code: APP.shopCode,
    },
  });

  if (props.min) {
    return (
      <Box
        mt={10}
        style={{ width: '100%' }}
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Text color={theme.vars.colors.dark[0]} fontSize="2xl" bold>
          {data?.UserShop?.name}
        </Text>
        <Box flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
          <Text color={theme.vars.colors.dark[0]} fontSize="md" bold>
            {data?.UserShop?.code}
          </Text>
          <Box
            style={{
              backgroundColor: '#fff',
              padding: 2,
              borderRadius: 10,
              ...theme.vars.shadow['lg'],
              shadowColor: theme.vars.colors.violet[0],
              shadowRadius: 8,
              shadowOpacity: 0.3,
            }}
          >
            <QRCode value={'https://get.easypixy.com/' + data?.UserShop?.code} size={70} />
          </Box>
        </Box>
        <Pressable
          onPress={() => {
            shopInstant(true);
          }}
          mt={10}
        >
          <Text color={theme.vars.colors.blue[0]} fontSize="xl" bold>
            {T('instant_order')}
          </Text>
        </Pressable>
        <Pressable
          onPress={() => {
            setDelPromtVisible(true);
          }}
          flexDirection={'row'}
          alignItems="center"
          my={3}
        >
          <Text color={theme.vars.colors.blue[0]} fontSize="xl" bold>
            {T('shop_exit')}
          </Text>
        </Pressable>
        <Pressable
          onPress={() => {
            setResetPromtVisible(true);
          }}
          mb={5}
        >
          <Text color={theme.vars.colors.dark[0]} fontSize={'md'}>
            {T('delete_cache')}
          </Text>
        </Pressable>

        <Text color={theme.vars.colors.grey[0]} fontSize="xs" bold>
          {T('app_version')} {ENV.deviceInfo.app.version}
        </Text>
        <Text color={theme.vars.colors.grey[0]} fontSize="xs" bold>
          {T('editor_version')} {ENV.deviceInfo.app.editor}
        </Text>
        <Promt
          visible={delPromtVisible}
          text={T('shop_exit_promt')}
          onYes={() => {
            DB_AUTH.set({ shopCode: null }).then(() => {
              if (Platform.OS === 'web') {
                location.reload();
              } else {
                RNRestart.Restart();
              }
            });
          }}
          onNo={() => {
            setDelPromtVisible(false);
          }}
          onCancel={() => {
            setDelPromtVisible(false);
          }}
        />
        <Promt
          visible={resetPromtVisible}
          subText={T('delete_cache_promt_sub')}
          text={T('delete_cache_promt')}
          onYes={() => {
            clearCache();
            logout();
            filledProjects(false);
            filledCart(false);

            setTimeout(() => {
              if (Platform.OS === 'web') {
                location.reload();
              } else {
                RNRestart.Restart();
              }
            }, 300);
          }}
          onNo={() => {
            setResetPromtVisible(false);
          }}
          onCancel={() => {
            setResetPromtVisible(false);
          }}
        />
      </Box>
    );
  }

  return (
    <Box
      style={{ width: '100%' }}
      flexDirection="column"
      alignItems="flex-start"
      justifyContent="flex-start"
    >
      <Box
        style={{ width: '100%' }}
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Box flex={1} style={{ marginVertical: 5 }}>
          <Text
            color={theme.vars.colors.dark[0]}
            fontSize="lg"
            style={{ textTransform: 'uppercase' }}
            bold
          >
            {data?.UserShop?.name}
          </Text>

          {data?.UserShop?.addresses?.commercial[0]?.denomination ? (
            <Text color={theme.vars.colors.dark[0]} fontSize="md">
              {data.UserShop.addresses.commercial[0].denomination}
            </Text>
          ) : null}

          {data?.UserShop?.addresses?.commercial[0]?.street ? (
            <Text color={theme.vars.colors.dark[0]} fontSize="md">
              {data?.UserShop?.addresses?.commercial[0]?.street}
            </Text>
          ) : null}

          {data?.UserShop?.addresses?.commercial[0]?.city ? (
            <Text color={theme.vars.colors.dark[0]} fontSize="md">
              {data?.UserShop?.addresses?.commercial[0]?.zipCode} -{' '}
              {data?.UserShop?.addresses?.commercial[0]?.city},{' '}
              {data?.UserShop?.addresses?.commercial[0]?.state}
            </Text>
          ) : null}
          {/* <Text color={theme.vars.colors.dark[0]} fontSize="md">
            Tel. {data?.UserShop?.}
          </Text>
          <Text color={theme.vars.colors.dark[0]} fontSize="md">
            www.easypixy.com
          </Text> */}
          <Pressable
            onPress={() => {
              setDelPromtVisible(true);
            }}
            flexDirection={'row'}
            alignItems="center"
          >
            <Text color={theme.vars.colors.blue[0]} fontSize="xl" bold>
              {T('shop_exit')}
            </Text>
          </Pressable>
        </Box>
        <Box flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
          <Text color={theme.vars.colors.dark[0]} fontSize="md" bold>
            {data?.UserShop?.code}
          </Text>
          <Box
            style={{
              backgroundColor: '#fff',
              padding: 2,
              borderRadius: 10,
              ...theme.vars.shadow['lg'],
              shadowColor: theme.vars.colors.violet[0],
              shadowRadius: 8,
              shadowOpacity: 0.3,
            }}
          >
            <QRCode value={'https://get.easypixy.com/' + data?.UserShop?.code} size={70} />
          </Box>
        </Box>
      </Box>

      <Promt
        visible={delPromtVisible}
        text={T('shop_exit_promt')}
        onYes={() => {
          DB_AUTH.set({ shopCode: null }).then(() => {
            if (Platform.OS === 'web') {
              location.reload();
            } else {
              RNRestart.Restart();
            }
          });
        }}
        onNo={() => {
          setDelPromtVisible(false);
        }}
        onCancel={() => {
          setDelPromtVisible(false);
        }}
      />
    </Box>
  );
};

export { ShopInfo };
