import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {
  StyleSheet,
  View,
  TouchableOpacity,
  ScrollView,
  Keyboard,
  AppState,
  Platform,
} from 'react-native';
import { Text, Box, Image, Center, Pressable, Select, CheckIcon } from 'native-base';
import { useKeepAwake } from 'expo-keep-awake';
import * as ImageManipulator from 'expo-image-manipulator';
import * as FileSystem from 'expo-file-system';

import { Product, Shop, USER_SHOP_MIN } from '../../stores/queries/shop';
import { Button, Icon } from '../../ui';
import { ENV, LOG, theme } from '../../config';
import { LocalImage } from '../../stores/db/orders';
import { shopInstant } from '../../stores/db/appstate';

import { Promt } from '../../ui/Promt';
import { Loading } from '../../ui/Loading';

import { ProgressBar } from '../../ui/ProgressBar';
import { PhotoPicker_Next } from '../../components/Editor/PhotoPicker_Next';
import { T } from '../../config/localization';
import { CodeInput } from '../../ui/CodeInput';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useQuery } from '@apollo/client';
import { ACTUAL_AUTH, DB_AUTH } from '../../stores/db/auth';
import { APP_STATE, APP_STATE_PROPS } from '../../stores/queries/appstate';
import { uploadInstant } from '../../libs/upload';
import RNRestart from 'react-native-restart';

const log = LOG.extend('INSTANT');

const QUALITY = {
  low: 1000,
  med: 2000,
  hig: 3000,
};

type elaborateImageProps = {
  image: LocalImage;
  quality: 'low' | 'med' | 'hig';
};

const elaborateImage = async (props: elaborateImageProps): Promise<string | null> => {
  try {
    let resize;

    if (props.image.width > props.image.height) {
      resize = { width: QUALITY[props.quality] };
    } else {
      resize = { height: QUALITY[props.quality] };
    }

    let manipResult = await ImageManipulator.manipulateAsync(props.image.path, [{ resize }], {
      compress: 0.8,
      format: ImageManipulator.SaveFormat.JPEG,
      base64: true,
    });
    let image = {
      path: manipResult.base64,
      width: manipResult.width,
      height: manipResult.height,
    };

    if (Platform.OS !== 'web') {
      await FileSystem.deleteAsync(manipResult.uri);
    }

    image.path = 'data:image/gif;base64,' + image.path;
    return image.path;
  } catch (error) {
    log.error(error);
    return null;
  }
};

const ShopInstant = () => {
  const [images, setImages] = useState<LocalImage[]>([]);
  const [code, setCode] = useState<string>();
  const [step, setStep] = useState<'code' | 'picker' | 'upload' | 'complete' | 'error'>('code');
  const [quality, setQuality] = useState<'low' | 'med' | 'hig'>('med');
  const [progress, setProgress] = useState<number>(0);
  const [progressMsg, setProgressMsg] = useState<string>();
  const [fullEnabled, setFullEnabled] = useState(false);
  const [delPromtVisible, setDelPromtVisible] = useState(false);

  useKeepAwake();

  const { data: APP } = useQuery<APP_STATE_PROPS>(APP_STATE);

  const { loading, error, data, refetch } = useQuery<{ UserShop: Shop }>(USER_SHOP_MIN, {
    fetchPolicy: 'cache-and-network',
    skip: !APP.shopCode,
    variables: {
      code: ACTUAL_AUTH.shopCode,
    },
  });

  const [appCurState, setAppCurState] = useState<string>('active');
  const handleAppStateChange = (state: string) => {
    setAppCurState(state);
  };
  useEffect(() => {
    const appstateEvent = AppState.addEventListener('change', handleAppStateChange);
    return () => {
      appstateEvent.remove();
    };
  }, []);

  useEffect(() => {
    if (appCurState === 'active') {
      refetch();
    }
  }, [appCurState]);

  useEffect(() => {
    if (data?.UserShop?.type === 'full') {
      setFullEnabled(true);
    }
  }, [data?.UserShop?.type]);

  useEffect(() => {
    if (images?.length > 0 && step === 'picker') {
      setStep('upload');
      setTimeout(() => {
        upload(images);
      }, 50);
    }
  }, [images]);

  const upload = async (images: LocalImage[]) => {
    setProgress(0);
    setProgressMsg(null);
    for (let i = 0; i < images.length; i++) {
      const image = images[i];
      let base64 = await elaborateImage({ image, quality });
      if (base64) {
        let up = await uploadInstant({
          name: code + '_' + i + '.jpg',
          code,
          imageBase64: base64,
          onProgress: (w, t) => {
            setProgressMsg('Immagine ' + (i + 1) + ' di ' + images.length);
            setProgress((100 / images.length) * i + (w / t) * (100 / images.length));
          },
        });
        if (!up) {
          setImages([]);
          setStep('error');
          return;
        }
      } else {
        setImages([]);
        setStep('error');
        return;
      }
    }
    setImages([]);
    setStep('complete');
  };

  if (step === 'picker') {
    return (
      <Box style={{ width: '100%', flex: 1 }} justifyContent={'center'} alignItems={'center'}>
        <Image
          style={{
            width: '50%',
            maxWidth: 280,
            height: '20%',
            resizeMode: 'contain',
            marginBottom: 0,
          }}
          source={require('../../assets/img/illustrations/upload-foto.png')}
          alt="logo"
        />

        <Text
          maxW={300}
          mb={2}
          color={theme.vars.colors.dark[0]}
          fontSize={'2xl'}
          textAlign={'center'}
          lineHeight={30}
          bold
        >
          1. Seleziona la qualità adatta per le tue foto
        </Text>

        <Center>
          <Box maxW="500">
            <Select
              selectedValue={quality}
              minWidth="300"
              accessibilityLabel="Choose Service"
              placeholder="Choose Service"
              _selectedItem={{
                bg: theme.vars.colors.violet[2],
                endIcon: <CheckIcon size="5" />,
                fontSize: 20,
              }}
              _text={{ fontSize: 20 }}
              _item={{
                fontSize: 20,
              }}
              // @ts-ignore
              style={{ fontSize: 18 }}
              mt={1}
              onValueChange={itemValue => setQuality(itemValue as any)}
            >
              <Select.Item label="Alta, per stampe di grandi dimensioni" value="hig" />
              <Select.Item label="Media, per tutte le stampe" value="med" />
              <Select.Item label="Bassa, per stampe di piccole dimensioni" value="low" />
            </Select>
          </Box>
        </Center>

        <Text
          mt={8}
          maxW={300}
          mb={2}
          color={theme.vars.colors.dark[0]}
          fontSize={'2xl'}
          textAlign={'center'}
          lineHeight={30}
          bold
        >
          2. {T('select_pictures_title')}
        </Text>

        <Center mt={2} flexDirection={'row'}>
          <PhotoPicker_Next
            isOpen
            style={{ marginHorizontal: 8 }}
            setImages={setImages}
            totalImages={0}
            onStart={() => {
              //setLoading(true);
            }}
            onFinish={() => {
              //setLoading(false);
            }}
          />
        </Center>
      </Box>
    );
  }

  if (step === 'upload') {
    return (
      <Box
        pt={2}
        bg={theme.vars.colors.light[0]}
        flex={1}
        alignItems="center"
        justifyContent="flex-start"
      >
        <Box flex={1} alignItems="center" justifyContent="center" safeAreaBottom>
          <Image
            mb={6}
            alt={'ritoro_negozio'}
            style={{ resizeMode: 'contain', width: 160, height: 160 }}
            source={require('../../assets/img/illustrations/rocket.png')}
          ></Image>
          <Text color={theme.vars.colors.grey[0]} mb={0} fontSize={'lg'} bold>
            {T('upload_order')}
          </Text>
          <Text color={theme.vars.colors.grey[0]} mb={6} fontSize={'md'}>
            {T('upload_order_hint')}
          </Text>

          <Box>
            <Center style={{ width: '100%', maxWidth: 500 }} mb={6}>
              <Text color={theme.vars.colors.blue[0]} mt={4} fontSize={30} bold>
                {progress.toFixed(0)} %
              </Text>

              <ProgressBar
                width={300}
                color={theme.vars.colors.blue[0]}
                bg={theme.vars.colors.blue[2]}
                progress={progress || 0}
              />
              <Text color={theme.vars.colors.grey[0]} mb={6} fontSize={'xs'}>
                {progressMsg || T('upload_begin')}
              </Text>
            </Center>
          </Box>
        </Box>

        <Loading bg={'rgba(255,255,255,0.5)'} visible={loading} />
      </Box>
    );
  }

  if (step === 'complete') {
    return (
      <Box
        pt={2}
        bg={theme.vars.colors.light[0]}
        flex={1}
        alignItems="center"
        justifyContent="flex-start"
      >
        <Box flex={1} alignItems="center" justifyContent="center" safeAreaBottom>
          <Image
            mb={6}
            alt={'ritoro_negozio'}
            style={{ resizeMode: 'contain', width: 160, height: 160 }}
            source={require('../../assets/img/illustrations/ok.png')}
          ></Image>
          <Text color={theme.vars.colors.grey[0]} mb={6} fontSize={30} bold>
            {T('thanks')}
          </Text>
          <Text color={theme.vars.colors.grey[0]} mb={0} fontSize={'lg'} bold>
            {T('complete_order_title')}
          </Text>
          {/* <Text color={theme.vars.colors.grey[0]} mb={8} fontSize={'md'}>
            {T('complete_order_hint')}
          </Text> */}

          <Button
            color="violet"
            shadow="lg"
            style={{ width: '100%', maxWidth: 300, shadowOpacity: 0.2 }}
            onPress={() => {
              setCode(undefined);
              setImages([]);
              setStep('code');
            }}
          >
            {T('continue')}
          </Button>
        </Box>
      </Box>
    );
  }

  if (step === 'error') {
    return (
      <Box
        pt={2}
        bg={theme.vars.colors.light[0]}
        flex={1}
        alignItems="center"
        justifyContent="flex-start"
      >
        <Box flex={1} alignItems="center" justifyContent="center" safeAreaBottom>
          <Image
            mb={2}
            alt={'ritoro_negozio'}
            style={{ resizeMode: 'contain', width: 180, height: 180 }}
            source={require('../../assets/img/illustrations/avviso.png')}
          ></Image>

          <Text color={theme.vars.colors.red[0]} mb={0} fontSize={'2xl'} bold textAlign={'center'}>
            {T('order_error_title')}
          </Text>
          <Text mx={5} textAlign={'center'} color={theme.vars.colors.red[0]} mb={6} fontSize={'lg'}>
            Errore imprevisto, controlla che il codice istantaneo {code} sia corretto!
          </Text>

          <Button
            color="violet"
            shadow="lg"
            style={{ shadowOpacity: 0.2 }}
            onPress={() => {
              setImages([]);
              setStep('code');
              /* props.navigation.replace('JobUpload', {
                jobId: props.route.params.jobId,
              }); */
            }}
          >
            {T('retry')}
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <Box style={{ width: '100%', flex: 1 }} justifyContent={'center'} alignItems={'center'}>
      {/*       <Text
        width={200}
        color={theme.vars.colors.dark[0]}
        textAlign={'center'}
        lineHeight={20}
        fontSize={'lg'}
      >
        {T('select_pictures_hint')}
      </Text> */}
      <KeyboardAwareScrollView
        contentContainerStyle={{ justifyContent: 'center', alignItems: 'center' }}
      >
        <Image
          style={{
            marginTop: '40%',
            width: 240,
            height: 50,
            resizeMode: 'contain',
            marginBottom: 50,
          }}
          source={require('../../assets/img/logo.png')}
          alt="logo"
        />
        <Text
          width={270}
          mb={2}
          color={theme.vars.colors.dark[0]}
          fontSize={'2xl'}
          textAlign={'center'}
          lineHeight={30}
          bold
        >
          Inserisci il codice istantaneo che ti ha fornito il fotografo per caricare le tue foto
        </Text>
        <Center mt={2} flexDirection={'row'}>
          <Box style={{ marginVertical: 0, marginBottom: 30 }}>
            <CodeInput
              value={code}
              onChange={code => setCode(code.toUpperCase())}
              onComplete={code => {
                //checkShop(code);
              }}
            />
          </Box>
        </Center>
        <Button
          shadow="lg"
          color="violet"
          onPress={() => {
            if (code?.length > 4) {
              setStep('picker');
            }

            /* if (actualShopCode.length > 4) {
                Keyboard.dismiss();
                checkShop(actualShopCode);
              } */
          }}
        >
          {T('begin')}
        </Button>
        <Text
          mt={20}
          style={{
            fontSize: 18,
            color: theme.vars.colors.violet[0],
            textTransform: 'uppercase',
          }}
          bold
        >
          {data?.UserShop?.name} - #{data?.UserShop?.code}
        </Text>
        {fullEnabled ? (
          <Pressable
            onPress={() => {
              shopInstant(false);
            }}
            flexDirection={'row'}
            alignItems="center"
          >
            <Text color={theme.vars.colors.blue[0]} fontSize="xl" bold>
              {T('return_to_full')}
            </Text>
          </Pressable>
        ) : null}
        <Pressable
          mt={10}
          onPress={() => {
            setDelPromtVisible(true);
          }}
          flexDirection={'row'}
          alignItems="center"
        >
          <Text color={theme.vars.colors.blue[0]} fontSize="xl" bold>
            {T('shop_exit')}
          </Text>
        </Pressable>
      </KeyboardAwareScrollView>
      <Promt
        visible={delPromtVisible}
        text={T('shop_exit_promt')}
        onYes={() => {
          DB_AUTH.set({ shopCode: null }).then(() => {
            if (Platform.OS === 'web') {
              location.reload();
            } else {
              RNRestart.Restart();
            }
          });
        }}
        onNo={() => {
          setDelPromtVisible(false);
        }}
        onCancel={() => {
          setDelPromtVisible(false);
        }}
      />
    </Box>
  );
};

export { ShopInstant };
