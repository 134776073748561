import { Spinner, Text } from 'native-base';
import React, { useEffect, useState } from 'react';
import { Image, ViewStyle } from 'react-native';
import Animated, {
  Easing,
  withTiming,
  useSharedValue,
  useAnimatedStyle,
  runOnJS,
} from 'react-native-reanimated';
import { theme } from '../../config';

interface LoadingProps {
  visible: boolean;
  text?: string;
  hint?: string;
  bg?: string;
  style?: ViewStyle;
}

const Loading = (props: LoadingProps) => {
  const opacity = useSharedValue(100);

  const animOpacity = useAnimatedStyle(() => {
    return { opacity: opacity.value / 100 };
  });

  const [display, setDisplay] = useState<boolean>(true);

  const removeElement = () => {
    setDisplay(false);
  };

  useEffect(() => {
    if (props.visible) {
      setDisplay(true);
      opacity.value = withTiming(100, {
        duration: 1,
        easing: Easing.out(Easing.exp),
      });
    } else {
      opacity.value = withTiming(
        0,
        {
          duration: 500,
          easing: Easing.out(Easing.exp),
        },
        isFinished => {
          if (isFinished) {
            runOnJS(removeElement)();
          }
        }
      );
    }
  }, [props.visible]);

  if (!display) return null;

  return (
    <Animated.View
      style={[
        {
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: '100%',
          height: '100%',
          backgroundColor: props.bg ? props.bg : undefined,
        },
        props.style,
        animOpacity,
      ]}
    >
      <Spinner size={'lg'} color={theme.vars.colors.dark[0]} accessibilityLabel="Loading Shop" />
      {props.text && (
        <Text color={theme.vars.colors.dark[0]} fontSize="md" p={2} bold>
          {props.text}
        </Text>
      )}
      {props.hint && (
        <Text
          style={{ position: 'absolute', bottom: 10, width: '80%', textAlign: 'center' }}
          color={theme.vars.colors.dark[0]}
          fontSize="sm"
          p={2}
          bold
        >
          {props.hint}
        </Text>
      )}
    </Animated.View>
  );
};

export { Loading };
