import React, { useEffect, useState } from 'react';
import { InteractionManager } from 'react-native';
import { Text, Box, Image, Center, HStack, Pressable, VStack } from 'native-base';
import { SwipeListView } from 'react-native-swipe-list-view';
import { theme } from '../../config';
import { DB_ORDERS, Order } from '../../stores/db/orders';
import { useFocusEffect } from '@react-navigation/core';
import { OrderItem } from '../../components/Order/OrderItem';
import { filledProjects } from '../../stores/db/appstate';
import { Button, Icon } from '../../ui';
import { Promt } from '../../ui/Promt';
import { Loading } from '../../ui/Loading';
import { T } from '../../config/localization';

interface ProjectsProps {
  navigation: any;
}

const Projects = (props: ProjectsProps) => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [selectedOrder, setSelectedOrder] = useState<{
    order: Order;
    index: number;
  }>();
  const [delPromtVisible, setDelPromtVisible] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);

  useFocusEffect(
    React.useCallback(() => {
      const task = InteractionManager.runAfterInteractions(() => {
        DB_ORDERS.getOrdersByState('project').then(orders => {
          setOrders(orders);
          setLoading(false);
        });
      });

      return () => task.cancel();
    }, [])
  );

  useEffect(() => {
    if (orders.length < 1) {
      filledProjects(false);
    }
  }, [orders]);

  const deleteOrder = async (order: Order, index: number) => {
    let newOrders = [...orders];
    newOrders.splice(index, 1);
    setOrders(newOrders);
    DB_ORDERS.removeOrder(order.key);
  };

  const renderItem = ({ item, index }) => (
    <OrderItem
      onDel={() => {
        setSelectedOrder({
          order: item,
          index: index,
        });
        setDelPromtVisible(true);
      }}
      key={index}
      order={item}
    />
  );

  const renderHiddenItem = (data, rowMap) => (
    <HStack
      alignContent={'flex-end'}
      justifyContent={'flex-end'}
      flex="1"
      width={'100%'}
      style={{ padding: 6, paddingRight: 0, overflow: 'hidden' }}
    >
      <Pressable
        w="100"
        // @ts-ignore
        cursor="pointer"
        bg={theme.vars.colors.red[0]}
        justifyContent="center"
        onPress={() => {
          rowMap[data.item.key].closeRow();
          setSelectedOrder({
            order: data.item,
            index: data.index,
          });
          setDelPromtVisible(true);
        }}
        _pressed={{
          opacity: 0.5,
        }}
        style={{
          flex: 1,
          alignItems: 'flex-end',
          paddingTop: 5,
          paddingRight: 10,
          borderTopLeftRadius: 20,
          borderBottomLeftRadius: 20,
          borderRadius: 20,
        }}
      >
        <VStack alignItems="center" space={2}>
          <Icon name="cestino" size={18} />
          <Text
            color={theme.vars.colors.light[0]}
            textAlign={'center'}
            fontSize="xs"
            style={{ height: 30, lineHeight: 30 }}
            bold
          >
            {T('delete')}
          </Text>
        </VStack>
      </Pressable>
    </HStack>
  );

  return (
    <Box bg={theme.vars.colors.light[0]} flex={1} style={{ alignItems: 'center' }} safeAreaTop>
      <Box
        style={{
          maxWidth: 1024,
          width: '100%',
          alignItems: 'center',
        }}
        flex={1}
      >
        {orders.length > 0 ? (
          <>
            <Box
              pt={3}
              style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}
            >
              <Text
                style={{
                  paddingTop: 10,
                  paddingBottom: 3,
                  marginBottom: 5,
                  fontSize: 24,
                  width: '100%',
                  textAlign: 'center',
                  color: theme.vars.colors.dark[0],
                  textTransform: 'capitalize',
                }}
                bold
              >
                {T('projects')}
              </Text>
            </Box>
            <SwipeListView
              style={{ flex: 1, width: '100%' }}
              contentContainerStyle={{
                paddingTop: 0,
                paddingBottom: 40,
                paddingHorizontal: 20,
              }}
              data={orders}
              renderItem={renderItem}
              renderHiddenItem={renderHiddenItem}
              rightOpenValue={-70}
              previewRowKey={'0'}
              previewOpenValue={-40}
              previewOpenDelay={3000}
              //onRowDidOpen={onRowDidOpen}
            />
          </>
        ) : (
          <>
            <Box style={{ width: '100%', flex: 1 }} justifyContent={'center'} alignItems={'center'}>
              <Text
                width={200}
                mb={2}
                color={theme.vars.colors.dark[0]}
                fontSize={'3xl'}
                textAlign={'center'}
                lineHeight={28}
                bold
              >
                {T('void_projects')}
              </Text>
              <Text
                width={200}
                mb={2}
                color={theme.vars.colors.dark[0]}
                textAlign={'center'}
                lineHeight={20}
                fontSize={'lg'}
              >
                {T('void_projects_hint')}
              </Text>
              <Image
                style={{
                  width: '70%',
                  maxWidth: 280,
                  height: '30%',
                  resizeMode: 'contain',
                }}
                source={require('../../assets/img/illustrations/upload-foto.png')}
                alt="logo"
              />
              <Center style={{ width: '100%' }} mt={2}>
                <Button
                  color="violet"
                  shadow="md"
                  onPress={() => {
                    props.navigation.navigate('Shop');
                  }}
                >
                  {T('begin_now')}
                </Button>
              </Center>
            </Box>
          </>
        )}
        <Promt
          visible={delPromtVisible}
          text={T('delete_project_promt')}
          onYes={() => {
            setDelPromtVisible(false);
            if (selectedOrder?.order) {
              deleteOrder(selectedOrder.order, selectedOrder.index);
            }
          }}
          onNo={() => {
            setDelPromtVisible(false);
          }}
          onCancel={() => {
            setDelPromtVisible(false);
          }}
        />
      </Box>
      <Loading bg={theme.vars.colors.light[0]} visible={loading} />
    </Box>
  );
};

export { Projects };
