import React, { useEffect } from 'react';
import { Box } from 'native-base';
import { theme } from '../../config';
import { LinearGradient } from 'expo-linear-gradient';
import { ProductTree } from '../../stores/queries/shop';
import { Colors } from '../../config/theme';
import { FolderView } from '../../components/ProductTree/FolderView';
import { T } from '../../config/localization';

interface ProductsPathProps {
  navigation: any;
  route: {
    params: {
      productTree: ProductTree[];
      title: string;
      color: Colors;
    };
  };
}

const ProductsPath = (props: ProductsPathProps) => {
  useEffect(() => {
    props.navigation.setOptions({
      headerTitle: props?.route?.params?.title || T('product'),
    });
  }, []);

  return (
    <Box bg={theme.vars.colors.light[0]} flex={1}>
      <FolderView color={props.route.params.color} productTree={props.route.params.productTree} />
    </Box>
  );
};

export { ProductsPath };
