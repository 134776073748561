import React from 'react';
import { Image as RNImage, ImageProps, View } from 'react-native';
interface Props extends ImageProps {
  blurhash: string;
}
const Image: React.FC<Props> = ({ blurhash, ...imageProps }) => {
  return (
    <View style={imageProps.style}>
      <RNImage {...imageProps} style={imageProps.style} />
    </View>
  );
};

export { Image };
