import React, { ReactNode } from 'react';
import { Pressable, Box } from 'native-base';

import { theme } from '../../config';
import { Colors, Sizes } from '../../config/theme';
import { Platform, ViewStyle } from 'react-native';
import { Icon, Icons } from '../Icon';

interface IconButtonProps {
  icon: Icons;
  color?: Colors;
  size?: Sizes;
  shadow?: Sizes;
  boxStyle?: ViewStyle;
  style?: ViewStyle;
  onPress?: Function;
  disabled?: boolean;
}

const IconButton = (props: IconButtonProps) => {
  let containerStyle: ViewStyle = {
    borderRadius: 23,
    height: 46,
    width: 46,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    margin: 5,
  };

  let p = 12;

  switch (props.size) {
    case 'xs':
      p = 12;
      break;
    case 'sm':
      p = 14;
      break;
    case 'md':
      p = 16;
      break;
    case 'lg':
      p = 20;
      break;
    case 'xl':
      p = 22;
      break;
    default:
      break;
  }

  let color = theme.vars.colors.violet;

  if (props.color && theme.vars.colors[props.color]) {
    color = theme.vars.colors[props.color];
  }

  let bg =
    props.color === 'light'
      ? '#fff'
      : {
          linearGradient: {
            colors: [color[0], color[1]],
            start: [0.3, 1.1],
            end: [0.01, 0.1],
          },
        };

  return (
    <Pressable
      cursor={Platform.OS == 'web' ? 'pointer' : undefined}
      style={[
        containerStyle,
        props.shadow
          ? {
              ...theme.vars.shadow[props.shadow],
              shadowColor: props.color === 'light' ? theme.vars.colors.dark[0] : color[0],
            }
          : undefined,
        props.style,
        { opacity: props.disabled ? 0.4 : 1 },
      ]}
      _pressed={{
        opacity: 0.7,
      }}
      onPress={() => {
        props.onPress && props.onPress();
      }}
    >
      <Box
        bg={bg}
        flex={1}
        style={[
          {
            justifyContent: 'center',
            alignItems: 'center',
            height: 46,
            width: 46,
            borderRadius: 23,
            flex: 1,
          },
          props.boxStyle,
        ]}
      >
        <Icon name={props.icon} size={p} style={{ color: '#fff' }} />
      </Box>
    </Pressable>
  );
};

export { IconButton };
