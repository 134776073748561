import React, { useEffect, useState } from 'react';
import * as SplashScreen from 'expo-splash-screen';
import { Image } from 'react-native';
import Animated, {
  Easing,
  withTiming,
  useSharedValue,
  useAnimatedStyle,
  runOnJS,
} from 'react-native-reanimated';

interface GlobalLoadingProps {
  visible: boolean;
}

const GlobalLoading = (props: GlobalLoadingProps) => {
  const opacity = useSharedValue(100);

  const animOpacity = useAnimatedStyle(() => {
    return { opacity: opacity.value / 100 };
  });

  const [display, setDisplay] = useState<boolean>(true);

  const removeElement = () => {
    setDisplay(false);
  };

  useEffect(() => {
    if (props.visible) {
      setDisplay(true);
      opacity.value = withTiming(100, {
        duration: 1,
        easing: Easing.out(Easing.exp),
      });
    } else {
      opacity.value = withTiming(
        0,
        {
          duration: 1000,
          easing: Easing.out(Easing.exp),
        },
        isFinished => {
          if (isFinished) {
            runOnJS(removeElement)();
          }
        }
      );
    }
  }, [props.visible]);

  useEffect(() => {
    setTimeout(() => {
      SplashScreen.hideAsync();
    }, 500);
  }, []);

  if (!display) return null;

  return (
    <Animated.View
      //pointerEvents={'none'}
      style={[
        {
          alignItems: 'center',
          justifyContent: 'center',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: '100%',
          height: '100%',
        },
        animOpacity,
      ]}
    >
      <Image
        style={{ flex: 1, resizeMode: 'cover', width: '100%', height: '100%' }}
        source={require('../../assets/img/bg/splashscreen_image.png')}
      />
    </Animated.View>
  );
};

export { GlobalLoading };
