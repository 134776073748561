import * as React from 'react';
import { Dimensions, Platform, Pressable } from 'react-native';
import { Box, Modal, Center, useDisclose } from 'native-base';

import { theme } from '../../config';
import { BlurView } from 'expo-blur';
import { Loading } from '../Loading';
import { Icon } from '../Icon';
import { IconButton } from '../IconButton';

interface BlurModalProps {
  children: React.ReactNode;
  isOpen: boolean;
  isLoading?: boolean;
  height?: number;
  onClose?: Function;
}

const BlurModal = (props: BlurModalProps) => {
  const MD = useDisclose();

  return (
    <Modal
      backdropVisible={true}
      _backdrop={{
        _dark: {
          bg: 'rgba(0,0,0,0)',
        },
        bg: 'rgba(0,0,0,0)',
      }}
      isOpen={props.isOpen}
      onClose={props.onClose}
      size={'md'}
      isKeyboardDismissable={true}
      //avoidKeyboard={true}
      animationPreset={'slide'}
      shadow={1}
      style={{ shadowRadius: 8, shadowOpacity: 0.1 }}
    >
      <Box
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
          width: '86%',
          height: props.height || '80%',
          maxWidth: 600,
          borderColor: theme.vars.colors.light[2],
          borderWidth: 1,
          borderRadius: 20,
          backgroundColor:
            Platform.OS === 'android' ? 'rgba(255,255,255,0.94)' : 'rgba(255,255,255,0.75)',
        }}
      >
        <BlurView
          intensity={60}
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            width: '100%',
          }}
        >
          {/*          <Modal.CloseButton
            _icon={() => <Icon name="aggiungi" />}
            style={{ backgroundColor: theme.vars.colors.grey[0] }}
          /> */}
          {props.children}

          {props.onClose && (
            <IconButton
              onPress={() => {
                props.onClose();
              }}
              size={'lg'}
              icon="elimina"
              color="grey"
              style={{ position: 'absolute', right: 0, top: 0, transform: [{ scale: 0.6 }] }}
            />
          )}
        </BlurView>
        <Loading bg={'rgba(255,255,255,0.2)'} text="Elaborazione Foto" visible={props.isLoading} />
      </Box>
    </Modal>
  );
};

export { BlurModal };
