import React, { useEffect } from 'react';
import { ViewStyle } from 'react-native';

import { Icon } from '../../ui';
import { theme } from '../../config';
import { useFilePicker } from 'use-file-picker';
import { Pressable } from 'native-base';

const constructImagesArray = async files => {
  let images = [];
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    let image = {
      path: file.content,
    };
    images.push(image);
  }
  return images;
};

interface PhotoPicker_NextProps {
  style: ViewStyle;
  setImages: Function;
  totalImages: number;
  onStart: Function;
  onFinish: Function;
}

const PhotoPicker_Next = (props: PhotoPicker_NextProps) => {
  const [openFileSelector, { filesContent, loading, errors }] = useFilePicker({
    readAs: 'DataURL',
    accept: 'image/*',
    multiple: true,
    limitFilesConfig: { max: 500 - props.totalImages },
    // minFileSize: 0.1, // in megabytes
    maxFileSize: 500,
    /* imageSizeRestrictions: {
      maxHeight: 900, // in pixels
      maxWidth: 1600,
      minHeight: 600,
      minWidth: 768,
    }, */
  });

  useEffect(() => {
    if (loading) {
      //props.onStart && props.onStart();
    }
  }, [loading]);

  useEffect(() => {
    if (filesContent && filesContent.length > 0) {
      //props.onStart && props.onStart();
      setTimeout(() => {
        constructImagesArray(filesContent).then(images => {
          props.setImages && props.setImages(images);
        });
      }, 500);
    } else {
      //props.onFinish && props.onFinish();
    }
  }, [filesContent]);

  return (
    <Pressable
      onPress={() => {
        //props.onStart && props.onStart();
        openFileSelector();
      }}
      style={[
        {
          borderColor: theme.vars.colors.violet[1],
          borderWidth: 1,
          width: 66,
          height: 66,
          borderRadius: 33,
          backgroundColor: theme.vars.colors.light[0],
          ...theme.vars.shadow['lg'],
          shadowColor: theme.vars.colors.violet[0],
          shadowRadius: 8,
          shadowOpacity: 0.3,
          justifyContent: 'center',
          alignItems: 'center',
        },
        props.style,
      ]}
    >
      <Icon color={'violet'} size={28} name={'aggiungi-foto'} />
    </Pressable>
  );
};

export { PhotoPicker_Next };
