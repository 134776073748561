import { Dimensions, StyleSheet, Platform } from 'react-native';

const screenHeight = Dimensions.get('window').height;
const screenWidth = Dimensions.get('window').width;

type Sizes = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

type Colors = 'yellow' | 'pink' | 'green' | 'blue' | 'violet' | 'red' | 'grey' | 'dark' | 'light';

let colors: { [Color in Colors]: string[] } = {
  yellow: ['#DDDD6F', '#EDEA94', '#F9F3C0'],
  pink: ['#FF7D7D', '#FFAEAE', '#FAE3E6'],
  green: ['#208920', '#54B254', '#E2F7E6'],
  blue: ['#3DB7B4', '#93C7C6', '#D5E8E8'],
  violet: ['#6464FF', '#9797FC', '#D6D9FE'],
  red: ['#FF5E3E', '#FF9880', '#FFD9CC'],
  grey: ['#707070', '#CECECE', '#E2E2E2'],
  dark: ['#575757', '#575757', '#575757'],
  light: ['#F5F5F5', '#F5F5F5', '#F5F5F5'],
};

const shadow: {
  [Size in Sizes]: object;
} = {
  xs: {
    shadowColor: colors.dark[0],
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.5,
    shadowRadius: 1.41,
    elevation: 2,
  },
  sm: {
    shadowColor: colors.dark[0],
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.6,
    shadowRadius: 2.22,
    elevation: 3,
  },
  md: {
    shadowColor: colors.dark[0],
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.6,
    shadowRadius: 2.62,
    elevation: 4,
  },
  lg: {
    shadowColor: colors.dark[0],
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.7,
    shadowRadius: 3.84,
    elevation: 5,
  },
  xl: {
    shadowColor: colors.dark[0],
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.7,
    shadowRadius: 5,
    elevation: 7,
  },
};

const vars = {
  fontSizes: {
    xs: 8,
    s: 12,
    m: 16,
    l: 22,
    xl: 28,
    xxl: 36,
  },
  colors,
  shadow,
  screen: {
    width: screenWidth,
    height: screenHeight,
    sm: 576,
    md: 768,
  },
  base: {
    margin: 5,
    radius: 10,
    border: 0,
    //textColor: colors.dar[0],
    //textFont: 'kanit-regular',
    //textFontBold: 'kanit-bold',
    //iconFont: 'mowi-icons',
    bkgColor: colors.dark,
  },
};

// Texts

const textDefault = {
  //color: vars.base.textColor,
  //fontFamily: vars.base.textFont,
  fontSize: vars.fontSizes.l,
};

const text = StyleSheet.create({
  xs: { ...textDefault, fontSize: vars.fontSizes.xs },
  s: { ...textDefault, fontSize: vars.fontSizes.s },
  m: { ...textDefault, fontSize: vars.fontSizes.m },
  l: { ...textDefault, fontSize: vars.fontSizes.l },
  xl: { ...textDefault, fontSize: vars.fontSizes.xl },
  bold: {
    fontWeight: 'bold',
    //fontFamily: 'kanit-bold',
  },
  blockCenter: {
    width: '100%',
    textAlign: 'center',
  },
});

// Generals

const general = StyleSheet.create({
  fullAbsulute: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: screenWidth,
    height: screenHeight,
  },
  fullWidth: {
    width: '100%',
    maxWidth: '100%',
  },
  pageContainer: {
    width: '100%',
    height: '100%',
    maxWidth: 1000,
    borderLeftWidth: Platform.OS === 'web' ? 2 : 0,
    borderRightWidth: Platform.OS === 'web' ? 2 : 0,
    borderColor: colors.dark[1],
    alignSelf: 'center',
  },
  pageContainerMin: {
    width: '100%',
    height: '100%',
    maxWidth: 600,
    alignSelf: 'center',
  },
});

const theme = {
  vars,
  general,
  text,
};

export { theme, Colors, Sizes };
