import React, { useState } from 'react';
import { Text, Box, Pressable } from 'native-base';
import { theme } from '../../config';

import { Image, Platform } from 'react-native';
import { Job } from '../../stores/queries/jobs';
import { T } from '../../config/localization';

/* const statusLabels = {
  initializing: 'Upload in corso',
  readyForPayment: 'In attesa di pagamento',
  completed: 'In elaborazione',
  canceledByUser: "Annullato dall'utente",
  canceledByAdmin: 'Annullato dal fotografo',
  confirmed: 'Confermato',
  printed: 'Pronto',
}; */

/* enum JobStatus {
  initializing
  job si sta creando
  readyForPayment
  job e' pronto per il pagamento, tutti i files sono stati caricati (ma non ancora elaborati)
  readyForProcessing
  il pagamento e' gia' stato settato e quindi il job e' pronto per essere elaborato, se ne occupa in automatico un microservizio
  processing
  il job e' in fase di elaborazione, i files vengono presi uno alla volta ed elaborati se necessario
  canceledByUser
  l'utente ha annullato il job oppure il job e' stato cancellato poiche' scaduto
  canceledByAdmin
  l'amministratore ha annullato il job
  confirmed
  il job e' pronto per essere mostrato al fotografo
  errorInProcessing
  durante il processin c'e' stato un errore (es, un file non e' stato trovato)
  inElaboration
  in fase di creazione del file zip il job sara' in inElaboration e quindi non e' possibile modificarlo
  elaborated
  il file zip e' stato creato e quindi il job e' pronto per essere scaricato
  ready
  ready
  } */

const statusColor = {
  initializing: theme.vars.colors.grey,
  // job si sta creando

  readyForPayment: theme.vars.colors.grey,
  // job e' pronto per il pagamento, tutti i files sono stati caricati (ma non ancora elaborati)

  readyForProcessing: theme.vars.colors.grey,
  // il pagamento e' gia' stato settato e quindi il job e' pronto per essere elaborato, se ne occupa in automatico un microservizio

  processing: theme.vars.colors.grey,
  // il job e' in fase di elaborazione, i files vengono presi uno alla volta ed elaborati se necessario

  canceledByUser: theme.vars.colors.grey,
  // l'utente ha annullato il job oppure il job e' stato cancellato poiche' scaduto

  errorInProcessing: theme.vars.colors.grey,
  // durante il processin c'e' stato un errore (es, un file non e' stato trovato)

  inElaboration: theme.vars.colors.yellow,
  // in fase di creazione del file zip il job sara' in inElaboration e quindi non e' possibile modificarlo

  confirmed: theme.vars.colors.yellow,
  // il job e' pronto per essere mostrato al fotografo

  elaborated: theme.vars.colors.yellow,
  // il file zip e' stato creato e quindi il job e' pronto per essere scaricato

  canceledByAdmin: theme.vars.colors.red,
  // l'amministratore ha annullato il job

  ready: theme.vars.colors.green,
  // pronto
};

/*

LEGENDA:

yellow: 'Ordine in lavorazione',
green: 'Ordine spedito o in attesa di ritiro',
red: 'Ordine annullato'

*/

const availableStatus = Object.keys(statusColor);

/* const statusIcon = {
  initializing: 'impostazioni',
  readyForPayment: 'costo-spedizione',
  completed: 'tick',
  canceledByUser: 'elimina',
  canceledByAdmin: 'elimina',
  confirmed: 'tick',
  printed: 'tick',
}; */

interface HistoryItemProps {
  job: Job;
}

const HistoryItem = (props: HistoryItemProps) => {
  const [details, setDetails] = useState(false);

  const colorStatus = availableStatus.includes(props.job?.status)
    ? statusColor[props.job.status]
    : theme.vars.colors.grey;

  if (!props.job || props.job.status === 'canceledByUser') return null;

  return (
    <Box width="100%" rounded={20} bg={theme.vars.colors.grey[2]} flexDirection="column" mb={5}>
      <Box
        alignItems="center"
        justifyContent="flex-start"
        flexDirection="row"
        width="100%"
        //height="60"
        style={{ marginVertical: 5, paddingHorizontal: 15 }}
      >
        <Box flex={1} flexDirection="column" alignItems="flex-start" justifyContent="space-between">
          <Text color={theme.vars.colors.grey[0]} fontSize="lg" bold>
            {props?.job?.shop?.name}
          </Text>

          <Text color={theme.vars.colors.grey[0]} fontSize="xs" bold>
            {T('order')} #{props?.job?._id}
          </Text>

          <Text color={theme.vars.colors.grey[0]} fontSize="md" bold>
            {new Date(props?.job?.creationDate).toLocaleString('it-IT', {
              timeZone: 'Europe/Rome',
            })}
          </Text>

          {!details && (
            <Text mt={6} color={theme.vars.colors.grey[0]} fontSize="lg" bold>
              {T('total')}: {props?.job?.finalPrice?.toFixed(2) || 0.0} €
            </Text>
          )}
          {/* <Text color={theme.vars.colors.blue[0]} fontSize="md">
            {props.order.pages.map(page => {
              return page.name + ', ';
            })}
          </Text>
          {props.order.state === 'cart' ? (
            <Text color={theme.vars.colors.blue[0]} fontSize="md" bold>
              {props.order.finalPrice.toFixed(2)} €
            </Text>
          ) : null} */}
        </Box>
        <Box
          bg={{
            linearGradient: {
              colors: [colorStatus[0], colorStatus[2]],
              start: [0.3, 1],
              end: [0.01, 0.1],
            },
          }}
          // @ts-ignore
          cursor={Platform.OS == 'web' ? 'pointer' : undefined}
          style={{
            borderRadius: 18,
            height: 36,
            width: 36,
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            top: 10,
            right: 15,
          }}
        ></Box>
        {!details && (
          <Pressable
            mt={10}
            accessibilityRole="button"
            //accessibilityStates={isFocused ? ['selected'] : []}
            //accessibilityLabel={options.tabBarAccessibilityLabel}

            onPress={() => {
              setDetails(true);
            }}
            style={{
              flex: 1,
              //backgroundColor: theme.vars.colors.light[0],
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: 10,
              position: 'absolute',
              bottom: 0,
              right: 15,
            }}
          >
            <Box
              bg={{
                linearGradient: {
                  colors: [theme.vars.colors.grey[0], theme.vars.colors.grey[2]],
                  start: [0.3, 1],
                  end: [0.01, 0.1],
                },
              }}
              // @ts-ignore
              cursor={Platform.OS == 'web' ? 'pointer' : undefined}
              style={{
                borderRadius: 18,
                height: 36,
                width: 36,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Image
                style={{ height: 20, width: 20 }}
                resizeMode={'contain'}
                source={require('../../assets/img/symbols/details.png')}
              />
            </Box>
          </Pressable>
        )}
        {/* <Box
          key={'price_' + '00'}
          mt={2}
          flexDirection="row"
          alignItems="center"
          style={{
            width: '100%',
            borderBottomWidth: 2,
            borderBottomColor: theme.vars.colors.grey[0],
          }}
        >
          <Text color={theme.vars.colors.dark[0]} style={{ width: '50%' }} bold>
            asdas
          </Text>
          <Box flexDirection={'row'} justifyContent={'flex-end'} style={{ width: '50%' }}>
            <Text color={theme.vars.colors.dark[0]} bold>
              sadas
            </Text>
          </Box>
        </Box> */}
      </Box>
      {details && (
        <Box>
          <Box
            key={'price_' + '00'}
            mt={2}
            flexDirection="row"
            alignItems="center"
            justifyContent={'space-between'}
            style={{
              marginHorizontal: 15,
              //width: '100%',
            }}
          >
            <Box flexDirection={'row'}>
              <Text color={theme.vars.colors.dark[0]} width={60} bold>
                {T('quantityMin')}
              </Text>

              <Text color={theme.vars.colors.dark[0]} style={{ textTransform: 'capitalize' }} bold>
                {T('product')}
              </Text>
            </Box>

            <Box flexDirection={'row'} justifyContent={'flex-end'}>
              <Text color={theme.vars.colors.dark[0]} bold>
                €
              </Text>
            </Box>
          </Box>
          {props.job?.orders?.map((order, index) => {
            if (!order) return null;
            return (
              <Box
                key={'price_' + index}
                mt={2}
                flexDirection="row"
                alignItems="center"
                justifyContent={'space-between'}
                style={{
                  marginHorizontal: 15,
                  //width: '100%',
                  borderTopWidth: 1,
                  borderTopColor: theme.vars.colors.grey[1],
                }}
              >
                <Box flexDirection={'row'}>
                  <Text color={theme.vars.colors.dark[0]} width={60}>
                    {order.userEditing?.images?.length}
                    {/* {isGoods ? T('prints') : T('pieces')} */}
                  </Text>

                  <Text color={theme.vars.colors.dark[0]}>{order.rawProduct?.name}</Text>
                </Box>

                <Box flexDirection={'row'} justifyContent={'flex-end'}>
                  <Text color={theme.vars.colors.dark[0]}>{order.finalPrice?.toFixed(2)} €</Text>
                </Box>
              </Box>
            );
          })}
          {props?.job?.delivery?.cost ? (
            <Box
              mt={2}
              bg={theme.vars.colors.grey[1]}
              style={{ marginHorizontal: 15, borderRadius: 5 }}
              alignItems="center"
              flexDirection={'row'}
            >
              <Box flexDirection={'row'} flex={1}>
                <Text color={theme.vars.colors.dark[0]} width={60}></Text>

                <Text color={theme.vars.colors.dark[0]} bold>
                  {T('shipping')}
                </Text>
              </Box>
              <Box flexDirection={'row'} bg={'#fff'} justifyContent={'flex-end'}>
                <Text color={theme.vars.colors.grey[0]} fontSize="lg" bold>
                  {props.job.delivery.cost.toFixed(2)} €
                </Text>
              </Box>
            </Box>
          ) : null}
          <Box style={{ marginHorizontal: 15, marginBottom: 10 }} alignItems="flex-end">
            <Text mt={6} color={theme.vars.colors.grey[0]} fontSize="lg" bold>
              {T('total')}: {props?.job?.finalPrice?.toFixed(2) || 0.0} €
            </Text>
          </Box>
          {props?.job?.delivery?.type === 'atShop' ? (
            <Box flexDirection={'column'} mb={2} mt={4}>
              <Text
                style={{ marginHorizontal: 15 }}
                color={theme.vars.colors.grey[0]}
                fontSize="md"
                bold
              >
                {T('shipping_place')}
              </Text>

              <Text
                style={{ marginHorizontal: 15, textTransform: 'capitalize' }}
                color={theme.vars.colors.grey[0]}
                fontSize="md"
              >
                {props?.job?.delivery?.business?.denomination || props?.job?.shop?.name}
              </Text>
              <Text
                style={{ marginHorizontal: 15 }}
                color={theme.vars.colors.grey[0]}
                fontSize="md"
              >
                {props?.job?.delivery?.business?.street} {props?.job?.delivery?.business?.city}
              </Text>
            </Box>
          ) : null}

          <Box justifyContent={'flex-end'} alignItems={'flex-end'}>
            <Pressable
              accessibilityRole="button"
              //accessibilityStates={isFocused ? ['selected'] : []}
              //accessibilityLabel={options.tabBarAccessibilityLabel}
              onPress={() => {
                setDetails(false);
              }}
              style={{
                flex: 1,
                //backgroundColor: theme.vars.colors.light[0],
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 15,
                marginRight: 15,
              }}
            >
              <Box
                bg={{
                  linearGradient: {
                    colors: [theme.vars.colors.grey[0], theme.vars.colors.grey[2]],
                    start: [0.3, 1],
                    end: [0.01, 0.1],
                  },
                }}
                // @ts-ignore
                cursor={Platform.OS == 'web' ? 'pointer' : undefined}
                style={{
                  borderRadius: 18,
                  height: 36,
                  width: 36,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Image
                  style={{ height: 20, width: 20 }}
                  resizeMode={'contain'}
                  source={require('../../assets/img/symbols/details.png')}
                />
              </Box>
            </Pressable>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export { HistoryItem };
