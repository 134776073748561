import React, { useEffect, useState } from 'react';
import { Text, Box, Pressable, Image } from 'native-base';
import { useQuery } from '@apollo/client';
import { theme } from '../../config';
import { Shop, ProductTree, USER_SHOP, USER_SHOP_MIN, Promo } from '../../stores/queries/shop';
import { useNavigation, useRoute } from '@react-navigation/native';
import { Icon } from '../../ui';
import { User, USER_ME } from '../../stores/queries/user';
import { APP_STATE, APP_STATE_PROPS } from '../../stores/queries/appstate';
import { destructureTree } from '../../libs/utils';
import { T } from '../../config/localization';

const UserName = () => {
  const { loading, error, data } = useQuery<{ UserMe: User }>(USER_ME, {
    fetchPolicy: 'cache-and-network',
  });
  return (
    <Text color={theme.vars.colors.violet[0]} ml={6} mr={1} style={theme.text.m} bold>
      {data?.UserMe?.email}
    </Text>
  );
};

const ShopHeader = () => {
  const navigation = useNavigation<any>();
  const [promos, setPromos] = useState<Promo[]>();

  const { data: APP } = useQuery<APP_STATE_PROPS>(APP_STATE);

  const { loading, error, data } = useQuery<{ UserShop: Shop }>(USER_SHOP, {
    fetchPolicy: 'cache-and-network',
    variables: {
      code: APP.shopCode,
    },
    skip: !APP.shopCode,
  });

  useEffect(() => {
    if (data?.UserShop?.productTree) {
      let tPromos: Promo[] = [];
      for (let index = 0; index < data?.UserShop?.productTree?.length; index++) {
        const element = data.UserShop.productTree[index];
        if (element.isSponsored) {
          let { title, desc, color, image, grouped, price } = destructureTree(element);
          let promo = {
            type: element.type,
            title,
            desc,
            color,
            image,
            grouped,
            price,
            product: element.product ? element.product : undefined,
            next: element.next ? element.next : undefined,
          };
          tPromos.push(promo);
        }
        if (element.next) {
          for (let j = 0; j < element.next.length; j++) {
            const nextElement = element.next[j];
            if (nextElement.isSponsored) {
              let { title, desc, color, image, grouped, price } = destructureTree(nextElement);
              let promo = {
                type: nextElement.type,
                title,
                desc,
                color,
                image,
                grouped,
                price,
                product: nextElement.product ? nextElement.product : undefined,
                next: nextElement.next ? nextElement.next : undefined,
              };
              tPromos.push(promo);
            }
            if (nextElement.next) {
              for (let j = 0; j < nextElement.next.length; j++) {
                const nextNextElement = nextElement.next[j];
                if (nextNextElement.isSponsored) {
                  let { title, desc, color, image, grouped, price } =
                    destructureTree(nextNextElement);
                  let promo = {
                    type: nextNextElement.type,
                    title,
                    desc,
                    color,
                    image,
                    grouped,
                    price,
                    product: nextNextElement.product ? nextNextElement.product : undefined,
                    next: nextNextElement.next ? nextNextElement.next : undefined,
                  };
                  tPromos.push(promo);
                }
              }
            }
          }
        }
      }
      if (tPromos.length > 0) {
        setPromos(tPromos);
      }
    }
  }, [data?.UserShop?.productTree]);

  if (!APP.shopCode) {
    return null;
  }

  return (
    <>
      <Box safeAreaTop={true} style={{ width: '100%' }} mb={4}>
        <Box
          width="100%"
          pt={1}
          flexDirection="row"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Box ml={'2%'} flexDirection="row" mt={3} justifyContent="flex-start" alignItems="center">
            <Image
              style={{
                width: 180,
                height: 36,
                resizeMode: 'contain',
              }}
              source={require('../../assets/img/logo.png')}
              alt="logo"
            />
          </Box>
          <Box flexDirection={'row'} alignItems={'center'} mr={'2%'}>
            <Pressable
              flexDirection={'row'}
              alignItems={'center'}
              onPress={() => {
                navigation.navigate('User');
              }}
            >
              {APP?.userLogged ? (
                <UserName />
              ) : (
                <Text color={theme.vars.colors.violet[0]} ml={6} mr={1} style={theme.text.m} bold>
                  {T('signin_header')}
                </Text>
              )}

              <Icon size={20} name="profilo" color="violet" />
            </Pressable>

            {/* <Pressable
              flexDirection={'row'}
              alignItems={'center'}
              onPress={() => {
                navigation.navigate('User');
              }}
            >
              <Text color={theme.vars.colors.violet[0]} ml={6} mr={1} style={theme.text.m}>
                Notifche
              </Text>
              <Icon name="notifiche" color="violet" />
            </Pressable> */}
          </Box>
        </Box>
      </Box>
      <Box
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        bg={theme.vars.colors.violet[1]}
        borderRadius={40}
        py={1}
        style={{ width: '96%', margin: 'auto' }}
      >
        <Box style={{ flex: 1, overflow: 'hidden' }} flexDirection={'row'} alignItems={'center'}>
          <Pressable
            onPress={() => {
              navigation.navigate('ShopWindow');
              //navigation.reset('Home');
            }}
          >
            <Box
              bg={theme.vars.colors.light[0]}
              flexDirection={'row'}
              rounded={999}
              p={2}
              ml={1}
              alignItems={'center'}
            >
              <Icon size={20} name="home" color="violet" />
            </Box>
          </Pressable>

          {/*  {promos?.map((promo: Promo, index: number) => {
            return (
              <Pressable
                key={'promo_' + index}
                onPress={() => {
                  if (promo.type === 'folder' && promo.next) {
                    navigation.navigate('Wizard', {
                      screen: 'ProductPath',
                      params: {
                        productTree: promo.next,
                        title: promo.title,
                      },
                    });
                    return;
                  } else if (promo.type === 'product' && promo.product) {
                    navigation.navigate('Wizard', {
                      screen: 'ProductSummary',
                      params: {
                        product: promo.product,
                        title: promo.title,
                      },
                    });
                    return;
                  }
                }}
              >
                <Text
                  numberOfLines={1}
                  ellipsizeMode={'tail'}
                  color="white"
                  ml={6}
                  mr={6}
                  fontSize={20}
                  //style={{ textTransform: 'lowercase' }}
                >
                  {promo.title}
                </Text>
              </Pressable>
            );
          })} */}

          <Text numberOfLines={1} color="white" ml={5} mr={5} style={theme.text.m} bold>
            Home
          </Text>
          {/* 
          <Text numberOfLines={1} color="white" ml={5} mr={5} style={theme.text.m} bold>
            Chi Siamo
          </Text>
          <Text numberOfLines={1} color="white" ml={5} mr={5} style={theme.text.m} bold>
            Dove Siamo
          </Text>
          <Text numberOfLines={1} color="white" ml={5} mr={5} style={theme.text.m} bold>
            Contatti
          </Text> */}

          {/*  <Text numberOfLines={1} color="white" ml={6} mr={6} style={theme.text.l} bold>
            San.Valentino
          </Text>
          <Text numberOfLines={1} color="white" ml={6} mr={6} style={theme.text.l} bold>
            Gadget
          </Text>
          {data?.UserShop?.productTree?.map((tree: ProductTree, index: number) => {
            return (
              <Text key={index} color="white" ml={6} mr={6} style={theme.text.l}>
                {tree.name}
              </Text>
            );
          })} */}
        </Box>
        <Box flexDirection={'row'}>
          <Pressable
            onPress={() => {
              navigation.navigate('Projects');
            }}
          >
            <Box
              bg={theme.vars.colors.light[0]}
              flexDirection={'row'}
              rounded={999}
              p={2}
              mr={7}
              alignItems={'center'}
            >
              <Icon size={20} name="stella" color="violet" />
            </Box>
            {APP.filledProjects && (
              <Box
                shadow={6}
                style={{
                  width: 12,
                  height: 12,
                  borderRadius: 6,
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: theme.vars.colors.red[0],
                  position: 'absolute',
                  top: 1,
                  right: 26,
                }}
              ></Box>
            )}
          </Pressable>
          <Pressable
            onPress={() => {
              navigation.navigate('Cart');
            }}
          >
            <Box
              bg={theme.vars.colors.light[0]}
              flexDirection={'row'}
              rounded={999}
              p={2}
              mr={7}
              alignItems={'center'}
            >
              <Icon size={20} name="carrello" color="violet" />
            </Box>
            {APP.filledCart && (
              <Box
                shadow={6}
                style={{
                  width: 12,
                  height: 12,
                  borderRadius: 6,
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: theme.vars.colors.red[0],
                  position: 'absolute',
                  top: 1,
                  right: 26,
                }}
              ></Box>
            )}
          </Pressable>
          <Pressable
            onPress={() => {
              navigation.navigate('Projects');
            }}
          >
            <Box
              bg={theme.vars.colors.light[0]}
              flexDirection={'row'}
              rounded={999}
              p={2}
              mr={1}
              alignItems={'center'}
            >
              <Icon size={20} name="notifiche" color="violet" />
            </Box>
            {false && (
              <Box
                shadow={6}
                style={{
                  width: 12,
                  height: 12,
                  borderRadius: 6,
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: theme.vars.colors.red[0],
                  position: 'absolute',
                  top: 1,
                  right: 26,
                }}
              ></Box>
            )}
          </Pressable>
        </Box>
      </Box>
      <Box
        width={'100%'}
        mt={6}
        paddingY={4}
        paddingX={'3%'}
        bg={theme.vars.colors.violet[2]}
        flexDirection={'row'}
        justifyContent="space-between"
      >
        <Text
          ml={0}
          style={{
            fontSize: 18,
            color: theme.vars.colors.violet[0],
            textTransform: 'uppercase',
          }}
          bold
        >
          {data?.UserShop?.name}
        </Text>
        <Text
          ml={0}
          style={{
            fontSize: 18,
            color: theme.vars.colors.violet[0],
            textTransform: 'uppercase',
          }}
          bold
        >
          #{data?.UserShop?.code}
        </Text>
      </Box>
    </>
  );
};

export { ShopHeader };
