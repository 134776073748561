import React, { useEffect, useMemo } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  Easing,
  withTiming,
} from 'react-native-reanimated';

const HEIGHT = 8;

type ProgressBarProps = {
  width?: number;
  bg?: string;
  color?: string;
  style?: StyleProp<ViewStyle>;
  progress: number;
};
const ProgressBar = ({
  width = 200,
  bg = '#cecece',
  color = '#8f8f8f',
  style,
  progress,
}: ProgressBarProps) => {
  const { backgroundColor, foregroundColor } = useMemo(() => {
    return {
      backgroundColor: bg,
      foregroundColor: color,
    };
  }, [color, bg]);

  const progressWidth = useSharedValue(0);

  useEffect(() => {
    progress = progress / 100;
    if (progress > 100 || progress < 0) {
      throw new Error('Invalid range (progress should be between 100 and 1');
    }
    // Animate progressWidth to the selected progress value
    progressWidth.value = withTiming(progress * width, {
      duration: 2000,
      easing: Easing.bezier(0.25, 0.1, 0.25, 1),
    });
    //progressWidth.value = withSpring(progress * width);
  }, [progress, progressWidth]);

  const progressStyle = useAnimatedStyle(() => {
    return {
      // Set progressWidth as the width of our progress view
      width: progressWidth.value,
      height: HEIGHT,
    };
  });

  return (
    <View style={[style, styles.container, { width }, { backgroundColor }]}>
      <Animated.View style={[progressStyle, { backgroundColor: foregroundColor }]} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 4,
    overflow: 'hidden',
    height: HEIGHT,
  },
});

export { ProgressBar };
