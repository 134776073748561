import React, { useCallback, useEffect, useRef } from 'react';
import { Box, Center, Image, Pressable, Text } from 'native-base';
import { theme, LOG } from '../../config';
import { Button } from '../../ui/Button';
import { DB_JOBS } from '../../stores/db/orders';
import { useFocusEffect } from '@react-navigation/native';
import { BackHandler } from 'react-native';
import { activeOrder } from '../../stores/db/appstate';
import { T } from '../../config/localization';

interface JobErrorProps {
  navigation: any;
  route: {
    params: {
      error: string;
      jobId: string;
    };
  };
}

const JobError = (props: JobErrorProps) => {
  useFocusEffect(
    useCallback(() => {
      const onBackPress = () => {
        return true;
      };

      BackHandler.addEventListener('hardwareBackPress', onBackPress);

      return () => BackHandler.removeEventListener('hardwareBackPress', onBackPress);
    }, [])
  );

  return (
    <Box
      pt={2}
      bg={theme.vars.colors.light[0]}
      flex={1}
      alignItems="center"
      justifyContent="flex-start"
    >
      <Box flex={1} alignItems="center" justifyContent="center" safeAreaBottom>
        <Image
          mb={2}
          alt={'ritoro_negozio'}
          style={{ resizeMode: 'contain', width: 180, height: 180 }}
          source={require('../../assets/img/illustrations/avviso.png')}
        ></Image>

        <Text color={theme.vars.colors.red[0]} mb={0} fontSize={'2xl'} bold textAlign={'center'}>
          {T('order_error_title')}
        </Text>
        <Text color={theme.vars.colors.red[0]} mb={6} fontSize={'lg'}>
          {props.route.params.error}
        </Text>

        <Button
          color="violet"
          shadow="lg"
          style={{ shadowOpacity: 0.2 }}
          onPress={() => {
            props.navigation.replace('JobUpload', {
              jobId: props.route.params.jobId,
            });
          }}
        >
          {T('retry')}
        </Button>

        <Pressable
          style={{
            width: '100%',
            position: 'absolute',
            bottom: 20,
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onPress={() => {
            activeOrder(null);
            DB_JOBS.removeCurrentJob();
            props.navigation.navigate('Home', {
              screen: 'Cart',
              initial: false,
            });
          }}
        >
          <Text color={theme.vars.colors.dark[0]} fontSize={12} underline bold>
            {T('cancel_order')}
          </Text>
        </Pressable>
      </Box>
    </Box>
  );
};

export { JobError };
