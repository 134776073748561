const IT = {
  // Generali
  yes: 'si',
  no: 'no',
  continue: 'continua',
  retry: 'riprova',
  begin: 'inizia',
  begin_now: 'inizia ora',
  save: 'salva',
  edit: 'modifica',
  delete: 'elimina',
  cancel: 'annulla',
  confirm: 'conferma',
  done: 'Prosegui',
  ok: 'ok',
  name: 'Nome',
  unknown_error: 'Un errore imprevisto, riprova più tardi...',
  quantity: 'quantità',
  picture: 'foto',
  pictures: 'foto',
  print: 'stampa',
  prints: 'stampe',
  pieces: 'pezzi',
  of: 'di',
  in: 'in',
  from: 'da',
  to: 'a',
  order: 'ordine',
  work: 'lavorazione',
  job: 'lavorazione',
  product: 'prodotto',
  variant: 'variante',
  shop: 'negozio',

  // Selezione negozio
  frame_code: 'Inquadra Codice',
  camera_permission: 'Concedi i permessi per utilizzare la fotocamera',
  camera_permission_denied: 'Non hai accesso alla fotocamera',
  maintenance_title: 'Manutenzione in corso',
  maintenance_sub: 'Ci dispiace per il disagio, riprova più tardi!',

  // Header menu webapp
  signin_header: 'Accedi o registrati',

  // Albero prodotti
  start_from: 'A partire da',
  comingsoon: 'Presto disponibile',

  // Carrello
  page_title_cart: 'I miei ordini',
  cart: 'carrello',
  move_in_projects: 'Sposta in\nprogetti',
  minium_order_price: 'Ordine minimo di 0,50€',
  continue_shop: 'Continua gli Acquisti',
  send_order: "Procedi all'ordine",
  void_cart: 'Il tuo carrello è ancora vuoto',
  void_cart_hint: 'Inizia subito a stampare le tue emozioni',
  delete_order_promt: 'Sei sicuro di voler eliminare la lavorazione?',

  // Progetti
  projects: 'progetti',
  void_projects: 'Non hai ancora dei progetti',
  void_projects_hint: 'Inizia subito a stampare le tue emozioni',
  delete_project_promt: 'Sei sicuro di voler eliminare il progetto?',

  // Indirizzi
  page_title_addresses: 'I miei Indirizzi',
  edit_address: 'Modifica il tuo indirizzo',
  street: 'Via',
  zip_code: 'CAP',
  city: 'Città',
  province: 'Provincia',
  state: 'Stato',
  delete_address_promt: "Vuoi eliminare l'indirizzo?",

  // Preferenze
  page_title_preferences: 'Preferenze',

  // Info
  page_title_info: 'Informazioni',
  page_header: 'EasyPixy by DLogic srl',
  privacy_policy: 'Privacy policy',
  use_terms: "Termini d'uso",
  credits: 'Crediti',

  // Profilo e login
  profile_title: 'Profile',
  code_sended: 'Abbiamo inviato un codice di verifica alla tua email, inseriscilo qui!',
  code_resend: 'Non hai ricevuto il codice? controlla nella cartella della posta indesiderata',
  code_retry: 'O clicca qui per riprovare',
  insert_email: 'Inserisci la tua email',
  login: 'accedi',
  login_hint: 'Accedi o registrati per completare e seguire i tuoi ordini',
  login_facebook: 'Continua con Facebook',
  login_apple: 'Continua con Apple',
  login_email: 'Continua con email',
  logout: 'logout',
  logout_promt: 'Voi uscire dal tuo account?',
  shop_exit: 'Esci da questo negozio',
  shop_exit_promt: 'Vuoi uscire da questo negozio?',
  my_orders: 'I miei ordini',
  my_addresses: 'I miei indirizzi',
  my_preferences: 'Preferenze',
  my_infos: 'Informazioni',
  instant_order: 'Ordine istantaneo',
  return_to_full: 'Torna al negozio',
  user_verified: 'Utente verificato',

  // Storico
  void_history: 'Non hai ancora effettuato ordini',
  void_history_hint: 'Inizia subito a stampare le tue emozioni',
  total: 'Totale',
  legend: 'Legenda',
  waitingPayment: 'In attesa di pagamento',
  processingOrder: 'Ordine in lavorazione',
  orderShipped: 'Ordine spedito o in attesa di ritiro',
  orderCanceld: 'Ordine annullato',
  quantityMin: 'Q.ta',

  // Preferenze
  comunications: 'Comunicazioni',
  noti_orders: 'Ricevi aggiornamenti sullo stato dei tuoi ordini e spedizioni',
  noti_commercial: 'Ricevi messaggi su promozioni e sconti del tuo fotografo',
  noti_ep: 'Ricevi aggiornamenti sulle novità e nuove funzioni di EasyPixy',
  system: 'Sistema',
  app_version: 'Versione App',
  editor_version: 'Versione Editor',
  device_language: 'Lingua dispositivo',
  delete_cache: 'Cancella la cache',
  delete_cache_promt: 'Vuoi cancellare la cache?',
  delete_cache_promt_sub:
    'Cosi facendo eliminerai il carrello, tutti i tuoi progetti, ed effettuerai il logout...',
  delete_user: 'Elimina il tuo utente e dati',
  delete_user_promt: 'Elimina utente e dati?',
  delete_user_promt_sub:
    "Proseguendo chiederai l'eliminazione del tuo utente e di tutti i dati ad esso correlati dai database Easypixy. Sicuro di voler continuare?",

  // Invio ordine
  send_order_hint: 'Invio del tuo ordine in corso!',
  cancel_order_promt: "Sei sicuro di voler annullare l'ordine?",
  cancel_order: 'Annulla ordine e torna al carrello',

  // Upload Ordine
  upload_order: 'Upload foto in corso',
  upload_order_hint: " Non chiudere l'applicazione!",
  upload_complete: 'Upload Completato!',
  upload_begin: 'Inizio upload',

  // Spedizione ordine
  page_title_ship: 'Spedizione',
  shipping: 'Spedizione',
  contacts: 'Contatti',
  mandatory_input: '*campo obbligatorio',
  shipping_method: 'Modalità di consegna',
  shop_delivery: 'Ritiro in negozio',
  home_shipping: 'Spedizione tracciata',
  free_shipping: 'Gratuita',
  shipping_in: 'Consegna in',
  ready_shipping: 'Pronta consegna',
  shipping_address: 'Indirizzo di Spedizione',
  void_shipping_address: 'Indirizzo non ancora inserito',
  shipping_place: 'Punto di ritiro',
  go_to_payment: 'Vai al pagamento',
  select_address: 'Premi per usare questo indirizzo',
  new_address: 'Nuovo Indirizzo',
  remember_address: 'Ricorda indirizzo',
  err_no_name: 'Inserisci il tuo nome!',
  err_no_email: 'Inserisci la tua email!',
  err_no_phone: 'Inserisci il numero di telefono!',
  err_no_address: 'Completa il tuo indirizzo!',
  homeAddress: 'Casa',
  workAddress: 'Lavoro',
  otherAddress: 'Altro',
  phone: 'Cellulare',
  address: 'Indirizzo',

  // Pagamento Ordine
  page_title_pay: 'Pagamento',
  order_price: 'Costo ordine',
  ship_price: 'Costo spedizione',
  total_price: 'Importo totale',
  payment_method: 'Scegli il metodo di pagamento',
  payment_on_site: 'Paga in negozio',
  payment_on_site_hint: 'Pagamento in negozio disponibile solo con ritiro in sede',
  payment_cards: 'Carta di credito',
  pay: 'Paga',

  // Errore Ordine
  order_error_title: "Oops, c'è stato un problema nel caricamento del tuo ordine!",
  order_no_methods:
    'Oops, in questo negozio non ci sono metodi di spedizione abilitati, contatta il fotografo.',

  // Completato Ordine
  thanks: 'Grazie!',
  complete_order_title: 'Ordine inviato correttamente',
  complete_order_hint: 'controlla lo stato nella sezione apposita del tuo profilo',

  // Selezione Foto
  process_picture: 'Elaboro foto',
  prosessing_pictures: 'Stiamo elaborando le tue fotografie',
  processing_pictures_hint: "Non chiudere l'applicazione!",
  in_processing: 'In elaborazione',
  select_pictures_title: 'Seleziona le foto che desideri stampare',
  select_pictures_hint: 'Proveremo ad inquadrarle al meglio e potrai modificarle come preferisci',
  min_prints: 'Ordine minimo',
  no_variants: 'Nessuna variante',
  add_others: 'Aggiungi altre',
  prints_to_go: 'stampe per proseguire',
  select_per_page: 'Seleziona una foto per ogni pagina',
  to_cart: 'Al carrello',
  save_work_promt: 'Vuoi salvare la lavorazione?',
  save_work_hint:
    'Potrai continuare più tardi nella sezione progetti!\nLe immagini non elaborate non verranno salvate',
  open_editor: 'Apri editor',
  select_all: 'Seleziona tutto',
  deselect_all: 'Deseleziona tutto',
  select_pictures: 'Seleziona Immagini',
  select_picture: 'Scegli foto',
  selected_pictures: 'foto selezionate',
  pictures_permission_limited: 'Hai i permessi solo per alcune foto',
  pictures_permission_denied: 'Non hai i permessi per accedere alle tue foto',
  open_settings_to_add: 'Apri Impostazioni per aggiungerne',
  open_settings: 'Apri Impostazioni',
  your_albums: 'I tuoi album',

  // Riepilogo prodotto
  ship_at_home: 'Consegna a casa tua',
  ship_at_shop: 'Ritiro in negozio',
  ship_at_shop_cost: 'Costo ritiro',
  work_days: 'giorni lavorativi',
  format_in_cm: 'Formato in cm',
  quotes: 'Listino',
  more_print_less: 'Più stampi meno spendi!',
  look_quotes: 'Guarda il listino',
  until: 'fino al',
  free: 'gratis',
  freeOver: 'gratuito con più di',
  fixedCost: 'Costo di gestione',
  fixedCostHint: 'per ordini infieriori a',
  price: 'prezzo',
  dimensions: 'dimensioni',
};

export { IT };
