import React from 'react';
import { Promo } from '../../stores/queries/shop';
import { useNavigation } from '@react-navigation/core';
import { PromoItem } from './PromoItem';
import Carousel from 'react-grid-carousel';
import { useWindowDimensions } from 'react-native';
import { Box, Flex } from 'native-base';
import { theme } from '../../config';

interface PromoViewProps {
  promos: Promo[];
}

const PromoView = (props: PromoViewProps) => {
  const navigation: any = useNavigation();

  return (
    <Flex
      width={'100%'}
      height={200}
      justifyContent={'center'}
      alignItems={'center'}
      mb={5}
      bg={'#eee'}
      padding={0}
    >
      <Carousel
        containerStyle={{
          maxWidth: '1200px',
          width: '100%',
          zIndex: 999,
          padding: 0,
        }}
        cols={3}
        rows={1}
        gap={30}
        loop
        hideArrow
        showDots
      >
        {props.promos.map((promo: Promo, index: number) => {
          return (
            <Carousel.Item key={'promo_' + index}>
              <PromoItem
                title={promo.title}
                desc={promo.desc}
                color={promo.color}
                image={promo.image}
                price={promo.price}
                onPress={() => {
                  if (promo.type === 'folder' && promo.next) {
                    navigation.navigate('Wizard', {
                      screen: 'ProductPath',
                      params: {
                        productTree: promo.next,
                        title: promo.title,
                        color: promo.color,
                      },
                    });
                    return;
                  }
                  if (promo.type === 'product' && promo.product) {
                    navigation.navigate('Wizard', {
                      screen: 'ProductSummary',
                      params: {
                        product: promo.product,
                        title: promo.title,
                      },
                    });
                    return;
                  }
                }}
              />
            </Carousel.Item>
          );
        })}
      </Carousel>
    </Flex>
  );
};

export { PromoView };
