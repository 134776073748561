import React, { useEffect, useRef, useState } from 'react';
import { ViewStyle, useWindowDimensions, Platform } from 'react-native';
import { StyleSheet, Image } from 'react-native';
import { useNavigation } from '@react-navigation/core';

import { Icon } from '../../ui';
import { PLACEHOLDER, theme } from '../../config';
import { Box, Text, Pressable } from 'native-base';
import { Product, ProductType } from '../../stores/queries/shop';
import { DB_ORDERS, LocalImage, Order } from '../../stores/db/orders';
import { DPI, elaborateImage } from '../../libs/utils';
import { PhotoPickerSingle } from './PhotoPickerSingle';
import { T } from '../../config/localization';
import { FastImage } from '../../ui/FastImage';

interface QuantitySelectorProps {
  image: Order['images'][0];
  index: number;
  deleteImage: (index: number) => {};
  updateImage: (index: number, image: Order['images'][0]) => {};
}

const QuantitySelector = (props: QuantitySelectorProps) => {
  const [quantity, setQuantity] = useState<number>(props.image.quantity);

  const quantityTimer = useRef(null);

  return (
    <Box flexDirection="row" alignItems={'center'} justifyContent="space-between">
      <Pressable
        onPress={() => {
          if (quantity > 1) {
            let newQuantity = quantity - 1;
            setQuantity(newQuantity);
            if (quantityTimer.current) clearTimeout(quantityTimer.current);
            quantityTimer.current = setTimeout(() => {
              let newImage = {
                ...props.image,
                quantity: newQuantity,
              };
              props.updateImage(props.index, newImage);
            }, 300);
          }
        }}
        style={{
          width: 30,
          height: 30,
          margin: 0,
          padding: 0,
          borderRadius: 999,
          backgroundColor: theme.vars.colors.violet[0],
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Text color={theme.vars.colors.light[0]} fontSize={24} lineHeight={26}>
          -
        </Text>
      </Pressable>
      <Text
        fontSize={18}
        style={{ width: 36, textAlign: 'center' }}
        color={theme.vars.colors.violet[0]}
        bold
      >
        {quantity}
      </Text>
      <Pressable
        onPress={() => {
          let newQuantity = quantity + 1;
          setQuantity(newQuantity);
          if (quantityTimer.current) clearTimeout(quantityTimer.current);
          quantityTimer.current = setTimeout(() => {
            let newImage = {
              ...props.image,
              quantity: newQuantity,
            };
            props.updateImage(props.index, newImage);
          }, 300);
        }}
        style={{
          width: 30,
          height: 30,
          margin: 0,
          padding: 0,
          borderRadius: 999,
          backgroundColor: theme.vars.colors.violet[0],
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Text color={theme.vars.colors.light[0]} fontSize={24} lineHeight={24}>
          +
        </Text>
      </Pressable>
    </Box>
  );
};

interface PhotoItemProps {
  image: LocalImage;
  type: ProductType;
  wizard: Product['wizards'][0];
  fixedPage: boolean;
  imgDim: {
    width: number;
    height: number;
  };
  imgBorder: ViewStyle;
  imgThickness: number;
  deleteImage: (index: number) => {};
  updateImage: (index: number, image: Order['images'][0]) => {};
  index: number;
  placeholder?: boolean;
}

const PhotoItem = (props: PhotoItemProps) => {
  const dimensions = useWindowDimensions();
  const navigation = useNavigation<any>();
  const [imageData, setImageData] = useState<string>();
  const [history, setHistory] = useState<string>(props.image.history);
  const [processing, setProcessing] = useState(true);
  const [isPlaceholder, setIsPlaceHolder] = useState(props.image.placeholder);

  const updatedImage = useRef(props.image);

  const goToEditor = (
    image: LocalImage,
    index: number,
    wizard: Product['wizards'][0],
    updateImage: (index: number, image: Order['images'][0]) => void,
    setImageData: Function,
    setHistory: Function,
    imageData?: string
  ) => {
    navigation.navigate('ImageEditor', {
      image,
      index,
      wizard,
      updateImage,
      setImageData,
      setHistory,
      imageData,
    });
    return null;
  };

  useEffect(() => {
    setTimeout(() => {
      if (props.image.placeholder) {
        setImageData(PLACEHOLDER);
      } else if (props.image.waiting && props.image.path) {
        setImageData(props.image.path);
      } else if (props.image.attachment) {
        DB_ORDERS.getAttachment(props.image.attachment).then(data => {
          setProcessing(false);
          setImageData(data);
        });
      }
    }, props.index * 10 + 50);
  }, []);

  useEffect(() => {
    if (!props.image.waiting) {
      setProcessing(false);
    }
  }, [props.image.waiting]);

  const updateImage = (index: number, image: LocalImage) => {
    updatedImage.current = image;
    props.updateImage(index, image);
  };

  const renderBtnMultiple = () => (
    <Box
      width="100%"
      flexDirection="row"
      alignItems={'center'}
      justifyContent="space-between"
      p={4}
    >
      <Box flexDirection="row">
        <Pressable
          onPress={() => {
            let base64 = history ? null : imageData;
            let img = { ...props.image, history: history ? history : undefined };
            goToEditor(
              img,
              props.index,
              props.wizard,
              updateImage,
              setImageData,
              setHistory,
              base64
            );
          }}
          _pressed={{
            opacity: 0.7,
          }}
          flexDirection="column"
          alignItems={'center'}
          justifyContent="center"
        >
          <Box
            rounded={999}
            bg={theme.vars.colors.violet[2]}
            alignItems={'center'}
            justifyContent="center"
            style={{ height: 46, width: 46 }}
          >
            <Icon name="modifica" size={18} color={'violet'} />
          </Box>
          <Text
            style={{ textTransform: 'capitalize' }}
            color={theme.vars.colors.violet[0]}
            fontSize="sm"
            pt={2}
          >
            {T('edit')}
          </Text>
        </Pressable>
        <Pressable
          ml={4}
          onPress={() => {
            props.deleteImage(props.index);
          }}
          _pressed={{
            opacity: 0.7,
          }}
          flexDirection="column"
          alignItems={'center'}
          justifyContent="center"
        >
          <Box
            rounded={999}
            bg={theme.vars.colors.violet[2]}
            alignItems={'center'}
            justifyContent="center"
            style={{ height: 46, width: 46 }}
          >
            <Icon name="cestino" size={18} color={'violet'} />
          </Box>
          <Text
            style={{ textTransform: 'capitalize' }}
            color={theme.vars.colors.violet[0]}
            fontSize="sm"
            pt={2}
          >
            {T('delete')}
          </Text>
        </Pressable>
      </Box>

      <Box flexDirection="column" alignItems={'center'} justifyContent="center">
        <Box
          rounded={20}
          bg={theme.vars.colors.violet[2]}
          alignItems={'center'}
          justifyContent="center"
          height="46"
          pl={4}
          pr={4}
        >
          <QuantitySelector
            updateImage={props.updateImage}
            deleteImage={props.deleteImage}
            index={props.index}
            image={updatedImage.current}
          />
        </Box>
        <Text
          style={{ textTransform: 'capitalize' }}
          color={theme.vars.colors.violet[0]}
          fontSize="sm"
          pt={2}
        >
          {T('quantity')}
        </Text>
      </Box>
    </Box>
  );

  const renderBtnFixed = (placeholder: boolean) => (
    <Box width="100%" flexDirection="row" alignItems={'center'} justifyContent="space-around" p={4}>
      {!isPlaceholder && (
        <Pressable
          onPress={() => {
            let base64 = history ? null : imageData;
            let img = { ...props.image, history: history ? history : undefined };
            goToEditor(
              img,
              props.index,
              props.wizard,
              props.updateImage,
              setImageData,
              setHistory,
              base64
            );
          }}
          _pressed={{
            opacity: 0.7,
          }}
          flexDirection="column"
          alignItems={'center'}
          justifyContent="center"
        >
          <Box
            rounded={999}
            bg={theme.vars.colors.violet[2]}
            alignItems={'center'}
            justifyContent="center"
            style={{ height: 46, width: 46 }}
          >
            <Icon name="modifica" size={18} color={'violet'} />
          </Box>
          <Text color={theme.vars.colors.violet[0]} fontSize="sm" pt={2}>
            {T('edit')}
          </Text>
        </Pressable>
      )}
      <PhotoPickerSingle
        onSelected={(pickerImage: any) => {
          let image: LocalImage = {
            attachment: props.image.attachment,
            quantity: 1,
            path: pickerImage.path,
            height: pickerImage.height,
            width: pickerImage.width,
            wizardId: props.wizard._id,
            dpi: DPI,
          };
          elaborateImage(image, props.wizard.sizes).then(res => {
            let tempImage = { ...image, placeholder: false };
            props.updateImage(props.index, tempImage);
            setIsPlaceHolder(false);
            setImageData(res.attachment);
            setHistory(null);
            DB_ORDERS.updateAttachment(props.image.attachment, res.attachment);
          });
        }}
      />
    </Box>
  );

  const renderBtn = () => {
    if (processing) {
      return (
        <Box width="100%" flexDirection="row" alignItems={'center'} justifyContent="center" p={4}>
          <Text color={theme.vars.colors.violet[0]} fontSize="md" pt={2} bold>
            {T('in_processing')}...
          </Text>
        </Box>
      );
    }

    if (props.fixedPage) {
      return renderBtnFixed(props.placeholder);
    } else {
      return renderBtnMultiple();
    }
  };

  if (!imageData) return null;

  return (
    <Box
      bg={'rgba(0,0,0,0.03)'}
      style={[
        style.container,
        {
          //transform: [{ rotate: props.image.height > props.image.width ? '90deg' : '0deg' }],
          width: dimensions.width <= theme.vars.screen.sm ? dimensions.width * 0.92 : 280,
          marginHorizontal: dimensions.width <= theme.vars.screen.sm ? dimensions.width * 0.04 : 10,
        },
      ]}
      pt={5}
      mt={2}
    >
      {props.image?.label && props.image.label.trim()?.length && (
        <Box width="100%" flexDirection="row" alignItems={'center'} justifyContent="center" mb={2}>
          <Icon size={18} name="fronte" color="violet" />
          <Text color={theme.vars.colors.violet[0]} ml={2} fontSize="lg" bold>
            {props.image?.label}
          </Text>
        </Box>
      )}
      <Box bg={theme.vars.colors.violet[2]} style={[theme.vars.shadow.lg, props.imgDim]}>
        <FastImage source={{ uri: imageData }} style={[style.media, props.imgDim]} />
        {!history ? (
          <Box
            style={[{ position: 'absolute', top: 0, left: 0 }, props.imgBorder, props.imgDim]}
          ></Box>
        ) : null}
        {props.imgThickness ? (
          <>
            <Box
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                height: props.imgThickness,
                width: props.imgThickness,
                backgroundColor: '#fff',
              }}
            ></Box>
            <Box
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                height: props.imgThickness,
                width: props.imgThickness,
                backgroundColor: '#fff',
              }}
            ></Box>
            <Box
              style={{
                position: 'absolute',
                bottom: 0,
                right: 0,
                height: props.imgThickness,
                width: props.imgThickness,
                backgroundColor: '#fff',
              }}
            ></Box>
            <Box
              style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                height: props.imgThickness,
                width: props.imgThickness,
                backgroundColor: '#fff',
              }}
            ></Box>
            <Box
              style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                height: props.imgDim.height - props.imgThickness * 2,
                width: props.imgDim.width - props.imgThickness * 2,
                margin: props.imgThickness,
                borderColor: '#666',
                borderStyle: 'dotted',
                borderRadius: 1,
                borderWidth: 1,
              }}
            ></Box>
            <Box
              style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                height: props.imgDim.height - props.imgThickness * 2,
                width: props.imgDim.width - props.imgThickness * 2,
                margin: props.imgThickness,
                borderColor: '#fff',
                borderStyle: 'dashed',
                borderRadius: 1,
                borderWidth: 1,
              }}
            ></Box>
          </>
        ) : null}
        {props.wizard?.masks?.cut?.url && !history ? (
          <Image
            source={{ uri: props.wizard.masks.cut.url }}
            style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              height: '100%',
              width: '100%',
            }}
          />
        ) : null}
        {props.wizard?.masks?.safety?.url && (
          <Image
            source={{ uri: props.wizard.masks.safety.url }}
            style={{ position: 'absolute', bottom: 0, left: 0, height: '100%', width: '100%' }}
          />
        )}
        {props.image?.dpi && props.image?.dpi !== 300 ? (
          <Box
            style={{ position: 'absolute', top: 5, right: 5, backgroundColor: 'rgba(0,0,0,0.7)' }}
            borderRadius={10}
            paddingRight={2}
          >
            <Text color={theme.vars.colors.light[0]} ml={2} fontSize="xs" bold>
              {props.image?.dpi} dpi
            </Text>
          </Box>
        ) : null}
      </Box>
      {renderBtn()}
    </Box>
  );
};

const style = StyleSheet.create({
  container: {
    borderRadius: 20,
    marginVertical: 20,
    justifyContent: 'center',
    alignItems: 'center',
    //height: 310,
  },
  media: {
    backgroundColor: 'rgba(0,0,0,0.2)',
  },
});

export { PhotoItem };
