import * as React from 'react';
import { Keyboard, Platform, StyleSheet, View } from 'react-native';
import {
  Text,
  Box,
  Input,
  Image,
  Pressable,
  Modal,
  KeyboardAvoidingView,
  ScrollView,
} from 'native-base';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import {
  deregister,
  loginEmail,
  loginCode,
  loginFacebook,
  loginApple,
  logout,
  registerEmail,
} from '../../libs/apollo/auth';
import { Button, Icon, TextBox } from '../../ui';
import { LOG, theme } from '../../config';
import { CHECK_USER } from '../../stores/queries/user';
import { useApolloClient } from '@apollo/client';
import { BlurModal } from '../../ui/BlurModal';
import { Loading } from '../../ui/Loading';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { T } from '../../config/localization';

const osVersion: any = Platform.Version;
let majorVersionIOS = 13;
if (Platform.OS === 'ios') {
  majorVersionIOS = parseInt(osVersion, 10);
}

interface EmailLoginProps {
  isOpen: boolean;
  onClose?: Function;
  onSuccess?: Function;
}

const EmailLogin = (props: EmailLoginProps) => {
  const client = useApolloClient();
  const [error, setError] = React.useState<null | string>(null);
  const [form, setForm] = React.useState<{ code: string; email: string }>({
    code: '',
    email: '',
  });
  const [existingUser, setExistingUser] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const onSubmit = async () => {
    setIsLoading(true);
    let res = null;
    if (existingUser) {
      res = await loginCode(form.email, form.code);
    } else {
      res = await loginEmail(form.email);
    }
    if (res?.success && res?.isEmailVerified) {
      props.onClose && props.onClose();
      props.onSuccess && props.onSuccess();
    } else if (res?.success && !res?.isEmailVerified && !existingUser) {
      setExistingUser(true);
    } else {
      if (res?.msg) {
        setError(res?.msg);
      }
    }
    Keyboard.dismiss();
    setIsLoading(false);
  };

  const isButtonDisabled = () => {
    if (form.email.length < 3) {
      return true;
    }
    if (form.code.length < 5 && existingUser) {
      return true;
    }
    return false;
  };

  return (
    <BlurModal
      height={500}
      isOpen={props.isOpen}
      onClose={() => {
        props.onClose && props.onClose();
        setForm({ email: '', code: '' });
        setError(null);
        setExistingUser(false);
        setIsLoading(false);
      }}
    >
      <KeyboardAwareScrollView
        style={{ flex: 1, width: '100%' }}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{
          padding: 20,
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Box
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Image
            mt={0}
            mb={8}
            height={100}
            width={100}
            resizeMode={'contain'}
            source={require('../../assets/img/symbols/accesso.png')}
            alt="accesso"
          />
          {error ? (
            <View
              style={{
                //backgroundColor: theme.vars.colors.red[0],
                borderRadius: 999,
                marginTop: 2,
                marginBottom: 2,
                width: '100%',
                maxWidth: 300,
                padding: 5,
              }}
            >
              <Text
                width={'100%'}
                textAlign={'center'}
                textTransform={'capitalize'}
                color={theme.vars.colors.red[0]}
              >
                {error}
              </Text>
            </View>
          ) : null}
          {existingUser ? (
            <>
              <Text fontSize={'md'} textAlign={'center'}>
                {T('code_sended')}
              </Text>
              <TextBox
                flat
                color="green"
                autoCapitalize={'none'}
                keyboardType={'number-pad'}
                maxLength={5}
                onSubmitEditing={() => {
                  onSubmit();
                  Keyboard.dismiss();
                }}
                placeholder="Codice"
                value={form.code}
                onChangeText={value => {
                  setForm({ ...form, code: value });
                }}
                style={{ marginVertical: 5 }}
              />
            </>
          ) : (
            <>
              <Text fontSize={'md'}>{T('insert_email')}</Text>
              <TextBox
                flat
                color="green"
                autoCapitalize={'none'}
                keyboardType={'email-address'}
                onSubmitEditing={() => {
                  onSubmit();
                  Keyboard.dismiss();
                }}
                placeholder="Email"
                value={form.email}
                onChangeText={value => {
                  setForm({ ...form, email: value.trim() });
                }}
                style={{ marginVertical: 5 }}
              />
            </>
          )}
        </Box>

        <Button
          color="green"
          style={{ marginTop: 20, minWidth: 200 }}
          shadow={'lg'}
          onPress={() => {
            onSubmit();
          }}
          disabled={isButtonDisabled()}
        >
          {T('login')}
        </Button>

        {existingUser && (
          <>
            <Text fontSize={'xs'} textAlign={'center'} marginY={2} marginTop={4}>
              {T('code_resend')}
            </Text>

            <TouchableOpacity
              onPress={() => {
                setExistingUser(false);
              }}
            >
              <Text fontSize={'xs'} textAlign={'center'} underline>
                {T('code_retry')}
              </Text>
            </TouchableOpacity>
          </>
        )}
      </KeyboardAwareScrollView>
      <Loading visible={isLoading} bg={'rgba(255,255,255,0.5)'} />
    </BlurModal>
  );
};

interface LoginFormProps {
  onSuccess?: Function;
}

const LoginForm = (props: LoginFormProps) => {
  const [emailModalVisible, setEmailModalVisible] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<null | string>(null);

  const onFacebookLogin = async () => {
    setIsLoading(true);
    let res = null;
    res = await loginFacebook();
    if (res?.success) {
      props.onSuccess && props.onSuccess();
    } else {
      if (res?.msg) {
        setError(res?.msg);
      }
    }
    setIsLoading(false);
  };

  const onAppleLogin = async () => {
    setIsLoading(true);
    let res = null;
    res = await loginApple();
    if (res?.success) {
      props.onSuccess && props.onSuccess();
    } else {
      if (res?.msg) {
        setError(res?.msg);
      }
    }
    setIsLoading(false);
  };

  return (
    <Box
      bg={theme.vars.colors.light[0]}
      flex={1}
      alignItems="center"
      justifyContent="flex-start"
      safeAreaTop
    >
      <Box flex={1} width={'100%'} alignItems="center" justifyContent="center">
        <Image
          mb={8}
          height={160}
          minWidth={200}
          resizeMode={'contain'}
          source={require('../../assets/img/illustrations/verifica-mail.png')}
          alt="accesso"
        />

        <Text
          mt={1}
          mb={6}
          fontSize={28}
          style={{
            width: 280,
            lineHeight: 28,
            textAlign: 'center',
            color: theme.vars.colors.dark[0],
          }}
          bold
        >
          {T('login_hint')}
        </Text>

        {error ? (
          <View
            style={{
              //backgroundColor: theme.vars.colors.red[0],
              borderRadius: 999,
              marginTop: 2,
              marginBottom: 2,
              width: '100%',
              maxWidth: 300,
              padding: 5,
            }}
          >
            <Text
              width={'100%'}
              textAlign={'center'}
              textTransform={'capitalize'}
              color={theme.vars.colors.red[0]}
            >
              {error}
            </Text>
          </View>
        ) : null}

        <Pressable
          style={[styles.loginBtn, { backgroundColor: '#4267b2' }]}
          onPress={() => onFacebookLogin()}
        >
          <Image
            alt={'facebook'}
            source={require('../../assets/img/symbols/facebook.png')}
            style={styles.loginBtnImg}
          />
          <Text color={theme.vars.colors.light[0]} style={styles.loginBtnTxt} bold>
            {T('login_facebook')}
          </Text>
        </Pressable>
        {/* <TouchableOpacity
            style={[styles.loginBtn, { backgroundColor: theme.vars.colors.light }]}
            onPress={() => {}}
          >
            <Image
              source={require('../../../../assets/images/sym/google.png')}
              style={styles.loginBtnImg}
            />
            <Text style={[theme.text.l, styles.loginBtnTxt, { color: theme.vars.colors.dark }]}>
              Continua con Google
            </Text>
          </TouchableOpacity> */}

        {Platform.OS === 'ios' && majorVersionIOS > 12 ? (
          <Pressable
            style={[
              styles.loginBtn,
              {
                backgroundColor: '#fff',
                borderWidth: 1,
                borderColor: theme.vars.colors.dark[0],
              },
            ]}
            onPress={() => onAppleLogin()}
          >
            <Image
              alt="apple"
              source={require('../../assets/img/symbols/appleBlack.png')}
              style={styles.loginBtnImg}
            />
            <Text color={theme.vars.colors.dark[0]} style={styles.loginBtnTxt} bold>
              {T('login_apple')}
            </Text>
          </Pressable>
        ) : null}

        <Pressable
          style={[styles.loginBtn, { backgroundColor: '#101010' }]}
          onPress={() => {
            setEmailModalVisible(true);
          }}
        >
          <Image
            alt="email"
            source={require('../../assets/img/symbols/mail.png')}
            style={styles.loginBtnImg}
          />
          <Text color={theme.vars.colors.light[0]} style={styles.loginBtnTxt} bold>
            {T('login_email')}
          </Text>
        </Pressable>
      </Box>
      <Loading visible={isLoading} bg={'rgba(255,255,255,0.5)'} />
      <EmailLogin
        isOpen={emailModalVisible}
        onClose={setEmailModalVisible}
        onSuccess={props.onSuccess}
      />
      {/* <RegisterEmail isOpen={registerModalVisible} onClose={setRegisterModalVisible} /> */}
    </Box>
  );
};

const styles = StyleSheet.create({
  view: {
    flex: 1,
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  infoView: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  btnView: {
    width: '100%',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingBottom: 15,
  },
  loginBtn: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.vars.colors.dark[0],
    paddingVertical: 10,
    paddingHorizontal: 20,
    width: '100%',
    maxWidth: 280,
    height: 50,
    borderRadius: 99,
    marginVertical: 5,
  },
  loginBtnImg: {
    height: 25,
    width: 25,
    marginRight: 10,
    resizeMode: 'contain',
  },
  loginBtnTxt: {
    marginTop: 4,
    textAlign: 'center',
    fontSize: 17,
  },
  upperFormText: {
    width: '100%',
    textAlign: 'center',
    paddingLeft: 10,
    paddingRight: 10,
    margin: 5,
    color: '#fff',
  },
  textShadow: {
    textShadowColor: 'rgba(0, 0, 0, 0.5)',
    textShadowOffset: { width: -1, height: 1 },
    textShadowRadius: 3,
  },
});

export { LoginForm };
