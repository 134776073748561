import React from 'react';
import { StyleSheet } from 'react-native';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { App } from './src/App';

export default function Root() {
  return (
    <GestureHandlerRootView style={styles.container} pointerEvents={'box-none'}>
      <App />
    </GestureHandlerRootView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
