import React, { useEffect } from 'react';
import { useWindowDimensions, View } from 'react-native';
import { Text, Box, ScrollView, Image } from 'native-base';
import { Folder } from './Folder';
import { LOG, theme } from '../../config';
import { Shop, ProductTree, Product, Promo } from '../../stores/queries/shop';
import { useNavigation } from '@react-navigation/core';
import { PromoView } from './PromoView';
import { destructureTree } from '../../libs/utils';
import { Colors } from '../../config/theme';
import { useDebounce } from '../../hooks/helpers';

const log = LOG.extend('FOLDEERVIEW');

interface FolderViewProps {
  productTree: Shop['productTree'];
  promos?: Promo[];
  color?: Colors;
}

const FolderView = (props: FolderViewProps) => {
  const navigation: any = useNavigation();
  const dimensions = useWindowDimensions();
  const { debounce } = useDebounce();

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      style={{ flex: 1 }}
      contentContainerStyle={{
        width: '100%',
        flexWrap: 'wrap',
        paddingBottom: 40,
        flexDirection: dimensions.width <= theme.vars.screen.sm ? 'column' : 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {/*      <Folder
        type={'product'}
        key={'tree_' + 'yy'}
        title={gadget.name}
        desc={gadget.descriptions.short}
        color={'red'}
        image={gadget.images.gallery[0].url}
        price={gadget.prices.quantityDiscounts[0].newPrice}
        onPress={() => {
          navigation.navigate('Wizard', {
            screen: 'ProductSummary',
            params: {
              product: gadget,
              title: gadget.name,
            },
          });
          return;
        }}
      /> */}

      {props.promos && <PromoView promos={props.promos} />}
      {props.productTree?.map((tree: ProductTree, index: number) => {
        if (tree.type === 'folder' && !tree.isVisible) return null;

        if (tree.type === 'folder' && (!tree.next || tree.next.length <= 0)) {
          log.warn('Folder void: ' + tree.name);
          return null;
        }

        if (tree.type !== 'folder' && tree.type !== 'product') {
          log.warn('Element with unknown type: ' + tree.name);
          return null;
        }

        let { title, desc, color, image, grouped, price, gross, discount } = destructureTree(tree);

        if (!color && props.color) {
          color = props.color;
        }

        if (grouped) {
          return (
            <View
              key={'treeGrouped_' + index}
              style={{
                width: '100%',
                backgroundColor: color ? theme.vars.colors[color][2] : theme.vars.colors.pink[2],
                marginVertical: 14,
              }}
            >
              <Text
                mt={4}
                style={{
                  paddingTop: 10,
                  paddingBottom: 3,
                  marginBottom: -5,
                  fontSize: 24,
                  width: '100%',
                  textAlign: 'center',
                  paddingLeft: 20,
                  color: theme.vars.colors.grey[0],
                }}
                bold
              >
                {title}
              </Text>

              <ScrollView
                contentContainerStyle={{
                  paddingHorizontal: 10,
                  flexWrap: 'wrap',
                  paddingBottom: 20,
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                horizontal={dimensions.width <= theme.vars.screen.sm ? true : false}
                showsHorizontalScrollIndicator={false}
              >
                {tree.next.map((tree: ProductTree, indexMin: number) => {
                  let { title, desc, color, image, price, gross, discount } = destructureTree(tree);

                  return (
                    <Folder
                      type={tree.type}
                      key={'tree_' + '_' + index + '_' + indexMin}
                      min={true}
                      title={title}
                      price={price}
                      desc={desc}
                      color={color}
                      image={image}
                      onPress={() => {
                        if (tree.type === 'folder') {
                          debounce(() => {
                            navigation.push('Wizard', {
                              screen: 'ProductPath',
                              params: {
                                productTree: tree.next,
                                title,
                                color,
                              },
                            });
                          });
                          return;
                        }
                        if (tree.type === 'product') {
                          navigation.navigate('Wizard', {
                            screen: 'ProductSummary',
                            params: {
                              product: tree.product,
                              title,
                            },
                          });
                          return;
                        }
                        return;
                      }}
                    />
                  );
                })}
              </ScrollView>
            </View>
          );
        }

        return (
          <Folder
            type={tree.type}
            key={'tree_' + index}
            title={title}
            desc={desc}
            color={color}
            image={image}
            price={price}
            discount={discount}
            gross={gross}
            onPress={() => {
              if (tree.type === 'folder') {
                debounce(() => {
                  navigation.push('Wizard', {
                    screen: 'ProductPath',
                    params: {
                      productTree: tree.next,
                      title,
                      color,
                    },
                  });
                });
                return;
              } else if (tree.type === 'product') {
                navigation.navigate('Wizard', {
                  screen: 'ProductSummary',
                  params: {
                    product: tree.product,
                    title,
                  },
                });
                return;
              }
            }}
          />
        );
      })}
    </ScrollView>
  );
};

export { FolderView };
