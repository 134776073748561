import { Box } from 'native-base';
import React from 'react';
import { useWindowDimensions } from 'react-native';
import PinField from 'react-pin-field';
import { theme } from '../../config';

interface CodeInputProps {
  value: string | number;
  onChange?: (code: string) => void;
  onComplete?: (code: string) => void;
}

const CodeInput = (props: CodeInputProps) => {
  const dimensions = useWindowDimensions();

  return (
    <Box style={{ flex: 1, flexDirection: 'row', paddingTop: 5 }}>
      <PinField
        //value={props.value}
        style={{
          width: dimensions.width < theme.vars.screen.sm ? 40 : undefined,
        }}
        className="pin-field"
        onChange={code => props.onChange(code.toUpperCase())}
        onComplete={code => {
          props.onComplete(code);
        }}
        length={5}
      />
    </Box>
  );
};

export { CodeInput };
