import React, { useEffect, useState } from 'react';
import { Box, Spinner, Text } from 'native-base';
import {
  Elements,
  PaymentElement,
  useStripe,
  useElements,
  CardElement,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { ENV, LOG, theme } from '../../config';
import { Button } from '../../ui/Button';
import { Icon } from '../../ui';
import { BlurModal } from '../../ui/BlurModal';
import { DB_JOBS } from '../../stores/db/orders';
import { Loading } from '../../ui/Loading';
import { T } from '../../config/localization';

const log = LOG.extend('STRIPE');

const CheckoutForm = (props: { jobId: string }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [load, setLoad] = useState(false);

  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async event => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    setLoad(true);
    setErrorMessage(null);

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const res = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: 'https://app.easypixy.com?payment=stripe&jobId=' + props.jobId,
      },
      //redirect: 'if_required',
    });

    if (res?.error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(res?.error?.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }

    setLoad(false);
  };

  return (
    <>
      <Text mb={4} fontSize={20} bold>
        {T('payment_cards')}
      </Text>
      {errorMessage && (
        <Text mb={8} fontSize={20} color={theme.vars.colors.red[0]}>
          {errorMessage}
        </Text>
      )}
      <form
        style={{
          marginLeft: 20,
          marginRight: 20,
          width: '90%',
          alignSelf: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 20,
        }}
        onSubmit={handleSubmit}
      >
        <PaymentElement />
        <Box width={'100%'} justifyContent="center" alignItems={'center'}>
          <button
            style={{
              backgroundColor: theme.vars.colors.violet[1],
              borderRadius: 999,
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'row',
              height: 46,
              width: 260,
              marginTop: 30,
              textAlign: 'center',
              fontSize: 'sm',
              fontWeight: 'bold',
              textTransform: 'uppercase',
              color: theme.vars.colors.light[0],
              cursor: 'pointer',
              borderWidth: 0,
            }}
            type="submit"
            disabled={!stripe}
          >
            {load ? (
              <Spinner
                size={'sm'}
                color={theme.vars.colors.light[0]}
                accessibilityLabel="Loading Shop"
              />
            ) : (
              T('pay')
            )}
          </button>
        </Box>
      </form>
    </>
  );
};

interface StripePaymentProps {
  getClientSecret: () => Promise<{ clientSecret: string; jobId: string } | undefined>;
  stripeAccountId: string;
  shopName: string;
  onSuccess?: () => void;
  onError?: (err: any) => void;
}

const StripePayment = (props: StripePaymentProps) => {
  const [showStripePayment, setShowStripePayment] = useState(false);
  const [stripeObject, setStripeObject] = useState(null);
  const [clientSecret, setClientSecret] = useState<string>();
  const [jobId, setJobId] = useState<string>();
  const [load, setLoad] = useState<boolean>(true);

  useEffect(() => {
    if (!props.stripeAccountId) {
      props.onError && props.onError('publishableKey not defined');
    }
    if (!props.stripeAccountId) {
      props.onError && props.onError('stripeAccountId not defined');
    }
    async function initialize() {
      const res = await loadStripe(ENV.payment.stripe.publishableKey, {
        stripeAccount: props.stripeAccountId,
      });

      // When we have got the Stripe object, pass it into our useState.
      setStripeObject(res);

      /* await initStripe({
        publishableKey: ENV.payment.stripe.publishableKey,
        stripeAccountId: props.stripeAccountId,
        merchantIdentifier: props.shopName,

        //urlScheme: 'stripe-ep',
        //setUrlSchemeOnAndroid: true,
      }); */
      setLoad(false);
    }
    initialize();
  }, []);

  const initialisePaymentSheet = async (): Promise<{
    clientSecret: string;
    jobId: string;
    error: any;
  }> => {
    const res = await props.getClientSecret();

    if (!res?.clientSecret) {
      return { clientSecret: null, jobId: null, error: 'Error on get client secret' };
    }

    if (!res?.jobId) {
      return { clientSecret: null, jobId: null, error: 'Error on save job' };
    }

    return { clientSecret: res.clientSecret, jobId: res.jobId, error: null };
  };

  return (
    <>
      <Button
        color="light"
        shadow="lg"
        style={{ width: '100%', maxWidth: 300, shadowOpacity: 0.2 }}
        disabled={load}
        onPress={() => {
          setLoad(true);
          initialisePaymentSheet().then(res => {
            if (!res.error) {
              setClientSecret(res.clientSecret);
              setJobId(res.jobId);
              setTimeout(() => {
                setShowStripePayment(true);
              }, 100);
            } else {
              props.onError && props.onError(res.error);
            }
            setLoad(false);
          });
        }}
      >
        <Box width={'100%'} justifyContent={'center'} alignItems={'center'} flexDirection={'row'}>
          <Icon name="fronte" size={16} color="dark" style={{ position: 'absolute', left: 5 }} />
          <Text color={theme.vars.colors.dark[0]} bold>
            {T('payment_cards')}
          </Text>
        </Box>
      </Button>
      <BlurModal
        height={window.screen.height * 0.6}
        isOpen={showStripePayment}
        onClose={() => {
          setShowStripePayment(false);
        }}
      >
        {clientSecret && stripeObject && jobId && (
          <Elements
            options={{
              clientSecret,
              appearance: {
                theme: 'stripe',
              },
            }}
            stripe={stripeObject}
          >
            <CheckoutForm jobId={jobId} />
          </Elements>
        )}
      </BlurModal>
    </>
  );
};

export { StripePayment };
