import React, { useEffect, useState } from 'react';
import { Linking, Platform, StyleSheet } from 'react-native';
import { Text, Box, Pressable, Switch, ScrollView } from 'native-base';
import { ENV, LOG, theme } from '../../config';
import { Button, Icon } from '../../ui';
import { Promt } from '../../ui/Promt';
import { Loading } from '../../ui/Loading';
import { useNavigation } from '@react-navigation/core';
import { logout } from '../../libs/apollo/auth';
import { clearCache } from '../../libs/storages';
import { filledCart, filledProjects } from '../../stores/db/appstate';
import RNRestart from 'react-native-restart';
import { LOCALE, T } from '../../config/localization';
import { useQuery } from '@apollo/client';
import { User, USER_ME } from '../../stores/queries/user';
// @ts-ignore
import qs from 'qs';

interface PreferencesProps {
  navigation: any;
}

const Preferences = (props: PreferencesProps) => {
  const [resetPromtVisible, setResetPromtVisible] = useState(false);
  const [deletePromtVisible, setDeletePromtVisible] = useState(false);

  const { loading, error, data } = useQuery<{ UserMe: User }>(USER_ME, {
    fetchPolicy: 'cache-and-network',
  });

  const sendEmail = () => {
    let url = `mailto:info@easypixy.com`;

    const query = qs.stringify({
      subject: 'Delete my user and data',
      body: `Please I would like to have my user and all related data deleted from the Easypixy databases, thanks.\n
        Email: ${data?.UserMe?.email}`,
    });

    if (query.length) {
      url += `?${query}`;
    }

    try {
      Linking.openURL(url);
    } catch (error) {
      LOG.error(error);
    }
  };

  return (
    <Box bg={theme.vars.colors.light[0]} flex={1} style={{ alignItems: 'center' }}>
      <ScrollView
        style={{
          flex: 1,
          maxWidth: 1024,
          width: '100%',
        }}
        contentContainerStyle={{
          alignItems: 'center',
        }}
        flex={1}
      >
        {/* <Text
          paddingX={5}
          width={'100%'}
          mt={10}
          mb={2}
          color={theme.vars.colors.dark[0]}
          textAlign={'left'}
          lineHeight={20}
          fontSize={'lg'}
          bold
        >
          {T('comunications')}
        </Text>
        <Box style={styles.entry}>
          <Text color={theme.vars.colors.dark[0]} fontSize={'md'} flex={1} mr={5}>
            {T('noti_orders')}
          </Text>
          <Switch size={'md'} onTrackColor={theme.vars.colors.blue[0]} />
        </Box>
        <Box style={styles.entry}>
          <Text color={theme.vars.colors.dark[0]} fontSize={'md'} flex={1} mr={5}>
            {T('noti_commercial')}
          </Text>
          <Switch size={'md'} onTrackColor={theme.vars.colors.blue[0]} />
        </Box>
        <Box style={styles.entry}>
          <Text color={theme.vars.colors.dark[0]} fontSize={'md'} flex={1} mr={5}>
            {T('noti_ep')}
          </Text>
          <Switch size={'md'} onTrackColor={theme.vars.colors.blue[0]} />
        </Box> */}
        <Text
          paddingX={5}
          width={'100%'}
          mt={10}
          mb={2}
          color={theme.vars.colors.dark[0]}
          textAlign={'left'}
          lineHeight={20}
          fontSize={'lg'}
          bold
        >
          {T('system')}
        </Text>

        {/* <Box style={styles.entry}>
          <Text color={theme.vars.colors.dark[0]} fontSize={'md'} flex={1} mr={5}>
            {T('device_language')}
          </Text>
          <Text
            color={theme.vars.colors.dark[0]}
            fontSize={'md'}
            style={{ textTransform: 'uppercase' }}
            bold
          >
            {LOCALE}
          </Text>
        </Box>
 */}

        <Pressable
          style={styles.entry}
          onPress={() => {
            setResetPromtVisible(true);
          }}
        >
          <Text color={theme.vars.colors.dark[0]} fontSize={'md'} flex={1} mr={5}>
            {T('delete_cache')}
          </Text>
          <Icon color="dark" size={20} name={'freccia-destra'} />
        </Pressable>

        <Pressable
          style={styles.entry}
          onPress={() => {
            setDeletePromtVisible(true);
          }}
        >
          <Text color={theme.vars.colors.dark[0]} fontSize={'md'} flex={1} mr={5}>
            {T('delete_user')}
          </Text>
          <Icon color="dark" size={20} name={'freccia-destra'} />
        </Pressable>

        {/* {Platform.OS !== 'web' && (
          <Pressable
            style={styles.entry}
            onPress={() => {
              navigation.navigate('Logs');
            }}
          >
            <Text color={theme.vars.colors.dark[0]} fontSize={'md'} flex={1} mr={5}>
              Show logs
            </Text>
            <Icon color="dark" size={20} name={'freccia-destra'} />
          </Pressable>
        )} */}
      </ScrollView>
      <Promt
        visible={resetPromtVisible}
        subText={T('delete_cache_promt_sub')}
        text={T('delete_cache_promt')}
        onYes={() => {
          clearCache();
          logout();
          filledProjects(false);
          filledCart(false);

          setTimeout(() => {
            if (Platform.OS === 'web') {
              location.reload();
            } else {
              RNRestart.Restart();
            }
          }, 300);
        }}
        onNo={() => {
          setResetPromtVisible(false);
        }}
        onCancel={() => {
          setResetPromtVisible(false);
        }}
      />
      <Promt
        visible={deletePromtVisible}
        subText={T('delete_user_promt_sub')}
        text={T('delete_user_promt')}
        onYes={() => {
          sendEmail();
          clearCache();
          logout();
          filledProjects(false);
          filledCart(false);

          setTimeout(() => {
            if (Platform.OS === 'web') {
              location.reload();
            } else {
              RNRestart.Restart();
            }
          }, 300);
        }}
        onNo={() => {
          setDeletePromtVisible(false);
        }}
        onCancel={() => {
          setDeletePromtVisible(false);
        }}
      />
      <Loading bg={theme.vars.colors.light[0]} visible={false} />
    </Box>
  );
};

const styles = StyleSheet.create({
  entry: {
    width: '100%',
    paddingHorizontal: 20,
    paddingVertical: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.vars.colors.light[2],
    borderColor: theme.vars.colors.blue[2],
    borderBottomWidth: 1,
  },
});

export { Preferences };
