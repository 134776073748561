import React, { useEffect } from 'react';
import { ViewStyle } from 'react-native';

import { Icon } from '../../ui';
import { theme } from '../../config';
import { useFilePicker } from 'use-file-picker';
import { Box, Pressable, Text } from 'native-base';
import { T } from '../../config/localization';

const getImageDimensions = async file => {
  return new Promise(function (resolved, rejected) {
    // @ts-ignore
    var i = new Image();
    // @ts-ignore
    i.onload = function () {
      // @ts-ignore
      resolved({ width: i.width, height: i.height });
    };
    // @ts-ignore
    i.src = file;
  });
};

type PikerImage = {
  path: string;
  height: number;
  width: number;
};

interface PhotoPickerSingleProps {
  style: ViewStyle;
  onSelected: Function;
}

const PhotoPickerSingle = (props: PhotoPickerSingleProps) => {
  const [openFileSelector, { filesContent, loading, errors }] = useFilePicker({
    readAs: 'DataURL',
    accept: 'image/*',
    multiple: false,
    limitFilesConfig: { max: 1 },
    // minFileSize: 0.1, // in megabytes
    maxFileSize: 500,
    /* imageSizeRestrictions: {
      maxHeight: 900, // in pixels
      maxWidth: 1600,
      minHeight: 600,
      minWidth: 768,
    }, */
  });

  useEffect(() => {
    if (filesContent && filesContent[0]) {
      getImageDimensions(filesContent[0].content).then(size => {
        let image: PikerImage = {
          path: filesContent[0].content,
          // @ts-ignore
          height: size.height,
          // @ts-ignore
          width: size.width,
        };
        props.onSelected(image);
      });
    }
  }, [filesContent]);

  return (
    <Pressable
      onPress={() => {
        openFileSelector();
      }}
      _pressed={{
        opacity: 0.7,
      }}
      flexDirection="column"
      alignItems={'center'}
      justifyContent="center"
    >
      <Box
        rounded={999}
        bg={theme.vars.colors.violet[2]}
        alignItems={'center'}
        justifyContent="center"
        style={{ height: 46, width: 46 }}
      >
        <Icon name="aggiungi-foto" size={18} color={'violet'} />
      </Box>
      <Text textAlign={'center'} color={theme.vars.colors.violet[0]} fontSize="sm" pt={2}>
        {T('select_picture')}
      </Text>
    </Pressable>
  );
};

export { PhotoPickerSingle };
