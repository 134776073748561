import React from 'react';
import { Text, TextStyle } from 'react-native';

import { LOG, theme } from '../../config';
import { Colors } from '../../config/theme';

type Icons =
  | 'aggiungi-foto'
  | 'aggiungi'
  | 'blocca'
  | 'bordo'
  | 'carrello'
  | 'carta'
  | 'cestino'
  | 'consegna'
  | 'costo-spedizione'
  | 'elimina'
  | 'facebook'
  | 'filtri'
  | 'formato'
  | 'freccia-destra'
  | 'freccia-sinistra'
  | 'fronte'
  | 'google'
  | 'home'
  | 'how-to'
  | 'impostazioni'
  | 'indirizzi'
  | 'info'
  | 'inquadra'
  | 'instagram'
  | 'layout'
  | 'logout'
  | 'maschera'
  | 'materiali'
  | 'modifica'
  | 'mostra-password'
  | 'nascondi-password'
  | 'negozio'
  | 'notifiche'
  | 'ordini'
  | 'profilo'
  | 'retro'
  | 'ruota'
  | 'sblocca'
  | 'sconti'
  | 'sostituisci-foto'
  | 'stampante'
  | 'stella'
  | 'sticker'
  | 'telefono'
  | 'testi'
  | 'tick';

interface IconProps {
  name: Icons;
  size?: number;
  color?: Colors;
  style?: TextStyle;
}

const Icon = (props: IconProps) => {
  if (!props.name) return null;
  let size = theme.vars.fontSizes.xl;
  if (props.size) {
    size = props.size;
  }

  let code = '';

  switch (props.name) {
    case 'aggiungi-foto':
      code = '\ue800';
      break;
    case 'aggiungi':
      code = '\ue801';
      break;
    case 'blocca':
      code = '\ue802';
      break;
    case 'bordo':
      code = '\ue803';
      break;
    case 'carrello':
      code = '\ue804';
      break;
    case 'carta':
      code = '\ue805';
      break;
    case 'cestino':
      code = '\ue806';
      break;
    case 'consegna':
      code = '\ue807';
      break;
    case 'costo-spedizione':
      code = '\ue808';
      break;
    case 'elimina':
      code = '\ue809';
      break;
    case 'facebook':
      code = '\ue80a';
      break;
    case 'filtri':
      code = '\ue80b';
      break;
    case 'formato':
      code = '\ue80c';
      break;
    case 'freccia-destra':
      code = '\ue80d';
      break;
    case 'freccia-sinistra':
      code = '\ue80e';
      break;
    case 'fronte':
      code = '\ue80f';
      break;
    case 'google':
      code = '\ue810';
      break;
    case 'home':
      code = '\ue811';
      break;
    case 'how-to':
      code = '\ue812';
      break;
    case 'impostazioni':
      code = '\ue813';
      break;
    case 'indirizzi':
      code = '\ue814';
      break;
    case 'info':
      code = '\ue815';
      break;
    case 'inquadra':
      code = '\ue816';
      break;
    case 'instagram':
      code = '\ue817';
      break;
    case 'layout':
      code = '\ue818';
      break;
    case 'logout':
      code = '\ue819';
      break;
    case 'maschera':
      code = '\ue81a';
      break;
    case 'materiali':
      code = '\ue81b';
      break;
    case 'modifica':
      code = '\ue81c';
      break;
    case 'mostra-password':
      code = '\ue81d';
      break;
    case 'nascondi-password':
      code = '\ue81e';
      break;
    case 'negozio':
      code = '\ue81f';
      break;
    case 'notifiche':
      code = '\ue820';
      break;
    case 'ordini':
      code = '\ue821';
      break;
    case 'profilo':
      code = '\ue822';
      break;
    case 'retro':
      code = '\ue823';
      break;
    case 'ruota':
      code = '\ue824';
      break;
    case 'sblocca':
      code = '\ue825';
      break;
    case 'sconti':
      code = '\ue826';
      break;
    case 'sostituisci-foto':
      code = '\ue827';
      break;
    case 'stampante':
      code = '\ue828';
      break;
    case 'stella':
      code = '\ue829';
      break;
    case 'sticker':
      code = '\ue82a';
      break;
    case 'telefono':
      code = '\ue82b';
      break;
    case 'testi':
      code = '\ue82c';
      break;
    case 'tick':
      code = '\ue82d';
      break;
    default:
      LOG.warn('Icon not found: ' + props.name);
      code = ' ' + '\u25CF' + ' ';
      size = size - 5;
  }

  let color = theme.vars.colors.light[0];
  if (props.color && theme.vars.colors[props.color]) {
    color = theme.vars.colors[props.color][0];
  }

  return (
    <Text
      style={[{ fontFamily: 'fontello', textAlign: 'center', color, fontSize: size }, props.style]}
    >
      {code}
    </Text>
  );
};

export { Icon, Icons };
