import React, { useCallback, useEffect, useRef } from 'react';
import { Box, Center, Image, Text } from 'native-base';
import { theme, LOG } from '../../config';
import { Button } from '../../ui/Button';
import { DB_JOBS } from '../../stores/db/orders';
import { useFocusEffect } from '@react-navigation/native';
import { BackHandler } from 'react-native';
import { activeOrder } from '../../stores/db/appstate';
import { T } from '../../config/localization';

interface JobCompleteProps {
  navigation: any;
  route: {
    params: {
      shopId: string;
      jobId: string;
    };
  };
}

const JobComplete = (props: JobCompleteProps) => {
  useFocusEffect(
    useCallback(() => {
      const onBackPress = () => {
        return true;
      };

      BackHandler.addEventListener('hardwareBackPress', onBackPress);

      return () => BackHandler.removeEventListener('hardwareBackPress', onBackPress);
    }, [])
  );

  useEffect(() => {
    activeOrder(null);
    //DB_JOBS.clearCurrentJob();
  }, []);

  return (
    <Box
      pt={2}
      bg={theme.vars.colors.light[0]}
      flex={1}
      alignItems="center"
      justifyContent="flex-start"
    >
      <Box flex={1} alignItems="center" justifyContent="center" safeAreaBottom>
        <Image
          mb={6}
          alt={'ritoro_negozio'}
          style={{ resizeMode: 'contain', width: 160, height: 160 }}
          source={require('../../assets/img/illustrations/ok.png')}
        ></Image>
        <Text color={theme.vars.colors.grey[0]} mb={6} fontSize={30} bold>
          {T('thanks')}
        </Text>
        <Text color={theme.vars.colors.grey[0]} mb={0} fontSize={'lg'} bold>
          {T('complete_order_title')}
        </Text>
        <Text color={theme.vars.colors.grey[0]} mb={8} fontSize={'md'}>
          {T('complete_order_hint')}
        </Text>

        <Button
          color="violet"
          shadow="lg"
          style={{ width: '100%', maxWidth: 300, shadowOpacity: 0.2 }}
          onPress={() => {
            DB_JOBS.clearCurrentJob();
            props.navigation.navigate('Home', {
              screen: 'Shop',
              initial: false,
            });
          }}
        >
          {T('continue')}
        </Button>
      </Box>
    </Box>
  );
};

export { JobComplete };
