import React, { ReactNode } from 'react';
import { Pressable, Box } from 'native-base';

import { theme } from '../../config';
import { ViewStyle } from 'react-native';
import { Icon } from '..';
import { IconButton } from '../IconButton';

interface ButtonProps {
  children: ReactNode;
  style?: ViewStyle;
  onPressEdit?: Function;
  onPressRemove?: Function;
  error?: boolean;
}

const EditBox = (props: ButtonProps) => {
  return (
    <Box
      style={[
        {
          margin: 10,
          padding: 10,
          paddingHorizontal: 15,
          backgroundColor: theme.vars.colors.blue[2],
          borderRadius: 20,
          borderWidth: props.error ? 1 : 0,
          borderColor: props.error ? theme.vars.colors.red[0] : undefined,
          minHeight: 76,
        },
        props.style,
      ]}
    >
      {props.children}

      {props.onPressEdit && (
        <IconButton
          style={{
            position: 'absolute',
            right: 10,
            top: 10,
          }}
          icon="modifica"
          color="blue"
          onPress={() => {
            props.onPressEdit();
          }}
        />
      )}
      {props.onPressRemove && (
        <IconButton
          style={{
            position: 'absolute',
            right: 10,
            bottom: 10,
          }}
          icon="cestino"
          color="blue"
          onPress={() => {
            props.onPressRemove();
          }}
        />
      )}
    </Box>
  );
};

export { EditBox };
