import React, { useEffect, useState } from 'react';
import { Platform, useWindowDimensions, View } from 'react-native';
import { Box, Image, Pressable, Text } from 'native-base';
import { AppState } from 'react-native';
import RNRestart from 'react-native-restart';

import { useQuery } from '@apollo/client';
import { theme } from '../../config';
import { LinearGradient } from 'expo-linear-gradient';
import { Promo, Shop, USER_SHOP } from '../../stores/queries/shop';
import { ACTUAL_AUTH, DB_AUTH } from '../../stores/db/auth';
import { FolderView } from '../../components/ProductTree/FolderView';
import { Button, Icon } from '../../ui';
import { Loading } from '../../ui/Loading';
import { APP_STATE, APP_STATE_PROPS } from '../../stores/queries/appstate';
import { EditBox } from '../../ui/EditBox';
import { IconButton } from '../../ui/IconButton';
import { destructureTree } from '../../libs/utils';
import { propsFlattener } from 'native-base/lib/typescript/hooks/useThemeProps/propsFlattener';
import { Promt } from '../../ui/Promt';
import { logout } from '../../libs/apollo/auth';
import { T } from '../../config/localization';

interface MaintenanceProps {
  navigation: any;
  route: {
    params: {
      shop: string;
    };
  };
}

const Maintenance = (props: MaintenanceProps) => {
  const [promtVisible, setPromtVisible] = useState(false);

  return (
    <Box
      bg={theme.vars.colors.light[0]}
      flex={1}
      width={'100%'}
      justifyContent={'center'}
      alignItems={'center'}
      flexDirection="column"
    >
      <Image
        style={{
          width: '70%',
          maxWidth: 280,
          height: 300,
          marginBottom: -30,
          resizeMode: 'contain',
        }}
        alt={'maintenance'}
        source={require('../../assets/img/illustrations/avviso.png')}
      />
      <Text style={{ fontSize: 20, fontWeight: 'bold' }}>{T('maintenance_title')}</Text>
      <Text mb={2} style={{ fontSize: 14, fontWeight: 'bold', marginTop: 10 }}>
        {T('maintenance_sub')}
      </Text>
      <Box mb={6}>
        <Button
          onPress={() => {
            if (Platform.OS === 'web') {
              location.reload();
            } else {
              RNRestart.Restart();
            }
          }}
        >
          {T('retry')}
        </Button>
      </Box>

      {props?.route?.params?.shop ? (
        <Button
          onPress={() => {
            setPromtVisible(true);
          }}
          color="grey"
        >
          {T('shop_exit')}
        </Button>
      ) : null}
      <Promt
        visible={promtVisible}
        text={T('shop_exit_promt')}
        onYes={() => {
          DB_AUTH.set({ shopCode: null }).then(() => {
            if (Platform.OS === 'web') {
              location.reload();
            } else {
              RNRestart.Restart();
            }
          });
        }}
        onNo={() => {
          setPromtVisible(false);
        }}
        onCancel={() => {
          setPromtVisible(false);
        }}
      />
    </Box>
  );
};

export { Maintenance };
