import React, { ReactNode, useState } from 'react';
import { Pressable, Text, Center, Box } from 'native-base';

import { BLURHASH, theme } from '../../config';
import { Colors, Sizes } from '../../config/theme';
import {
  Dimensions,
  ImageStyle,
  Platform,
  TextStyle,
  useWindowDimensions,
  ViewStyle,
} from 'react-native';
import { BlurView } from 'expo-blur';
import { T } from '../../config/localization';
import { Image } from '../../ui/Image';

interface FolderProps {
  type: 'folder' | 'productGroup' | 'product';
  color?: Colors;
  style?: ViewStyle;
  onPress?: Function;
  price?: number;
  priceIncrement?: number;
  image?: string;
  title?: string;
  desc?: string;
  min?: boolean;
  discount?: number;
  gross?: number;
}

const Folder = (props: FolderProps) => {
  const dimensions = useWindowDimensions();
  const [image, setImage] = useState(props.image);

  let containerStyle: ViewStyle = {
    borderRadius: 20,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    width: dimensions.width <= theme.vars.screen.sm ? dimensions.width * 0.92 : 380,
    marginHorizontal: dimensions.width <= theme.vars.screen.sm ? dimensions.width * 0.04 : 20,
    marginVertical: 14,
    marginTop: 20,
    overflow: 'hidden',
  };

  let imageStyle: ImageStyle = {
    width: dimensions.width <= theme.vars.screen.sm ? dimensions.width * 0.76 : 330,
    height: 210,
    borderRadius: 20,
    resizeMode: 'cover',
  };

  let boxstyle: ViewStyle = {
    width: '100%',
    marginTop: image ? 115 : 0,
    paddingTop: image ? 105 : 10,
    borderRadius: 20,
    paddingHorizontal: 20,
    paddingBottom: 14,
  };

  let color = theme.vars.colors.violet;
  let bg: any = theme.vars.colors.violet[2];

  if (props.color && theme.vars.colors[props.color] && props.color !== 'light') {
    color = theme.vars.colors[props.color];
    bg = {
      linearGradient: {
        colors: [color[0], color[2]],
        start: [0.3, 1.9],
        end: [0.01, 0.04],
      },
    };
  }

  if (props.min) {
    containerStyle.width = 250;
    containerStyle.marginBottom = 30;
    containerStyle.marginHorizontal = 10;
    imageStyle.width = 250;
    imageStyle.height = 220;
    boxstyle.height = 220;
    boxstyle.paddingHorizontal = 0;
    boxstyle.marginTop = 0;
    boxstyle.paddingTop = 0;
    boxstyle.justifyContent = 'flex-end';
    return (
      <Pressable
        // @ts-ignore
        cursor={Platform.OS == 'web' ? 'pointer' : undefined}
        style={[
          containerStyle,
          {
            ...theme.vars.shadow['md'],
            shadowOffset: {
              width: 0,
              height: 3,
            },
            shadowRadius: 6,
            shadowOpacity: 0.3,
          },
          props.style,
        ]}
        _pressed={{
          opacity: 0.9,
        }}
        onPress={() => {
          if (props.type === 'product' && !props.price) return false;
          props.onPress && props.onPress();
        }}
      >
        <Box bg={bg} style={boxstyle}>
          {image && (
            <Box
              //bg={theme.vars.colors.grey[0]}
              style={{
                position: 'absolute',
                top: 0,
                height: imageStyle.height,
                width: imageStyle.width,
              }}
              rounded={20}
            >
              <Image
                blurhash={BLURHASH[props.color || 'light']}
                style={imageStyle}
                source={{
                  uri: image,
                }}
                onError={() => {
                  setImage(BLURHASH[props.color || 'light']);
                }}
              />
            </Box>
          )}
          <Box
            style={{
              marginHorizontal: 12,
              borderRadius: 20,
              overflow: 'hidden',
              borderWidth: 1,
              borderColor: '#fff',
              backgroundColor:
                Platform.OS == 'ios' ? 'rgba(255,255,255,0.2)' : 'rgba(255,255,255,0.6)',
            }}
          >
            <BlurView
              intensity={50}
              style={{
                padding: 10,
                paddingHorizontal: 15,
                width: '100%',
              }}
            >
              <Text
                color={theme.vars.colors.dark[0]}
                numberOfLines={1}
                //style={{ textTransform: 'uppercase' }}
                fontSize={'md'}
                bold
              >
                {props.title}
              </Text>

              {props.desc ? (
                <Text numberOfLines={2} color={theme.vars.colors.dark[0]} fontSize={'xs'}>
                  {props.desc}
                </Text>
              ) : null}

              {props.type === 'product' || props.type === 'productGroup' ? (
                <>
                  {props.price ? (
                    <Text color={theme.vars.colors.dark[0]} fontSize={'xs'} mt={1} bold>
                      {T('start_from')} {props.price.toFixed(2)} €
                    </Text>
                  ) : (
                    <Text color={theme.vars.colors.dark[0]} fontSize={'xs'} mt={1} bold>
                      {T('comingsoon')}...
                    </Text>
                  )}
                </>
              ) : null}
            </BlurView>
          </Box>
        </Box>
      </Pressable>
    );
  }

  let showPrice = props.type === 'product' && props.price;

  return (
    <Pressable
      // @ts-ignore
      cursor={Platform.OS == 'web' ? 'pointer' : undefined}
      style={[containerStyle, props.style]}
      _pressed={{
        opacity: 0.9,
      }}
      onPress={() => {
        if (props.type === 'product' && !props.price) return false;
        props.onPress && props.onPress();
      }}
    >
      <Box bg={bg} style={boxstyle}>
        <Box justifyContent={'space-between'} alignItems={'flex-end'} flexDirection={'row'}>
          <Text
            color={props.color ? 'white' : color[0]}
            numberOfLines={1}
            //style={{ textTransform: 'uppercase' }}
            fontSize={'2xl'}
            bold
          >
            {props.title}
          </Text>
          {props.priceIncrement && (
            <Text color={props.color ? 'white' : color[0]} fontSize={'lg'} bold>
              + {props.priceIncrement} €
            </Text>
          )}
        </Box>
        <Box style={{ height: dimensions.width <= theme.vars.screen.sm ? undefined : 50 }}>
          <Text
            color={props.color ? 'white' : color[0]}
            fontSize={'md'}
            style={theme.text.m}
            numberOfLines={2}
          >
            {props.desc}
          </Text>
        </Box>

        <Box style={{ height: dimensions.width <= theme.vars.screen.sm ? undefined : 25 }}>
          {showPrice ? (
            <>
              {props.gross && props.discount ? (
                <>
                  {Platform.OS !== 'web' ? (
                    <Text
                      color={props.color ? 'white' : color[0]}
                      mt={1}
                      textDecorationLine="line-through"
                      fontSize={'lg'}
                    >
                      {T('start_from')} {props.gross.toFixed(2)} €
                    </Text>
                  ) : null}
                  <Text color={props.color ? 'white' : color[0]} fontSize={'lg'} bold>
                    {Platform.OS === 'web' ? 'In Promo! ' : ''}
                    {T('start_from')} {props.price.toFixed(2)} €
                  </Text>
                </>
              ) : (
                <Text color={props.color ? 'white' : color[0]} mt={1} fontSize={'lg'} bold>
                  {T('start_from')} {props.price.toFixed(2)} €
                </Text>
              )}
            </>
          ) : (
            <>
              {props.type === 'product' ? (
                <Text color={props.color ? 'white' : color[0]} mt={1} fontSize={'lg'}>
                  {T('comingsoon')}...
                </Text>
              ) : null}
            </>
          )}
        </Box>
      </Box>
      {image && (
        <Box
          bg={color[2] || theme.vars.colors.grey[0]}
          style={{
            position: 'absolute',
            top: 0,
            ...theme.vars.shadow['md'],
            shadowOffset: {
              width: 0,
              height: 3,
            },
            height: imageStyle.height,
            width: imageStyle.width,
            shadowRadius: 6,
            shadowOpacity: 0.3,
            /* borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0, */
          }}
          rounded={20}
        >
          <Image
            blurhash={BLURHASH[props.color || 'light']}
            style={imageStyle}
            source={{
              uri: image,
            }}
            onError={() => {
              setImage(BLURHASH[props.color || 'light']);
            }}
          />
        </Box>
      )}
      {showPrice && props.gross && props.discount ? (
        <Box
          bg={'#fff'}
          px={3}
          py={2}
          style={{
            borderTopLeftRadius: 20,
            borderBottomRightRadius: 20,
            position: 'absolute',
            bottom: 0,
            right: 0,
          }}
        >
          <Text color={color[0]} fontSize={'lg'} bold>
            -{props.discount}%
          </Text>
        </Box>
      ) : null}
    </Pressable>
  );
};

export { Folder };
