import React, { ReactNode } from 'react';
import { Pressable, Text, Center, Box } from 'native-base';

import { BLURHASH, theme } from '../../config';
import { Colors, Sizes } from '../../config/theme';
import { Platform, TextStyle, useWindowDimensions, ViewStyle } from 'react-native';
import { Image } from '../../ui/Image';

interface VariantProps {
  style?: ViewStyle;
  onPress?: Function;
  image?: string;
  title?: string;
  desc?: string;
  price?: string;
}

const Variant = (props: VariantProps) => {
  const dimensions = useWindowDimensions();

  let containerStyle: ViewStyle = {
    borderRadius: 20,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    width: dimensions.width <= theme.vars.screen.sm ? dimensions.width * 0.92 : 400,
    marginHorizontal: dimensions.width <= theme.vars.screen.sm ? dimensions.width * 0.04 : 20,
    marginVertical: 20,
  };

  return (
    <Pressable
      //@ts-ignore
      cursor={Platform.OS == 'web' ? 'pointer' : undefined}
      style={[containerStyle, props.style]}
      _pressed={{
        opacity: 0.8,
      }}
      onPress={() => {
        props.onPress && props.onPress();
      }}
    >
      <Box
        bg={theme.vars.colors.violet[2]}
        style={{
          width: '100%',
          marginTop: 100,
          paddingTop: 150,
          borderRadius: 20,
          padding: 20,
        }}
        _text={{
          fontSize: 'sm',
          fontWeight: 'bold',
          color: theme.vars.colors.light[0],
        }}
      >
        <Box flexDirection="row" justifyContent="space-between" mb={5}>
          <Text
            color={theme.vars.colors.violet[0]}
            style={[theme.text.l, { textTransform: 'uppercase' }]}
            bold
          >
            {props.title}
          </Text>
          <Text
            color={theme.vars.colors.violet[0]}
            style={[theme.text.l, { textTransform: 'uppercase' }]}
            bold
          >
            {props.price}
          </Text>
        </Box>

        <Text color={theme.vars.colors.violet[0]} style={theme.text.m}>
          {props.desc}
        </Text>
      </Box>

      <Box
        bg={theme.vars.colors.grey[0]}
        style={{
          position: 'absolute',
          top: 0,
          ...theme.vars.shadow['md'],
          shadowOffset: {
            width: 0,
            height: 4,
          },
          shadowRadius: 5,
          shadowOpacity: 0.5,
        }}
        rounded={20}
      >
        <Image
          style={{
            width: dimensions.width <= theme.vars.screen.sm ? dimensions.width * 0.8 : 360,
            height: 240,
            resizeMode: 'cover',
            borderRadius: 20,
          }}
          source={{
            uri: props.image,
          }}
          blurhash={BLURHASH['violet']}
        />
      </Box>
    </Pressable>
  );
};

export { Variant };
