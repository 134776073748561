import React, { useEffect, useRef, useState } from 'react';
import { AppState, Linking, Platform, StyleSheet } from 'react-native';
import { useQuery } from '@apollo/client';
import { Text, Box, Image } from 'native-base';
import { loginCode } from '../../libs/apollo/auth';

import { Button } from '../../ui';
import { LOG, theme } from '../../config';
import { Loading } from '../../ui/Loading';
import { BlurModal } from '../../ui/BlurModal';
import { T } from '../../config/localization';
import { APP_STATE, APP_STATE_PROPS } from '../../stores/queries/appstate';

const log = LOG.extend('VERIFYHELPER');

const VerifyEmailHelper = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const { data: APP } = useQuery<APP_STATE_PROPS>(APP_STATE);

  const appState = useRef(AppState.currentState);
  const verifiedCode = useRef(null);

  const [appStateVisible, setAppStateVisible] = useState(appState.current);

  useEffect(() => {
    const subscription = AppState.addEventListener('change', nextAppState => {
      /*       if (appState.current.match(/inactive|background/) && nextAppState === 'active') {
        console.error('STATE', nextAppState);
        checkVerify(null);
      } */
      appState.current = appStateVisible;
      setAppStateVisible(appState.current);
    });

    const urlSubscribtion = Linking.addEventListener('url', data => {
      log.info('Url retrived:', data?.url);
      if (!APP.userLogged && appStateVisible === 'active') {
        checkVerify(data?.url);
      }
    });

    return () => {
      subscription.remove();
      urlSubscribtion.remove();
    };
  }, []);

  useEffect(() => {
    if (appStateVisible) {
      checkVerify(null);
    }
  }, [appStateVisible]);

  const checkVerify = async (url: string | null) => {
    log.debug('Verify authcode', url);
    setIsLoading(true);
    if (Platform.OS === 'web') {
      var params = new URLSearchParams(window.location.search);
      /* if (params.has('code')) {
        linkCode = params.get('code');
      } */
    } else {
      try {
        //let url = await Linking.getInitialURL();
        if (!url) {
          url = await Linking.getInitialURL();
        }
        if (url) {
          let regex = /[?&]([^=#]+)=([^&#]*)/g,
            params = {},
            match;
          while ((match = regex.exec(url))) {
            params[match[1]] = match[2];
          }

          const { email, authcode }: any = params;

          if (email && authcode && authcode !== verifiedCode.current) {
            log.info('Verify user auth! ' + email + ' - ' + authcode);
            setIsOpen(true);
            let res = await loginCode(email, authcode);
            if (!res?.success) {
              setIsOpen(false);
            }
            setIsLoading(false);
            verifiedCode.current = authcode;
          }
        }
      } catch (error) {
        setIsOpen(false);
        setIsLoading(false);
        log.error('Linking.getInitialURL error:', error);
      }
    }
  };

  return (
    <BlurModal
      height={350}
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
        setIsLoading(false);
      }}
    >
      <Box flex={1} pt={8} alignItems="center" justifyContent="center" safeAreaBottom>
        <Image
          alt={'ok_verify'}
          style={{ resizeMode: 'contain', width: 120, height: 120 }}
          source={require('../../assets/img/illustrations/ok.png')}
        ></Image>
        <Text color={theme.vars.colors.grey[0]} mb={2} fontSize={30} bold>
          {T('thanks')}
        </Text>
        <Text color={theme.vars.colors.grey[0]} mb={6} fontSize={'lg'} bold>
          {T('user_verified')}!
        </Text>

        <Button
          color="violet"
          shadow="lg"
          style={{ width: '100%', maxWidth: 300, shadowOpacity: 0.2 }}
          onPress={() => {
            setIsOpen(false);
          }}
        >
          {T('continue')}
        </Button>
      </Box>
      <Loading visible={isLoading} bg={'rgba(255,255,255,1)'} />
    </BlurModal>
  );
};

const styles = StyleSheet.create({});

export { VerifyEmailHelper };
