import React, { useEffect, useState } from 'react';
import { Keyboard, Platform, ViewStyle } from 'react-native';
import { Text, Box, Pressable, Switch } from 'native-base';
import { theme } from '../../config';

import { useNavigation } from '@react-navigation/core';
import { Button, Icon, TextBox } from '../../ui';
import { Promt } from '../../ui/Promt';
import { useMutation, useQuery } from '@apollo/client';
import { ScrollView } from 'react-native-gesture-handler';
import { User, UserAddress, USER_EDIT_INFO, USER_ME } from '../../stores/queries/user';
import { EditBox } from '../../ui/EditBox';
import { BlurModal } from '../../ui/BlurModal';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { LinearGradient } from 'expo-linear-gradient';
import { T } from '../../config/localization';
import { Loading } from '../../ui/Loading';

interface AddressesProps {
  onSelect?: (adress: UserAddress) => void;
  onClose?: () => void;
  style?: ViewStyle;
}

const checkUserContacts = (address: UserAddress) => {
  if (address?.name?.length > 3 && address?.phone?.length > 3) return true;
  return false;
};

const checkUserAddress = (address: UserAddress) => {
  if (
    checkUserContacts(address) &&
    address?.city?.length &&
    address?.province?.length &&
    address?.street?.length &&
    address?.zipCode?.length
  )
    return true;
  return false;
};

const checkUserAddressVoid = (address: UserAddress) => {
  if (
    address?.name?.length ||
    address?.phone?.length ||
    address?.city?.length ||
    address?.province?.length ||
    address?.street?.length ||
    address?.zipCode?.length ||
    address?.state?.length
  )
    return false;
  return true;
};

const AddressSelector = (props: AddressesProps) => {
  const [addressSelected, setAddressSelected] = useState<'home' | 'work' | 'other'>();
  const [address, setAddress] = useState<UserAddress>({
    name: '',
    phone: '',
    street: '',
    zipCode: '',
    city: '',
    province: '',
    state: '',
  });

  const { loading, error, data } = useQuery<{ UserMe: User }>(USER_ME, {
    fetchPolicy: 'cache-and-network',
  });

  const [userEditInfo, { loading: editLoading, error: editError, data: editData }] = useMutation(
    USER_EDIT_INFO,
    {
      refetchQueries: [{ query: USER_ME }],
      awaitRefetchQueries: true,
    }
  );

  if (addressSelected) {
    return (
      <Box alignItems="center" flex={1} style={[{ width: '100%' }, props.style]}>
        <KeyboardAwareScrollView
          style={{ flex: 1, width: '100%' }}
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{ padding: 30, width: '100%' }}
        >
          <Box
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Text color={theme.vars.colors.blue[0]} mb={4} fontSize="lg" bold>
              {T('edit_address')} "{T(addressSelected + 'Address')}"
            </Text>

            <TextBox
              autoCapitalize={'none'}
              keyboardType={'default'}
              onSubmitEditing={() => {
                Keyboard.dismiss();
              }}
              color="grey"
              placeholder={T('name')}
              value={address.name}
              onChangeText={value => {
                setAddress({ ...address, name: value });
              }}
              style={{ marginVertical: 5, width: '100%' }}
            />

            <TextBox
              autoCapitalize={'none'}
              keyboardType={'default'}
              onSubmitEditing={() => {
                Keyboard.dismiss();
              }}
              color="grey"
              placeholder={T('phone')}
              value={address.phone}
              onChangeText={value => {
                setAddress({ ...address, phone: value });
              }}
              style={{ marginVertical: 5, width: '100%' }}
            />

            <TextBox
              autoCapitalize={'none'}
              keyboardType={'default'}
              onSubmitEditing={() => {
                Keyboard.dismiss();
              }}
              color="grey"
              placeholder={T('street')}
              value={address.street}
              onChangeText={value => {
                setAddress({ ...address, street: value });
              }}
              style={{ marginVertical: 5, width: '100%' }}
            />
            <TextBox
              autoCapitalize={'none'}
              keyboardType={'default'}
              onSubmitEditing={() => {
                Keyboard.dismiss();
              }}
              color="grey"
              placeholder={T('zip_code')}
              value={address.zipCode}
              onChangeText={value => {
                setAddress({ ...address, zipCode: value });
              }}
              style={{ marginVertical: 5, width: '100%' }}
            />
            <TextBox
              autoCapitalize={'none'}
              keyboardType={'default'}
              onSubmitEditing={() => {
                Keyboard.dismiss();
              }}
              color="grey"
              placeholder={T('city')}
              value={address.city}
              onChangeText={value => {
                setAddress({ ...address, city: value });
              }}
              style={{ marginVertical: 5, width: '100%' }}
            />
            <TextBox
              autoCapitalize={'none'}
              keyboardType={'default'}
              onSubmitEditing={() => {
                Keyboard.dismiss();
              }}
              color="grey"
              placeholder={T('province')}
              value={address.province}
              onChangeText={value => {
                setAddress({ ...address, province: value });
              }}
              style={{ marginVertical: 5, width: '100%' }}
            />
            <TextBox
              autoCapitalize={'none'}
              keyboardType={'default'}
              onSubmitEditing={() => {
                Keyboard.dismiss();
              }}
              color="grey"
              placeholder={T('state')}
              value={address.state}
              onChangeText={value => {
                setAddress({ ...address, state: value });
              }}
              style={{ marginVertical: 5, width: '100%' }}
            />
          </Box>
        </KeyboardAwareScrollView>
        {/* <Box
          mt={4}
          mb={2}
          alignItems="center"
          flexDirection={'row'}
          justifyContent={'space-around'}
        >
          <Text fontSize="md" textTransform={'capitalize'}>
            {T('save')} {T('address')}
          </Text>
          <Switch
            value={true}
            onValueChange={val => {
              //setSaveAddress(val);
            }}
            onTrackColor={theme.vars.colors.blue[1]}
            size="sm"
          />
        </Box> */}
        <Box
          width="100%"
          paddingX={4}
          flexDirection="row"
          alignItems={'center'}
          justifyContent={'space-between'}
          pb={4}
        >
          <Button
            color="grey"
            half
            style={{ marginTop: 10 }}
            onPress={() => {
              setAddressSelected(undefined);
            }}
          >
            {T('cancel')}
          </Button>

          <Button
            color="blue"
            half
            style={{ marginTop: 10 }}
            onPress={() => {
              if (!data?.UserMe?.basicInfo) return false;
              let basicInfo = {
                addresses: {},
              };

              basicInfo.addresses[addressSelected] = address;

              delete basicInfo.addresses[addressSelected].__typename;

              userEditInfo({ variables: { basicInfo } }).then(res => {
                Keyboard.dismiss();
                if (props.onSelect) {
                  props.onSelect(basicInfo.addresses[addressSelected]);
                }
                setAddressSelected(undefined);
              });
            }}
            disabled={props.onSelect ? !checkUserAddress(address) : !checkUserContacts(address)}
          >
            {props.onSelect ? T('confirm') : T('save')}
          </Button>
        </Box>
        <Loading visible={editLoading} />
      </Box>
    );
  }

  return (
    <>
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingBottom: 20 }}
        style={[{ flex: 1, width: '100%' }, props.style]}
      >
        <EditBox
          style={{ marginBottom: 16 }}
          onPressEdit={
            props.onSelect
              ? undefined
              : () => {
                  setAddress(data?.UserMe?.basicInfo?.addresses?.home);
                  setAddressSelected('home');
                }
          }
        >
          <Text fontSize="md" bold>
            {T('homeAddress')}
          </Text>
          <>
            <Text fontSize="sm">{data?.UserMe?.basicInfo?.addresses?.home?.name}</Text>
            <Text fontSize="sm">{data?.UserMe?.basicInfo?.addresses?.home?.phone}</Text>
            <Text fontSize="sm">{data?.UserMe?.basicInfo?.addresses?.home?.street}</Text>
            <Text fontSize="sm">
              {data?.UserMe?.basicInfo?.addresses?.home?.zipCode}{' '}
              {data?.UserMe?.basicInfo?.addresses?.home?.city}{' '}
              {data?.UserMe?.basicInfo?.addresses?.home?.province}
            </Text>
            <Text fontSize="sm">{data?.UserMe?.basicInfo?.addresses?.home?.state}</Text>
          </>
          {checkUserAddressVoid(data?.UserMe?.basicInfo?.addresses?.home) && (
            <Text fontSize="sm">{T('void_shipping_address')}</Text>
          )}
          {props.onSelect && (
            <Pressable
              mt={2}
              justifyContent={'center'}
              p={1}
              borderRadius={20}
              style={{ backgroundColor: theme.vars.colors.blue[0] }}
              onPress={() => {
                setAddress(data?.UserMe?.basicInfo?.addresses?.home);
                setAddressSelected('home');
              }}
            >
              <Text
                width={'100%'}
                textAlign={'center'}
                color={theme.vars.colors.light[0]}
                fontSize="sm"
                bold
              >
                {T('select_address')}
              </Text>
            </Pressable>
          )}
        </EditBox>

        <EditBox
          style={{ marginBottom: 16 }}
          onPressEdit={
            props.onSelect
              ? undefined
              : () => {
                  setAddress(data?.UserMe?.basicInfo?.addresses?.work);
                  setAddressSelected('work');
                }
          }
        >
          <Text fontSize="md" bold>
            {T('workAddress')}
          </Text>
          <>
            <Text fontSize="sm">{data?.UserMe?.basicInfo?.addresses?.work?.name}</Text>
            <Text fontSize="sm">{data?.UserMe?.basicInfo?.addresses?.work?.phone}</Text>
            <Text fontSize="sm">{data?.UserMe?.basicInfo?.addresses?.work?.street}</Text>
            <Text fontSize="sm">
              {data?.UserMe?.basicInfo?.addresses?.work?.zipCode}{' '}
              {data?.UserMe?.basicInfo?.addresses?.work?.city}{' '}
              {data?.UserMe?.basicInfo?.addresses?.work?.province}
            </Text>
            <Text fontSize="sm">{data?.UserMe?.basicInfo?.addresses?.work?.state}</Text>
          </>
          {checkUserAddressVoid(data?.UserMe?.basicInfo?.addresses?.work) && (
            <Text fontSize="sm">{T('void_shipping_address')}</Text>
          )}
          {props.onSelect && (
            <Pressable
              mt={2}
              justifyContent={'center'}
              p={1}
              borderRadius={20}
              style={{ backgroundColor: theme.vars.colors.blue[0] }}
              onPress={() => {
                setAddress(data?.UserMe?.basicInfo?.addresses?.work);
                setAddressSelected('work');
              }}
            >
              <Text
                width={'100%'}
                textAlign={'center'}
                color={theme.vars.colors.light[0]}
                fontSize="sm"
                bold
              >
                {T('select_address')}
              </Text>
            </Pressable>
          )}
        </EditBox>

        <EditBox
          style={{ marginBottom: 16 }}
          onPressEdit={
            props.onSelect
              ? undefined
              : () => {
                  setAddress(data?.UserMe?.basicInfo?.addresses?.other);
                  setAddressSelected('other');
                }
          }
        >
          <Text fontSize="md" bold>
            {T('otherAddress')}
          </Text>
          <>
            <Text fontSize="sm">{data?.UserMe?.basicInfo?.addresses?.other?.name}</Text>
            <Text fontSize="sm">{data?.UserMe?.basicInfo?.addresses?.other?.phone}</Text>
            <Text fontSize="sm">{data?.UserMe?.basicInfo?.addresses?.other?.street}</Text>
            <Text fontSize="sm">
              {data?.UserMe?.basicInfo?.addresses?.other?.zipCode}{' '}
              {data?.UserMe?.basicInfo?.addresses?.other?.city}{' '}
              {data?.UserMe?.basicInfo?.addresses?.other?.province}
            </Text>
            <Text fontSize="sm">{data?.UserMe?.basicInfo?.addresses?.other?.state}</Text>
          </>
          {checkUserAddressVoid(data?.UserMe?.basicInfo?.addresses?.other) && (
            <Text fontSize="sm">{T('void_shipping_address')}</Text>
          )}
          {props.onSelect && (
            <Pressable
              mt={2}
              justifyContent={'center'}
              p={1}
              borderRadius={20}
              style={{ backgroundColor: theme.vars.colors.blue[0] }}
              onPress={() => {
                setAddress(data?.UserMe?.basicInfo?.addresses?.other);
                setAddressSelected('other');
              }}
            >
              <Text
                width={'100%'}
                textAlign={'center'}
                color={theme.vars.colors.light[0]}
                fontSize="sm"
                bold
              >
                {T('select_address')}
              </Text>
            </Pressable>
          )}
        </EditBox>

        {/*    {props.onSelect && (
        <Box mt={2} justifyContent={'center'} alignItems={'center'}>
          <Button color="blue" style={{ backgroundColor: theme.vars.colors.blue[0] }}>
            <Text
              width={'100%'}
              textAlign={'center'}
              color={theme.vars.colors.light[0]}
              fontSize="sm"
              bold
            >
              {T('new_address')}
            </Text>
          </Button>
        </Box>
      )} */}

        <Loading visible={loading} />
      </ScrollView>
      {props.onClose && (
        <Box mt={2} mb={4} justifyContent={'center'} alignItems={'center'}>
          <Button
            onPress={() => {
              props.onClose();
            }}
            color="grey"
            style={{ backgroundColor: theme.vars.colors.blue[0] }}
          >
            <Text
              width={'100%'}
              textAlign={'center'}
              color={theme.vars.colors.light[0]}
              fontSize="sm"
              bold
              textTransform={'uppercase'}
            >
              {T('cancel')}
            </Text>
          </Button>
        </Box>
      )}
    </>
  );
};

export { AddressSelector, checkUserAddress, checkUserContacts };
