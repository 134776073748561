import React, { useEffect, useState } from 'react';
import { Text, Box, ScrollView, Pressable, Center } from 'native-base';
import { Button, Icon } from '../../ui';
import { theme } from '../../config';
import { DB_ORDERS, Order } from '../../stores/db/orders';
import { useFocusEffect, useNavigation } from '@react-navigation/core';
import { Image, useWindowDimensions } from 'react-native';
import { Promt } from '../../ui/Promt';
import { IconButton } from '../../ui/IconButton';
import { consoleTransport } from 'react-native-logs';

interface OrderItemProps {
  order: Order;
  onDel: Function;
}

const OrderItem = (props: OrderItemProps) => {
  const navigation = useNavigation<any>();
  const [image, setImage] = useState<any>();
  const dimensions = useWindowDimensions();

  useEffect(() => {
    if (props?.order?.images && props.order.images[0]?.attachment) {
      DB_ORDERS.getAttachment(props.order.images[0].attachment).then(data => {
        setImage(data);
      });
    } else {
      setImage(props.order?.rawProduct?.images?.gallery[0]?.url);
    }
  }, []);

  const editOrder = () => {
    if (props?.order?.rawProduct?.type === 'goods') {
      navigation.navigate('Wizard', {
        screen: 'ProductSummary',
        params: {
          product: props.order.rawProduct,
          title: props.order.rawProduct.name,
          quantity: props.order.quantity || 1,
          orderKey: props.order.key,
        },
      });
    } else {
      navigation.navigate('Wizard', {
        screen: 'PhotoSelect',
        params: {
          product: props.order.rawProduct,
          selectedVariants: props.order.pages,
          orderKey: props.order.key,
        },
      });
    }
  };

  return (
    <Pressable
      width="100%"
      onPress={() => {
        editOrder();
      }}
    >
      <Box
        rounded={20}
        bg={theme.vars.colors.blue[2]}
        alignItems="center"
        justifyContent="flex-start"
        flexDirection="row"
        width="100%"
        //height="60"
        style={{ marginVertical: 5 }}
        p={2}
      >
        <Box flexDirection="row" alignItems="center" justifyContent="center">
          <Image
            style={{
              width: 80,
              height: 80,
              resizeMode: 'cover',
              marginRight: 10,
              borderRadius: 20,
              backgroundColor: 'rgba(0,0,0,0.2)',
            }}
            source={{
              uri: image,
            }}
          />
        </Box>
        <Box flex={1} flexDirection="column" alignItems="flex-start" justifyContent="center" pl={1}>
          <Text
            lineHeight={20}
            numberOfLines={1}
            color={theme.vars.colors.blue[0]}
            fontSize="md"
            bold
          >
            {props?.order?.rawProduct?.name}
          </Text>
          <Text lineHeight={14} color={theme.vars.colors.blue[0]} fontSize="xs">
            {props?.order?.pages?.map(page => {
              return page.name + ': ' + page.choice.name + ', ';
            })}
          </Text>
          <Text mt={3} lineHeight={14} color={theme.vars.colors.blue[0]} fontSize="xs">
            {props?.order?.images?.length || 0} foto
          </Text>
          {props.order.state === 'cart' ? (
            <Text lineHeight={20} color={theme.vars.colors.blue[0]} fontSize="md" bold>
              {props.order?.finalPrice?.toFixed(2)} €
            </Text>
          ) : (
            <Text lineHeight={20} color={theme.vars.colors.blue[0]} fontSize="md" bold>
              {new Date(props.order?.createdAt).toLocaleDateString() || ''}
            </Text>
          )}
        </Box>

        <Box
          flexDirection="row"
          style={{ height: '100%', justifyContent: 'flex-end', alignItems: 'flex-end' }}
        >
          <IconButton
            onPress={() => {
              editOrder();
            }}
            icon="modifica"
            color="blue"
            size="lg"
          />
        </Box>
        {dimensions.width > theme.vars.screen.sm && (
          <Box
            flexDirection="row"
            style={{ height: '100%', justifyContent: 'flex-end', alignItems: 'flex-end' }}
          >
            <IconButton
              onPress={() => {
                props.onDel && props.onDel();
              }}
              icon="cestino"
              color="red"
              size="lg"
            />
          </Box>
        )}
      </Box>
    </Pressable>
  );
};

export { OrderItem };
