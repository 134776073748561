import { useApolloClient } from '@apollo/client';
import React, { useEffect } from 'react';
import { Linking } from 'react-native';
import { LOG } from '../../config';
import { shopCode } from '../../stores/db/appstate';
import { DB_AUTH } from '../../stores/db/auth';
import { DB_JOBS } from '../../stores/db/orders';
import { USER_SHOP_MIN } from '../../stores/queries/shop';
import RNRestart from 'react-native-restart';

const log = LOG.extend('DEEPLINK');

const DeepLink = () => {
  const client = useApolloClient();

  const checkLink = async (url: string) => {
    let linkCode = null;
    if (url) {
      let regex = /[?&]([^=#]+)=([^&#]*)/g,
        params = {},
        match;
      while ((match = regex.exec(url))) {
        params[match[1]] = match[2];
      }
      const { code }: any = params;

      if (code) {
        linkCode = code;
      }
    }

    if (linkCode) {
      let res;
      try {
        res = await client
          .query({
            query: USER_SHOP_MIN,
            fetchPolicy: 'no-cache',
            variables: {
              code: linkCode,
            },
          })
          .then(res => res);
      } catch (error) {}
      if (!res?.data?.UserShop?._id) {
        log.error('Code param shop not exist');
        linkCode = null;
      } else {
        log.info('Shop found: ' + res.data.UserShop.code);
        if (shopCode() !== linkCode) {
          log.info('Change shop: ', shopCode(), ' TO ', linkCode);
          await DB_AUTH.set({ shopCode: linkCode });
          await DB_JOBS.removeCurrentJob();
          RNRestart.Restart();
        }
      }
    }
  };

  useEffect(() => {
    const subscription = Linking.addEventListener('url', event => {
      log.info('Deep Link Rilevated:', event?.url);
      if (event?.url) {
        checkLink(event?.url);
      }
    });

    return () => {
      subscription.remove();
    };
  }, []);

  return null;
};

export { DeepLink };
