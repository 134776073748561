import * as Localization from 'expo-localization';
import { I18n } from 'i18n-js';

import { IT } from './languages/it';
import { EN } from './languages/en';

const translations = {
  it: IT,
  en: EN,
};
const i18n = new I18n(translations);
const LOCALE = Localization.locale;

i18n.defaultLocale = 'en';
i18n.locale = Localization.locale;
i18n.enableFallback = true;

const T = (txt: string) => i18n.t(txt);

export { T, LOCALE };
