import React, { useEffect, useState } from 'react';
import { InteractionManager, useWindowDimensions } from 'react-native';
import { Text, Box, Center, Image, Pressable, HStack, VStack } from 'native-base';
import { SwipeListView } from 'react-native-swipe-list-view';
import { useFocusEffect } from '@react-navigation/core';
import { useQuery } from '@apollo/client';

import { OrderItem } from '../../components/Order/OrderItem';
import { theme } from '../../config';
import { DB_ORDERS, Order } from '../../stores/db/orders';
import { filledCart, filledProjects } from '../../stores/db/appstate';
import { Button, Icon } from '../../ui';
import { Promt } from '../../ui/Promt';
import { APP_STATE, APP_STATE_PROPS } from '../../stores/queries/appstate';
import { Loading } from '../../ui/Loading';
import { roundPrice } from '../../libs/utils';
import { T } from '../../config/localization';

interface CartProps {
  navigation: any;
}

const Cart = (props: CartProps) => {
  const { data: APP } = useQuery<APP_STATE_PROPS>(APP_STATE);
  const [loading, setLoading] = useState<boolean>(true);

  const dimensions = useWindowDimensions();

  const [orders, setOrders] = useState<Order[]>([]);
  const [selectedOrder, setSelectedOrder] = useState<{
    order: Order;
    index: number;
  }>();
  const [ordersTotalPrice, setOrdersTotalPrice] = useState(0);
  const [delPromtVisible, setDelPromtVisible] = useState(false);

  useFocusEffect(
    React.useCallback(() => {
      const task = InteractionManager.runAfterInteractions(() => {
        DB_ORDERS.getOrdersByState('cart').then(orders => {
          setOrders(orders);
          setTimeout(() => {
            setLoading(false);
          }, 10);
        });
      });

      return () => task.cancel();
    }, [])
  );

  useEffect(() => {
    if (orders.length < 1) {
      filledCart(false);
      setOrdersTotalPrice(0);
    } else {
      let totalPrice = 0;
      for (let i = 0; i < orders.length; i++) {
        let order = orders[i];
        totalPrice = totalPrice + parseFloat(order.finalPrice as any);
      }
      setOrdersTotalPrice(totalPrice);
    }
  }, [orders]);

  const deleteOrder = async (order: Order, index: number) => {
    let newOrders = [...orders];
    newOrders.splice(index, 1);
    setOrders(newOrders);
    DB_ORDERS.removeOrder(order.key);
  };

  const renderItem = ({ item, index }) => (
    <OrderItem
      key={index}
      order={item}
      onDel={() => {
        setSelectedOrder({
          order: item,
          index: index,
        });
        setDelPromtVisible(true);
      }}
    />
  );

  const renderHiddenItem = (data, rowMap) => (
    <HStack flex="1" width={'100%'} style={{ padding: 6, paddingRight: 0, overflow: 'hidden' }}>
      <Pressable
        w="300"
        style={{
          flex: 1,
          alignItems: 'flex-end',
          paddingTop: 5,
          paddingRight: 10,
          borderTopLeftRadius: 20,
          borderBottomLeftRadius: 20,
        }}
        ml="auto"
        // @ts-ignore
        cursor="pointer"
        bg={theme.vars.colors.blue[1]}
        justifyContent="center"
        onPress={() => {
          rowMap[data.item.key].closeRow();

          let newOrder = {
            ...data.item,
            state: 'project',
          };

          setLoading(true);

          DB_ORDERS.updateOrder(newOrder).then(updatedOrder => {
            DB_ORDERS.getOrdersByState('cart').then(orders => {
              setOrders(orders);
              setLoading(false);
              filledProjects(true);
            });
          });

          return null;
        }}
        _pressed={{
          opacity: 0.5,
        }}
      >
        <VStack alignItems="center" space={2}>
          <Icon name="stella" size={18} />
          <Text
            color={theme.vars.colors.light[0]}
            textAlign={'center'}
            fontSize="xs"
            style={{ height: 30, lineHeight: 15 }}
            bold
          >
            {T('move_in_projects')}
          </Text>
        </VStack>
      </Pressable>
      <Pressable
        w="65"
        // @ts-ignore
        cursor="pointer"
        bg={theme.vars.colors.red[0]}
        justifyContent="center"
        onPress={() => {
          rowMap[data.item.key].closeRow();
          setSelectedOrder({
            order: data.item,
            index: data.index,
          });
          setDelPromtVisible(true);
        }}
        _pressed={{
          opacity: 0.5,
        }}
        style={{ paddingTop: 5, borderTopRightRadius: 20, borderBottomRightRadius: 20 }}
      >
        <VStack alignItems="center" space={2}>
          <Icon name="cestino" size={18} />
          <Text
            color={theme.vars.colors.light[0]}
            textAlign={'center'}
            fontSize="xs"
            style={{ height: 30, lineHeight: 30 }}
            bold
          >
            {T('delete')}
          </Text>
        </VStack>
      </Pressable>
    </HStack>
  );

  return (
    <>
      <Box bg={theme.vars.colors.light[0]} alignItems={'center'} flex={1} safeAreaTop>
        <Box style={{ maxWidth: 1024, width: '100%' }} flex={1}>
          {orders.length > 0 ? (
            <>
              <Box
                pt={3}
                style={{
                  width: '100%',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Text
                  style={{
                    paddingTop: 10,
                    paddingBottom: 3,
                    marginBottom: 5,
                    fontSize: 24,
                    width: '100%',
                    textAlign: 'center',
                    color: theme.vars.colors.dark[0],
                    textTransform: 'capitalize',
                  }}
                  bold
                >
                  {T('cart')}
                </Text>
                {orders.length > 0 && (
                  <Box
                    style={{
                      position: 'absolute',
                      right: 8,
                      paddingTop: 5,
                      //backgroundColor: '#666',
                      //height: '100%',
                    }}
                    flexDirection="row"
                    alignItems="flex-end"
                    justifyContent="center"
                  >
                    <Icon name="costo-spedizione" color={'blue'} size={22} />
                    <Box
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      mr={1}
                      ml={1}
                    >
                      <Text lineHeight={18} color={theme.vars.colors.blue[0]} fontSize="lg" bold>
                        {ordersTotalPrice.toFixed(2)} €
                      </Text>
                    </Box>
                  </Box>
                )}
              </Box>
              <SwipeListView
                style={{ flex: 1, width: '100%' }}
                contentContainerStyle={{
                  paddingTop: 0,
                  paddingBottom: 40,
                  paddingHorizontal: 20,
                }}
                data={orders}
                renderItem={renderItem}
                renderHiddenItem={renderHiddenItem}
                rightOpenValue={-130}
                previewRowKey={'0'}
                previewOpenValue={-40}
                previewOpenDelay={3000}
                //onRowDidOpen={onRowDidOpen}
              />
              <Center style={{ width: '100%' }} pb={3}>
                {ordersTotalPrice < 0.5 && <Text>{T('minium_order_price')}</Text>}

                <Box
                  style={{ width: '100%', maxWidth: 800 }}
                  justifyContent={
                    dimensions.width > theme.vars.screen.sm ? 'space-between' : 'center'
                  }
                  flexDirection={'row'}
                >
                  {dimensions.width > theme.vars.screen.sm && (
                    <Button
                      color="violet"
                      shadow="md"
                      onPress={() => {
                        props.navigation.navigate('Home', {
                          screen: 'Shop',
                          initial: false,
                        });
                      }}
                    >
                      {T('continue_shop')}
                    </Button>
                  )}
                  <Button
                    disabled={ordersTotalPrice < 0.5 ? true : false}
                    color="blue"
                    shadow="md"
                    onPress={() => {
                      let ordersList = [];
                      for (let i = 0; i < orders.length; i++) {
                        ordersList.push(orders[i].key);
                      }

                      if (APP?.userLogged) {
                        props.navigation.navigate('Job', {
                          screen: 'JobCreate',
                          params: {
                            orders: ordersList,
                          },
                        });
                      } else {
                        props.navigation.navigate('Job', {
                          screen: 'JobUser',
                          params: {
                            orders: ordersList,
                          },
                        });
                      }
                    }}
                  >
                    {T('send_order')}
                  </Button>
                </Box>
              </Center>
            </>
          ) : (
            <>
              <Box
                style={{ width: '100%', flex: 1 }}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Text
                  width={200}
                  mb={2}
                  color={theme.vars.colors.dark[0]}
                  fontSize={'3xl'}
                  textAlign={'center'}
                  lineHeight={28}
                  bold
                >
                  {T('void_cart')}
                </Text>
                <Text
                  width={200}
                  mb={2}
                  color={theme.vars.colors.dark[0]}
                  textAlign={'center'}
                  lineHeight={20}
                  fontSize={'lg'}
                >
                  {T('void_cart_hint')}
                </Text>
                <Image
                  style={{
                    width: '70%',
                    maxWidth: 280,
                    height: '30%',
                    resizeMode: 'contain',
                  }}
                  source={require('../../assets/img/illustrations/carrello-vuoto.png')}
                  alt="logo"
                />
                <Center style={{ width: '100%' }} mt={2}>
                  <Button
                    color="violet"
                    shadow="md"
                    onPress={() => {
                      props.navigation.navigate('Shop');
                    }}
                  >
                    {T('begin_now')}
                  </Button>
                </Center>
              </Box>
            </>
          )}

          <Promt
            visible={delPromtVisible}
            text={T('delete_order_promt')}
            onYes={() => {
              setDelPromtVisible(false);
              if (selectedOrder?.order) {
                deleteOrder(selectedOrder.order, selectedOrder.index);
              }
            }}
            onNo={() => {
              setDelPromtVisible(false);
            }}
            onCancel={() => {
              setDelPromtVisible(false);
            }}
          />
        </Box>
      </Box>
      <Loading bg={theme.vars.colors.light[0]} visible={loading} />
    </>
  );
};

export { Cart };
