import React, { useEffect, useRef, useState } from 'react';
import { Box, Image, Text, ScrollView, Pressable } from 'native-base';
import { EditBox } from '../../ui/EditBox';
import { LOG, theme } from '../../config';
import { Button } from '../../ui/Button';
import { Icon, TextBox } from '../../ui';
import { Keyboard, Platform, StyleSheet } from 'react-native';
import { useMutation, useQuery } from '@apollo/client';
import { UserAddress, USER_EDIT_INFO, USER_ME } from '../../stores/queries/user';
import { BlurModal } from '../../ui/BlurModal';
import { Job, JobPaymentInput, JOB_CANCEL } from '../../stores/queries/jobs';
import { LinearGradient } from 'expo-linear-gradient';
import { Promt } from '../../ui/Promt';
import { DB_JOBS } from '../../stores/db/orders';
import { activeOrder } from '../../stores/db/appstate';
import { T } from '../../config/localization';
import {
  AddressSelector,
  checkUserAddress,
  checkUserContacts,
} from '../../components/Profile/AddressSelector';
import { Shop } from '../../stores/queries/shop';
// import { APP_STATE, APP_STATE_PROPS } from '../../stores/queries/appstate';

const log = LOG.extend('JOB_SHIPMENT');

const checkEnabledMethods = (shop: Shop) => {
  let enabledMethods = {
    atHome: {
      stripe: false,
      onSite: false,
    },
    onSite: {
      stripe: false,
      onSite: false,
    },
  };

  let onSiteEnabled = false;
  let atHomeEnabled = false;

  if (
    shop.paymentMethods.stripe.isEnabled &&
    shop.paymentMethods.stripe.stripeInfo?.accountId &&
    shop.deliveryInfo.atHome.allowedPaymentTypes.stripe
  ) {
    enabledMethods.atHome.stripe = true;
    atHomeEnabled = true;
  }
  if (shop.paymentMethods.onSite.isEnabled && shop.deliveryInfo.atHome.allowedPaymentTypes.onSite) {
    enabledMethods.atHome.onSite = true;
    atHomeEnabled = true;
  }

  if (
    shop.paymentMethods.stripe.isEnabled &&
    shop.paymentMethods.stripe.stripeInfo?.accountId &&
    shop.deliveryInfo.atShop.allowedPaymentTypes.stripe
  ) {
    enabledMethods.onSite.stripe = true;
    onSiteEnabled = true;
  }
  if (shop.paymentMethods.onSite.isEnabled && shop.deliveryInfo.atShop.allowedPaymentTypes.onSite) {
    enabledMethods.onSite.onSite = true;
    onSiteEnabled = true;
  }
  return { enabledMethods, onSiteEnabled, atHomeEnabled };
};
interface JobShipmentProps {
  navigation: any;
  route: {
    params: {
      job: Job;
      name: string;
      phone: string;
    };
  };
}

const JobShipment = (props: JobShipmentProps) => {
  const [homeShip, setHomeShip] = useState(false);
  const [shipPrice, setShipPrice] = useState(0);
  const [error, setError] = useState();

  const [delPromtVisible, setDelPromtVisible] = useState(false);

  const [shipAddressModalVisible, setShipAddressModalVisible] = useState(false);

  //const { data: APP } = useQuery<APP_STATE_PROPS>(APP_STATE);

  const scrollRef = useRef<any>();

  const [jobCancel, { loading: jcLoading, error: jcError, data: jcData }] = useMutation(
    JOB_CANCEL,
    {
      onCompleted: res => {
        log.debug('Job cancelled:', res);
      },
      onError: err => {
        log.error('Error on cancel job:', err);
      },
    }
  );

  const [userEditInfo, { loading: editLoading, error: editError, data: editData }] = useMutation(
    USER_EDIT_INFO,
    {
      refetchQueries: [{ query: USER_ME }],
      awaitRefetchQueries: true,
    }
  );

  const [address, setAddress] = useState<UserAddress>({
    name: props.route.params.name || '',
    street: '',
    zipCode: '',
    city: '',
    province: '',
    state: '',
    phone: props.route.params.phone || '',
  });
  const [selectedPick, setSelectedPick] = useState(0);

  const { enabledMethods, atHomeEnabled, onSiteEnabled } = checkEnabledMethods(
    props.route.params.job.shop
  );

  /*   useEffect(() => {
    if (userData?.UserMe?.basicInfo?.addresses?.home.) {
      setContacts({ ...contacts, name: userData.UserMe.basicInfo.addresses.home.name });
    }
    if (userData?.UserMe?.email) {
      setContacts({ ...contacts, email: userData.UserMe.email });
    }
  }, [userData]); */

  useEffect(() => {
    if (!homeShip) {
      setShipPrice(props?.route?.params?.job?.shop?.deliveryInfo?.atShop?.cost || 0);
    }
  }, [props?.route?.params?.job?.shop?.deliveryInfo?.atShop?.cost, homeShip]);

  const [errors, setErrors] = useState({
    name: false,
    phone: false,
    address: false,
  });

  let completeAddress = checkUserAddress(address);

  const checkErrors = () => {
    if (homeShip && !completeAddress) {
      setErrors({
        name: false,
        phone: false,
        address: true,
      });
      scrollRef.current.scrollToEnd({ animated: true });
      return T('err_no_address');
    }
    if (!address.name.length) {
      setErrors({
        name: true,
        phone: false,
        address: false,
      });
      scrollRef.current.scrollTo({ y: 30 });
      return T('err_no_name');
    }
    if (!address.phone.length) {
      setErrors({
        name: false,
        phone: true,
        address: false,
      });
      scrollRef.current.scrollTo({ y: 30 });
      return T('err_no_phone');
    }
  };

  if (!atHomeEnabled && !onSiteEnabled) {
    return (
      <Box
        pt={2}
        bg={theme.vars.colors.light[0]}
        paddingX={4}
        flex={1}
        alignItems="center"
        justifyContent="center"
      >
        <Image
          mb={2}
          alt={'ritoro_negozio'}
          style={{ resizeMode: 'contain', width: 180, height: 180 }}
          source={require('../../assets/img/illustrations/avviso.png')}
        ></Image>
        <Text mb={0} fontSize={'xl'} bold textAlign={'center'}>
          {T('order_no_methods')}
        </Text>
        {/* <Text color={theme.vars.colors.dark[0]} fontSize={16} underline bold>
          {JSON.stringify(props.route.params.job.shop.deliveryInfo)}
        </Text> */}
        <Pressable
          style={{
            width: '100%',
            top: 40,
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onPress={() => {
            activeOrder(null);
            DB_JOBS.removeCurrentJob();
            props.navigation.navigate('Home', {
              screen: 'Cart',
              initial: false,
            });
          }}
        >
          <Text color={theme.vars.colors.dark[0]} fontSize={16} underline bold>
            {T('cancel_order')}
          </Text>
        </Pressable>
      </Box>
    );
  }

  return (
    <Box
      pt={2}
      bg={theme.vars.colors.light[0]}
      flex={1}
      alignItems="center"
      justifyContent="flex-start"
    >
      <Box
        safeAreaTop
        alignItems="center"
        justifyContent="flex-start"
        flex={1}
        width={'100%'}
        style={{ maxWidth: 600 }}
      >
        <Text
          style={{
            paddingTop: 5,
            paddingBottom: 3,
            marginBottom: 5,
            fontSize: 24,
            width: '100%',
            textAlign: 'center',
            color: theme.vars.colors.dark[0],
          }}
          bold
        >
          {T('shipping')}
        </Text>
        <ScrollView
          ref={scrollRef}
          showsVerticalScrollIndicator={false}
          style={{ width: '100%', flex: 1 }}
          contentContainerStyle={{ alignItems: 'center', paddingBottom: 30 }}
        >
          <Text pt={4} fontSize="lg" bold>
            {T('shipping_method')}
          </Text>

          {/* {props?.route?.params?.job?.shop?.deliveryInfo?.atShop?.} */}

          <Box
            width="100%"
            pt={4}
            paddingX={4}
            flexDirection={'row'}
            alignItems="center"
            justifyContent="space-around"
          >
            {onSiteEnabled && (
              <Pressable
                style={[
                  {
                    width: '46%',
                    padding: 10,
                    borderRadius: 20,
                  },
                  homeShip ? styles.boxInactive : styles.boxAtive,
                ]}
                onPress={() => {
                  setShipPrice(props?.route?.params?.job?.shop?.deliveryInfo?.atShop?.cost || 0);
                  setHomeShip(false);
                }}
                alignItems="center"
                justifyContent="center"
              >
                <Image
                  alt={'ritoro_negozio'}
                  style={{ resizeMode: 'contain', width: 100, height: 100 }}
                  source={require('../../assets/img/illustrations/upload-foto.png')}
                ></Image>
                <Text color={theme.vars.colors.blue[0]} fontSize="md">
                  {T('shop_delivery')}
                </Text>
                <Text color={theme.vars.colors.blue[0]} fontSize="md" bold>
                  {props?.route?.params?.job?.shop?.deliveryInfo?.atShop?.cost
                    ? props.route.params.job.shop.deliveryInfo.atShop.cost.toFixed(2) + ' €'
                    : T('free_shipping')}
                </Text>
                <Text color={theme.vars.colors.blue[0]} fontSize="xs">
                  {props?.route?.params?.job?.shop?.deliveryInfo?.atShop?.time
                    ? T('shipping_in') +
                      ' ' +
                      props.route.params.job.shop.deliveryInfo.atShop.time +
                      ' gg'
                    : T('ready_shipping')}
                </Text>
              </Pressable>
            )}

            {atHomeEnabled && (
              <Pressable
                style={[
                  {
                    width: '46%',
                    padding: 10,
                    borderRadius: 20,
                  },
                  homeShip ? styles.boxAtive : styles.boxInactive,
                ]}
                onPress={() => {
                  setShipPrice(
                    props.route.params.job?.shop?.deliveryInfo?.atHome?.costs[0]?.cost || 0
                  );
                  setHomeShip(true);
                  setTimeout(() => {
                    scrollRef && scrollRef.current.scrollToEnd({ animated: true });
                  }, 100);
                }}
                alignItems="center"
                justifyContent="center"
              >
                <Image
                  alt={'consegna_casa'}
                  style={{ resizeMode: 'contain', width: 100, height: 100 }}
                  source={require('../../assets/img/illustrations/consegne.png')}
                ></Image>
                <Text color={theme.vars.colors.blue[0]} fontSize="md">
                  {T('home_shipping')}
                </Text>
                <Text color={theme.vars.colors.blue[0]} fontSize="md" bold>
                  {props.route.params.job?.shop?.deliveryInfo?.atHome?.costs[0]?.cost
                    ? props.route.params.job.shop.deliveryInfo.atHome.costs[0].cost.toFixed(2) +
                      ' €'
                    : T('free_shipping')}
                </Text>
                <Text color={theme.vars.colors.blue[0]} fontSize="xs">
                  {props.route.params.job?.shop?.deliveryInfo?.atHome?.timings[0]?.time
                    ? T('shipping_in') +
                      ' ' +
                      props.route.params.job.shop.deliveryInfo.atHome.timings[0]?.time +
                      ' gg'
                    : T('ready_shipping')}
                </Text>
              </Pressable>
            )}
          </Box>

          {homeShip ? (
            <>
              <Text pt={6} fontSize="lg" bold>
                {T('shipping_address')}
              </Text>

              <EditBox
                onPressEdit={() => {
                  setShipAddressModalVisible(true);
                }}
                style={{
                  marginTop: 20,
                  width: '90%',
                  borderWidth: errors.address ? 2 : 0,
                  borderColor: theme.vars.colors.red[2],
                }}
                error={errors.address}
              >
                {completeAddress ? (
                  <Box flex={1} flexDirection={'column'}>
                    <Text fontSize="sm" bold>
                      {address.name}
                    </Text>
                    <Text fontSize="sm" bold>
                      {address.phone}
                    </Text>
                    <Text fontSize="sm">{address.street}</Text>
                    <Text fontSize="sm">
                      {address.zipCode} {address.city} {address.province}
                    </Text>
                    <Text fontSize="sm">{address.state}</Text>
                  </Box>
                ) : (
                  <Text fontSize="md" bold>
                    {T('void_shipping_address')}
                  </Text>
                )}
              </EditBox>

              {/* <EditBox style={{ marginBottom: 16, width: '90%' }}>
                <Text color={theme.vars.colors.blue[0]} fontSize="lg" bold>
                  Indirizzo di Fatturazione
                </Text>
                <Text fontSize="sm">Usa lo stesso indirizzo</Text>
              </EditBox> */}
            </>
          ) : (
            <>
              <Text pt={2} pb={2} mt={4} fontSize="lg" bold>
                {T('contacts')}
              </Text>
              <Box
                px={6}
                width={'100%'}
                style={{
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  maxWidth: 500,
                }}
              >
                <TextBox
                  color="blue"
                  autoCapitalize={'none'}
                  keyboardType={'default'}
                  onSubmitEditing={() => {
                    //onSubmit();
                    Keyboard.dismiss();
                  }}
                  placeholder="Nome*"
                  value={address.name}
                  onChangeText={value => {
                    setAddress({ ...address, name: value });
                  }}
                  style={{ marginVertical: 5 }}
                  error={errors.name}
                />
                <TextBox
                  color="blue"
                  autoCapitalize={'none'}
                  keyboardType={'phone-pad'}
                  onSubmitEditing={() => {
                    //onSubmit();
                    Keyboard.dismiss();
                  }}
                  placeholder="Numero di Telefono*"
                  value={address.phone}
                  onChangeText={value => {
                    setAddress({ ...address, phone: value });
                  }}
                  style={{ marginVertical: 5 }}
                  error={errors.phone}
                />
                <Text color={theme.vars.colors.blue[0]} pl={1} pt={1} fontSize="sm">
                  {T('mandatory_input')}
                </Text>
              </Box>
              {props?.route?.params?.job?.shop?.addresses?.commercial?.length ? (
                <Text pt={6} fontSize="lg" bold>
                  {T('shipping_place')}
                </Text>
              ) : null}
              {props?.route?.params?.job?.shop?.addresses?.commercial?.map((address, index) => {
                return (
                  <Pressable
                    onPress={() => {
                      setSelectedPick(index);
                      /*    setAddress({
                        name: address.denomination || '',
                        street: address.street || '',
                        city: address.city || '',
                        province: '',
                        state: address.state || '',
                        zipCode: address.zipCode || '',
                        phone: address.phoneNumber || '',
                      }); */
                    }}
                    key={'stie_' + index}
                    style={[
                      {
                        width: '90%',
                        marginVertical: 10,
                        padding: 10,
                        paddingHorizontal: 15,
                        backgroundColor: theme.vars.colors.blue[2],
                        borderRadius: 20,
                      },
                      index === selectedPick ? styles.boxAtive : styles.boxInactive,
                    ]}
                  >
                    <Text color={theme.vars.colors.blue[0]} fontSize="md" mt={2} bold>
                      {address.denomination}
                    </Text>
                    <Text color={theme.vars.colors.blue[0]} fontSize="sm" mt={2} bold>
                      {address.city}
                    </Text>
                    <Text color={theme.vars.colors.blue[0]} fontSize="sm">
                      {address.street}
                    </Text>
                    <Text color={theme.vars.colors.blue[0]} fontSize="sm">
                      {address.zipCode}, {address.state}, {address.state}
                    </Text>
                    <Text color={theme.vars.colors.blue[0]} fontSize="sm">
                      {address.phoneNumber}
                    </Text>
                    {index === selectedPick && (
                      <Box style={{ position: 'absolute', bottom: 15, right: 15 }}>
                        <Icon name={'tick'} color="blue"></Icon>
                      </Box>
                    )}
                  </Pressable>
                );
              })}
            </>
          )}
        </ScrollView>
        <LinearGradient
          colors={['rgba(255,255,255,0)', theme.vars.colors.light[0]]}
          style={{
            height: 40,
            width: '100%',
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 0,
          }}
          pointerEvents={'none'}
        />
      </Box>

      <Box width={'100%'} justifyContent={'center'} alignItems={'center'} pb={3} safeAreaBottom>
        {/* {error ? (
          <Box
            style={{
              backgroundColor: theme.vars.colors.red[0],
              paddingHorizontal: 15,
              borderRadius: 999,
              marginTop: 10,
              marginBottom: 10,
              width: '100%',
              maxWidth: 300,
              padding: 5,
            }}
          >
            <Text
              style={{ textAlign: 'center', width: '100%' }}
              color={theme.vars.colors.light[0]}
              bold
            >
              {error}
            </Text>
          </Box>
        ) : null} */}
        <Button
          //disabled={!checkContacts() || !checkAddress()}
          color="violet"
          shadow="md"
          style={{ maxWidth: 200, marginTop: 8, marginBottom: 8 }}
          onPress={() => {
            if (checkErrors()) {
              return;
            }

            let businessAddress = !homeShip
              ? props?.route?.params?.job?.shop?.addresses?.commercial[selectedPick]
              : null;

            if (businessAddress) {
              delete businessAddress['__typename'];
            }

            let delivery: JobPaymentInput['delivery'] = {
              type: homeShip ? 'atHome' : 'atShop',
              address: address,
              business: !homeShip
                ? props?.route?.params?.job?.shop?.addresses?.commercial[selectedPick]
                : null,
              cost: shipPrice,
              country: address.state,
              timing: 0,
            };

            let finalPrice = 0;
            for (let i = 0; i < props.route.params.job.orders.length; i++) {
              let order = props.route.params.job.orders[i];
              let price = 0;

              if (order.finalPrice) {
                price = order.finalPrice;
              }
              finalPrice = finalPrice + price;
            }

            let job = {
              ...props.route.params.job,
              finalPrice,
            };

            let enabledPayments = {
              stripe: false,
              onSite: false,
            };

            if (homeShip) {
              enabledPayments = enabledMethods.atHome;
            } else {
              enabledPayments = enabledMethods.onSite;
            }

            if (!props.route.params.name || !props.route.params.phone) {
              let basicInfo = {
                addresses: {
                  home: {
                    phone: address.phone,
                    name: address.name,
                  },
                },
              };
              userEditInfo({ variables: { basicInfo } });
            }

            props.navigation.navigate('JobPayment', {
              job,
              shipping: {
                price: shipPrice,
                delivery,
              },
              enabledPayments,
            });
          }}
        >
          {T('go_to_payment')}
        </Button>
        <Pressable
          style={{
            width: '100%',
            top: 15,
            bottom: 0,
            marginBottom: 20,
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onPress={() => {
            setDelPromtVisible(true);
          }}
        >
          <Text color={theme.vars.colors.dark[0]} fontSize={12} underline bold>
            {T('cancel_order')}
          </Text>
        </Pressable>
      </Box>
      <Promt
        visible={delPromtVisible}
        text={T('cancel_order_promt')}
        onYes={() => {
          setDelPromtVisible(false);
          activeOrder(null);
          DB_JOBS.removeCurrentJob();
          jobCancel({
            variables: { jobId: props.route.params.job._id },
          });
          setTimeout(() => {
            props.navigation.navigate('Home', {
              screen: 'Cart',
              initial: false,
            });
          }, 100);
        }}
        onNo={() => {
          setDelPromtVisible(false);
        }}
        onCancel={() => {
          setDelPromtVisible(false);
        }}
      />
      <BlurModal
        height={Platform.OS === 'web' ? window.screen.height * 0.6 : undefined}
        isOpen={shipAddressModalVisible}
      >
        <AddressSelector
          style={{ paddingTop: 0 }}
          onSelect={address => {
            setAddress(address);
            setShipAddressModalVisible(false);
          }}
          onClose={() => {
            setShipAddressModalVisible(false);
          }}
        />
      </BlurModal>
    </Box>
  );
};

const styles = StyleSheet.create({
  boxInactive: {
    backgroundColor: theme.vars.colors.light[2],
    opacity: 0.8,
    borderColor: theme.vars.colors.blue[2],
    borderWidth: 1,
  },
  boxAtive: {
    backgroundColor: theme.vars.colors.blue[2],
    ...theme.vars.shadow['lg'],
    shadowColor: theme.vars.colors.blue[0],
    shadowRadius: 3,
    shadowOpacity: 0.4,
  },
});

export { JobShipment };
