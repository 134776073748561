import React, { useCallback, useEffect, useRef, useState } from 'react';
import { BackHandler, Dimensions, Pressable } from 'react-native';
import { Box, Center, Image, Text } from 'native-base';
import { ProgressBar } from '../../ui/ProgressBar';
import { theme, LOG } from '../../config';
import { useMutation, useQuery } from '@apollo/client';
import { JOB, Job, JOB_CANCEL } from '../../stores/queries/jobs';
import { DB_JOBS, DB_ORDERS } from '../../stores/db/orders';
import { Loading } from '../../ui/Loading';
import { uploadHistory, uploadImage } from '../../libs/upload';
import { useFocusEffect } from '@react-navigation/native';
import { useKeepAwake } from 'expo-keep-awake';
import { Promt } from '../../ui/Promt';
import { activeOrder } from '../../stores/db/appstate';
import { T } from '../../config/localization';
import { User, USER_ME } from '../../stores/queries/user';
import { DPI } from '../../libs/utils';

const log = LOG.extend('JOB');
interface JobUploadProps {
  navigation: any;
  route: {
    params: {
      jobId: string;
    };
  };
}

const JobUpload = (props: JobUploadProps) => {
  useKeepAwake();

  const [curFile, setCurFile] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [delPromtVisible, setDelPromtVisible] = useState(false);

  const {
    loading: userLoading,
    error: userError,
    data: userData,
  } = useQuery<{ UserMe: User }>(USER_ME, {
    fetchPolicy: 'cache-and-network',
  });

  const { loading, error, data } = useQuery<{ GuestJobById: Job }>(JOB, {
    fetchPolicy: 'network-only',
    variables: {
      jobId: props?.route?.params?.jobId,
    },
  });

  const [jobCancel, { loading: jcLoading, error: jcError, data: jcData }] = useMutation(
    JOB_CANCEL,
    {
      onCompleted: res => {
        log.debug('Job cancelled:', res);
      },
      onError: err => {
        log.error('Error on cancel job:', err);
      },
    }
  );

  useFocusEffect(
    useCallback(() => {
      const onBackPress = () => {
        return true;
      };

      BackHandler.addEventListener('hardwareBackPress', onBackPress);

      return () => BackHandler.removeEventListener('hardwareBackPress', onBackPress);
    }, [])
  );

  if (!props?.route?.params?.jobId) {
    props.navigation.replace('JobError', {
      error: 'Errore sconosciuto',
      jobId: props.route.params.jobId,
    });
  }

  const uploadJob = async (jobId: string, shopId: string) => {
    let ordersImageCount = [];
    let imageCount = 0;
    let imageIndex = 0;

    for (let i = 0; i < data.GuestJobById.orders.length; i++) {
      imageCount += data.GuestJobById.orders[i].userEditing.images.length;
      ordersImageCount.push(data.GuestJobById.orders[i].userEditing.images.length);
    }

    setUploadProgress(0);
    let totalProgress = 0;

    uploadBlock: {
      for (let i = 0; i < data.GuestJobById.orders.length; i++) {
        if (data.GuestJobById.orders[i].status === 'complete') {
          log.info('Order ' + data.GuestJobById.orders[i].orderKey + ' alredy uploaded');
          totalProgress =
            totalProgress +
            (100 * data.GuestJobById.orders[i].userEditing.images.length) / imageCount;
          imageIndex = imageIndex + data.GuestJobById.orders[i].userEditing.images.length;
          continue;
        }

        const orderKey = data.GuestJobById.orders[i].orderKey;
        /*let order = await DB_ORDERS.getOrder(orderKey).then(); */

        for (let j = 0; j < data.GuestJobById.orders[i].userEditing.images.length; j++) {
          if (uploadCancelled.current) {
            return null;
          }

          imageIndex++;

          if (data.GuestJobById.orders[i].userEditing.images[j].status === 'uploaded') {
            log.info(
              'Image ' +
                data.GuestJobById.orders[i].userEditing.images[j].attachmentKey +
                ' alredy uploaded'
            );
            totalProgress = totalProgress + 100 / imageCount;
            continue;
          }

          const image = data.GuestJobById.orders[i].userEditing.images[j];

          let imageFile = await DB_ORDERS.getAttachment(image.attachmentKey);
          let fileCount = 1;

          if (image.historyKey) {
            log.debug('History ' + image.historyKey + ' upload start');
            let historyFile = await DB_ORDERS.getAttachment(image.historyKey);
            fileCount = 2;
            let historyErr = null;

            let uploadHistoryRes = await uploadHistory({
              shopId: shopId,
              jobId: jobId,
              orderKey: orderKey,
              attachmentKey: image.attachmentKey,
              wizardId: image.wizardId,
              history: historyFile,
              onProgress: (written: number, total: number) => {
                let progress = totalProgress + ((written / total) * 100) / imageCount / fileCount;
                setUploadProgress(progress);
              },
              onError: (err: any) => {
                historyErr = err;
              },
            });

            if (!uploadHistoryRes || !uploadHistoryRes?.success) {
              log.error('History ' + image.attachmentKey + ' upload error:', historyErr);
              props.navigation.replace('JobError', {
                error: historyErr?.message || 'Errore di rete',
                jobId: jobId,
              });
              return null;
            }

            log.debug('History ' + image.historyKey + ' upload success');

            totalProgress = totalProgress + 100 / imageCount / fileCount;
          }

          setCurFile(T('picture') + ' ' + imageIndex + ' ' + T('of') + ' ' + imageCount);

          log.debug('Image ' + image.attachmentKey + ' upload start');

          let imageErr = null;

          let uploadImageRes = await uploadImage({
            shopId: shopId,
            jobId: jobId,
            orderKey: orderKey,
            attachmentKey: image.attachmentKey,
            imageBase64: imageFile,
            wizardId: image.wizardId,
            edit: image.historyKey ? true : false,
            dpi: image.dpi || DPI,
            onProgress: (written: number, total: number) => {
              let progress = totalProgress + ((written / total) * 100) / imageCount / fileCount;
              setUploadProgress(progress);
            },
            onError: (err: any) => {
              if (typeof err === 'string') {
                imageErr = err;
              } else if (typeof err.message === 'string') {
                imageErr = err.message;
              } else {
                imageErr = 'Errore di rete';
              }
            },
          });

          setUploadProgress((100 / imageCount) * imageIndex);

          if (!uploadImageRes || !uploadImageRes?.success) {
            if (uploadImageRes?.errors && uploadImageRes?.errors[0]) {
              imageErr = uploadImageRes.errors[0];
            }
            log.error('Image ' + image.attachmentKey + ' upload error:', imageErr);
            props.navigation.replace('JobError', {
              error: imageErr,
              jobId: jobId,
            });
            return null;
          }

          log.info('Image ' + image.attachmentKey + ' upload success');

          totalProgress = totalProgress + 100 / imageCount / fileCount;
        }
      }
    }

    setUploadProgress(100);
    setCurFile(T('upload_complete'));
    setTimeout(() => {
      props.navigation.replace('JobShipment', {
        job: data.GuestJobById,
        name: userData?.UserMe?.basicInfo?.addresses?.home?.name,
        phone: userData?.UserMe?.basicInfo?.addresses?.home?.phone,
      });
    }, 500);
  };

  const uploadStarted = useRef<boolean>(false);
  const uploadCancelled = useRef<boolean>(false);

  useEffect(() => {
    if (data?.GuestJobById?._id) {
      if (data.GuestJobById.status === 'readyForPayment') {
        log.warn('Job upload completed, need payment');
        props.navigation.replace('JobShipment', {
          job: data.GuestJobById,
        });
      } else if (data.GuestJobById.status === 'initializing' && !uploadStarted.current) {
        uploadStarted.current = true;
        uploadJob(data.GuestJobById._id, data.GuestJobById.shop?._id);
      } else {
        props.navigation.replace('JobComplete', {
          shopId: data.GuestJobById._id,
          jobId: data?.GuestJobById.shop?._id,
        });
      }
    }
  }, [data?.GuestJobById?._id]);

  return (
    <Box
      pt={2}
      bg={theme.vars.colors.light[0]}
      flex={1}
      alignItems="center"
      justifyContent="flex-start"
    >
      <Box flex={1} alignItems="center" justifyContent="center" safeAreaBottom>
        <Image
          mb={6}
          alt={'ritoro_negozio'}
          style={{ resizeMode: 'contain', width: 160, height: 160 }}
          source={require('../../assets/img/illustrations/rocket.png')}
        ></Image>
        <Text color={theme.vars.colors.grey[0]} mb={0} fontSize={'lg'} bold>
          {T('upload_order')}
        </Text>
        <Text color={theme.vars.colors.grey[0]} mb={6} fontSize={'md'}>
          {T('upload_order_hint')}
        </Text>

        <Box>
          <Center style={{ width: '100%', maxWidth: 500 }} mb={6}>
            <Text color={theme.vars.colors.blue[0]} mt={4} fontSize={30} bold>
              {uploadProgress.toFixed(0)} %
            </Text>

            <ProgressBar
              width={300}
              color={theme.vars.colors.blue[0]}
              bg={theme.vars.colors.blue[2]}
              progress={uploadProgress || 0}
            />
            <Text color={theme.vars.colors.grey[0]} mb={6} fontSize={'xs'}>
              {curFile || T('upload_begin')}
            </Text>
          </Center>
        </Box>
        <Pressable
          style={{
            width: '100%',
            position: 'absolute',
            bottom: 20,
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onPress={() => {
            setDelPromtVisible(true);
          }}
        >
          <Text color={theme.vars.colors.dark[0]} fontSize={12} underline bold>
            {T('cancel_order')}
          </Text>
        </Pressable>
      </Box>
      <Promt
        visible={delPromtVisible}
        text={T('cancel_order_promt')}
        onYes={() => {
          uploadCancelled.current = true;
          setDelPromtVisible(false);
          activeOrder(null);
          DB_JOBS.removeCurrentJob();
          jobCancel({
            variables: { jobId: props.route.params.jobId },
          });
          setTimeout(() => {
            props.navigation.navigate('Home', {
              screen: 'Cart',
              initial: false,
            });
          }, 100);
        }}
        onNo={() => {
          setDelPromtVisible(false);
        }}
        onCancel={() => {
          setDelPromtVisible(false);
        }}
      />
      <Loading bg={'rgba(255,255,255,0.5)'} visible={loading} />
    </Box>
  );
};

export { JobUpload };
