import React, { useState, useEffect } from 'react';
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native';
import { BarCodeScanner } from 'expo-barcode-scanner';
import { LOG } from '../../config';
import { Box } from 'native-base';
import { T } from '../../config/localization';

interface QrReaderProps {
  onRead: (code: string) => void;
}

const QrReader = (props: QrReaderProps) => {
  const [hasPermission, setHasPermission] = useState(null);

  useEffect(() => {
    (async () => {
      const { status } = await BarCodeScanner.requestPermissionsAsync();
      setHasPermission(status === 'granted');
    })();
  }, []);

  const handleBarCodeScanned = ({ type, data }) => {
    LOG.debug(type, data);

    if (data.substring(0, 25) === 'https://get.easypixy.com/') {
      let shopCode = data.substring(25, 30);
      if (shopCode && shopCode.length > 4) {
        props.onRead(shopCode);
      }
    }
  };

  if (hasPermission === null) {
    return <Text>{T('camera_permission')}</Text>;
  }
  if (hasPermission === false) {
    return <Text>{T('camera_permission_denied')}</Text>;
  }

  return (
    <View style={styles.container}>
      <BarCodeScanner
        onBarCodeScanned={handleBarCodeScanned}
        style={{ width: '100%', height: '100%' }}
      />
      <Box
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Box
          style={{
            width: 160,
            height: 160,

            borderStyle: 'dashed',
            borderColor: '#fff',
            borderWidth: 5,
          }}
        ></Box>
      </Box>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

export { QrReader };
