import { gql } from '@apollo/client';

interface Choice {
  name: string;
  percentualPriceIncrement: number;
  picture: {
    url: string | null;
    _id: string | null;
  };
}

interface Page {
  choices: Choice[];
  name: string;
}

interface Discount {
  quantityFrom: number | null;
  quantityTo: number | null;
  newPrice: number | null;
  timeDiscount: {
    from: string | null; // Date ISO Format es: "2021-03-01T00:00:00.000Z"
    to: string | null; // Date ISO Format es: "2021-03-01T00:00:00.000Z"
    newPrice: number | null;
  };
}

interface Prices {
  basePrice: number;
  quantityDiscounts: Discount[];
  basePriceLabel: string;
  basePriceMaxQnt: number;
}

type ProductType = 'pictureStandard' | 'pictureCreative' | 'gadget' | 'canvas' | 'panel' | 'goods';
type DisplayType = 'block' | 'list_horizontal';

interface Product {
  _id: string;
  name: string;
  version: number;
  priceVersion: number;
  type: ProductType;
  descriptions: {
    long: string;
    short: string;
  };
  shop?: any; // da divedere
  images: {
    cover: {
      url: string;
      // _id: string;
    };
    gallery: {
      url: string;
      // _id: string;
    }[];
  };
  pages?: Page[];
  prices: Prices;
  wizards: {
    _id: string;
    borders?: {
      bottom: {
        color: string;
        size: number;
      };
      left: {
        color: string;
        size: number;
      };
      right: {
        color: string;
        size: number;
      };
      top: {
        color: string;
        size: number;
      };
    };
    frame?: {
      mode: {
        colored: string;
        mirrored: string;
        normal: string;
      };
      thickness: {
        size: number;
      };
    };
    sizes: {
      height: number;
      width: number;
    };
    label?: string;
    masks?: {
      cut?: {
        url: string;
        _id: string;
      };
      safety?: {
        url: string;
        _id: string;
      };
    };
  }[];
}

interface ProductTree {
  type: 'folder' | 'product';
  description: string;
  name: string;
  style?: {
    color?: string;
    display?: DisplayType;
  };
  picture?: {
    url: string;
  };
  product?: Product;
  isSponsored?: boolean;
  isVisible?: boolean;
  //productGroup: ProductGroup;
  next?: ProductTree[];
}

interface Shop {
  _id: string;
  name: string;
  owner: {
    _id: string;
    username: string;
  };
  isEnabled: boolean;
  isAccepted: boolean;
  isMaintenance: Boolean;
  code: string;
  type: 'light' | 'full';
  addresses: {
    commercial: {
      country: string;
      state: string;
      city: string;
      zipCode: string;
      phoneNumber: string;
      denomination: string;
      street: string;
    }[];
  };
  deliveryInfo: {
    atHome: {
      allowedPaymentTypes: {
        onSite: boolean;
        stripe: boolean;
      };
      timings: {
        country: string;
        time: string;
      }[];
      costs: {
        country: string;
        cost: number;
      }[];
    };
    atShop: {
      allowedPaymentTypes: {
        onSite: boolean;
        stripe: boolean;
      };
      time: string;
      cost: number;
    };
  };
  paymentMethods: {
    onSite: {
      isEnabled: boolean;
    };
    stripe: {
      isEnabled: boolean;
      stripeInfo: {
        accountId: string;
      };
    };
  };
  productTree: ProductTree[]; // da scomporre
}

interface Promo {
  type: 'folder' | 'product';
  title: string;
  desc: string;
  color: string;
  image: string;
  grouped?: boolean;
  price?: number;
  next?: ProductTree[];
  product?: Product;
  isVisible?: boolean;
}

const PRODUCT_FRAGMENT = gql`
  fragment productFragment on Product {
    _id
    version
    priceVersion
    name
    type
    descriptions {
      long
      short
    }
    images {
      cover {
        url
        # _id
      }
      gallery {
        url
        # _id
      }
    }
    pages {
      choices {
        name
        percentualPriceIncrement
        picture {
          url
          _id
        }
      }
      name
    }
    prices {
      basePrice
      basePriceLabel
      basePriceMaxQnt
      quantityDiscounts {
        quantityFrom
        quantityTo
        newPrice
        timeDiscount {
          from
          to
          newPrice
        }
      }
    }
    wizards {
      _id
      borders {
        bottom {
          color
          size
        }
        left {
          color
          size
        }
        right {
          color
          size
        }
        top {
          color
          size
        }
      }
      frame {
        mode {
          colored
          mirrored
          normal
        }
        thickness {
          size
        }
      }
      sizes {
        height
        width
      }
      label
      masks {
        cut {
          url
          _id
        }
        safety {
          url
          _id
        }
      }
    }
  }
`;

const SHOP_FRAGMENT = gql`
  ${PRODUCT_FRAGMENT}
  fragment shopFragment on Shop {
    _id
    name
    owner {
      _id
      username
    }
    isEnabled
    isAccepted
    isMaintenance
    code
    type
    addresses {
      commercial {
        country
        state
        city
        zipCode
        phoneNumber
        denomination
        street
      }
    }
    deliveryInfo {
      atHome {
        allowedPaymentTypes {
          onSite
          stripe
        }
        timings {
          country
          time
        }
        costs {
          country
          cost
        }
      }
      atShop {
        allowedPaymentTypes {
          onSite
          stripe
        }
        time
        cost
      }
    }
    paymentMethods {
      onSite {
        isEnabled
      }
      stripe {
        isEnabled
        stripeInfo {
          accountId
        }
      }
    }
    productTree {
      isVisible
      type
      name
      description
      picture {
        url
        _id
      }
      style {
        color
        display
      }
      product {
        ...productFragment
      }
      isSponsored
      next {
        isVisible
        type
        name
        description
        picture {
          url
          _id
        }
        style {
          color
          display
        }
        product {
          ...productFragment
        }
        isSponsored
        next {
          type
          style {
            color
            display
          }
          product {
            ...productFragment
          }
          isSponsored
        }
      }
    }
  }
`;

const SHOP_MIN_FRAGMENT = gql`
  fragment shopMinFragment on Shop {
    _id
    code
    name
    owner {
      _id
      username
    }
    isEnabled
    isAccepted
    isMaintenance
    type
    addresses {
      commercial {
        country
        state
        city
        zipCode
        phoneNumber
        denomination
        street
      }
    }
    deliveryInfo {
      atHome {
        allowedPaymentTypes {
          onSite
          stripe
        }
        timings {
          country
          time
        }
        costs {
          country
          cost
        }
      }
      atShop {
        allowedPaymentTypes {
          onSite
          stripe
        }
        time
        cost
      }
    }
    paymentMethods {
      onSite {
        isEnabled
      }
      stripe {
        isEnabled
        stripeInfo {
          accountId
        }
      }
    }
  }
`;

const USER_SHOP = gql`
  query USER_SHOP($code: String!) {
    UserShop(code: $code) {
      ...shopFragment
    }
  }
  ${SHOP_FRAGMENT}
`;

const USER_SHOP_MIN = gql`
  query USER_SHOP_MIN($code: String!) {
    UserShop(code: $code) {
      ...shopMinFragment
    }
  }
  ${SHOP_MIN_FRAGMENT}
`;

const USER_PRODUCT = gql`
  query USER_PRODUCT($_id: ID!) {
    UserProduct(_id: $_id) {
      ...productFragment
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export {
  Shop,
  ProductTree,
  Product,
  ProductType,
  Page,
  Choice,
  Promo,
  USER_SHOP,
  USER_SHOP_MIN,
  PRODUCT_FRAGMENT,
  SHOP_FRAGMENT,
  SHOP_MIN_FRAGMENT,
  USER_PRODUCT,
};
