import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Image, Text } from 'native-base';
import { Button } from '../../ui/Button';
import { useMutation, useQuery } from '@apollo/client';
import { Shop, USER_SHOP_MIN } from '../../stores/queries/shop';
import { ACTUAL_AUTH } from '../../stores/db/auth';
import { BackHandler, Pressable } from 'react-native';
import { Icon } from '../../ui';
import {
  Job,
  JobInput,
  JobPaymentInput,
  JOB_ADD,
  JOB_ADD_PAYMENT,
  JOB_CHANGE_PAYMENT,
} from '../../stores/queries/jobs';
import { DB_JOBS, DB_ORDERS } from '../../stores/db/orders';
import { Loading } from '../../ui/Loading';
import { StripePayment } from '../../components/Payments/Stripe';
import { LOG, theme } from '../../config';
import { APP_STATE, APP_STATE_PROPS } from '../../stores/queries/appstate';
import { roundPrice } from '../../libs/utils';
import { useFocusEffect } from '@react-navigation/native';
import { activeOrder } from '../../stores/db/appstate';
import { T } from '../../config/localization';

const log = LOG.extend('JOBPAYMENT');
interface JobPaymentProps {
  navigation: any;
  route: {
    params: {
      shipping: {
        price: number;
        delivery: JobPaymentInput['delivery'];
      };
      enabledPayments: {
        stripe: boolean;
        onSite: boolean;
      };
      job: Job;
    };
  };
}

const JobPayment = (props: JobPaymentProps) => {
  const [error, setError] = useState(null);

  const finalPrice = useRef(props.route?.params?.job?.finalPrice || 0);

  useFocusEffect(
    useCallback(() => {
      const onBackPress = () => {
        return true;
      };

      BackHandler.addEventListener('hardwareBackPress', onBackPress);

      return () => BackHandler.removeEventListener('hardwareBackPress', onBackPress);
    }, [])
  );

  useEffect(() => {
    props.navigation.setOptions({
      headerLeft: null,
    });
  }, []);

  const [jobAddPayment, { loading: jobLoading, error: jobError, data: jobData }] = useMutation<{
    UserJobPaymentAdd: Job;
  }>(JOB_ADD_PAYMENT, {
    onCompleted: res => {
      if (res?.UserJobPaymentAdd?._id) {
        log.info('Job Payment added:', res?.UserJobPaymentAdd?.payment);
      } else {
        log.error('Error on null on add job payment:', res);
        /* log.error('Error on null on add job payment:', res);
        setError(T('unknown_error')); */
        /* DB_JOBS.removeCurrentJob();
        props.navigation.navigate('Home', {
          screen: 'Shop',
          initial: false,
        }); */
      }
    },
    onError: err => {
      setError(T('unknown_error'));
      log.error('Error on add job payment:', err);
    },
  });

  const addJobPayment = async (payment: JobPaymentInput['payment']) => {
    let userJobPayment: JobPaymentInput = {
      jobId: props.route.params.job._id,
      payment,
      finalPrice: roundPrice(finalPrice.current + (props.route.params.shipping.price || 0)),
      delivery: props.route.params.shipping.delivery,
    };

    let res = await jobAddPayment({
      variables: userJobPayment,
    });
    return res;
  };

  return (
    <Box
      pt={2}
      bg={theme.vars.colors.light[0]}
      flex={1}
      alignItems="center"
      justifyContent="flex-start"
      safeAreaBottom
    >
      <Box flex={1} alignItems="center" justifyContent="center" safeAreaBottom>
        <Image
          mb={2}
          alt={'pay_negozio'}
          style={{ resizeMode: 'contain', width: 160, height: 160 }}
          source={require('../../assets/img/illustrations/metodo-pagamento.png')}
        ></Image>
        <Text color={theme.vars.colors.blue[0]} fontSize={'lg'}>
          {T('order_price')}: {finalPrice.current.toFixed(2)} €
        </Text>
        <Text color={theme.vars.colors.blue[0]} fontSize={'lg'}>
          {T('ship_price')}:{' '}
          {props.route.params.shipping.price
            ? props.route.params.shipping.price + ' €'
            : T('free_shipping')}
        </Text>
        <Text color={theme.vars.colors.blue[0]} mb={5} fontSize={'lg'} bold>
          {T('total_price')}:{' '}
          {(finalPrice.current + (props.route.params.shipping.price || 0)).toFixed(2)} €
        </Text>
        <Text color={theme.vars.colors.grey[0]} mb={4} fontSize={'md'} bold>
          {T('payment_method')}{' '}
        </Text>

        {error && (
          <Text color={theme.vars.colors.red[0]} bold>
            {error}
          </Text>
        )}

        {props.route?.params?.enabledPayments?.stripe && (
          <StripePayment
            shopName={props.route.params.job.shop.name}
            stripeAccountId={props.route.params.job.shop.paymentMethods.stripe.stripeInfo.accountId}
            getClientSecret={async () => {
              const res = await addJobPayment({ type: 'stripe' });
              return {
                clientSecret: res?.data?.UserJobPaymentAdd?.payment?.stripe?.clientSecret,
                jobId: res?.data?.UserJobPaymentAdd?._id,
              };
            }}
            onSuccess={() => {
              setTimeout(() => {
                props.navigation.replace('JobComplete', {
                  shopId: props.route.params.job.shop._id,
                  jobId: props.route.params.job._id,
                });
              }, 50);
            }}
            onError={err => {
              setTimeout(() => {
                if (typeof err === 'string') {
                  setError(err);
                } else if (err?.localizedMessage) {
                  setError(err?.localizedMessage);
                } else {
                  setError(T('unknown_error'));
                }
              }, 50);
            }}
          />
        )}

        {props.route?.params?.enabledPayments?.onSite && (
          <Button
            color="light"
            shadow="lg"
            style={{ width: '100%', maxWidth: 300, shadowOpacity: 0.2 }}
            onPress={() => {
              addJobPayment({ type: 'onSite' }).then(res => {
                if (res?.data?.UserJobPaymentAdd?._id) {
                  props.navigation.replace('JobComplete', {
                    shopId: props.route.params.job.shop._id,
                    jobId: res.data.UserJobPaymentAdd._id,
                  });
                } else {
                  setError(T('unknown_error'));
                }
              });
            }}
          >
            <Box
              width={'100%'}
              justifyContent={'center'}
              alignItems={'center'}
              flexDirection={'row'}
            >
              <Icon
                name="negozio"
                size={16}
                color="dark"
                style={{ position: 'absolute', left: 5 }}
              />
              <Text color={theme.vars.colors.dark[0]} bold>
                {T('payment_on_site')}
              </Text>
            </Box>
          </Button>
        )}
        {/*      {props.route.params.shipping.delivery.type === 'atHome' ? (
          <Text color={theme.vars.colors.red[0]}>{T('payment_on_site_hint')}</Text>
        ) : null} */}
        <Pressable
          style={{
            width: '100%',
            position: 'absolute',
            bottom: 20,
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onPress={() => {
            activeOrder(null);
            DB_JOBS.removeCurrentJob();
            props.navigation.navigate('Home', {
              screen: 'Cart',
              initial: false,
            });
          }}
        >
          <Text color={theme.vars.colors.dark[0]} fontSize={12} underline bold>
            {T('cancel_order')}
          </Text>
        </Pressable>
      </Box>

      <Loading bg={'rgba(255,255,255,0.5)'} visible={jobLoading} />
    </Box>
  );
};

export { JobPayment };
