import React, { useEffect } from 'react';

import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { Linking, Platform, StyleSheet, TouchableOpacity, useWindowDimensions } from 'react-native';
import { useQuery } from '@apollo/client';

import { T } from '../config/localization';

import { Profile } from '../screens/Profile/Profile';
import { Box, Image, Pressable, Text, View } from 'native-base';
import { theme } from '../config';
import { ShopWindow } from '../screens/Shop/ShopWindow';
import { ShopSelect } from '../screens/Root/ShopSelect';
import { Icon } from '../ui';
import { ProductsPath } from '../screens/Wizard/ProductsPath';
import { Icons } from '../ui/Icon';
import { ProductSummary } from '../screens/Wizard/ProductSummary';
import { ProductPage } from '../screens/Wizard/ProductPage';
import { PhotoSelect } from '../screens/Wizard/PhotoSelect';
import { ImageEditor } from '../screens/Wizard/ImageEditor';
import { ShopHeader } from '../screens/Shop/ShopHeader';
import { Cart } from '../screens/Cart/Cart';
import { Projects } from '../screens/Projects/Projects';
import { APP_STATE, APP_STATE_PROPS } from '../stores/queries/appstate';
import { History } from '../screens/Profile/History';
import { JobCreate } from '../screens/Job/JobCreate';
import { JobShipment } from '../screens/Job/JobShipment';
import { JobUser } from '../screens/Job/JobUser';
import { JobPayment } from '../screens/Job/JobPayment';
import { JobUpload } from '../screens/Job/JobUpload';
import { JobComplete } from '../screens/Job/JobComplete';
import { JobError } from '../screens/Job/JobError';
import { Addresses } from '../screens/Profile/Adresses';
import { Help } from '../screens/Profile/Help';
import { Preferences } from '../screens/Profile/Preferences';
import { Logs } from '../screens/Root/Logs';
import { Maintenance } from '../screens/Root/Maintenance';
import { ShopInstant } from '../screens/Root/ShopInstant';
import { VerifyEmailHelper } from '../components/Profile/VerifyEmailHelper';

const HomeTabs = createBottomTabNavigator();
const HomeStack = createStackNavigator();
const RootStack = createStackNavigator();
const ShopStack = createStackNavigator();
const WizardStack = createStackNavigator();
const UserStack = createStackNavigator();
const JobStack = createStackNavigator();

interface HeaderProps {
  navigation: any;
  reset?: boolean;
  route: any;
}
const HeaderBack = (props: HeaderProps) => {
  return (
    <TouchableOpacity
      style={{
        marginLeft: 10,
        backgroundColor: theme.vars.colors.red[2],
        borderRadius: 999,
        height: 34,
        width: 34,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 6,
      }}
      onPress={() => {
        if (props.reset) {
          props.navigation.navigate('Home', {
            screen: 'Projects',
            initial: false,
          });
          /* props.navigation.dispatch(
            CommonActions.reset({
              routes: [
                {
                  name: 'Home',
                  state: {
                    routes: [{ name: 'Projects' }],
                  },
                },
              ],
            })
          ); */
        } else {
          props.navigation.goBack();
        }
      }}
    >
      <Icon color={'red'} name={'freccia-sinistra'} size={15} />
    </TouchableOpacity>
  );
};

const Shop = () => {
  return (
    <ShopStack.Navigator
      screenOptions={({ navigation, route }) => ({
        cardStyle: { flex: 1 },
        gestureEnabled: false,
        animationEnabled: true,
        headerStyle: {
          backgroundColor: theme.vars.colors.light[0],
          borderBottomWidth: 0,
          borderColor: theme.vars.colors.light[0],
          shadowColor: 'transparent',
          shadowRadius: 0,
          elevation: 0,
          shadowOpacity: 0,
        },
        headerLeft: () => <HeaderBack route={route} navigation={navigation} />,
        headerTintColor: theme.vars.colors.dark[0],
        headerTitleStyle: styles.headerTitle,
        headerBackTitleVisible: false,
      })}
    >
      <ShopStack.Screen name="ShopWindow" component={ShopWindow} options={{ headerShown: false }} />
    </ShopStack.Navigator>
  );
};

const User = () => {
  return (
    <UserStack.Navigator
      screenOptions={({ navigation, route }) => ({
        cardStyle: { flex: 1 },
        gestureEnabled: false,
        animationEnabled: true,
        headerStyle: {
          backgroundColor: theme.vars.colors.light[0],
          borderBottomWidth: 0,
          borderColor: theme.vars.colors.light[0],
          shadowColor: 'transparent',
          shadowRadius: 0,
          elevation: 0,
          shadowOpacity: 0,
        },
        headerLeft: () => <HeaderBack route={route} navigation={navigation} />,
        headerTintColor: theme.vars.colors.dark[0],
        headerTitleStyle: styles.headerTitle,
        headerBackTitleVisible: false,
      })}
    >
      <UserStack.Screen name="Profile" component={Profile} options={{ headerShown: false }} />
      <UserStack.Screen
        name="History"
        component={History}
        options={{ headerTitle: T('page_title_cart') }}
      />
      <UserStack.Screen
        name="Addresses"
        component={Addresses}
        options={{ headerTitle: T('page_title_addresses') }}
      />
      <UserStack.Screen
        name="Preferences"
        component={Preferences}
        options={{ headerTitle: T('page_title_preferences') }}
      />
      <UserStack.Screen
        name="Help"
        component={Help}
        options={{ headerTitle: T('page_title_info') }}
      />
    </UserStack.Navigator>
  );
};

const HomeTabBar = ({ state, descriptors, navigation }) => {
  const { data: APP } = useQuery<APP_STATE_PROPS>(APP_STATE);

  return (
    <Box
      safeAreaBottom
      style={{
        flexDirection: 'row',
        paddingVertical: 5,
        paddingTop: 8,
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        //paddingBottom: 10,
        backgroundColor: theme.vars.colors.light[0],
        paddingHorizontal: '3%',
        justifyContent: 'center',
        alignItems: 'center',
        /* shadowColor: theme.vars.colors.dark[0],
        shadowOffset: {
          width: 0,
          height: 0,
        },
        shadowOpacity: 0.3,
        shadowRadius: 6,
        elevation: 5, */
      }}
    >
      {state.routes.map((route, index) => {
        const { options } = descriptors[route.key];
        const label =
          options.tabBarLabel !== undefined
            ? options.tabBarLabel
            : options.title !== undefined
            ? options.title
            : route.name;

        const isFocused = state.index === index;

        let color: string[];
        let icon: Icons;
        let badge: boolean = false;

        switch (index) {
          case 0:
            color = theme.vars.colors.red;
            icon = 'home';
            break;
          case 1:
            color = theme.vars.colors.violet;
            icon = 'profilo';
            break;
          case 2:
            color = theme.vars.colors.pink;
            icon = 'stella';
            badge = APP?.filledProjects;
            break;
          case 3:
            color = theme.vars.colors.blue;
            icon = 'carrello';
            badge = APP?.filledCart;
            break;
          default:
            color = theme.vars.colors.red;
            icon = 'home';
            break;
        }

        const onPress = () => {
          const event = navigation.emit({
            type: 'tabPress',
            target: route.key,
          });

          if (!isFocused && !event.defaultPrevented) {
            navigation.navigate(route.name);
          }
        };

        const onLongPress = () => {
          navigation.emit({
            type: 'tabLongPress',
            target: route.key,
          });
        };

        return (
          <Pressable
            key={index}
            accessibilityRole="button"
            //accessibilityStates={isFocused ? ['selected'] : []}
            accessibilityLabel={options.tabBarAccessibilityLabel}
            testID={options.tabBarTestID}
            onPress={onPress}
            onLongPress={onLongPress}
            style={{
              flex: 1,
              backgroundColor: theme.vars.colors.light[0],
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: 8,
            }}
          >
            <Box
              bg={
                !isFocused
                  ? {
                      linearGradient: {
                        colors: [color[0], color[2]],
                        start: [0.3, 1],
                        end: [0.01, 0.1],
                      },
                    }
                  : '#fff'
              }
              // @ts-ignore
              cursor={Platform.OS == 'web' ? 'pointer' : undefined}
              style={[
                {
                  borderRadius: 25,
                  height: 50,
                  width: 50,
                  justifyContent: 'center',
                  alignItems: 'center',
                },
                isFocused
                  ? {
                      ...theme.vars.shadow['md'],
                      shadowRadius: 10,
                      shadowOpacity: 0.5,
                      shadowColor: color[1],
                      height: 50,
                      width: 50,
                      borderWidth: 1,
                      borderColor: color[2],
                    }
                  : undefined,
              ]}
            >
              <Icon name={icon} size={22} style={{ color: isFocused ? color[0] : '#fff' }} />
            </Box>
            {badge && (
              <Box
                shadow={6}
                style={{
                  width: 12,
                  height: 12,
                  borderRadius: 6,
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: theme.vars.colors.red[0],
                  position: 'absolute',
                  top: '1%',
                  right: '25%',
                }}
              ></Box>
            )}
          </Pressable>
        );
      })}
    </Box>
  );
};

const Home = () => {
  const window = useWindowDimensions();

  if (window.width > theme.vars.screen.sm) {
    return (
      <View style={{ flex: 1, flexDirection: 'column' }}>
        {/* <ShopHeader /> */}
        <HomeStack.Navigator
          screenOptions={({ navigation, route }) => ({
            cardStyle: { flex: 1 },
            gestureEnabled: false,
            animationEnabled: true,
            headerStyle: {
              backgroundColor: theme.vars.colors.light[0],
              borderBottomWidth: 0,
              borderColor: theme.vars.colors.light[0],
              shadowColor: 'transparent',
              shadowRadius: 0,
              elevation: 0,
              shadowOpacity: 0,
            },
            headerLeft:
              Platform.OS === 'web'
                ? () => <HeaderBack route={route} navigation={navigation} />
                : undefined,
            headerTintColor: theme.vars.colors.light[0],
            headerTitleStyle: styles.headerTitle,
            headerBackTitleVisible: false,
            headerShown: false,
          })}
        >
          <HomeStack.Screen name="Shop" component={Shop} />
          <HomeStack.Screen name="User" component={User} />
          <HomeStack.Screen name="Projects" component={Projects} />
          <HomeStack.Screen name="Cart" component={Cart} />
        </HomeStack.Navigator>
      </View>
    );
  }
  return (
    <HomeTabs.Navigator
      tabBar={props => <HomeTabBar {...props} />}
      screenOptions={({ navigation, route }) => ({
        cardStyle: { flex: 1 },
        gestureEnabled: false,
        animationEnabled: true,
        headerStyle: {
          backgroundColor: theme.vars.colors.light[0],
          borderBottomWidth: 0,
          borderColor: theme.vars.colors.light[0],
          shadowColor: 'transparent',
          shadowRadius: 0,
          elevation: 0,
          shadowOpacity: 0,
        },
        headerLeft:
          Platform.OS === 'web'
            ? () => <HeaderBack route={route} navigation={navigation} />
            : undefined,
        headerTintColor: theme.vars.colors.light[0],
        headerTitleStyle: styles.headerTitle,
        headerBackTitleVisible: false,
        headerShown: false,
        //tabBarActiveTintColor: "LightBlue.50",
        tabBarInactiveTintColor: 'gray',
        tabBarStyle: {
          //backgroundColor: "#F5F5F5",
        },
      })}
    >
      <HomeTabs.Screen name="Shop" component={Shop} />
      <HomeTabs.Screen name="User" component={User} options={{ unmountOnBlur: true }} />
      <HomeTabs.Screen name="Projects" component={Projects} options={{ unmountOnBlur: true }} />
      <HomeTabs.Screen name="Cart" component={Cart} options={{ unmountOnBlur: true }} />
    </HomeTabs.Navigator>
  );
};

const Wizard = () => {
  return (
    <WizardStack.Navigator
      screenOptions={({ navigation, route }) => ({
        cardStyle: { flex: 1 },
        gestureEnabled: false,
        animationEnabled: true,
        headerStyle: {
          backgroundColor: theme.vars.colors.light[0],
          borderBottomWidth: 0,
          borderColor: theme.vars.colors.light[0],
          shadowColor: 'transparent',
          shadowRadius: 0,
          elevation: 0,
          shadowOpacity: 0,
        },
        headerLeft: () => <HeaderBack route={route} navigation={navigation} />,
        headerTintColor: theme.vars.colors.dark[0],
        headerTitleStyle: styles.headerTitle,
        headerBackTitleVisible: false,
      })}
    >
      <WizardStack.Screen name="ProductPath" component={ProductsPath as any} />
      <WizardStack.Screen name="ProductSummary" component={ProductSummary as any} />
      <WizardStack.Screen name="ProductPage" component={ProductPage as any} />
      <WizardStack.Screen
        options={({ navigation, route }) => ({
          header: () => null,
        })}
        name="PhotoSelect"
        component={PhotoSelect as any}
      />
      <WizardStack.Group screenOptions={{ presentation: 'modal' }}>
        <RootStack.Screen
          name="ImageEditor"
          options={{
            header: () => null,
          }}
          component={ImageEditor as any}
        />
      </WizardStack.Group>
    </WizardStack.Navigator>
  );
};

const Job = () => {
  return (
    <JobStack.Navigator
      screenOptions={({ navigation, route }) => ({
        cardStyle: { flex: 1 },
        gestureEnabled: false,
        animationEnabled: true,
        headerShown: false,
        /* headerStyle: {
          backgroundColor: theme.vars.colors.light[0],
          borderBottomWidth: 0,
          borderColor: theme.vars.colors.light[0],
          shadowColor: 'transparent',
          shadowRadius: 0,
          elevation: 0,
          shadowOpacity: 0,
        },
        headerLeft: () => <HeaderBack route={route} navigation={navigation} />,
        headerTintColor: theme.vars.colors.dark[0],
        headerTitleStyle: styles.headerTitle,
        headerBackTitleVisible: false, */
      })}
    >
      <JobStack.Screen
        name="JobCreate"
        component={JobCreate as any}
        options={{ headerShown: false }}
      />
      <JobStack.Screen
        name="JobShipment"
        component={JobShipment as any}
        options={{ headerTitle: T('page_title_ship') }}
      />
      <JobStack.Screen
        name="JobUser"
        component={JobUser as any}
        options={{ headerTitle: 'Profilo' }}
      />
      <JobStack.Screen
        name="JobPayment"
        component={JobPayment as any}
        options={{ headerTitle: T('page_title_pay') }}
      />
      <JobStack.Screen
        name="JobUpload"
        component={JobUpload as any}
        options={{ header: () => null }}
      />
      <JobStack.Screen
        name="JobComplete"
        component={JobComplete as any}
        options={{ header: () => null }}
      />
      <JobStack.Screen
        name="JobError"
        component={JobError as any}
        options={{ header: () => null }}
      />
    </JobStack.Navigator>
  );
};

const Root = () => {
  const window = useWindowDimensions();
  const { data: APP } = useQuery<APP_STATE_PROPS>(APP_STATE);

  if (APP.shopInstant) {
    return <ShopInstant />;
  }

  return (
    <>
      {window.width > theme.vars.screen.sm && !APP.activeOrder && !APP.activeJob ? (
        <ShopHeader />
      ) : null}
      <RootStack.Navigator
        screenOptions={({ navigation, route }) => ({
          cardStyle: { flex: 1 },
          gestureEnabled: false,
          animationEnabled: true,
          headerStyle: {
            backgroundColor: theme.vars.colors.light[0],
            borderBottomWidth: 0,
            borderColor: theme.vars.colors.light[0],
            shadowColor: 'transparent',
            shadowRadius: 0,
            elevation: 0,
            shadowOpacity: 0,
          },
          headerLeft:
            Platform.OS === 'web'
              ? () => <HeaderBack route={route} navigation={navigation} />
              : undefined,
          headerTintColor: theme.vars.colors.light[0],
          headerTitleStyle: styles.headerTitle,
          headerBackTitleVisible: false,
          headerShown: false,
        })}
      >
        {!APP.shopCode ? (
          <>
            <RootStack.Screen
              name="ShopSelect"
              component={ShopSelect}
              options={{
                animationTypeForReplace: 'pop',
              }}
            />
          </>
        ) : (
          <>
            <RootStack.Screen
              name="Home"
              component={Home}
              options={{
                headerTitle: 'EasyPixy',
              }}
            />
            <RootStack.Screen name="Wizard" component={Wizard} />
            <RootStack.Screen name="Job" component={Job} />
            <RootStack.Screen name="Logs" component={Logs} options={{ presentation: 'modal' }} />
            <RootStack.Screen name="Maintenance" component={Maintenance} />
          </>
        )}
      </RootStack.Navigator>
      <VerifyEmailHelper />
    </>
  );
};

const styles = StyleSheet.create({
  headerTitle: {
    ...theme.text.l,
    ...theme.text.bold,
    fontSize: 20,
  },
});

export { Root };
