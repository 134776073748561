import { BlurView } from 'expo-blur';
import { Box, Modal, Text } from 'native-base';
import React, { useEffect } from 'react';
import { Image, Platform } from 'react-native';
import { theme } from '../../config';
import { T } from '../../config/localization';
import { Button } from '../Button';

interface PromtProps {
  visible: boolean;
  text: string;
  onYes: Function;
  onNo: Function;
  onCancel: Function;
  subText?: string;
  yesText?: string;
  noText?: string;
}

const Promt = (props: PromtProps) => {
  const [modalVisible, setModalVisible] = React.useState(false);

  useEffect(() => {
    if (props.visible) {
      setModalVisible(true);
    } else {
      setModalVisible(false);
    }
  }, [props.visible]);

  return (
    <Modal
      backdropVisible={true}
      _backdrop={{
        _dark: {
          bg: 'rgba(0,0,0,0)',
        },
        bg: 'rgba(0,0,0,0)',
      }}
      isOpen={modalVisible}
      onClose={() => {
        props.onCancel && props.onCancel();
        setModalVisible(false);
      }}
      size={'md'}
      isKeyboardDismissable={true}
      avoidKeyboard={true}
      animationPreset={'slide'}
    >
      <Box
        shadow={1}
        style={{
          shadowRadius: 8,
          shadowOpacity: 0.1,
          width: '92%',
          maxWidth: 380,
          overflow: 'hidden',
          borderColor: theme.vars.colors.light[2],
          borderWidth: 1,
          borderRadius: 20,
          backgroundColor:
            Platform.OS === 'android' ? 'rgba(255,255,255,0.9)' : 'rgba(255,255,255,0.5)',
        }}
      >
        <BlurView
          intensity={60}
          style={{
            alignItems: 'center',
          }}
        >
          {/* <Modal.CloseButton style={{ backgroundColor: theme.vars.colors.light[0] }} /> */}
          <Box px={4} py={8} alignItems="center">
            <Box
              style={{
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Text textAlign={'center'} color={'#020202'} fontSize={20} mt={4} mb={6} bold>
                {props.text}
              </Text>
              {props.subText && (
                <Text
                  mb={6}
                  textAlign={'center'}
                  color={'#020202'}
                  fontSize={16}
                  style={{ marginTop: -20 }}
                >
                  {props.subText}
                </Text>
              )}
            </Box>
            <Box
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'row',
                width: '100%',
              }}
            >
              <Button
                color={'blue'}
                half
                shadow={'md'}
                onPress={() => {
                  props.onNo && props.onNo();
                }}
              >
                {props.noText || T('no')}
              </Button>
              <Button
                color={'violet'}
                half
                shadow={'md'}
                onPress={() => {
                  props.onYes && props.onYes();
                  setModalVisible(false);
                }}
              >
                {props.yesText || T('yes')}
              </Button>
            </Box>
          </Box>
        </BlurView>
      </Box>
    </Modal>
  );
};

export { Promt };
