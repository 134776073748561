import * as SplashScreen from 'expo-splash-screen';
import * as React from 'react';
import * as Font from 'expo-font';

const iconsFont = require('../assets/fonts/ep-icons.ttf');
const bariolLight = require('../assets/fonts/bariol_light-webfont.ttf');
const bariolLightItalic = require('../assets/fonts/bariol_light_italic-webfont.ttf');
const bariolRegular = require('../assets/fonts/bariol_regular-webfont.ttf');
const bariolRegularItalic = require('../assets/fonts/bariol_regular_italic-webfont.ttf');
const bariolBold = require('../assets/fonts/bariol_bold-webfont.ttf');
const bariolBoldItalic = require('../assets/fonts/bariol_bold_italic-webfont.ttf');

import { createApolloClient } from '../libs/apollo/createClient';
import { activeJob, filledCart, filledProjects, shopCode, userLogged } from '../stores/db/appstate';
import { Auth, DB_AUTH } from '../stores/db/auth';
import { DB_JOBS, DB_ORDERS, Order } from '../stores/db/orders';
import { calcOrderPrice } from '../libs/utils';
import { Product, USER_PRODUCT, USER_SHOP_MIN } from '../stores/queries/shop';
import { ENV, LOG } from '../config';
import { compareVersions } from 'compare-versions';
// @ts-ignore
import Timeout from 'await-timeout';
import { Linking, Platform } from 'react-native';

const log = LOG.extend('INITHOOK');

const FETCHTIMEOUT = 10000;

interface AppConfig {
  appMinVersion: string;
  maintenance: boolean;
}
let ACTUAL_APPCONFIG: AppConfig = {
  maintenance: false,
  appMinVersion: '0.0.0',
};

const checkAppUpdates = (appMinVersion: string): boolean => {
  let regexVersion: any;

  regexVersion = new RegExp(/(\d+\.)(\d+\.)(\d+)/g);
  let mVerCorrect = regexVersion.test(appMinVersion);
  if (!mVerCorrect) {
    log.error('checkAppUpdates | Error appMinVersion regex invalid: ' + appMinVersion);
    return true;
  }

  regexVersion = new RegExp(/(\d+\.)(\d+\.)(\d+)/g);
  let aVerCorrect = regexVersion.test(ENV.deviceInfo.app.version);
  if (!aVerCorrect) {
    log.error(
      'checkAppUpdates message | Error message actualAPPversion regex invalid: ' +
        ENV.deviceInfo.app.version
    );
    return true;
  }

  let compare = compareVersions(appMinVersion, ENV.deviceInfo.app.version);

  if (compare === 1) {
    log.warn(
      'APP UPDATE REQUIRED, actual: ' + ENV.deviceInfo.app.version + ' required: ' + appMinVersion
    );
    return true;
  } else {
    log.info(
      'APP COMPATIBLE, actual: ' + ENV.deviceInfo.app.version + ' required: ' + appMinVersion
    );
    return false;
  }
};

const fetchAppConfig = async () => {
  __DEV__ && console.time(`TIME: fetchAppConfig`);
  let config: AppConfig = { maintenance: false, appMinVersion: null };
  const timeout = FETCHTIMEOUT;
  const timer = new Timeout();
  try {
    let promise = getAppConfig();
    config = await Promise.race([
      promise,
      timer.set(timeout, 'fetchAppConfig ' + timeout + 'ms timeout!'),
    ]);
  } catch (e) {
    log.warn('unable to fetchAppConfig: ' + e.message);
  }
  if (!config || !config.appMinVersion) {
    config = { maintenance: false, appMinVersion: null };
    log.warn('unable to read config');
  }
  __DEV__ && console.timeEnd(`TIME: fetchAppConfig`);

  return config;
};

const getAppConfig = async (): Promise<AppConfig | null> => {
  try {
    let response = await fetch(ENV.backend.config);
    let responseJson = await response.json();
    ACTUAL_APPCONFIG = responseJson;
    return responseJson;
  } catch (error) {
    log.error('getAppConfig error: ' + error.message);
    return null;
  }
};

const calcOrderUpdates = async (client: any, order: Order) => {
  if (!order?.key || !order?.rawProduct?._id || !client) {
    log.error('Errore in update orders: ', order?.key, order?.rawProduct?._id);
    return;
  }

  let productDeleted = false;

  let res: { data: { UserProduct: Product } };
  try {
    res = await client.query({
      query: USER_PRODUCT,
      fetchPolicy: 'network-only',
      variables: { _id: order.rawProduct._id },
    });
    if (res?.data?.UserProduct === null) {
      productDeleted = true;
    }
    log.debug(
      'Product ' + order?.rawProduct?._id + ' from order ' + order.key + ' fetched:',
      res?.data?.UserProduct?._id
    );
  } catch (error) {
    log.error('Unable to fetch product:', order?.rawProduct?._id, error);
  }

  if (productDeleted) {
    log.warn('Ordine con prodotto eliminato o inesistente:', order.key, order?.rawProduct?._id);
    DB_ORDERS.removeOrder(order.key);
    return;
  }

  const orderProductVersion = order.rawProduct?.version || 0;
  const orderProductPriceVersion = order.rawProduct?.priceVersion || 0;
  const version = res?.data?.UserProduct?.version || 0;
  const priceVersion = res?.data?.UserProduct?.priceVersion || 0;

  if (orderProductVersion < version) {
    log.warn('Ordine con versione prodotto vecchia:', order.key, orderProductVersion, version);
    DB_ORDERS.removeOrder(order.key);
  } else if (orderProductPriceVersion < priceVersion) {
    log.warn(
      'Ordine con versione listino vecchia:',
      order.key,
      orderProductPriceVersion,
      priceVersion
    );
    let newOrder: Order = { ...order, rawProduct: res.data.UserProduct };
    let { price, expiration } = calcOrderPrice(newOrder);
    newOrder = { ...newOrder, finalPrice: price, priceExpiration: expiration };
    DB_ORDERS.updateOrder(newOrder);
  } else if (order.priceExpiration) {
    let now = new Date().getTime();
    if (now > order.priceExpiration) {
      log.info('Ordine con promozione scaduta:', order.key, orderProductPriceVersion, priceVersion);
      let { price, expiration } = calcOrderPrice(order);
      let newOrder = { ...order, finalPrice: price, priceExpiration: expiration };
      DB_ORDERS.updateOrder(newOrder);
    }
  }
  return;
};

const checkOrders = async client => {
  let actualFilledProjects = false;
  let actualFilledCart = false;
  let orders = await DB_ORDERS.getOrders();
  if (orders && orders.length > 0) {
    for (let i = 0; i < orders.length; i++) {
      if (orders[i]?.state === 'project') {
        actualFilledProjects = true;
      } else if (orders[i]?.state === 'cart') {
        actualFilledCart = true;
      }

      // Controllo aggiornamenti sui prodotti
      calcOrderUpdates(client, orders[i]);
    }
  }
  filledProjects(actualFilledProjects);
  filledCart(actualFilledCart);
};

// Hooks per bloccare la sparizione dello splashscreen finche non carico tutte le risorse iniziali
const useInitResource = () => {
  const [initResource, setInitResource] = React.useState<{
    apolloClient: any;
    auth: Auth | null;
    isInMaintenance: boolean;
    isUpdated: boolean;
  }>();

  React.useEffect(() => {
    async function loadResourcesAndDataAsync() {
      let apolloClient: any = null;
      let auth: Auth = null;
      try {
        SplashScreen.preventAutoHideAsync();

        // Carico Fonts
        await Font.loadAsync({
          bariolLight,
          bariolLightItalic,
          bariolRegular,
          bariolRegularItalic,
          bariolBold,
          bariolBoldItalic,
          fontello: iconsFont,
        });

        let config = ACTUAL_APPCONFIG;

        try {
          config = await fetchAppConfig();
        } catch (error) {}

        let config_isInMaintenance = config.maintenance;
        if (config_isInMaintenance) {
          setInitResource({ apolloClient, auth, isInMaintenance: true, isUpdated: true });
          throw new Error('App in mantenance');
        }

        if (config?.appMinVersion && Platform.OS !== 'web') {
          let config_needUpdate = false;
          try {
            config_needUpdate = checkAppUpdates(config.appMinVersion);
          } catch (error) {
            log.error(error);
          }

          if (config_needUpdate) {
            setInitResource({ apolloClient, auth, isInMaintenance: false, isUpdated: false });
            throw new Error('App update needed');
          }
        }

        // Inizializzo client apollo
        apolloClient = await createApolloClient();

        // Inizializzo authenticazione
        auth = await DB_AUTH.get();

        // controllo shop code in universal/deep link
        let linkCode = null;
        if (Platform.OS === 'web') {
          var params = new URLSearchParams(window.location.search);
          if (params.has('code')) {
            linkCode = params.get('code');
          }
        } else {
          try {
            let url = await Linking.getInitialURL();
            if (url) {
              let regex = /[?&]([^=#]+)=([^&#]*)/g,
                params = {},
                match;
              while ((match = regex.exec(url))) {
                params[match[1]] = match[2];
              }
              const { code }: any = params;

              if (code) {
                linkCode = code;
              }
            }
          } catch (error) {
            log.error('Linking.getInitialURL error:', error);
          }
        }
        if (linkCode) {
          let res;
          try {
            res = await apolloClient.query({
              query: USER_SHOP_MIN,
              fetchPolicy: 'no-cache',
              variables: {
                code: linkCode,
              },
            });
          } catch (error) {}
          if (!res?.data?.UserShop?._id) {
            log.error('Code param shop not exist');
            linkCode = null;
          } else {
            log.info('Shop found: ' + res.data.UserShop.code);
            if (auth.shopCode !== linkCode) {
              log.info('Change shop: ', auth.shopCode, ' TO ', linkCode);
              auth.shopCode = linkCode;
              await DB_AUTH.set({ shopCode: linkCode });
              await DB_JOBS.removeCurrentJob();
            }
          }
        }

        if (auth && auth.shopCode) {
          shopCode(auth.shopCode);
          setTimeout(() => {
            checkOrders(apolloClient);
          }, 10);
        } else {
          log.warn('No SHOP saved');
        }

        if (auth && auth.user) {
          // Se esiste authenticazione setto variabile globale logged
          userLogged(auth.user);
          // Controllo job attivi
          let isActiveJob = await DB_JOBS.getCurrentJob();
          if (isActiveJob) {
            activeJob(isActiveJob);
          }
        } else {
          log.warn('No USER saved');
        }
      } catch (e) {
        console.warn(e);
      } finally {
        setInitResource({ apolloClient, auth, isInMaintenance: false, isUpdated: true });
      }
    }

    loadResourcesAndDataAsync();
  }, []);

  return initResource;
};

export { useInitResource, ACTUAL_APPCONFIG };
