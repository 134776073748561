import { gql } from '@apollo/client';
import { Job } from './jobs';

interface UserAddress {
  name: string;
  street: string;
  city: string;
  province: string;
  state: string;
  zipCode: string;
  phone: string;
}

interface User {
  _id: string;
  email: string;
  username: string;
  basicInfo: {
    name: string;
    addresses: {
      home: UserAddress;
      work: UserAddress;
      other: UserAddress;
    };
  };
  auth: {
    createdAt: string;
    createdWith: string;
    isActive: boolean;
    isEmailVerified: boolean;
    permission: {
      scopes: string[];
    };
    lastLogin: string;
  };
  jobs: Job[];
}

const USER_ME = gql`
  query USER_ME {
    UserMe {
      _id
      email
      username
      basicInfo {
        name
        addresses {
          home {
            name
            street
            city
            province
            state
            zipCode
            phone
          }
          work {
            name
            street
            city
            province
            state
            zipCode
            phone
          }
          other {
            name
            street
            city
            province
            state
            zipCode
            phone
          }
        }
      }
      auth {
        createdAt
        createdWith
        isActive
        isEmailVerified
        permission {
          scopes
        }
        lastLogin
      }
      jobs {
        _id
        shop {
          _id
          name
        }
        creationDate
        delivery {
          type
          address {
            name
            street
            city
            province
            state
            zipCode
          }
          business {
            country
            state
            city
            zipCode
            phoneNumber
            denomination
            street
          }
        }
        status
        lastUpdateDate
        payment {
          type
          status
        }
        finalPrice
        orders {
          _id
          orderKey
          rawProduct {
            name
            type
          }
          userEditing {
            images {
              _id
              quantity
              attachmentUrl
              status
            }
          }
          finalPrice
          status
          quantity
          finalPrice
        }
      }
    }
  }
`;

const CHECK_USER = gql`
  query CHECK_USER($email: String) {
    UserCheckEmailExists(email: $email)
  }
`;

const USER_EDIT_INFO = gql`
  mutation UserBesicInfoEdit($basicInfo: UserBasicInfoInput!) {
    UserBesicInfoEdit(basicInfo: $basicInfo) {
      _id
    }
  }
`;

export { User, UserAddress, USER_ME, CHECK_USER, USER_EDIT_INFO };
