import React, { useState } from 'react';
import { Keyboard, Platform, StyleSheet } from 'react-native';
import { Text, Box, Pressable } from 'native-base';
import { ScrollView } from 'react-native-gesture-handler';
import { logout } from '../../libs/apollo/auth';
import { useMutation, useQuery } from '@apollo/client';
import { Icon, TextBox, Button } from '../../ui';
import { theme } from '../../config';
import { User, USER_ME } from '../../stores/queries/user';
import { ShopInfo } from './ShopInfo';
import { EditBox } from '../../ui/EditBox';
import { useNavigation } from '@react-navigation/core';
import { Promt } from '../../ui/Promt';
import { LinearGradient } from 'expo-linear-gradient';
import { Loading } from '../../ui/Loading';
import { BlurModal } from '../../ui/BlurModal';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { T } from '../../config/localization';
import { shopInstant } from '../../stores/db/appstate';

const UserMenu = () => {
  const { loading, error, data } = useQuery<{ UserMe: User }>(USER_ME, {
    fetchPolicy: 'cache-and-network',
  });

  const navigation = useNavigation<any>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [promtVisible, setPromtVisible] = React.useState(false);

  return (
    <Box
      bg={theme.vars.colors.light[0]}
      flex={1}
      alignItems="center"
      justifyContent="flex-start"
      safeAreaTop
    >
      <Box style={{ width: '100%', maxWidth: 1024 }} flex={1}>
        <Box
          pt={3}
          style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}
        >
          <Text
            style={{
              paddingTop: 10,
              paddingBottom: 3,
              marginBottom: 5,
              fontSize: 24,
              width: '100%',
              textAlign: 'center',
              color: theme.vars.colors.dark[0],
            }}
            bold
          >
            {T('profile_title')}
          </Text>
        </Box>

        <ScrollView
          style={{ flex: 1, width: '100%' }}
          contentContainerStyle={{
            paddingHorizontal: 10,
            paddingBottom: 40,
            //justifyContent: 'center',
            //alignItems: 'center',
          }}
        >
          <EditBox
            /*  onPressEdit={() => {
              setAddress({
                name:
                  data?.UserMe?.basicInfo?.addresses[0]?.name ||
                  data?.UserMe?.basicInfo?.name ||
                  '',
                street: data?.UserMe?.basicInfo?.addresses[0]?.street || '',
                city: data?.UserMe?.basicInfo?.addresses[0]?.city || '',
                province: data?.UserMe?.basicInfo?.addresses[0]?.province || '',
                state: data?.UserMe?.basicInfo?.addresses[0]?.state || '',
                zipCode: data?.UserMe?.basicInfo?.addresses[0]?.zipCode || '',
              });
              setShipAddressModalVisible(true);
            }} */
            style={{ marginBottom: 16 }}
          >
            <Text fontSize="2xl" numberOfLines={1} bold>
              {data?.UserMe?.email}
            </Text>
            <Text fontSize="md" bold>
              @{data?.UserMe?.username}
            </Text>
            {data?.UserMe?.basicInfo?.addresses?.home?.name && (
              <Text fontSize="md">{data.UserMe.basicInfo.addresses.home.name}</Text>
            )}
            {data?.UserMe?.basicInfo?.addresses?.home?.phone && (
              <Text fontSize="md">{data.UserMe.basicInfo.addresses.home.phone}</Text>
            )}
          </EditBox>

          <Pressable
            onPress={() => {
              navigation.navigate('History');
            }}
            flexDirection={'row'}
            p={3}
            alignItems="center"
          >
            <Icon size={22} color={'dark'} name="ordini" />
            <Text color={theme.vars.colors.dark[0]} fontSize="xl" ml={8}>
              {T('my_orders')}
            </Text>
            <Box style={{ flex: 1, alignItems: 'flex-end' }}>
              <Icon size={20} color={'dark'} name="freccia-destra" />
            </Box>
          </Pressable>
          {/* <Pressable flexDirection={'row'} p={3} alignItems="center">
          <Icon size={22} color={'dark'} name="profilo" />
          <Text color={theme.vars.colors.dark[0]} fontSize="xl" ml={8}>
            I miei dati
          </Text>
          <Box style={{ flex: 1, alignItems: 'flex-end' }}>
            <Icon size={20} color={'dark'} name="freccia_destra" />
          </Box>
        </Pressable> */}
          <Pressable
            onPress={() => {
              navigation.navigate('Addresses');
            }}
            flexDirection={'row'}
            p={3}
            alignItems="center"
          >
            <Icon size={22} color={'dark'} name="indirizzi" />
            <Text color={theme.vars.colors.dark[0]} fontSize="xl" ml={8}>
              {T('my_addresses')}
            </Text>
            <Box style={{ flex: 1, alignItems: 'flex-end' }}>
              <Icon size={20} color={'dark'} name="freccia-destra" />
            </Box>
          </Pressable>

          <Pressable
            onPress={() => {
              shopInstant(true);
            }}
            flexDirection={'row'}
            p={3}
            alignItems="center"
          >
            <Icon size={22} color={'dark'} name="layout" />
            <Text color={theme.vars.colors.dark[0]} fontSize="xl" ml={8}>
              {T('instant_order')}
            </Text>
            <Box style={{ flex: 1, alignItems: 'flex-end' }}>
              <Icon size={20} color={'dark'} name="freccia-destra" />
            </Box>
          </Pressable>

          <Pressable
            onPress={() => {
              navigation.navigate('Preferences');
            }}
            flexDirection={'row'}
            p={3}
            alignItems="center"
          >
            <Icon size={22} color={'dark'} name="impostazioni" />
            <Text color={theme.vars.colors.dark[0]} fontSize="xl" ml={8}>
              {T('my_preferences')}
            </Text>
            <Box style={{ flex: 1, alignItems: 'flex-end' }}>
              <Icon size={20} color={'dark'} name="freccia-destra" />
            </Box>
          </Pressable>

          <Pressable
            onPress={() => {
              navigation.navigate('Help');
            }}
            flexDirection={'row'}
            p={3}
            alignItems="center"
          >
            <Icon size={22} color={'dark'} name="info" />
            <Text color={theme.vars.colors.dark[0]} fontSize="xl" ml={8}>
              {T('my_infos')}
            </Text>
            <Box style={{ flex: 1, alignItems: 'flex-end' }}>
              <Icon size={20} color={'dark'} name="freccia-destra" />
            </Box>
          </Pressable>
          <Pressable
            onPress={() => {
              setPromtVisible(true);
            }}
            flexDirection={'row'}
            p={3}
            alignItems="center"
          >
            <Icon size={22} color={'violet'} name="logout" />
            <Text color={theme.vars.colors.violet[0]} fontSize="xl" ml={8}>
              {T('logout')}
            </Text>
          </Pressable>

          <Box m={4}>
            <ShopInfo />
          </Box>
        </ScrollView>

        <Loading visible={isLoading} />
        <Promt
          visible={promtVisible}
          text={T('logout_promt')}
          onYes={() => {
            setPromtVisible(false);
            setIsLoading(true);
            logout().then(() => {
              navigation.navigate('ShopWindow');
              setIsLoading(false);
            });
          }}
          onNo={() => {
            setPromtVisible(false);
          }}
          onCancel={() => {
            setPromtVisible(false);
          }}
        />
      </Box>

      <LinearGradient
        colors={['rgba(255,255,255,0)', theme.vars.colors.light[0]]}
        style={{
          height: 40,
          width: '100%',
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
        }}
        pointerEvents={'none'}
      />
    </Box>
  );
};

const styles = StyleSheet.create({
  view: {
    flex: 1,
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  infoView: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  btnView: {
    width: '100%',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingBottom: 15,
  },
  loginBtn: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.vars.colors.dark[0],
    paddingVertical: 10,
    paddingHorizontal: 20,
    width: '100%',
    maxWidth: 336,
    height: 50,
    borderRadius: 99,
    marginVertical: 5,
  },
  loginBtnImg: {
    height: 25,
    width: 25,
    marginRight: 10,
    resizeMode: 'contain',
  },
  loginBtnTxt: {
    marginTop: 4,
    textAlign: 'center',
    fontSize: 20,
  },
  upperFormText: {
    width: '100%',
    textAlign: 'center',
    paddingLeft: 10,
    paddingRight: 10,
    margin: 5,
    color: '#fff',
  },
  textShadow: {
    textShadowColor: 'rgba(0, 0, 0, 0.5)',
    textShadowOffset: { width: -1, height: 1 },
    textShadowRadius: 3,
  },
});

export { UserMenu };
