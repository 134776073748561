import * as React from 'react';
import { useQuery } from '@apollo/client';
import { APP_STATE, APP_STATE_PROPS } from '../../stores/queries/appstate';
import { UserMenu } from '../../components/Profile/UserMenu';
import { LoginForm } from '../../components/Profile/LoginForm';
import { Box, Pressable, ScrollView, Text } from 'native-base';
import { ShopInfo } from '../../components/Profile/ShopInfo';
import { LinearGradient } from 'expo-linear-gradient';
import { theme } from '../../config';
import { Platform } from 'react-native';

const Profile = ({ navigation }: any) => {
  const { data: APP } = useQuery<APP_STATE_PROPS>(APP_STATE);

  if (APP?.userLogged) {
    return <UserMenu />;
  } else {
    return (
      <Box bg={theme.vars.colors.light[0]} flex={1} flexDirection="column">
        <ScrollView
          flex={1}
          contentContainerStyle={{ paddingBottom: 30 }}
          showsVerticalScrollIndicator={false}
        >
          <Box width={'100%'} p={4}>
            <LoginForm />
            <ShopInfo min={true} />
          </Box>
        </ScrollView>
        <LinearGradient
          colors={['rgba(255,255,255,0)', theme.vars.colors.light[0]]}
          style={{
            height: 40,
            width: '100%',
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 0,
          }}
          pointerEvents={'none'}
        />
      </Box>
    );
  }
};

export { Profile };
