import { Platform, View, ViewStyle } from 'react-native';
import * as FileSystem from 'expo-file-system';
import * as ImageManipulator from 'expo-image-manipulator';

import { LOG } from '../../config';
import { DB_ORDERS, LocalImage, Order } from '../../stores/db/orders';
import { UserEditingPage } from '../../stores/queries/jobs';
import { Product, ProductTree } from '../../stores/queries/shop';
import { FlipType } from 'expo-image-manipulator';

// costanti
const SAFE_M = 1;
const DPI = 300;

const log = LOG.extend('UTILS');

const roundPrice = (value: number): number => {
  if (!value) return 0;
  //@ts-ignore
  return +(Math.round(value + 'e+2') + 'e-2');
};

// calcolo numero immagini e stampe di un ordine
const calcOrderQuantity = (order: Order) => {
  let photo = 0;
  let print = 0;

  if (order.rawProduct && isFixedPagesProduct(order.rawProduct)) {
    photo = order.rawProduct.wizards.length * order.quantity;
    print = order.quantity;
  } else {
    for (let i = 0; i < order.images.length; i++) {
      let image = order.images[i];
      print = print + (image?.quantity || 1);
      photo++;
    }
  }

  return { photo, print };
};

// calcolo prezzo delle prodotto
const calcProductPrice = (
  product: Product,
  quantity: number,
  pages: UserEditingPage[]
): {
  price: number; // Prezzo completo prodotto per la quantità
  unitPrice: number; // Prezzo unitario del prodotto
  variantsPrice: number; // Prezzo unitario solo varianti
  basePrice: number; // Prezzo Unitario solo prodotto
  expiration: number; // Timestamp scadenza promozione
} => {
  let prices = product?.prices;

  if (!prices || !product.prices.quantityDiscounts || product.prices.quantityDiscounts.length < 1) {
    log.error('Calcolo prezzo senza dati', prices);
    return { price: 0, unitPrice: 0, variantsPrice: 0, basePrice: 0, expiration: 0 };
  }

  let expiration = 0;
  let basePrice = 0;

  let q = quantity || 1;
  let now = new Date().getTime();

  // ciclo tutte le fasce di prezzo
  for (let i = 0; i < prices.quantityDiscounts.length; i++) {
    const priceRow = prices.quantityDiscounts[i];

    // cerco la fascia di prezzo
    if (q >= priceRow.quantityFrom && q <= priceRow.quantityTo) {
      // controllo se e' impostata un'offerta a tempo
      if (priceRow.timeDiscount.from && priceRow.timeDiscount.to) {
        let from = new Date(priceRow.timeDiscount.from).getTime();
        let to = new Date(priceRow.timeDiscount.to).getTime();

        if (now > from && now < to) {
          // prezzo da promozione
          basePrice = priceRow.timeDiscount.newPrice;
          // imposto scadenza prezzo in offerta
          expiration = to;
        } else {
          // prezzo non in promozione
          basePrice = priceRow.newPrice;
        }
      } else {
        // offerta a tempo non presente
        basePrice = priceRow.newPrice;
      }
      break;
    }
  }

  if (!basePrice) {
    return { price: 0, unitPrice: 0, variantsPrice: 0, basePrice: 0, expiration: 0 };
  }

  // calcolo prezzo varianti
  let variantsPrice = 0;
  if (pages && pages.length > 0) {
    for (let i = 0; i < pages.length; i++) {
      let variant = pages[i];
      let percPrice = parseFloat(variant?.choice?.percentualPriceIncrement as any);
      if (percPrice) {
        variantsPrice = variantsPrice + (basePrice / 100) * percPrice;
      }
    }
  }

  let unitPrice = roundPrice(basePrice + variantsPrice);
  let price = roundPrice(unitPrice * q);

  basePrice = roundPrice(basePrice);
  variantsPrice = roundPrice(variantsPrice);

  // Aggiungo costo fisso
  let fixedPrice = 0;
  if (prices.basePrice && (!prices.basePriceMaxQnt || prices.basePriceMaxQnt > q)) {
    fixedPrice = roundPrice(prices.basePrice);
  }
  price = price + fixedPrice;

  return { price, unitPrice, variantsPrice, basePrice, expiration };
};

// calcolo attuale prezzo minimo del prodotto
const calcProductMinPrice = (
  product: Product
): {
  minPrice: number;
  expiration: number; // Timestamp scadenza promozione
  discount: number;
  grossPrice: number;
} => {
  let prices = product?.prices;
  let now = new Date().getTime();

  if (!prices || !product.prices.quantityDiscounts || product.prices.quantityDiscounts.length < 1) {
    return { minPrice: 0, expiration: 0, discount: 0, grossPrice: 0 };
  }

  let minPrice = 0;
  let expiration = 0;
  let discount = null;
  let grossPrice = null;

  // ciclo tutte le fasce di prezzo per tyrovare in minimo ad oggi
  for (let i = 0; i < prices.quantityDiscounts.length; i++) {
    const priceRow = prices.quantityDiscounts[i];
    if (!priceRow?.newPrice) continue;

    if (!minPrice || priceRow.newPrice < minPrice) {
      minPrice = priceRow.newPrice;
      expiration = 0;
    }

    if (
      priceRow.timeDiscount?.newPrice &&
      priceRow.timeDiscount?.to &&
      priceRow.timeDiscount.newPrice < minPrice &&
      new Date(priceRow.timeDiscount.to).getTime() > now
    ) {
      minPrice = priceRow.timeDiscount.newPrice;
      expiration = new Date(priceRow.timeDiscount.to).getTime();
      grossPrice = roundPrice(priceRow.newPrice);
      discount = (100 - (priceRow.timeDiscount.newPrice / priceRow.newPrice) * 100).toFixed(0);
    }
  }

  minPrice = roundPrice(minPrice);

  return { minPrice, expiration, discount, grossPrice };
};

const calcOrderPrice = (order: Order) => {
  let { print } = calcOrderQuantity(order);
  let { price, expiration } = calcProductPrice(order.rawProduct, print, order.pages);
  return { price, expiration, print };
};

// prende valore in millimetri e lo strasofrma in pixel in base ai DPI
const mmToPixel = (mm: number, dpi: number, safe?: number): number => {
  return mm && dpi ? Math.ceil(((mm + (safe || 0)) / 25.4) * dpi) : 0;
};

// calcolo dimensione immagine in pixel
const calcImageSize = (image: Product['wizards'][0]['sizes']): Product['wizards'][0]['sizes'] => {
  return {
    width: mmToPixel(image?.width, DPI, SAFE_M * 2),
    height: mmToPixel(image?.height, DPI, SAFE_M * 2),
  };
};

// calcolo dimensione in pixel anteprima
const calcImageDim = (sizes: Product['wizards'][0]['sizes']): { width: number; height: number } => {
  if (!sizes?.width || !sizes?.height) {
    return {
      height: 250,
      width: 250,
    };
  }

  let w = sizes.width;
  let h = sizes.height;

  if (w > h) {
    let width = 300 * 0.8;
    return {
      width,
      height: (width * sizes.height) / sizes.width,
    };
  } else {
    let height = 300 * 0.8;
    return {
      height,
      width: (height * sizes.width) / sizes.height,
    };
  }
};

// calcolo bordi in pixel anteprima
const calcImageBorders = (
  sizes: Product['wizards'][0]['sizes'],
  borders: Product['wizards'][0]['borders'],
  dim: { height: number; width: number }
): ViewStyle => {
  if (!borders || !sizes?.height || !sizes?.width || !dim?.height || !dim?.width) return null;

  let w = sizes.width;
  let h = sizes.height;

  let bColor = borders?.bottom?.color || '#fff';
  let bSize = borders?.bottom?.size || 0;
  let tColor = borders?.top?.color || '#fff';
  let tSize = borders?.top?.size || 0;
  let lColor = borders?.left?.color || '#fff';
  let lSize = borders?.left?.size || 0;
  let rColor = borders?.right?.color || '#fff';
  let rSize = borders?.right?.size || 0;

  return {
    borderBottomColor: bColor,
    borderBottomWidth: bSize ? (bSize * dim.height) / h : 0,
    borderTopColor: tColor,
    borderTopWidth: tSize ? (tSize * dim.height) / h : 0,
    borderLeftColor: lColor,
    borderLeftWidth: lSize ? (lSize * dim.width) / w : 0,
    borderRightColor: rColor,
    borderRightWidth: rSize ? (rSize * dim.width) / w : 0,
  };
};

// calcolo spessore in pixel anteprima
const calcImageThickness = (
  thickness: number,
  size: number,
  dim: { height: number; width: number }
): number => {
  let w = size;

  if (!w) return null;

  let rSize = thickness || 0;

  return rSize ? (rSize * dim.width) / w - 4 : 0;
};

const elaborateImage = async (
  actualImage: LocalImage,
  sizes: Product['wizards'][0]['sizes']
): Promise<{ attachmentKey: string; attachment: string }> => {
  let { width, height } = calcImageSize(sizes);

  let resizedH;
  let resizedW;
  //let isLowRes = false;

  let refDim = height < width ? height : width;

  if (actualImage.height < actualImage.width) {
    if (actualImage.height > refDim) {
      resizedH = refDim;
    } else {
      /* if (actualImage.height < refDim * 0.8) {
        isLowRes = true;
      } */
      resizedH = actualImage.height;
    }
  } else {
    if (actualImage.width > refDim) {
      resizedW = refDim;
    } else {
      /*  if (actualImage.width < refDim * 0.8) {
        isLowRes = true;
      } */
      resizedW = actualImage.width;
    }
  }

  let rotate = 0;
  let flip = undefined;

  if (
    (sizes.width > sizes.height && actualImage.height > actualImage.width) ||
    (sizes.height > sizes.width && actualImage.width > actualImage.height)
  ) {
    rotate = 90;
    flip = FlipType.Vertical;
  }

  let manipResult = await ImageManipulator.manipulateAsync(
    actualImage.path,
    [{ resize: { width: resizedW, height: resizedH } }, { rotate }],
    { compress: 0.8, format: ImageManipulator.SaveFormat.JPEG, base64: true }
  );
  let image = {
    path: manipResult.base64,
    width: manipResult.width,
    height: manipResult.height,
  };

  if (Platform.OS !== 'web') {
    await FileSystem.deleteAsync(manipResult.uri);
  }

  image.path = 'data:image/gif;base64,' + image.path;

  let savedAttachment = await DB_ORDERS.saveAttachment(image.path, actualImage.attachment);

  return savedAttachment;
};

const getRandomInt = (base = 100) => {
  return Math.floor(Math.random() * base);
};

const isFixedPagesProduct = (product: Product): boolean => {
  let type = product.type;

  switch (type) {
    case 'pictureCreative':
    case 'pictureStandard':
      return false;
    case 'canvas':
    case 'gadget':
    case 'panel':
      return true;
    default:
      return false;
  }
};

const getWebImageDimensions = async (file: string): Promise<{ height: number; width: number }> => {
  return new Promise(function (resolved, rejected) {
    var i = new Image();
    i.onload = function () {
      resolved({ width: i.width, height: i.height });
    };
    i.src = file;
  });
};

const destructureTree = (tree: ProductTree) => {
  let title: string;
  let desc: string;
  let color: any;
  let image: string;
  let grouped: boolean;
  let price: number;
  let discount: number;
  let gross: number;

  switch (tree.type) {
    case 'folder':
      title = tree.name?.toUpperCase();
      desc = tree.description;
      color = tree.style?.color;
      image = tree.picture?.url;
      grouped = tree.style?.display === 'list_horizontal' ? true : false;
      break;
    case 'product':
      title = tree.product?.name;
      desc = tree.product?.descriptions?.short;
      color = tree.style?.color;
      image = tree.product?.images?.gallery[0]?.url;
      let { minPrice, discount: discountPerc, grossPrice } = calcProductMinPrice(tree.product);
      discount = discountPerc;
      gross = grossPrice;
      price = minPrice;
      grouped = false;
      break;
  }

  return { title, desc, color, image, grouped, price, discount, gross };
};

export {
  roundPrice,
  calcOrderQuantity,
  calcProductPrice,
  calcProductMinPrice,
  calcOrderPrice,
  calcImageSize,
  calcImageDim,
  calcImageBorders,
  calcImageThickness,
  elaborateImage,
  getRandomInt,
  isFixedPagesProduct,
  getWebImageDimensions,
  destructureTree,
  DPI,
};
