import Constants from 'expo-constants';
import { Platform } from 'react-native';

const deviceInfo = {
  system: Platform.OS + ' ' + Constants.deviceName,
  app: {
    version: '1.0.14',
    name: 'EasyPixy',
    editor: '1.0.1',
  },
};

export { deviceInfo };
