import React, { ReactNode } from 'react';
import { Pressable, Box } from 'native-base';

import { theme } from '../../config';
import { Colors, Sizes } from '../../config/theme';
import { Platform, ViewStyle } from 'react-native';

interface ButtonProps {
  children: ReactNode;
  half?: boolean;
  color?: Colors;
  shadow?: Sizes;
  boxStyle?: ViewStyle;
  style?: ViewStyle;
  onPress?: Function;
  disabled?: boolean;
}

const Button = (props: ButtonProps) => {
  let containerStyle: ViewStyle = {
    borderRadius: 999,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    height: 46,
    width: props.half ? 130 : 260,
    margin: 5,
  };

  let color = theme.vars.colors.violet;

  if (props.color && theme.vars.colors[props.color]) {
    color = theme.vars.colors[props.color];
  }

  let bg =
    props.color === 'light'
      ? '#fff'
      : {
          linearGradient: {
            colors: [color[0], color[1]],
            start: [0.1, 1.8],
            end: [0.01, 0.4],
          },
        };

  return (
    <Pressable
      // @ts-ignore
      cursor={Platform.OS == 'web' ? 'pointer' : undefined}
      style={[
        containerStyle,
        props.shadow
          ? {
              ...theme.vars.shadow[props.shadow],
              shadowColor: props.color === 'light' ? theme.vars.colors.dark[0] : color[0],
            }
          : undefined,
        props.style,
        { opacity: props.disabled ? 0.4 : 1 },
      ]}
      _pressed={{
        opacity: 0.7,
      }}
      onPress={() => {
        if (!props.disabled) {
          props.onPress && props.onPress();
        }
      }}
    >
      <Box
        bg={bg}
        flex={1}
        _text={{
          textAlign: 'center',
          fontSize: 'sm',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          color: props.color === 'light' ? theme.vars.colors.dark[0] : theme.vars.colors.light[0],
        }}
        style={[
          {
            justifyContent: 'center',
            alignItems: 'center',
            height: 46,
            width: props.half ? 130 : 260,
            borderRadius: 23,
            flex: 1,
            padding: 10,
          },
          props.boxStyle,
        ]}
      >
        {props.children}
      </Box>
    </Pressable>
  );
};

export { Button };
