import React, { useEffect } from 'react';
import { Platform, ViewStyle } from 'react-native';
import { Text, Box, Pressable } from 'native-base';
import { BLURHASH, theme } from '../../config';
import { Icon } from '../../ui';
import { Image } from '../../ui/Image';

interface PromoProps {
  color?: string;
  style?: ViewStyle;
  onPress?: Function;
  price?: number;
  image?: string;
  title?: string;
  desc?: string;
}

const PromoItem = (props: PromoProps) => {
  let color =
    props.color && props.color !== 'light' && theme.vars.colors[props.color]
      ? theme.vars.colors[props.color]
      : theme.vars.colors.violet;
  let bg: any = theme.vars.colors.violet[2];

  bg = {
    linearGradient: {
      colors: [color[1], color[2]],
      start: [0.3, 3],
      end: [0.01, 0.04],
    },
  };

  let width: number | string = theme.vars.screen.width * 0.82;
  if (width > 350) width = '100%';

  return (
    <Pressable
      //@ts-ignore
      cursor={Platform.OS == 'web' ? 'pointer' : undefined}
      style={{
        borderRadius: 20,
        alignItems: 'center',
        justifyContent: 'center',
        width,
        height: 100,
        marginHorizontal: width === '100%' ? 0 : 10,
        marginVertical: 20,
        ...theme.vars.shadow['xs'],
        shadowColor: color[1],
        shadowRadius: 5,
      }}
      _pressed={{
        opacity: 0.9,
      }}
      onPress={() => {
        props.onPress && props.onPress();
      }}
      flexDirection={'row'}
    >
      <Box
        bg={bg}
        style={{
          flex: 1,
          width: '100%',
          height: '100%',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          flexDirection: 'row',
          borderRadius: 20,
        }}
      >
        <Image
          blurhash={BLURHASH[props.color || 'light']}
          style={{
            width: '28%',
            minWidth: 100,
            height: '100%',
            borderTopLeftRadius: 20,
            borderBottomLeftRadius: 20,
          }}
          source={{
            uri: props.image,
          }}
        />
        <Box
          style={{
            flex: 1,
            paddingHorizontal: 12,
            paddingVertical: 12,
          }}
          flexDirection={'column'}
        >
          <Text
            color={theme.vars.colors.dark[0]}
            numberOfLines={1}
            //style={{ textTransform: 'uppercase' }}
            textAlign={'left'}
            fontSize={'xl'}
            bold
          >
            {props.title}
          </Text>
          <Text
            mt={'6px'}
            color={theme.vars.colors.dark[0]}
            width={'75%'}
            numberOfLines={2}
            //style={{ textTransform: 'uppercase' }}
            textAlign={'left'}
            fontSize={'lg'}
            lineHeight={18}
            bold
          >
            {props.desc}
          </Text>
        </Box>

        <Box
          style={{
            borderTopLeftRadius: 20,
            borderBottomRightRadius: 20,
            paddingHorizontal: 20,
            paddingVertical: 8,
            position: 'absolute',
            right: 0,
            bottom: 0,
            backgroundColor: '#fff',
          }}
        >
          <Icon size={18} name="stella" color={(props.color as any) || 'violet'} />
        </Box>
      </Box>
    </Pressable>
  );
};

export { PromoItem };
