import React, { useEffect } from 'react';
import { Platform, useWindowDimensions } from 'react-native';
import { Text, Box, ScrollView } from 'native-base';
import { theme } from '../../config';
import { Product, Page } from '../../stores/queries/shop';
import { Folder } from '../../components';
import { UserEditingPage } from '../../stores/queries/jobs';
import { roundPrice } from '../../libs/utils';
import { T } from '../../config/localization';
import { useDebounce } from '../../hooks/helpers';

interface ProductPageProps {
  navigation: any;
  route: {
    params: {
      product: Product;
      pageIndex: number;
      selectedVariants: UserEditingPage[];
    };
  };
}

const ProductPage = (props: ProductPageProps) => {
  const dimensions = useWindowDimensions();

  const pages: Product['pages'] = props.route.params.product.pages;
  const page: Page = pages[props.route.params.pageIndex];

  const { debounce } = useDebounce();

  useEffect(() => {
    props.navigation.setOptions({
      headerTitle: () => (
        <Box alignItems={Platform.OS === 'android' ? 'flex-start' : 'center'}>
          <Text fontSize="xl" bold>
            {page?.name || 'Variante'}
          </Text>
          {/*  <Text fontSize="xs">
            {T('variant')} {props.route.params.pageIndex + 1 + ' di ' + pages.length}
          </Text> */}
        </Box>
      ),
    });
  }, []);

  let basePrice = 0;
  let prices = props?.route?.params?.product?.prices?.quantityDiscounts;
  if (prices && prices[0]?.newPrice) {
    basePrice = prices[0]?.newPrice;
  }

  return (
    <Box bg={theme.vars.colors.light[0]} flex={1}>
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ flex: 1 }}
        contentContainerStyle={{
          width: dimensions.width,
          flexWrap: 'wrap',
          paddingBottom: 40,
          flexDirection: dimensions.width <= theme.vars.screen.sm ? 'column' : 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {page.choices.map((variant: Page['choices'][0], index: number) => (
          <Folder
            key={index}
            type="folder"
            title={variant.name}
            image={variant.picture?.url}
            priceIncrement={
              basePrice && variant.percentualPriceIncrement
                ? roundPrice((basePrice / 100) * variant.percentualPriceIncrement)
                : undefined
            }
            onPress={() => {
              let selectedVariants: UserEditingPage[] = [];
              if (props.route.params.selectedVariants) {
                selectedVariants = props.route.params.selectedVariants;
              }
              if (props.route.params.pageIndex || props.route.params.pageIndex === 0) {
                selectedVariants[props.route.params.pageIndex] = {
                  name: page.name,
                  choice: {
                    name: variant.name,
                    percentualPriceIncrement: variant.percentualPriceIncrement,
                  },
                };
              }

              if (pages.length - 1 > props.route.params.pageIndex) {
                debounce(() => {
                  props.navigation.push('ProductPage', {
                    product: props.route.params.product,
                    pageIndex: props.route.params.pageIndex + 1,
                    selectedVariants,
                  });
                });
              } else {
                props.navigation.navigate('PhotoSelect', {
                  product: props.route.params.product,
                  selectedVariants,
                });
              }
            }}
          />
        ))}
      </ScrollView>
    </Box>
  );
};

export { ProductPage };
