import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { View, StyleSheet, ViewStyle } from 'react-native';
import { ENV } from '../../config';

interface EditorEvent {
  event: string;
  data: any;
}

interface EditorProps {
  style?: ViewStyle;
  editorEventDispatcher?: (data: EditorEvent) => void;
  onLoadEnd?: () => void;
  onError?: (e: any) => void;
  onLoadStart?: () => void;
}

const Editor = forwardRef((props: EditorProps, ref) => {
  const EditorRef = useRef(null);

  const messageHandler = (message: any): void => {
    try {
      var event = JSON.parse(message.data);
    } catch (error) {
      return null;
    }
    if (props.editorEventDispatcher && event) {
      props.editorEventDispatcher(event);
    }
  };

  useEffect(() => {
    window.addEventListener('message', messageHandler);
    /* EditorRef.current.onload = () => {
      let msg = {
        event: 'loaded',
        data: null,
      };
      EditorRef.current && EditorRef.current.contentWindow.postMessage(msg, '*');
    }; */
    return () => {
      window.removeEventListener('message', messageHandler);
    };
  }, []);

  const editorMessage = (message: EditorEvent) => {
    if (!message) return false;
    let msg = {
      event: message.event,
      data: message.data ? JSON.parse(JSON.stringify(message.data)) : null,
    };
    EditorRef.current && EditorRef.current.contentWindow.postMessage(msg, '*');
  };

  const editorMessageStr = (message: EditorEvent) => {
    EditorRef.current.contentWindow.postMessage(message, '*');
  };

  useImperativeHandle(ref, () => {
    return {
      editorMessage,
      editorMessageStr,
    };
  });

  return (
    <View style={[styles.container, props.style]}>
      <iframe
        ref={EditorRef}
        onLoadStart={() => {
          props.onLoadStart && props.onLoadStart();
        }}
        onLoad={() => {
          props.onLoadEnd && props.onLoadEnd();
        }}
        style={{ flex: 1, width: '100%', height: '100%', border: 0 }}
        id="editorFrame"
        src={ENV.editor.uri}
      ></iframe>
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'darkcyan',
    flex: 1,
  },
  editorFrame: {
    width: '100%',
    minHeight: '100%',
    flex: 1,
    borderWidth: 0,
  },
});

export { Editor };
