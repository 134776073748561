import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { FlatList, useWindowDimensions, Platform, BackHandler, Pressable } from 'react-native';
import { StyleSheet, View, TouchableOpacity, ScrollView } from 'react-native';
import { Text, Box, Image, Center, Spinner } from 'native-base';
import { useKeepAwake } from 'expo-keep-awake';

import { Product } from '../../stores/queries/shop';
import { Button, Icon } from '../../ui';
import { LOG, theme } from '../../config';
import { PhotoItem } from '../../components/Editor/PhotoItem';
import { DB_ORDERS, generateAttachmentKey, LocalImage, Order } from '../../stores/db/orders';
import { activeOrder } from '../../stores/db/appstate';
import {
  calcImageBorders,
  calcImageDim,
  calcImageThickness,
  calcProductPrice,
  calcOrderQuantity,
  elaborateImage,
  getWebImageDimensions,
  isFixedPagesProduct,
  DPI,
} from '../../libs/utils';
import { LinearGradient } from 'expo-linear-gradient';
import { UserEditingPage } from '../../stores/queries/jobs';
import { Promt } from '../../ui/Promt';
import { Loading } from '../../ui/Loading';
import { useFocusEffect } from '@react-navigation/native';

import { ProgressBar } from '../../ui/ProgressBar';
import { PhotoPicker_Next } from '../../components/Editor/PhotoPicker_Next';
import { T } from '../../config/localization';
import { PQueue } from '../../libs/p-queue';

const log = LOG.extend('PHOTOSELECT');
const PROCESS = new PQueue({ concurrency: 4, autoStart: true });

interface QuantitySelectorProps {
  setFixedQuantity: Function;
  fixedQuantity: number;
}

const QuantitySelector = (props: QuantitySelectorProps) => {
  return (
    <Box flexDirection="row" alignItems={'center'} justifyContent="space-between">
      <Pressable
        onPress={() => {
          if (props.fixedQuantity > 1) {
            let newQuantity = props.fixedQuantity - 1;
            props.setFixedQuantity(newQuantity);
          }
        }}
        style={{
          width: 30,
          height: 30,
          margin: 0,
          padding: 0,
          borderRadius: 999,
          backgroundColor: theme.vars.colors.violet[0],
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Text color={theme.vars.colors.light[0]} fontSize={24} lineHeight={26}>
          -
        </Text>
      </Pressable>
      <Text
        fontSize={18}
        style={{ width: 36, textAlign: 'center' }}
        color={theme.vars.colors.violet[0]}
        bold
      >
        {props.fixedQuantity}
      </Text>
      <Pressable
        onPress={() => {
          let newQuantity = props.fixedQuantity + 1;
          props.setFixedQuantity(newQuantity);
        }}
        style={{
          width: 30,
          height: 30,
          margin: 0,
          padding: 0,
          borderRadius: 999,
          backgroundColor: theme.vars.colors.violet[0],
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Text color={theme.vars.colors.light[0]} fontSize={24} lineHeight={24}>
          +
        </Text>
      </Pressable>
    </Box>
  );
};

interface ImageProcessingBarProps {
  total: number;
}
const ImageProcessingBar = forwardRef((props: ImageProcessingBarProps, ref) => {
  const [progress, setProgress] = useState<number>(1);

  const updateProgress = (progress: number) => {
    setProgress(progress);
  };

  useImperativeHandle(ref, () => {
    return {
      updateProgress,
    };
  });

  return (
    <Box>
      <Center style={{ width: '90%', maxWidth: 500 }} mb={6}>
        <Text color={theme.vars.colors.blue[0]} mt={0} fontSize={18} bold>
          {((progress / props.total) * 100).toFixed(0)} %
        </Text>

        <ProgressBar
          width={300}
          color={theme.vars.colors.blue[0]}
          bg={theme.vars.colors.blue[2]}
          progress={(progress / props.total) * 100}
        />
        <Text color={theme.vars.colors.dark[0]} mb={1}>
          {T('process_picture') + ' ' + progress + ' ' + T('of') + ' ' + props.total}
        </Text>
      </Center>
    </Box>
  );
});

const getElaboratedImage = async (
  image: LocalImage,
  sizes: Order['rawProduct']['wizards'][0]['sizes'],
  index: number
) => {
  console.time('Elaborate Image ' + index);
  let atch = await elaborateImage(image, sizes);
  console.timeEnd('Elaborate Image ' + index);
  return { key: atch.attachmentKey, wizardId: image.wizardId, index };
};

interface PhotoSelectProps {
  navigation: any;
  route: {
    params: {
      product: Product;
      selectedVariants: UserEditingPage[];
      orderKey?: string;
    };
  };
}

const PhotoSelect = (props: PhotoSelectProps) => {
  useKeepAwake();

  const dimensions = useWindowDimensions();
  const [loading, setLoading] = useState(true);

  const [fixedQuantity, setFixedQuantity] = useState(1);
  const [quantity, setQuantity] = useState({
    print: 0,
    photo: 0,
  });
  const [finalPrice, setFinalPrice] = useState(0);
  const [images, setImages] = useState<LocalImage[]>([]);

  const [order, setOrder] = useState<Order>();
  const [orderImages, setOrderImages] = useState<LocalImage[]>([]);

  const [backPromtVisible, setBackPromtVisible] = useState(false);

  const orderKey = useRef<string>(props?.route?.params?.orderKey);
  const currentOrder = useRef(null);
  const prevOrderLoaded = useRef<boolean>(props?.route?.params?.orderKey ? false : true);

  const [imagesInProcessing, setImagesInProcessing] = useState(false);
  const [lastProcessingTime, setLastProcessingTime] = useState(0);

  const processingBarRef = useRef(null);
  const processingImages = useRef<LocalImage[]>([]);
  const processingCount = useRef<number>(0);
  const processingTotal = useRef<number>(0);
  const processingTimeStart = useRef<number>(0);
  const processingTimeEnd = useRef<number>(0);

  const fixedPages = useRef<boolean>(isFixedPagesProduct(props.route.params.product));

  const imgDim = useRef(
    fixedPages.current ? undefined : calcImageDim(props.route.params.product.wizards[0].sizes)
  );
  const imgBorder = useRef(
    fixedPages.current
      ? undefined
      : calcImageBorders(
          props.route.params.product.wizards[0].sizes,
          props.route.params.product.wizards[0].borders,
          imgDim.current
        )
  );
  const imgThickness = useRef(
    fixedPages.current
      ? undefined
      : calcImageThickness(
          props.route.params.product.wizards[0].frame?.thickness?.size,
          props.route.params.product.wizards[0].sizes?.width,
          imgDim.current
        )
  );

  useFocusEffect(
    useCallback(() => {
      const onBackPress = () => {
        if (order?.state === 'temp' || imagesInProcessing) {
          setBackPromtVisible(true);
        } else {
          props.navigation.goBack();
        }
        return true;
      };

      BackHandler.addEventListener('hardwareBackPress', onBackPress);

      return () => BackHandler.removeEventListener('hardwareBackPress', onBackPress);
    }, [order?.state])
  );

  useEffect(() => {
    if (orderKey.current) {
      DB_ORDERS.getOrder(orderKey.current).then(prevOrder => {
        if (prevOrder) {
          orderKey.current = prevOrder.key;
          currentOrder.current = prevOrder;
          setOrderImages(prevOrder.images);
        }

        setTimeout(() => {
          setOrder(currentOrder.current);
          setLoading(false);
        }, 100);
      });
    } else {
      if (fixedPages.current) {
        let fixedImages: LocalImage[] = [];
        for (let i = 0; i < props.route.params.product.wizards.length; i++) {
          const wizard = props.route.params.product.wizards[i];
          fixedImages.push({
            label: wizard.label || 'Fronte',
            quantity: 1,
            wizardId: wizard._id,
            placeholder: true,
            dpi: DPI,
          });
        }
        addFixedImages(fixedImages).then(tOrderImages => {
          setOrderImages(tOrderImages);
          let { price, expiration } = calcProductPrice(
            props.route.params.product,
            1,
            props.route.params.selectedVariants
          );
          setFinalPrice(price);
          let newOrder: Order = {
            state: 'temp',
            finalPrice: price,
            priceExpiration: expiration,
            pages: props.route.params.selectedVariants,
            images: tOrderImages,
            rawProduct: props.route.params.product,
            createdAt: new Date().getTime(),
            quantity: 1,
          };
          setOrder(newOrder);
          setLoading(false);
        });
      } else {
        setLoading(false);
      }
    }

    if (!fixedPages.current) {
      PROCESS.on('completed', (props: { key: string; wizardId: string; index: string }) => {
        let { key, wizardId, index } = props;

        processingCount.current = processingCount.current + 1;
        processingBarRef.current &&
          processingBarRef.current.updateProgress(processingCount.current);

        processingImages.current[index] = { attachment: key, quantity: 1, wizardId };
      });

      PROCESS.on('idle', () => {
        //setOrderImages(processingImages.current);

        setOrderImages([...processingImages.current]);

        if (!orderKey.current) {
          let quantity = processingImages.current?.length || 0;

          let { price, expiration } = calcProductPrice(
            props.route.params.product,
            quantity,
            props.route.params.selectedVariants
          );

          let newOrder: Order = {
            state: 'temp',
            finalPrice: price,
            priceExpiration: expiration,
            pages: props.route.params.selectedVariants,
            images: processingImages.current,
            rawProduct: props.route.params.product,
            createdAt: new Date().getTime(),
            quantity: 1,
          };

          setOrder(newOrder);
          log.debug('Create new Order');
        } else {
          setOrder({ ...currentOrder.current, images: processingImages.current });
        }

        processingImages.current = [];

        processingTimeEnd.current = new Date().getTime();
        let processingTime = processingTimeEnd.current - processingTimeStart.current || 1000;
        setLastProcessingTime(processingTime / 1000);
        setImagesInProcessing(false);
        log.debug(`Queue End, processing time: ${processingTime}`);
      });
    }

    return () => {
      PROCESS.clear();
      PROCESS.removeAllListeners();
    };
  }, []);

  useEffect(() => {
    if (images && images.length > 0 && !imagesInProcessing) {
      processingTotal.current = images.length;
      setImagesInProcessing(true);
      log.info('Processing new images: ' + images.length);
      setTimeout(() => {
        addImages(images);
      }, 300);
    }
  }, [images]);

  useEffect(() => {
    if (order?.key && !prevOrderLoaded.current && order?.images?.length > 0) {
      prevOrderLoaded.current = true;
      if (order?.finalPrice) {
        setFinalPrice(order.finalPrice);
      }
      let { photo, print } = calcOrderQuantity(order);
      setQuantity({ photo, print });
      setFixedQuantity(order.quantity || 1);
    } else {
      if (order?.finalPrice) {
        setFinalPrice(order?.finalPrice);
      }
      if (order?.images && order.images.length > 0) {
        let { photo, print } = calcOrderQuantity(order);
        setQuantity({ photo, print });

        if (order?.key) {
          let { price, expiration } = calcProductPrice(
            props.route.params.product,
            print,
            props.route.params.selectedVariants
          );
          let newOrder: Order = { ...order, finalPrice: price, priceExpiration: expiration };
          setFinalPrice(price);
          DB_ORDERS.updateOrder(newOrder).then(updatedOrder => {
            log.info('New Order updated: ' + updatedOrder.key);
          });
        } else {
          log.info('Save new Order');
          DB_ORDERS.saveOrder(order).then(newOrder => {
            setOrder(newOrder);
            currentOrder.current = newOrder;
            orderKey.current = newOrder.key;
            log.info('New Order saved in ' + newOrder.state + ': ' + newOrder.key);
          });
        }
      } else if (order) {
        setQuantity({ photo: 0, print: 0 });
        if (order?.key) {
          log.info('Remove Order: ' + order.key);
          DB_ORDERS.removeOrder(order.key);
        }
        setOrder(null);
        currentOrder.current = null;
        orderKey.current = null;
      }
    }
  }, [order]);

  useEffect(() => {
    if (order?.key && order?.images?.length > 0) {
      activeOrder(order?.key);
    } else {
      activeOrder(null);
    }
  }, [order?.images, order?.key]);

  useEffect(() => {
    if (order?.key && fixedQuantity) {
      let newOrder = { ...order, quantity: fixedQuantity };
      setOrder(newOrder);
    }
  }, [fixedQuantity]);

  const addImages = async (newImages: LocalImage[]) => {
    processingCount.current = 0;
    processingTimeStart.current = new Date().getTime();
    processingImages.current = order?.images ? [...order.images] : [];

    let startIndex = processingImages.current.length ? processingImages.current.length : 0;

    for (let i = 0; i < newImages.length; i++) {
      let key = generateAttachmentKey(i);

      let dimensions = {
        height: newImages[i].height,
        width: newImages[i].width,
      };

      if (Platform.OS === 'web' && (!dimensions.height || !dimensions.width)) {
        dimensions = await getWebImageDimensions(newImages[i].path);
      }

      let tempImage = {
        attachment: key,
        quantity: 1,
        path: newImages[i].path,
        height: dimensions.height,
        width: dimensions.width,
        waiting: true,
        wizardId: props.route.params.product.wizards[0]._id,
        dpi: DPI,
      };

      processingImages.current.push(tempImage);
      PROCESS.add(() =>
        getElaboratedImage(tempImage, props.route.params.product.wizards[0].sizes, i + startIndex)
      );
    }
    setOrderImages([...processingImages.current]);
  };

  const addFixedImages = async (newImages: LocalImage[]) => {
    let tOrderImages: LocalImage[] = [];

    for (let index = 0; index < newImages.length; index++) {
      let key = generateAttachmentKey(index);

      let tempImage: Order['images'][0] = {
        label: newImages[index].label,
        attachment: key,
        quantity: 1,
        wizardId: newImages[index].wizardId,
        placeholder: newImages[index].placeholder,
        dpi: DPI,
      };

      tOrderImages.push(tempImage);
    }

    return tOrderImages;
  };

  const deleteImage = async (index: number) => {
    let tempImages = order?.images ? [...order?.images] : [];
    let tempOrderImages = orderImages ? [...orderImages] : [];
    tempOrderImages.splice(index, 1);
    await DB_ORDERS.removeAttachment(tempImages[index].attachment);
    tempImages.splice(index, 1);
    let newOrder = { ...order, images: tempImages };
    setOrder(newOrder);
    setOrderImages(tempOrderImages);
  };

  /*   const updateAttachment = async (index: number, attachmentKey: string, attachment: string) => {
    log.debug('updateAttachment of image ' + index + ': ' + attachmentKey);
    let orderImages = order?.images ? [...order?.images] : [];
    await DB_ORDERS.updateAttachment(attachmentKey, attachment);
    let newOrder = { ...order, images: orderImages };
    await updateOrder(newOrder);
  }; */

  const updateImage = async (index: number, image: LocalImage) => {
    log.info('UPDATE IMAGE:', image);
    let orderImages = order?.images ? [...order?.images] : [];
    image.placeholder = false;
    orderImages[index] = image;
    let newOrder = { ...order, images: orderImages };
    setOrder(newOrder);
  };

  const updateOrder = async (newOrder: Order) => {
    setOrder(newOrder);
  };

  const renderImage =
    /* useCallback( */
    ({ item, index }) => (
      <PhotoItem
        key={item.attachment}
        type={props.route.params.product.type}
        deleteImage={deleteImage}
        updateImage={updateImage}
        fixedPage={fixedPages.current}
        image={item}
        wizard={props.route.params.product.wizards[fixedPages.current ? index : 0]}
        imgBorder={
          fixedPages.current
            ? calcImageBorders(
                props.route.params.product.wizards[index].sizes,
                props.route.params.product.wizards[index].borders,
                calcImageDim(props.route.params.product.wizards[index].sizes)
              )
            : imgBorder.current
        }
        imgDim={
          fixedPages.current
            ? calcImageDim(props.route.params.product.wizards[index].sizes)
            : imgDim.current
        }
        imgThickness={
          fixedPages.current
            ? calcImageThickness(
                props.route.params.product.wizards[index].frame?.thickness?.size,
                props.route.params.product.wizards[index].sizes?.width,
                calcImageDim(props.route.params.product.wizards[index].sizes)
              )
            : imgThickness.current
        }
        index={index}
      />
    ); /* ,
    []
  ) */

  const renderTopBar = () => {
    if (!fixedPages.current) {
      if (order && order?.images?.length > 0) {
        return renderTopBarMultiple();
      } else {
        return null;
      }
    } else {
      return renderTopBarFixed();
    }
  };

  const renderTopBarMultiple = () => (
    <Box width={'94%'} justifyContent="space-between" alignItems="center" flexDirection="row">
      <Box
        rounded={20}
        flex={1}
        bg={theme.vars.colors.violet[2]}
        alignItems="center"
        justifyContent="space-around"
        flexDirection="row"
        //width="94%"
        p={2}
        mt={2}
        mr={4}
      >
        <Box flexDirection="row" alignItems="center" justifyContent="center">
          <Icon name="sostituisci-foto" size={20} color={'violet'} />
          <Box flexDirection="column" alignItems="flex-start" justifyContent="center" pl={2}>
            <Text lineHeight={20} color={theme.vars.colors.violet[0]} fontSize="md">
              {quantity.photo}
            </Text>
            <Text
              style={{ textTransform: 'capitalize' }}
              lineHeight={14}
              color={theme.vars.colors.violet[0]}
              fontSize="xs"
            >
              {T('pictures')}
            </Text>
          </Box>
        </Box>
        <Box flexDirection="row" alignItems="center" justifyContent="center" px={2}>
          <Icon name="stampante" size={20} color={'violet'} />
          <Box flexDirection="column" alignItems="flex-start" justifyContent="center" pl={2}>
            <Text lineHeight={20} color={theme.vars.colors.violet[0]} fontSize="md">
              {quantity.print || quantity.photo}
            </Text>
            <Text
              style={{ textTransform: 'capitalize' }}
              lineHeight={14}
              color={theme.vars.colors.violet[0]}
              fontSize="xs"
            >
              {T('prints')}
            </Text>
          </Box>
        </Box>
        <Box flexDirection="row" alignItems="center" justifyContent="center">
          <Icon name="costo-spedizione" size={20} color={'violet'} />
          <Box flexDirection="column" alignItems="flex-start" justifyContent="center" pl={2}>
            <Text lineHeight={20} color={theme.vars.colors.violet[0]} fontSize="md">
              {finalPrice.toFixed(2)}
            </Text>
            <Text lineHeight={14} color={theme.vars.colors.violet[0]} fontSize="xs">
              Euro
            </Text>
          </Box>
        </Box>
      </Box>
      <Box py={1} mt={2}>
        {quantity.photo >= 500 ? (
          <Box
            bg={theme.vars.colors.red[1]}
            style={[
              {
                justifyContent: 'center',
                alignItems: 'center',
                height: 46,
                width: 46,
                borderRadius: 23,
              },
            ]}
          >
            <Text color={theme.vars.colors.light[0]} bold>
              MAX
            </Text>
          </Box>
        ) : (
          <>
            {imagesInProcessing ? (
              <Box
                bg={theme.vars.colors.violet[1]}
                style={[
                  {
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 46,
                    width: 46,
                    borderRadius: 23,
                  },
                ]}
              >
                <Spinner
                  size={'sm'}
                  color={theme.vars.colors.light[0]}
                  accessibilityLabel="Processing"
                />
              </Box>
            ) : (
              <PhotoPicker_Next
                isOpen
                setImages={setImages}
                totalImages={quantity.photo}
                onStart={() => {
                  setLoading(true);
                }}
                onFinish={() => {
                  setLoading(false);
                }}
              />
            )}
          </>
        )}
      </Box>
    </Box>
  );

  const renderTopBarFixed = () => (
    <Box width={'94%'} justifyContent="space-between" alignItems="center" flexDirection="row">
      <Box
        rounded={20}
        flex={1}
        bg={theme.vars.colors.violet[2]}
        alignItems="center"
        justifyContent="space-around"
        flexDirection="row"
        //width="94%"
        p={2}
        mt={2}
      >
        <QuantitySelector fixedQuantity={fixedQuantity} setFixedQuantity={setFixedQuantity} />
        <Box flexDirection="row" alignItems="center" justifyContent="center">
          <Icon name="costo-spedizione" size={20} color={'violet'} />
          <Box flexDirection="column" alignItems="flex-start" justifyContent="center" pl={2}>
            <Text lineHeight={20} color={theme.vars.colors.violet[0]} fontSize="md">
              {finalPrice.toFixed(2)}
            </Text>
            <Text lineHeight={14} color={theme.vars.colors.violet[0]} fontSize="xs">
              Euro
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  const renderImageList = () => (
    <>
      {orderImages.length > 0 ? (
        <Box flex={1}>
          {Platform.OS === 'web' ? (
            <ScrollView
              showsVerticalScrollIndicator={false}
              showsHorizontalScrollIndicator={false}
              style={{ flex: 1 }}
              contentContainerStyle={{
                width: dimensions.width,
                flexWrap: 'wrap',
                paddingBottom: 40,
                flexDirection: dimensions.width <= theme.vars.screen.sm ? 'column' : 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {orderImages.map((image: LocalImage, index: number) => {
                return renderImage({ item: image, index });
              })}
            </ScrollView>
          ) : (
            <FlatList
              //columnWrapperStyle={{ flexWrap: 'wrap' }}
              //numColumns={6}
              //getItemLayout={(data, index) => ({ length: 310, offset: 310 * index, index })}
              showsVerticalScrollIndicator={false}
              showsHorizontalScrollIndicator={false}
              contentContainerStyle={{
                width: '100%',
                paddingBottom: 40,
                //flexDirection: dimensions.width <= theme.vars.screen.sm ? 'column' : 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              data={orderImages}
              renderItem={renderImage}
            />
          )}

          <LinearGradient
            colors={['rgba(255,255,255,0)', theme.vars.colors.light[0]]}
            style={{
              height: 40,
              width: '100%',
              position: 'absolute',
              left: 0,
              right: 0,
              bottom: 0,
            }}
            pointerEvents={'none'}
          />
        </Box>
      ) : (
        <>
          <Box style={{ width: '100%', flex: 1 }} justifyContent={'center'} alignItems={'center'}>
            <Text
              width={250}
              mb={2}
              color={theme.vars.colors.dark[0]}
              fontSize={'3xl'}
              textAlign={'center'}
              lineHeight={30}
              bold
            >
              {T('select_pictures_title')}
            </Text>
            <Text
              width={200}
              color={theme.vars.colors.dark[0]}
              textAlign={'center'}
              lineHeight={20}
              fontSize={'lg'}
            >
              {T('select_pictures_hint')}
            </Text>
            {props.route.params.product?.prices?.quantityDiscounts[0]?.quantityFrom > 1 && (
              <Text
                width={200}
                color={theme.vars.colors.dark[0]}
                textAlign={'center'}
                style={{ width: '50%' }}
                bold
              >
                {T('min_prints')}{' '}
                {props.route.params.product?.prices?.quantityDiscounts[0]?.quantityFrom} stampe
              </Text>
            )}

            <Image
              style={{
                width: '70%',
                maxWidth: 280,
                height: '30%',
                resizeMode: 'contain',
                marginBottom: 0,
              }}
              source={require('../../assets/img/illustrations/upload-foto.png')}
              alt="logo"
            />
            <Center mt={2} flexDirection={'row'}>
              <PhotoPicker_Next
                isOpen
                style={{ marginHorizontal: 8 }}
                setImages={setImages}
                totalImages={quantity.photo}
                onStart={() => {
                  setLoading(true);
                }}
                onFinish={() => {
                  setLoading(false);
                }}
              />
            </Center>
          </Box>
        </>
      )}
    </>
  );

  const isFixedImagesSetted = () => {
    let setted = true;
    if (order?.images) {
      for (let index = 0; index < order.images.length; index++) {
        const image = order.images[index];
        if (image.placeholder) {
          setted = false;
          break;
        }
      }
    }
    return setted;
  };

  /* const renderImageListFixed = () => (
    <ScrollView
      showsVerticalScrollIndicator={false}
      showsHorizontalScrollIndicator={false}
      style={{ flex: 1 }}
      contentContainerStyle={{
        width: dimensions.width,
        flexWrap: 'wrap',
        paddingBottom: 40,
        flexDirection: dimensions.width <= theme.vars.screen.sm ? 'column' : 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Text>CIAOOO</Text>
      <PhotoItem
        key={'image_fixed'}
        type={props.route.params.product.type}
        deleteImage={deleteImage}
        updateImage={updateImage}
        image={{ width: 300, height: 200, quantity: 1 }}
        wizard={props.route.params.product.wizard}
        imgBorder={imgBorder}
        imgDim={imgDim}
        imgThickness={imgThickness}
        index={0}
      />
    </ScrollView>
  ); */

  return (
    <View style={style.container}>
      <Box
        safeAreaTop
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        pb={2}
        pt={2}
        width="100%"
      >
        <Box
          width={'100%'}
          alignItems={'flex-start'}
          justifyContent={'center'}
          flexDirection={'row'}
          style={{ paddingVertical: 5 }}
        >
          <TouchableOpacity
            style={{
              marginLeft: 8,
              backgroundColor: theme.vars.colors.red[2],
              borderRadius: 999,
              height: 34,
              width: 34,
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onPress={() => {
              if (order?.state === 'temp' || imagesInProcessing) {
                setBackPromtVisible(true);
              } else {
                props.navigation.goBack();
              }
            }}
          >
            <Icon color={'red'} name={'freccia-sinistra'} size={15} />
          </TouchableOpacity>
          <Box
            style={{
              marginLeft: -42,
              paddingHorizontal: 8,
            }}
            flex={1}
            alignItems={
              dimensions.width > theme.vars.screen.sm || Platform.OS === 'android'
                ? 'flex-start'
                : 'center'
            }
            pointerEvents="none"
          >
            <Text
              numberOfLines={1}
              ellipsizeMode="tail"
              lineHeight={20}
              fontSize="xl"
              style={{ marginHorizontal: 42, marginTop: 10 }}
              bold
            >
              {props.route.params.product.name}
            </Text>
            {/* <Text
              numberOfLines={1}
              ellipsizeMode="tail"
              lineHeight={14}
              style={{
                color: theme.vars.colors.dark[0],
                fontSize: 12,
                marginHorizontal: 50,
              }}
            >
              {props.route.params.selectedVariants?.map((page, index) => {
                return page.name + ': ' + page.choice?.name + ', ';
              })}
              {!props?.route?.params?.selectedVariants ||
              props.route.params.selectedVariants.length < 1
                ? T('no_variants')
                : ''}
            </Text> */}
          </Box>
        </Box>

        {renderTopBar()}
      </Box>

      {imagesInProcessing ? (
        <Box style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
          <Image
            style={{
              width: '70%',
              maxWidth: 280,
              height: 260,
              resizeMode: 'contain',
              marginBottom: 0,
            }}
            source={require('../../assets/img/illustrations/upload-foto.png')}
            alt="logo"
          />
          <Text color={theme.vars.colors.grey[0]} mb={0} fontSize={'lg'} bold>
            {T('prosessing_pictures')}
          </Text>
          <Text color={theme.vars.colors.grey[0]} mb={6} fontSize={'md'}>
            {T('processing_pictures_hint')}
          </Text>
        </Box>
      ) : (
        renderImageList()
      )}

      <Center safeAreaBottom style={{ marginBottom: 5 }}>
        <Center style={{ width: '100%' }}>
          {!fixedPages.current &&
          orderImages.length > 0 &&
          !imagesInProcessing &&
          props.route.params.product.prices?.quantityDiscounts[0]?.quantityFrom &&
          quantity.print < props.route.params.product.prices.quantityDiscounts[0].quantityFrom ? (
            <Box
              style={{
                marginTop: 0,
                marginBottom: 0,
                width: '100%',
                padding: 5,
              }}
            >
              <Text
                style={{ textAlign: 'center', width: '100%' }}
                color={theme.vars.colors.dark[0]}
                bold
              >
                {T('add_others')}{' '}
                {props.route.params.product.prices.quantityDiscounts[0].quantityFrom -
                  quantity.print}{' '}
                {T('prints_to_go')}
              </Text>
            </Box>
          ) : null}

          {fixedPages.current && !isFixedImagesSetted() ? (
            <Box
              style={{
                marginTop: 0,
                marginBottom: 0,
                width: '100%',
                padding: 5,
              }}
            >
              <Text
                style={{ textAlign: 'center', width: '100%' }}
                color={theme.vars.colors.dark[0]}
                bold
              >
                {T('select_per_page')}
              </Text>
            </Box>
          ) : null}

          {/* {!imagesInProcessing && lastProcessingTime ? (
            <Box
              style={{
                marginTop: 5,
                marginBottom: 5,
                width: '100%',
              }}
            >
              <Text
                style={{ textAlign: 'center', width: '100%' }}
                color={theme.vars.colors.dark[0]}
                bold
              >
                Ultima elaborazione foto: {lastProcessingTime.toFixed(0)} secondi
              </Text>
            </Box>
          ) : null} */}

          {!(fixedPages.current && !isFixedImagesSetted()) &&
          !imagesInProcessing &&
          order?.images?.length > 0 ? (
            <Box
              width="100%"
              paddingX={4}
              flexDirection="row"
              alignItems={'center'}
              justifyContent={
                order.state === 'temp' || order.state === 'project' ? 'space-between' : 'center'
              }
              pb={2}
            >
              {order.state === 'temp' || order.state === 'project' ? (
                <Button
                  color="blue"
                  shadow="md"
                  half
                  onPress={() => {
                    activeOrder(null);

                    let newOrder: Order = {
                      ...order,
                      state: 'project',
                    };
                    updateOrder(newOrder).then(() => {
                      props.navigation.navigate('Home', {
                        screen: 'Projects',
                        initial: false,
                      });
                    });
                  }}
                >
                  {T('save')}
                </Button>
              ) : null}
              <Button
                color="violet"
                shadow="md"
                half={order.state === 'temp' || order.state === 'project' ? true : false}
                disabled={
                  props.route.params.product.prices?.quantityDiscounts[0]?.quantityFrom &&
                  quantity.print <
                    props.route.params.product.prices.quantityDiscounts[0].quantityFrom
                    ? true
                    : false
                }
                onPress={() => {
                  activeOrder(null);

                  if (order.state === 'cart') {
                    props.navigation.navigate('Home', {
                      screen: 'Cart',
                      initial: false,
                    });
                  } else {
                    let newOrder: Order = {
                      ...order,
                      state: 'cart',
                    };
                    updateOrder(newOrder).then(() => {
                      props.navigation.navigate('Home', {
                        screen: 'Cart',
                        initial: false,
                      });
                    });
                  }
                }}
              >
                {T('to_cart')}
              </Button>
            </Box>
          ) : (
            <>
              {imagesInProcessing ? (
                <ImageProcessingBar ref={processingBarRef} total={processingTotal.current} />
              ) : null}
            </>
          )}
        </Center>
      </Center>
      <Loading
        bg={theme.vars.colors.light[0]}
        text="Caricamento Foto"
        /* hint={
          images.length > 50
            ? "Stiamo pocessando molte foto,\nl'operazione potrebbe durare alcuni minuti..."
            : undefined
        } */
        visible={loading}
      />

      <Promt
        visible={backPromtVisible}
        text={T('save_work_promt')}
        subText={T('save_work_hint')}
        onYes={() => {
          let newOrder: Order = {
            ...order,
            state: 'project',
          };
          activeOrder(null);
          updateOrder(newOrder).then(() => {
            props.navigation.navigate('Home', {
              screen: 'Projects',
              initial: false,
            });
          });
        }}
        onNo={() => {
          setBackPromtVisible(false);
          if (order?.key) {
            DB_ORDERS.removeOrder(order?.key);
          }
          activeOrder(null);
          props.navigation.goBack();
        }}
        onCancel={() => setBackPromtVisible(false)}
        noText={T('no') + ', ' + T('delete')}
        yesText={T('yes')}
      />
    </View>
  );
};

export { PhotoSelect };

const style = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: theme.vars.colors.light[0],
  },
});
